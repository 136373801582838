/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import cookie from "react-cookie"
import axios from "axios"
import Moment from "react-moment"
import DocumentMeta from "react-document-meta"
import getResult from "./Api"
import Loading from "./loading"

import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
const APPID = config.ids.appId
var currency = config.ids.currency
var helper = require("./Helper.js")
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParser = new HtmlToReactParser()
var strp = require("strp")
var monthNamesArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

/* Load meta tags */
const meta = {
  title: "My Reservation",
  description: "My Reservation",
  meta: {
    name: {
      keywords: "My Reservation",
    },
  },
}

class Reservation extends React.Component {
  constructor(props) {
    /*add class */
    helper.addBodyClass()

    super(props)
    this.state = { credit: [], debit: [], status: "Loading", windowHeightNormal: 0, windowHeight: 0, points: 0, creditLimit: 5, debitLimit: 5 }

    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    helper.isAnonymousUserRedirect()

    axios.all([axios.get(APIURL + "/customer/customerdetail?app_id=" + APPID + "&refrence=" + cookie.load("UserId"))]).then(
      axios.spread((customer) => {
        this.setState({ status: "ok" })

        if (customer.data.status === "ok") {
          this.setState({ points: customer.data.result_set.customer_reward_point })
        }
      })
    )

    this.loadData()
  }

  loadData(cLimit = null, dLimit = null) {
    var limitOne = cLimit !== null ? cLimit : this.state.creditLimit
    var limitTwo = dLimit !== null ? dLimit : this.state.debitLimit
    axios
      .all([
        axios.get(APIURL + "reports/reservation_orderhistory_details?app_id=" + APPID + "&customer_id=" + cookie.load("UserId") + "&limit=" + limitOne),
        axios.get(APIURL + "reports/reservation_orderhistory_details?statustxt=5&app_id=" + APPID + "&customer_id=" + cookie.load("UserId") + "&limit=" + limitTwo),
      ])
      .then(
        axios.spread((credit, debit) => {
          this.setState({ status: "ok" })
          setTimeout(
            function () {
              this.loadData()
            }.bind(this),
            10000
          )
          if (credit.data.status === "ok") {
            this.setState({ credit: credit.data.result_set })
          }

          if (debit.data.status === "ok") {
            this.setState({ debit: debit.data.result_set })
          }
        })
      )
  }

  componentWillMount() {
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })
  }

  /* Change earend records */
  handleChange(
    event //handleChangereedem
  ) {
    console.log(event.target.value)
    this.setState({ creditLimit: event.target.value })
    this.loadData(event.target.value, null)
  }

  /* Change redeem records */
  handleChangeRedeem(
    event //handleChangereedem
  ) {
    console.log(event.target.value)
    this.setState({ debitLimit: event.target.value })
    this.loadData(null, event.target.value)
  }

  getTblName(tblnme, status) {
    if (tblnme !== "") {
      var clsName = ""
      if (status === "6") {
        clsName = "text-yellow"
      }
      return (
        <tr>
          <td>Table No</td>
          <td>:</td>
          <td>
            <span className={clsName}>{tblnme}</span>
          </td>
        </tr>
      )
    }
  }

  getSpecification(msgtxt, status) {
    if (msgtxt !== "") {
      var clsName = ""
      if (status === "6") {
        clsName = "text-yellow"
      }
      return (
        <tr>
          <td>Message</td>
          <td>:</td>
          <td>
            <span className={clsName}>{msgtxt}</span>
          </td>
        </tr>
      )
    }
  }

  chgDatefrmt(datetxt) {
    if (datetxt !== "") {
      datetxt = datetxt.replace(" ", "T")
      var caldatesplt = new Date(datetxt)
      var calRsResDatepkidVal = ("0" + caldatesplt.getDate()).slice(-2) + " " + monthNamesArr[caldatesplt.getMonth()] + " " + caldatesplt.getFullYear()
      return calRsResDatepkidVal
    }
  }

  getActionBtn(status, sugPrmyId) {
    if (status === "6") {
      return (
        <div>
          <div className="btn-wp">
            <a className="btn-accept active" style={{ cursor: "pointer" }} onClick={this.acceptRejectFun.bind(this, sugPrmyId, "accept")}>
              Accept
            </a>
          </div>
          <div>
            <a className="btn-reject" style={{ cursor: "pointer" }} onClick={this.acceptRejectFun.bind(this, sugPrmyId, "reject")}>
              Reject
            </a>
          </div>
        </div>
      )
    }
  }

  acceptRejectFun(sugPrmyId, Actiontxt) {
    document.getElementById("resvloadIcon").style.display = "block"

    if (typeof sugPrmyId !== "undefined" && sugPrmyId !== "" && Actiontxt === "accept") {
      var qs = require("qs")
      var postObject = {
        app_id: APPID,
        sug_reser_id: sugPrmyId,
      }
      axios.post(APIURL + "reservation/reservation_suggest_accept", qs.stringify(postObject)).then((response) => {
        document.getElementById("resvloadIcon").style.display = "none"
        /* Success response */
        if (response.data.status === "success") {
          var errorMsg = "<h2>Thank you</h2> Order has been placed successfully."
          helper.showInfo(errorMsg)
          this.loadData()
        }

        /* error response */
        if (response.data.status === "error") {
          if (response.data.already_assigned === "1") {
            var errorMsg = "<h2>Sorry</h2> Your Reservation Already Expired.<br> You can make new reservation."
          } else {
            var errorMsg = "<h2>Thank you</h2> Reservation has been Cancelled successfully."
          }

          helper.showInfo(errorMsg)
          this.loadData()
        }
      })
    }
    if (typeof sugPrmyId !== "undefined" && sugPrmyId !== "" && Actiontxt === "reject") {
      var qs = require("qs")
      var postObject = {
        app_id: APPID,
        sug_reser_id: sugPrmyId,
      }
      axios.post(APIURL + "reservation/reservation_suggest_reject", qs.stringify(postObject)).then((response) => {
        document.getElementById("resvloadIcon").style.display = "none"
        /* Success response */
        if (response.data.status === "success") {
          var errorMsg = "<h2>Thank you</h2> Reservation has been Cancelled successfully."
          helper.showInfo(errorMsg)
          this.loadData()
        }

        /* Error response */
        if (response.data.status === "error") {
          if (response.data.already_assigned === "1") {
            var errorMsg = "<h2>Sorry</h2> Your Reservation Already Expired.<br> You can make new reservation."
          } else {
            var errorMsg = ""
          }

          helper.showInfo(errorMsg)
          this.loadData()
        }
      })
    }
  }

  showdateFun(datetimestr) {
    var datetimeArr = datetimestr.split(" ")
    var resulttxt = this.tmConvert(datetimeArr[1])
    return resulttxt
  }

  tmConvert(time) {
    time = time.substring(0, time.length - 3)
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
    if (time.length > 1) {
      time = time.slice(1)
      time[5] = +time[0] < 12 ? " am" : " pm"
      time[0] = +time[0] % 12 || 12
    }
    return time.join("")
  }

  loadCartImage() {
    return getResult("cartImage", "Yes")
  }

  getstatusText(status) {
    var statusTxt = "Pending"
    if (status === "1") {
      statusTxt = "Assigned"
    } else if (status === "2") {
      statusTxt = "Cancel"
    } else if (status === "3") {
      statusTxt = "Arrived"
    } else if (status === "4") {
      statusTxt = "Not Arrived"
    } else if (status === "5") {
      statusTxt = "Completed"
    }
    return statusTxt
  }

  getOrderStatusfun(statustxt) {
    var pendingcls = "completed"
    var completedcls = ""

    if (statustxt === "1" || statustxt === "2" || statustxt === "5") {
      completedcls = "completed"
    }

    var statusText = "Confirmed"
    if (statustxt === "2") {
      statusText = "Canceled"
    }

    return (
      <ul>
        <li className={pendingcls}>
          {" "}
          <span>1</span> Pending
        </li>
        <li className={completedcls}>
          {" "}
          <span>2</span> {statusText}
        </li>
      </ul>
    )
  }

  getStatusCls(status) {
    var clsName = ""
    if (status === "6") {
      clsName = "text-yellow"
    }
    return clsName
  }

  getNoofPax(adult, child) {
    var adultchild = ""
    if (adult !== "" && adult !== "0") {
      var camatxt = child !== "" && child !== "0" ? ", " : " "
      var Adultxt = parseInt(adult) > 1 ? adult + " Adults" : adult + " Adult"
      adultchild = Adultxt + camatxt
    }
    if (child !== "" && child !== "0") {
      var childtxt = parseInt(child) > 1 ? child + " Children" : child + " Child"
      adultchild = adultchild + " " + childtxt
    }
    return adultchild
  }

  /* this function used to load reservation orders */
  reservationOrder() {
    if (this.state.credit.length > 0) {
      return this.state.credit.map((cData, index) => (
        <div className="reserve-orders-item order_list_reservation" key={index}>
          <div className="reserve-od-inner">
            <div className="reserve-order-title">
              <div className="title-odr-left">
                {cData.reservation_status === "6" ? <span className="btn-suggest-ylw">Suggested</span> : ""}
                <h4>
                  Confirmation ID : <span>{cData.reservation_local_order_id}</span>
                </h4>
              </div>
              <span className="date-time">
                <Moment format="DD/MM/YYYY">{cData.reservation_created_on}</Moment>, {this.showdateFun(cData.reservation_created_on)}
              </span>
            </div>

            <div className="row">
              <div className="left-column-odr">
                <div className="reserve-order-cnt">
                  <table>
                    <tbody>
                      <tr>
                        <td>No of Pax</td>
                        <td>:</td>
                        <td>
                          <span className={this.getStatusCls(cData.reservation_status)}>{this.getNoofPax(cData.reservation_no_of_adult_pax, cData.reservation_no_of_children_pax)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>:</td>
                        <td>
                          <span className={this.getStatusCls(cData.reservation_status)}>
                            {cData.reservation_outlet_name}, {cData.outlet_address_line1}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>:</td>
                        <td>
                          <span className={this.getStatusCls(cData.reservation_status)}>
                            {this.chgDatefrmt(cData.reservation_date)}, {this.tmConvert(cData.reservation_start_time)}
                          </span>
                        </td>
                      </tr>
                      {this.getTblName(cData.table_name_txt, cData.reservation_status)}
                      {this.getSpecification(cData.reservation_specification, cData.reservation_status)}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="right-column-odr">{this.getActionBtn(cData.reservation_status, cData.sug_primary_id)}</div>
            </div>
          </div>

          <div className="ols-product-status">{this.getOrderStatusfun(cData.reservation_status)}</div>
        </div>
      ))
    } else {
      return <div className="order_list_rewards"> No records found. </div>
    }
  }

  /* this function used to load reservation orders */
  completedReservationOrder() {
    if (this.state.debit.length > 0) {
      return this.state.debit.map((dData, index) => (
        <div className="reserve-orders-item order_list_reservation" key={index}>
          <div className="reserve-od-inner">
            <div className="reserve-order-title">
              <div className="title-odr-left">
                {dData.reservation_status === "6" ? <span className="btn-suggest-ylw">Suggested</span> : ""}
                <h4>
                  Confirmation ID : <span>{dData.reservation_local_order_id}</span>
                </h4>
              </div>
              <span className="date-time">
                <Moment format="DD/MM/YYYY">{dData.reservation_created_on}</Moment>, {this.showdateFun(dData.reservation_created_on)}
              </span>
            </div>

            <div className="row">
              <div className="left-column-odr">
                <div className="reserve-order-cnt">
                  <table>
                    <tbody>
                      <tr>
                        <td>No of Pax</td>
                        <td>:</td>
                        <td>
                          <span className={this.getStatusCls(dData.reservation_status)}>{this.getNoofPax(dData.reservation_no_of_adult_pax, dData.reservation_no_of_children_pax)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>:</td>
                        <td>
                          <span className={this.getStatusCls(dData.reservation_status)}>
                            {dData.reservation_outlet_name}, {dData.outlet_address_line1}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>:</td>
                        <td>
                          <span className={this.getStatusCls(dData.reservation_status)}>
                            {this.chgDatefrmt(dData.reservation_date)}, {this.tmConvert(dData.reservation_start_time)}
                          </span>
                        </td>
                      </tr>
                      {this.getTblName(dData.table_name_txt, dData.reservation_status)}
                      {this.getSpecification(dData.reservation_specification, dData.reservation_status)}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="right-column-odr">{this.getActionBtn(dData.reservation_status, dData.sug_primary_id)}</div>
            </div>
          </div>

          <div className="ols-product-status">{this.getOrderStatusfun(dData.reservation_status)}</div>
        </div>
      ))
    } else {
      return <div className="order_list_rewards"> No records found. </div>
    }
  }
  componentDidMount() {
    loadAnalytics()

    var promise = getResult("settingsPro", "No")
    promise.then((res) => {
      if (!helper.disableMenu(res.data.result_set, "myaccount_reservation")) {
        hashHistory.push("/")
        return false
      }
    })
  }
  render() {
    if (this.state.status === "Loading") {
      return (
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row"></div>
          <div className="row">
            <div className="space-10 bg-gray"></div>
          </div>
          <div className=" ">
            <div className="col-xs-10 div-center">
              <div className="space-5"></div>
              <Loading />
              <div className="space-5"></div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-5"></div>
          <div className="space-5"></div>
          <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div>
        </div>
      )
    } else {
      return (
        <div>
          <DocumentMeta {...meta} />
          <div className="container-fluid desktop-container">
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20  head-child">
                  {" "}
                  <Link to={"/my-account"}>
                    <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                  </Link>
                </div>
                <div className="col-xs-6 nav-head   head-child">
                  <h1 className="main-title">Reservations</h1>
                </div>
                <div className="col-xs-3 nopadding   head-child">
                  <p className="b-txt text-right ">
                    <Link to={"/cart"} className={helper.cartClass()}>
                      {helper.cartCount()} <img alt="" src={this.loadCartImage()} className="icon-img-small-1" />{" "}
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="reserve-orders-tab">
              <div className="tabbable-panel">
                <div className="tabbable-line">
                  <div id="resvloadIcon" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 99, background: "rgba(255, 255, 255, 0.8)" }}>
                    {" "}
                    <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" />{" "}
                  </div>

                  <ul className="nav nav-tabs ">
                    <li className="active">
                      <a href="#tab_default_1" data-toggle="tab">
                        Current Orders{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#tab_default_2" data-toggle="tab">
                        PAST Orders{" "}
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane fade in active" id="tab_default_1">
                      <div className="pagination-rewards">
                        <span className="show_pgn">Show</span>
                        <div className="pagination_number">
                          <select value={this.state.creditLimit} onChange={this.handleChange.bind(this)}>
                            {" "}
                            <option value="5"> 5</option> <option value="10"> 10 </option> <option value="25"> 25 </option> <option value="50"> 50 </option>{" "}
                          </select>
                        </div>
                        <span>Per Page</span>
                      </div>

                      {this.reservationOrder()}
                    </div>

                    <div className="tab-pane fade" id="tab_default_2">
                      <div className="pagination-rewards">
                        <span className="show_pgn">Show</span>
                        <div className="pagination_number">
                          <select value={this.state.debitLimitLimit} onChange={this.handleChangeRedeem.bind(this)}>
                            {" "}
                            <option value="5"> 5 </option> <option value="10"> 10 </option> <option value="25"> 25 </option> <option value="50"> 50 </option>{" "}
                          </select>
                        </div>
                        <span>Per Page</span>
                      </div>

                      {this.completedReservationOrder()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}
export default Reservation
