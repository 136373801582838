import React from "react"
import { Link } from "react-router"
import cookie from "react-cookie"
import axios from "axios"

import config from "./config"
var HtmlToReactParser = require("html-to-react").Parser

const APPID = config.ids.appId
const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
var qs = require("qs")
var htmlToReactParser = new HtmlToReactParser()

class Alert extends React.Component {
  state = {
    successoff: null,
  }
  componentWillMount() {
    // cookie.remove('alertSuccess')
    //cookie.remove('alertError')

    setTimeout(function () {
      if (document.getElementById("AlertBox")) {
        document.getElementById("AlertBox").style.display = "none"
      }
      cookie.remove("alertSuccess")
      cookie.remove("alertError")
    }, 2000)
  }
  componentDidMount() {
    axios.get(APIURL + "settings/checkPaymentStatus?app_id=" + APPID).then((res) => {
      if (Object.values(res.data) == "fail" && typeof Object.values(res.data) != "undefined" && Object.values(res.data) != "") {
        this.setState({ successoff: 1 })
      }
    })
  }
  loadofflinepopup() {
    if (this.state.successoff == 1) {
      return htmlToReactParser.parse(
        '<div className="modal" style="display: block;z-index: 1000000;" id="offilepopoup"><div className="modal-dialog PopupWidth"><div className="modal-content"><div className="modal-body"><br /><img src="img/plug.png"	<br /><h2>Site Is Offline</h2><span>Please Contact the merchant to place order directly</span><br></div><div className="modal-footer"></div></div></div></div>'
      )
    } else {
      return true
    }
  }
  removeAlert() {
    if (document.getElementById("AlertBox")) {
      document.getElementById("AlertBox").style.display = "none"
      cookie.remove("alertSuccess")
      cookie.remove("alertError")
    }
  }
  render() {
    /* Success message */
    if (typeof cookie.load("alertSuccess") !== "undefined" && cookie.load("alertSuccess") !== "") {
      return (
        <div className="alert-box" style={{ display: "block" }} id="AlertBox">
          <p className="success-msg alert-msg">
            {cookie.load("alertSuccess")}
            <Link style={{ cusror: "pointer" }} onClick={this.removeAlert.bind(null, this)} className="close-icon">
              <i className="fa fa-times-circle-o" aria-hidden="true"></i>
            </Link>
          </p>
        </div>
      )

      /* remove cookie valuese */
    } else if (typeof cookie.load("alertError") !== "undefined" && cookie.load("alertError") !== "") {
      return (
        <div className="alert-box" style={{ display: "block" }} id="AlertBox">
          <p className="error-msg alert-msg">
            {cookie.load("alertError")}
            <Link style={{ cusror: "pointer" }} onClick={this.removeAlert.bind(null, this)} className="close-icon">
              <i className="fa fa-times-circle-o" aria-hidden="true"></i>
            </Link>
          </p>
        </div>
      )
    } else {
      return <div>{this.loadofflinepopup()} </div>
    }
  }
}

export default Alert
