/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import ThemeProvider from "react-toolbox/lib/ThemeProvider"
import theme from "./react-toolbox/theme.js"
import "./react-toolbox/theme.css"
import DatePicker from "react-toolbox/lib/date_picker/DatePicker"
import DocumentMeta from "react-document-meta"
import cookie from "react-cookie"
import getResult from "./Api"
import ReactDom from "react-dom"
import moment from "moment"
import TimePicker from "rc-time-picker"
import "rc-time-picker/assets/index.css"
import axios from "axios"

import lang from "./lang"
const apps = require("./apps")
import config from "./config"
const APPID = config.ids.appId
const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
const datetime = new Date()
const datetimecal = new Date()
var currentDateValue = datetime.getDate()
var helper = require("./Helper.js")

var CurrentDate = new Date()
var dateFormat = require("dateformat")
var currentDate2 = dateFormat(datetime, "d/m/yyyy")
var currentTime = dateFormat(datetime, "H")
var currentMins = dateFormat(datetime, "MM")
var currentMins3 = dateFormat(datetime, "HH:MM:ss")
var HtmlToReactParser = require("html-to-react").Parser
var tat = cookie.load("orderTAT") === "" ? 0 : cookie.load("orderTAT")
var htmlToReactParser = new HtmlToReactParser()

/* Only apply for chillz india gate */
var defaultDate = datetime
var disabledClassMins = ""
var disabledDays = []
var Product_LeadTime = 0

var newdefaultDate = new Date(new Date(datetime).setDate(currentDateValue + 2))
var min_datetime = new Date(new Date(datetime).setDate(currentDateValue - 1))
var max_datetime = new Date(new Date(datetime).setDate(currentDateValue))
var readOnly = ""
var disabledClass = ""

var weekdayArr = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
/* Load meta tags */
const meta = {
  title: lang.choose_delvery_date,
  description: lang.site_title,
  meta: {
    name: {
      keywords: lang.site_title,
    },
  },
}

const showSecond = false
const str = showSecond ? "HH:mm:ss" : "HH:mm"
const now = moment().hour(14).minute(30)

let vStartTime = ""
let vEndTime = ""
let vDisplayHrs = []
let vDisplayMins = []
var utc = ""
var valueutc = ""
class DeliveryDatePickerNew extends React.Component {
  constructor(props) {
    super(props)

    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    /* validate delivery avilablity */
    if (cookie.load("defaultAvilablityId") === config.ids.dineinId || typeof cookie.load("defaultAvilablityId") === "undefined" || cookie.load("defaultAvilablityId") === "") {
      hashHistory.push("/")
    }

    if (typeof cookie.load("DeliveryDate") === "undefined" || cookie.load("DeliveryDate") === "" || cookie.load("DeliveryDate") === null) {
      var autoFillDate = defaultDate
    } else {
      var cookieDate = cookie.load("DeliveryDate")
      var splitedDate = cookieDate.split("/")
      var selDate = this.pad(splitedDate[0])
      var selMonth = this.pad(splitedDate[1])
      var selYear = this.pad(splitedDate[2])
      autoFillDate = defaultDate
    }

    this.state = {
      date: autoFillDate,
      settings: [],
      selectTime: currentTime,
      selectMins: currentMins,
      minDatecount: 0,
      maxDayscount: 1,
      startTime: 0,
      endTime: 24,
      startMins: 0,
      endMins: 60,
      overWriteMins: "No",
      overWriteTime: "No",
      timeHtml: "",
      minsHtml: "",
      fianlstartTime: 0,
      serverTat: 14,
      timeExceed: "No",
      windowHeight: 0,
      timeSlots: [],
      disableDate: [],
      ststrTimeArr: [],
      ststrSecndArr: [],
      stendTimeArr: [],
      stendSecndArr: [],
      intervalTime: 2,
      itsCurtDay: "",
      allowTimeSlot: "No",
      hollidayList: [],
      allowHoliDay: "No",
      timeandsecArrayset: [],
      TimeSlotHtmlVal: "",
      timeText: "Time (HH/MM)",
    }
    this.changeTimer = this.changeTimer.bind(this) //changeMins
    this.changeMins = this.changeMins.bind(this) //changeMins
    this.getsettings()

    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getResult("referenceId")
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId")
    }
    var postalCodeWhere = ""
    if (typeof cookie.load("orderPostalCode") !== "undefined") {
      postalCodeWhere = "&postal_code=" + cookie.load("orderPostalCode")
    }
    axios.get(APIURL + "cartver2/contents?status=A&app_id=" + APPID + customerParam + postalCodeWhere).then((res) => {
      if (res.data.status === "ok") {
        if (typeof res.data.result_set !== "undefined") {
          Product_LeadTime = parseInt(res.data.result_set.product_lead_time)
          /*var productleadtime = parseInt(res.data.result_set.product_lead_time)-1;
			 if(productleadtime > 0){
				 min_datetime = new Date(new Date(datetime).setDate(currentDateValue+productleadtime));
				 this.setState({date:min_datetime})
			 }*/
        }
      }
    })
  }

  mainDateTimeFun() {
    this.setState({ timeExceed: "No" })

    axios.get(APIURL + "settings/deliverytime_slotminmax/?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + "&outlet_id=" + cookie.load("orderOutletId")).then((res) => {
      if (res.data.status === "ok") {
        if (typeof res.data.result_set !== "undefined") {
          var itsCurtDy = "no"
          var incr_minimum_days = res.data.result_set[0].minimum_date !== "" ? parseInt(res.data.result_set[0].minimum_date) : 0
          var incr_days = Product_LeadTime > incr_minimum_days ? Product_LeadTime : incr_minimum_days
          var incr_days_real = incr_days
          var max_days = res.data.result_set[0].maximum_date !== "" ? parseInt(res.data.result_set[0].maximum_date) : 1
          var cutOfftime = res.data.result_set[0].cut_off !== "" ? parseInt(res.data.result_set[0].cut_off) : 24

          var str_datetime = new Date(new Date(datetime).setDate(currentDateValue + incr_days))

          var crtutc = new Date().toJSON().slice(0, 10).replace(/-/g, "/")
          var sltutc = str_datetime.toJSON().slice(0, 10).replace(/-/g, "/")
          if (crtutc === sltutc) {
            var currentTimeNew = dateFormat(new Date(), "H")
            if (parseInt(currentTimeNew) < parseInt(cutOfftime)) {
              itsCurtDy = "yes"
            } else {
              incr_days = 1
              str_datetime = new Date(new Date(datetime).setDate(currentDateValue + incr_days))
            }
          } else if (parseInt(incr_days) > 0) {
            var currentTimeLst = dateFormat(new Date(), "H")
            if (parseInt(currentTimeLst) >= parseInt(cutOfftime)) {
              incr_days = incr_days + 1
              str_datetime = new Date(new Date(datetime).setDate(currentDateValue + incr_days))
            }
          }

          max_datetime = new Date(new Date(datetime).setDate(currentDateValue + incr_days + max_days))
          min_datetime = new Date(new Date(datetime).setDate(currentDateValue + (incr_days - 1)))

          this.setState({ cutOfftimeTxt: cutOfftime })
          this.setState({ maxDayscount: max_days })
          this.setState({ minDatecount: incr_days_real })
          this.setState({ date: str_datetime })
          this.setState({ itsCurtDay: itsCurtDy })
          var dayText = weekdayArr[str_datetime.getDay()]
          this.getTimesettingArrFun(dayText)

          setInterval(
            function () {
              var currentTimeNew = dateFormat(new Date(), "H")
              var currentMinsNew = dateFormat(new Date(), "MM")
              var tatvalnew = parseInt(this.state.serverTat)
              var dtnowobj = new Date()
              dtnowobj.setMinutes(dtnowobj.getMinutes() + tatvalnew)
              var currentTimetat = dtnowobj.getHours()
              var currentMinstat = dtnowobj.getMinutes()
              if (this.state.itsCurtDay === "yes") {
                if (parseInt(currentTimeNew) >= parseInt(this.state.cutOfftimeTxt)) {
                  this.mainDateTimeFun()
                }

                if (parseInt(this.state.selectTime) < parseInt(currentTimetat)) {
                  this.timeDropDownFunction()
                } else if (parseInt(this.state.selectTime) === parseInt(currentTimetat) && parseInt(this.state.selectMins) < parseInt(currentMinstat)) {
                  this.timeDropDownFunction()
                }
              }
            }.bind(this),
            60000
          )
        } else {
          this.setState({ timeExceed: "Yes" })
        }
      } else {
        this.setState({ timeExceed: "Yes" })
      }

      $(".custom-date-label").removeClass("loader-main-cls")
      $(".custom-date-label").find(".loader-sub-div").remove()
    })
  }

  handleChange = (item, value) => {
    var sltdDatevalue = value
    var des = document.getElementsByClassName("deliverydate")
    var label = des[0].getElementsByTagName("label")
    label[0].style.top = "0px"
    var input = des[0].getElementsByTagName("input")
    var itsCurtDy = "no"
    var utc = new Date().toJSON().slice(0, 10).replace(/-/g, "/")
    var valueutc = value.toJSON().slice(0, 10).replace(/-/g, "/")
    var cutOfftime = this.state.cutOfftimeTxt
    var maxdays = this.state.maxDayscount
    var incrDaysNw = this.state.minDatecount
    var strDatetimeVl = new Date(new Date(datetime).setDate(currentDateValue + incrDaysNw))
    var strDatetimeChk = strDatetimeVl.toJSON().slice(0, 10).replace(/-/g, "/")

    if (valueutc === utc) {
      var currentTimeNew = dateFormat(new Date(), "H")
      if (parseInt(currentTimeNew) < parseInt(cutOfftime)) {
        itsCurtDy = "yes"
      } else {
        var incr_daysincr_days = 1
        var str_datetime = new Date(new Date(datetime).setDate(currentDateValue + incr_daysincr_days))
        max_datetime = new Date(new Date(datetime).setDate(currentDateValue + incr_daysincr_days + maxdays))
        min_datetime = new Date(new Date(datetime).setDate(currentDateValue + (incr_daysincr_days - 1)))

        sltdDatevalue = str_datetime
        helper.showInfo("Sorry shop was closed or disabled by an administrator. Please choose another day.")
      }
    } else if (valueutc === strDatetimeChk) {
      var currentTimeChk = dateFormat(new Date(), "H")
      if (parseInt(currentTimeChk) >= parseInt(cutOfftime)) {
        var incrDaysincrDays = parseInt(incrDaysNw) + 1
        var str_datetime = new Date(new Date(datetime).setDate(currentDateValue + incrDaysincrDays))
        max_datetime = new Date(new Date(datetime).setDate(currentDateValue + incrDaysincrDays + maxdays))
        min_datetime = new Date(new Date(datetime).setDate(currentDateValue + (incrDaysincrDays - 1)))

        sltdDatevalue = str_datetime
        helper.showInfo("Sorry shop was closed or disabled by an administrator. Please choose another day.")
      }
    }

    this.setState({ ...this.state, [item]: sltdDatevalue })
    this.setState({ itsCurtDay: itsCurtDy })
    var dayText = weekdayArr[sltdDatevalue.getDay()]
    this.getTimesettingArrFun(dayText)
  }

  /* Disaple year */
  showTimeslotExceed() {
    if (this.state.timeExceed === "Yes") {
      if (APPID === "97347EE4-2845-4011-BCE6-9C0B806DA437" && (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11" || cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0")) {
        if (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11") {
          return <div className="timeslot_exc">Delivery available only on Saturdays (12- 4) pm</div>
        } else if (cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0") {
          return <div className="timeslot_exc">Takeaway available only on Saturdays (9 am to 2pm)</div>
        }
      } else if (APPID === "729A09D6-2A38-4E1C-ADB1-67A9242D2158" && (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11" || cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0")) {
        if (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11") {
          return <div className="timeslot_exc">Delivery available only on Fri, Sat & Sun</div>
        } else if (cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0") {
          return <div className="timeslot_exc">Pickup available only on Fri, Sat & Sun</div>
        }
      } else {
        return <div className="timeslot_exc"> Note: Please refer our operating hours. The time you are selecting is outside of our delivery timing. Apologies for the inconvenience.</div>
      }
    }
  }

  getTimesettingArrFun(daytxt) {
    axios.get(APIURL + "settings/delivery_time_slot/?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + "&outlet_id=" + cookie.load("orderOutletId") + "&daytxt=" + daytxt).then((tmres) => {
      if (tmres.data.status === "ok") {
        this.setState({ timeExceed: "No" })
        this.setState({ intervalTime: tmres.data.result_set[0].interval_time })
        this.setState({ timeandsecArrayset: tmres.data.result_set[0].slot })
        this.timeDropDownFunction()
      } else {
        this.setState({ intervalTime: 2 })
        this.setState({ timeandsecArrayset: [] })
        this.setState({ timeHtml: "" })
        this.setState({ minsHtml: "" })
        this.setState({ timeExceed: "Yes" })
      }
    })
  }

  get_strtatadded_timeArr(startTimeVal, startMinitVal, endTimeVal, endMinitVal) {
    var result_Arr = [],
      strtimetxt = "",
      endtimetxtval = ""
    var currentTimeNew = dateFormat(new Date(), "H")
    var currentMinsNew = dateFormat(new Date(), "MM")
    var tatvalnew = parseInt(this.state.serverTat)
    var dtnowobj = new Date()
    dtnowobj.setMinutes(dtnowobj.getMinutes() + tatvalnew)
    var currentTimetat = dtnowobj.getHours()
    var currentMinstat = dtnowobj.getMinutes()

    if (this.state.itsCurtDay === "yes") {
      if (parseInt(startTimeVal) <= parseInt(currentTimetat)) {
        if (parseInt(startTimeVal) === parseInt(endTimeVal) && parseInt(currentTimetat) === parseInt(endTimeVal) && parseInt(endMinitVal) < parseInt(currentMinstat)) {
          strtimetxt = ""
        } else {
          strtimetxt = currentTimetat
        }
      } else {
        strtimetxt = startTimeVal
      }
    } else if (startTimeVal !== null) {
      strtimetxt = startTimeVal
    }

    if (this.state.itsCurtDay === "yes") {
      if (parseInt(endTimeVal) <= parseInt(currentTimetat)) {
        if (parseInt(endTimeVal) === parseInt(currentTimetat) && parseInt(endMinitVal) >= parseInt(currentMinstat)) {
          endtimetxtval = endTimeVal
        } else {
          endtimetxtval = parseInt(endTimeVal) > 0 ? parseInt(endTimeVal) - 1 : 0
        }
      } else {
        endtimetxtval = endTimeVal
      }
    } else if (endTimeVal !== null) {
      endtimetxtval = endTimeVal
    }

    result_Arr["strtimetxt"] = strtimetxt
    result_Arr["endtimetxtval"] = endtimetxtval
    return result_Arr
  }

  get_strtatadded_minitArr(startTimeVal, startMinitVal) {
    var result_Arr = [],
      strminittxt = ""
    var currentTimeNew = dateFormat(new Date(), "H")
    var currentMinsNew = dateFormat(new Date(), "MM")
    var tatvalnew = parseInt(this.state.serverTat)
    var dtnowobj = new Date()
    dtnowobj.setMinutes(dtnowobj.getMinutes() + tatvalnew)
    var currentTimetat = dtnowobj.getHours()
    var currentMinstat = dtnowobj.getMinutes()

    if (this.state.itsCurtDay === "yes") {
      if (parseInt(startTimeVal) === parseInt(currentTimetat)) {
        if (parseInt(startMinitVal) <= parseInt(currentMinstat)) {
          strminittxt = currentMinstat
        } else {
          strminittxt = startMinitVal
        }
      } else {
        strminittxt = startMinitVal
      }
    } else if (startTimeVal !== null && startMinitVal !== null) {
      strminittxt = startMinitVal
    }

    result_Arr["strminittxt"] = strminittxt
    return result_Arr
  }

  timeDropDownFunction() {
    var TimeHtml = ""
    var tatval = parseInt(this.state.serverTat)
    var TimeandsecArr = this.state.timeandsecArrayset
    var j = 0,
      checkTimeArr = [],
      strTimeArr = [],
      endTimeArr = [],
      strSecndArr = [],
      endSecndArr = []
    var changeTmtxt = ""
    for (var i = 0; i < TimeandsecArr.length; i++) {
      var strArr = TimeandsecArr[i].slot_time1 !== "" ? TimeandsecArr[i].slot_time1.split(":") : []
      var startTimeVal = strArr.length > 0 ? strArr[0] : ""
      var startMinitVal = strArr.length > 0 ? strArr[1] : ""

      /* if(this.state.itsCurtDay === 'yes') { */
      var strdatevalobj = new Date()
      strdatevalobj.setHours(startTimeVal)
      var stringrMinits = parseInt(startMinitVal) + parseInt(tatval)
      strdatevalobj.setMinutes(stringrMinits)
      startTimeVal = strdatevalobj.getHours()
      startMinitVal = strdatevalobj.getMinutes()
      /* } */

      if (strArr.length > 0) {
        strTimeArr.push(startTimeVal)
        strSecndArr.push(startMinitVal)
      }

      var endArr = TimeandsecArr[i].slot_time2 !== "" ? TimeandsecArr[i].slot_time2.split(":") : []
      var endTimeVal = endArr.length > 0 ? endArr[0] : ""
      var endMinitVal = endArr.length > 0 ? endArr[1] : ""

      /* if(this.state.itsCurtDay === 'yes') { */
      var enddatevalobj = new Date()
      enddatevalobj.setHours(endTimeVal)
      var ingrendMinits = parseInt(endMinitVal) + parseInt(tatval)
      enddatevalobj.setMinutes(ingrendMinits)

      var mnthvaltxt = enddatevalobj.getMonth() + 1
      var sltutcnew = this.pad(enddatevalobj.getFullYear()) + "/" + this.pad(mnthvaltxt) + "/" + this.pad(enddatevalobj.getDate())
      var crtutcnew = new Date().toJSON().slice(0, 10).replace(/-/g, "/")

      if (sltutcnew === crtutcnew) {
        endTimeVal = enddatevalobj.getHours()
        endMinitVal = enddatevalobj.getMinutes()
      } else {
        endTimeVal = 23
        endMinitVal = 60
      }
      /* } */

      if (endArr.length > 0) {
        endTimeArr.push(endTimeVal)
        endSecndArr.push(endMinitVal)
      }

      if (startTimeVal !== "" && endTimeVal !== "") {
        var getStrTatHr = this.get_strtatadded_timeArr(startTimeVal, startMinitVal, endTimeVal, endMinitVal)
        if (getStrTatHr["strtimetxt"] !== "") {
          startTimeVal = getStrTatHr["strtimetxt"]
          startTimeVal = parseInt(startTimeVal)
          endTimeVal = getStrTatHr["endtimetxtval"]
          endTimeVal = parseInt(endTimeVal)
          for (let valueTime = startTimeVal; valueTime <= endTimeVal; valueTime++) {
            valueTime = parseInt(valueTime)
            if (j === 0) {
              changeTmtxt = startTimeVal
              this.setState({ selectTime: startTimeVal })
              this.setState({ selectMins: startMinitVal })
            }

            if (checkTimeArr.indexOf(valueTime) === -1) {
              checkTimeArr.push(valueTime)
              TimeHtml += "<option value='" + this.padnewone(valueTime) + "'  >" + this.padnewone(valueTime) + "</option>"
            }
            j++
          }
        }
      }
    }

    var timeDropDown = htmlToReactParser.parse(TimeHtml)

    this.setState({ timeHtml: timeDropDown })
    this.setState({ ststrTimeArr: strTimeArr })
    this.setState({ ststrSecndArr: strSecndArr })
    this.setState({ stendTimeArr: endTimeArr })
    this.setState({ stendSecndArr: endSecndArr })
    this.minsDropDownFunction(changeTmtxt)
    if (changeTmtxt === "" && TimeHtml === "") {
      this.setState({ timeExceed: "Yes" })
    }
  }

  minsDropDownFunction(chngTmtxt) {
    var ststrTimeArrtxt = this.state.ststrTimeArr
    var stendTimeArrtxt = this.state.stendTimeArr
    var ststrSecndArrtxt = this.state.ststrSecndArr
    var stendSecndArrtxt = this.state.stendSecndArr
    var intervalTimetxt = this.state.intervalTime
    var tatval = parseInt(this.state.serverTat)
    var scltdTime = chngTmtxt,
      htmlMins = "",
      checkMinsArr = []
    var k = 0
    for (var i = 0; i < ststrTimeArrtxt.length; i++) {
      if (parseInt(ststrTimeArrtxt[i]) === parseInt(scltdTime)) {
        var strsecnd = ststrSecndArrtxt[i]
        var endsecnd = parseInt(stendTimeArrtxt[i]) === parseInt(scltdTime) ? stendSecndArrtxt[i] : "60"
        var getStrTatHr = this.get_strtatadded_minitArr(scltdTime, strsecnd)
        strsecnd = getStrTatHr["strminittxt"]
        strsecnd = parseInt(strsecnd)
        endsecnd = parseInt(endsecnd)
        for (let valuemins = strsecnd; valuemins <= endsecnd; valuemins++) {
          valuemins = parseInt(valuemins)
          if ((valuemins === strsecnd || valuemins === endsecnd || valuemins % parseInt(intervalTimetxt) === 0) && checkMinsArr.indexOf(valuemins) === -1 && valuemins !== 60) {
            if (k === 0) {
              this.setState({ selectMins: valuemins })
            }
            checkMinsArr.push(valuemins)
            htmlMins += "<option value='" + this.pad(valuemins) + "'  >" + this.pad(valuemins) + "</option>"
            k++
          }
        }
        if (endsecnd >= 59) {
          break
        }
      } else if (parseInt(stendTimeArrtxt[i]) === parseInt(scltdTime)) {
        var strsecnd = "00"
        var endsecnd = stendSecndArrtxt[i]
        var getStrTatHr = this.get_strtatadded_minitArr(scltdTime, strsecnd)
        strsecnd = getStrTatHr["strminittxt"]
        strsecnd = parseInt(strsecnd)
        endsecnd = parseInt(endsecnd)
        for (let valuemins = strsecnd; valuemins <= endsecnd; valuemins++) {
          valuemins = parseInt(valuemins)
          if ((valuemins === strsecnd || valuemins === endsecnd || valuemins % parseInt(intervalTimetxt) === 0) && checkMinsArr.indexOf(valuemins) === -1 && valuemins !== 60) {
            if (k === 0) {
              this.setState({ selectMins: valuemins })
            }
            checkMinsArr.push(valuemins)
            htmlMins += "<option value='" + this.pad(valuemins) + "'  >" + this.pad(valuemins) + "</option>"
            k++
          }
        }
      }
    }
    if (htmlMins === "" && ststrTimeArrtxt.length > 0 && scltdTime !== "") {
      var strsecnd = "00"
      var endsecnd = "60"
      var getStrTatHr = this.get_strtatadded_minitArr(scltdTime, strsecnd)
      strsecnd = getStrTatHr["strminittxt"]
      strsecnd = parseInt(strsecnd)
      endsecnd = parseInt(endsecnd)
      for (let valuemins = strsecnd; valuemins <= endsecnd; valuemins++) {
        valuemins = parseInt(valuemins)
        if ((valuemins === strsecnd || valuemins === endsecnd || valuemins % parseInt(intervalTimetxt) === 0) && checkMinsArr.indexOf(valuemins) === -1 && valuemins !== 60) {
          if (k === 0) {
            this.setState({ selectMins: valuemins })
          }
          checkMinsArr.push(valuemins)
          htmlMins += "<option value='" + this.pad(valuemins) + "'  >" + this.pad(valuemins) + "</option>"
          k++
        }
      }
    }

    var minsDropDown = htmlToReactParser.parse(htmlMins)
    this.setState({ minsHtml: minsDropDown })
  }

  getsettings() {
    var promise = helper.getProSettings("", "No") //getResult('settings','');
    promise.then((res) => {
      if (res.data.result_set.client_advanced_timeslot_enable === "1") {
        hashHistory.push("/delivery-date-advanced")
      }
      this.setState({ settings: res.data.result_set })

      /* find current tat value */
      axios.get(APIURL + "outlets/gettat_details?app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&outlet_id=" + cookie.load("orderOutletId")).then((res) => {
        if (res.data.status === "ok") {
          if (res.data.result_set !== "" && res.data.result_set !== null) {
            cookie.save("orderTat", res.data.tat)
            this.setState({ serverTat: res.data.tat })
          }
          this.mainDateTimeFun()
        } else {
          helper.showInfo("Your selected outlet currently closed or disabled by an administrator. Please choose another outlet.")
          hashHistory.push("/")
        }
      })
      /* find current tat value - End  */

      /* Holiday  - start */
      var DisableDats = []
      if (parseInt(res.data.result_set.client_holiday_enable) === 1 && cookie.load("defaultAvilablityId") !== config.ids.dineinId) {
        axios.get(APIURLPRO + "settings/get_holidaylist?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + "&outlet_id=" + cookie.load("orderOutletId")).then((res) => {
          if (res.data.status === "ok") {
            var resultData = res.data.result_set

            resultData.map((loadData) => DisableDats.push(this.parseDate(loadData.date)))

            this.setState({ hollidayList: resultData, allowHoliDay: "Yes", disableDate: DisableDats })
          }
        })
      }
      /* Holiday - end */
    })
  }

  componentDidMount() {
    loadAnalytics()
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })

    var des = document.getElementsByClassName("deliverydate")
    var fc = des[0].getElementsByTagName("div")
    var se = fc[0].getElementsByTagName("div")
    se[0].style.padding = "0px"
    var input = se[0].getElementsByTagName("input")
    input[0].style.borderBottom = "0px"
    var label = des[0].getElementsByTagName("label")
    label[0].style.top = "6px"
    //document.getElementsByClassName('rc-time-picker-input')[0].classList.add("form-control");
    $(".custom-date-label").addClass("loader-main-cls").append('<div class="loader-sub-div"></div>')
  }

  onChange(value) {
    var list = document.getElementsByClassName("form-group")
    var n
    for (n = 0; n < list.length; ++n) {
      list[n].classList.remove("is-empty")
    }
  }

  /* this function used to load cart icon image */
  loadCartImage() {
    return getResult("cartImage", "Yes")
  }

  /* store unit no's */
  stroteUnitNoOne(event) {
    cookie.save("unitNoOne", event.target.value)
  }

  /* store unit no's */
  stroteUnitNoTwo(event) {
    cookie.save("unitNoTwo", event.target.value)
  }

  /* store unit no's */
  strotetowerNo(event) {
    cookie.save("towerNo", event.target.value)
  }

  /* validate delivery date */
  checkDeliveryDate(holidayData) {
    this.loadTimeDropDown("", "")
    var seldate = document.getElementsByClassName("deliverydate")[0].getElementsByTagName("input")[0].value

    if (this.state.allowTimeSlot === "Yes") {
      /* for timeslot */
      var selMins = ""
      var selTime = ""
      var Timeslot = document.getElementById("timeSlot").value
    } else {
      /* For TAT */
      selTime = document.getElementById("deliveryTime").value
      selMins = document.getElementById("deliveryMins").value
      Timeslot = ""
    }

    if ((seldate !== "" && selMins !== "" && selTime !== "") || (this.state.allowTimeSlot === "Yes" && Timeslot !== "")) {
      cookie.save("DeliveryDate", seldate)
      if (this.state.allowTimeSlot === "Yes") {
        /* For timeslot */

        var splited = Timeslot.split("/")
        cookie.save("isSletedTimeSlot", "Yes")
        cookie.save("DeliveryTimeSlotFormated", splited[1])
        cookie.save("DeliveryTimeSlot", splited[0])
      } else {
        /* For TAT  */
        cookie.save("isSletedTimeSlot", "No")
        cookie.save("DeliveryTime", selTime + ":" + selMins)
      }

      /* start - holiday validation  */
      var holidayLen = holidayData.length
      if (holidayLen > 0 && this.state.allowHoliDay === "Yes") {
        for (var hl = 0; hl < holidayLen; hl++) {
          if (seldate === dateFormat(holidayData[hl].date, "d/m/yyyy")) {
            helper.showInfo("The selected date is a holiday. Please select another day")
            return false
          }
        }
      }
      /* End of holiday validation  */

      //Check unit number validation
      if (cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
        var AlertOne = document.getElementById("unitone").value
        var AlertTwo = document.getElementById("unittwo").value
        if (AlertOne === "" || AlertTwo === "") {
          var res = confirm("You did not enter a Floor No / Unit No. \n Do you want to continue")
          if (!res) {
            error = "1"
            return false
          }
        }
      }

      var splitedDate = seldate.split("/")
      var OrderTime = splitedDate[2] + "-" + this.pad(splitedDate[1]) + "-" + this.pad(splitedDate[0]) + " " + selTime + ":" + selMins
      var orderTAT = typeof cookie.load("orderTAT") === "undefined" ? "" : cookie.load("orderTAT")
      axios.get(APIURLPRO + "ordersv1/validate_order_count?app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&outlet_id=" + cookie.load("orderOutletId") + "&order_date=" + OrderTime + "&order_tat_time=" + orderTAT).then((res) => {
        if (res.data.status === "ok") {
          hashHistory.push("/checkout")
        } else {
          helper.showInfo(res.data.message)
        }
      })
    } else {
      helper.showInfo(lang.choose_delvery_date_error)
    }
  }

  changeTimer(e) {
    var seletedTime = e.target.value
    this.setState({ selectTime: seletedTime })
    this.minsDropDownFunction(seletedTime)
  }

  /* chnage minutes */
  changeMins(e) {
    this.setState({ selectMins: e.target.value })
  }

  /* set default Avilablity */
  loadTimeDropDown(day = null, mins = null, triggerTime = null) {}

  /* add zero */
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString()
  }

  /* add zero */
  padnewone(d) {
    var dd = parseInt(d)
    return dd < 10 ? "0" + dd.toString() : dd.toString()
  }

  /* maek round of */
  makeround(value) {
    var returnValue = 0
    if (value > 0 && value <= 15) {
      returnValue = 15
    } else if (value >= 16 && value <= 30) {
      returnValue = 30
    } else if (value >= 31 && value <= 45) {
      returnValue = 45
    }

    return returnValue
  }

  /* format date'es */
  parseDate(input) {
    var parts = input.match(/(\d+)/g)
    return new Date(parts[0], parts[1] - 1, parts[2]) // months are 0-based
  }

  /*show outlet text for workspace */
  outletNameText() {
    if (apps.workspace === APPID) {
      return htmlToReactParser.parse("Workspace Espresso Bar <br/> 12 Marina Boulevard<br/> Marina Bay Financial Centre Tower 3<br/> #01-08 Singapore 018982")
    } else {
      /*return (cookie.load('defaultAvilablityId') === config.ids.deliveryId? cookie.load('orderDeliveryAddress'): cookie.load('orderHandled') );*/
      return cookie.load("defaultAvilablityId") === config.ids.deliveryId ? cookie.load("orderDeliveryAddress") + ", Singapore " + cookie.load("orderPostalCode") : cookie.load("orderHandled")
    }
  }

  render() {
    var morganfield_message = "Please allow an additional grace period of +/- 30 minutes from your preferred delivery time slot due to unforeseen weather and traffic conditions."
    return (
      <div>
        {" "}
        <DocumentMeta {...meta} />
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeight }}>
          <div className="row">
            <div className="col-xs-12 top-nav inner-top-nav head-main">
              <div className="col-xs-3 nav-head pd-l-20  head-child">
                <Link to={"/cart"}>
                  <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                </Link>
              </div>
              <div className="col-xs-6 nav-head head-child">
                <h1 className="main-title">{cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "Delivery" : "PICKUP"}</h1>
              </div>
              <div className="col-xs-3 nopadding head-child">
                {/*	<p className="b-txt text-right ">
								<Link to={"/cart"} >  <img alt=""  src={this.loadCartImage()}className="icon-img-small-1"/> </Link>
							</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 calender-area">
              <div className="col-xs-12 calender-area-sub">
                <div className="col-xs-offset-2 col-xs-8 cal-div">
                  <h1 className="sub-heading" style={{ textAlign: "center" }}>
                    {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "SELECT YOUR DELIVERY DATE AND TIME" : "SELECT YOUR PICKUP DATE AND TIME"}
                  </h1>
                  <div className="form-group label-floating is-empty card-no custom-date-label">
                    <div className="input-group deliverydate">
                      <ThemeProvider theme={theme}>
                        <DatePicker
                          label="Date (DD/MM/YYYY)"
                          readonly={readOnly}
                          inputFormat={(value) => `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`}
                          minDate={min_datetime}
                          maxDate={max_datetime}
                          sundayFirstDayOfWeek
                          onChange={this.handleChange.bind(this, "date")}
                          className="delivery_datepiker"
                          disabledDates={this.state.disableDate}
                          value={this.state.date}
                        />
                      </ThemeProvider>
                    </div>
                  </div>
                  <div className="form-group label-floating is-empty card-no custom-time-label min-hgt-hour-t">
                    <div className="input-group">
                      <label className="control-label">{this.state.timeText}</label>
                      <select className={disabledClass + " customdd"} value={this.state.selectTime} onChange={this.changeTimer} id="deliveryTime">
                        {this.state.timeHtml}
                      </select>{" "}
                      /
                      <select id="deliveryMins" className={disabledClassMins + " " + disabledClass + " customdd"} value={this.state.selectMins} onChange={this.changeMins}>
                        {this.state.minsHtml}
                      </select>
                    </div>
                  </div>
                  {this.showTimeslotExceed()}

                  <div className="all-time-text">{APPID == "CD9D3E7B-C4E4-4FF0-B751-A7FE97524F53" && cookie.load("defaultAvilablityId") === config.ids.deliveryId && moment(this.state.date).format("YYYY-M-D") == moment(new Date()).format("YYYY-M-D") ? morganfield_message : ""}</div>

                  <div className="space-35"></div>
                </div>
              </div>
              <div className="col-xs-12 calender-area-sub-2">
                <h1 className="sub-heading" style={{ textAlign: "center" }}>
                  {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "DELIVER TO" : "PICKUP AT"}
                </h1>
                <h1 className="sub-heading-2" style={{ textAlign: "center" }}>
                  {this.outletNameText()}
                </h1>

                {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? (
                  <div className="col-xs-12 unit-no-div">
                    <div className="form-group col-sm-4 col-xs-12 no-margin">
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label">Building Name/ Tower</label>
                          <input type="text" id="towerno" className="form-control" onChange={this.strotetowerNo} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-sm-4 col-xs-12 no-margin">
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label">Floor No</label>
                          <input type="text" id="unitone" className="form-control" onChange={this.stroteUnitNoOne} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-sm-4 col-xs-12 no-margin">
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label">Unit No</label>
                          <input type="text" id="unittwo" className="form-control" onChange={this.stroteUnitNoTwo} />
                        </div>
                      </div>
                      <br />
                    </div>

                    <div className="clearfix"></div>
                  </div>
                ) : (
                  <div className="space-30"></div>
                )}
              </div>
              <div className="space-30"></div>
            </div>
          </div>

          <div className="space-30"></div>

          <div className="row">
            <Link onClick={this.checkDeliveryDate.bind(this, this.state.hollidayList)}>
              <button className="col-lg-12 continue-but desktop-container container-fluid">Continue</button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
export default DeliveryDatePickerNew
