/* eslint-disable */
import cookie from "react-cookie"
import { hashHistory } from "react-router"
import axios from "axios"
import moment from "moment"
import { orderBy, isEmpty, includes } from "lodash"

import config from "./config"
import lang from "./lang"
const APPID = config.ids.appId
const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
var qs = require("qs")
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParser = new HtmlToReactParser()
const apps = require("./apps")
/* address format */
export const addressFormat = function (unitOne, unitTwo, addressOne, addressTwo, postCode) {
  var unit = unitTwo !== "" && unitOne !== "" ? "#" + unitOne + "-" + unitTwo + "," : unitOne !== "" ? "#" + unitOne + "," : ""
  return unit + addressOne
}

/* set default avilablity  */
export const setDefaultAvailability = function (avlId, avlText, avlImage) {
  if (
    typeof cookie.load("defaultAvilablityId") === "undefined" ||
    cookie.load("defaultAvilablityId") === "" ||
    typeof cookie.load("defaultAvilablity") === "undefined" ||
    cookie.load("defaultAvilablity") === "" ||
    typeof cookie.load("defaultAvilablityImage") === "undefined" ||
    cookie.load("defaultAvilablityImage") === ""
  ) {
    cookie.save("defaultAvilablityId", avlId, {})
    cookie.save("defaultAvilablity", avlText, {})
    cookie.save("defaultAvilablityImage", avlImage, {})
  }
}

export const loadingImage = function () {
  return "<div style={{bottom : 0, left: 0, position: 'fixed', right : 0,  top : 0, margin :'auto'}}> <img src='/img/loader.gif' alt=''  style={{bottom : 0, left: 0, position: 'fixed', right : 0,  top : 0, margin :'auto'}} /> </div>"
}

/* this function used to show unit */
export const unitFormat = function (noOne, noTwo) {
  if (noOne != "" && noTwo != "") {
    return "#" + noOne + "-" + noTwo
  } else if (noOne != "") {
    return "#" + noOne
  } else if (noTwo != "") {
    return noTwo
  }
}

/* this function used for static table setup sarvesh */
export const sarveshTableSetup = function () {
  var postObject = {}
  postObject = { app_id: APPID, tm_table_number: "Wavehouse" }
  axios.post(APIURL + "tablemanagement/find_table", qs.stringify(postObject)).then((res) => {
    if (res.data.status === "ok") {
      cookie.save("orderOutletId", res.data.result_set[0].outlet_id)
      cookie.save("orderOutletName", res.data.result_set[0].outlet_name)
      cookie.save("orderTableNo", res.data.result_set[0].tm_table_number)
      var tatVal = res.data.result_set[0].outlet_dine_tat !== "" ? res.data.result_set[0].outlet_dine_tat : res.data.result_set[0].outlet_delivery_timing
      cookie.save("orderTAT", tatVal)
      if (cookie.load("dineinClick") === "yes") {
        hashHistory.push("/")
      } else {
        hashHistory.push("/checkout")
      }
    } else if (res.data.status === "error") {
      cookie.save("orderOutletId", "76")
      cookie.save("orderOutletName", "Thaikkudam Bridge")
      cookie.save("orderTableNo", "Wavehouse")
      cookie.save("orderTAT", "15")
      hashHistory.push("/checkout")
      if (cookie.load("dineinClick") === "yes") {
        hashHistory.push("/")
      } else {
        hashHistory.push("/checkout")
      }
    }
  })
}

/* check vaild input */
export const validInput = function (inPut) {
  if (typeof cookie.load("defaultAvilablityId") === "undefined") {
  }
}

/* shop timing  funtion */
export const shopTiming = function (start, end) {
  if (typeof start !== "undefined" && start !== "" && typeof end !== "undefined" && end !== "") {
    return start + " - " + end
  }
}

/* shop timing  funtion */
export const defauktImage = function (availId) {
  if (typeof start !== "undefined" && start !== "" && typeof end !== "undefined" && end !== "") {
    return start + " - " + end
  }
}

/* sample funtion */
export const findDevice = function () {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    //|| navigator.userAgent.match(/iPhone/i)
    //|| navigator.userAgent.match(/iPad/i)
    //|| navigator.userAgent.match(/iPod/i)
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return "Others"
  } else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
    return "IOS"
  } else {
    return "Web"
  }
}

/* sample funtion */
export const getGstValue = function (gst, subtotal, format) {
  var gst = parseFloat(gst)
  var subtotal = parseFloat(subtotal)
  var gstResult = 0

  if (gst > 0) {
    gstResult = (gst / 100) * subtotal
  }

  if (format === "format") {
    return gstResult.toFixed(2)
  } else {
    return gstResult
  }
}

/* gstInclusive  amount funtion */
export const getInclusiveGst = function (gstVal, total, format) {
  if (gstVal > 0) {
    var vatDivisor = 1 + gstVal / 100
    var gstpercentage = gstVal / 100
    var productvalue = total / vatDivisor
    var gst = productvalue * gstpercentage
    var return_data = []
    return_data["text"] = "GST Inclusive" + "(" + gstVal + "%): $" + gst.toFixed(2)
    return_data["value"] = gst.toFixed(2)
    return_data["percentage"] = gstVal
  } else {
    var return_data = []
  }

  return return_data
}

/* sample funtion */
export const getServicecharge = function (service_percent, total_amt, format) {
  var service_percent = parseFloat(service_percent)
  var total_amt = parseFloat(total_amt)
  var cal_amount = 0

  if (service_percent > 0) {
    cal_amount = (service_percent / 100) * total_amt
  }

  if (format === "format") {
    return cal_amount.toFixed(2)
  } else {
    return cal_amount
  }
}

/*
 
showInfo(alrtmessage,alertheader)
{
$('.alrt_overlay').remove();
$('.custom_alert').remove();

alertheader =  (typeof(alertheader) =="undefined" )?  "Information" :  alertheader;


   $('body').append('<div class="alrt_overlay"></div><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+ alertheader +'</div><div class="alert_body"> '+ alrtmessage +' <div class="alt_btns"><a  class="btn btn-info reject" id="alt0">Close</a></div></div></div></div></div>');
	
	   var actheight=$('.alert_height').height();
	   $('.custom_alert').height(actheight+10);
   
	
   $('.alt_btns a').click(function(e) {
	  $('.alrt_overlay, .custom_alert').remove();
	  
   });
} 
 
*/
export const showInfo = function (alrtmessage, alertheade) {
  $(".alrt_overlay").remove()
  $(".custom_alert").remove()

  var alertheader = typeof alertheader == "undefined" ? "Information" : alertheader

  $("body").append(
    '<div class="alrt_overlay"></div><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
      alertheader +
      '</div><div class="alert_body"> ' +
      alrtmessage +
      ' <div class="alt_btns"><a  class="btn btn-info reject" id="alt0">Close</a></div></div></div></div></div>'
  )

  var actheight = $(".alert_height").height()
  $(".custom_alert").height(actheight + 10)

  $(".alt_btns a").click(function (e) {
    $(".alrt_overlay, .custom_alert").remove()
  })
}

export const confirmAlert = function (alrtmessage, alertheader) {
  $(".alrt_overlay").remove()
  $(".custom_alert").remove()

  var alertheader = typeof alertheader == "undefined" ? "Confirmation" : alertheader

  $("body").append(
    '<div class="alrt_overlay"></div><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
      alertheader +
      '</div><div class="alert_body"> ' +
      alrtmessage +
      ' <div class="alt_btns"><a  class="btn btn-primary confirm" id="alt1">OK</a><a  class="btn btn-info reject" id="alt0">Cancel</a></div></div></div></div></div>'
  )

  var actheight = $(".alert_height").height()
  $(".custom_alert").height(actheight + 10)

  $(".alt_btns a").click(function (e) {
    $(".alrt_overlay, .custom_alert").remove()
  })
}

/*  get pro settings */
export const getProSettings = function (allowPostalcode, postalFilter) {
  if (allowPostalcode === "Yes") {
    var postalStr = "&postal_code=" + cookie.load("orderPostalCode")
  } else {
    postalStr = ""
  }
  var postalFilter = postalFilter === "Yes" ? "Yes" : "No"
  return axios.get(APIURLPRO + "settings/get_common_settings?app_id=" + APPID + postalStr + "&postalFilter=" + postalFilter)
}

/* get static block content */
export const getStaticBlock = function (blockSlug) {
  return axios.get(APIURL + "cms/staticblocks?app_id=" + APPID + "&slug=" + blockSlug)
}

/* sample funtion */
export const getAliasName = function (alias, productName) {
  return alias != "" ? alias : productName
}

/* get subtotal value  */
export const getsubTotal = function (subTotal, OriginalAmount, promotionApplied, redeemptionApplied = null) {
  if (promotionApplied === "Yes") {
    return subTotal
  } else if (redeemptionApplied === "Yes") {
    return subTotal
  } else {
    return OriginalAmount
  }
}

/* get subtotal value  */
export const getDeliveryCharge = function (promotionApplied, deliveryEnabled, OriginalAmount, isFreeDelivery = null) {
  if ((promotionApplied === "Yes" && deliveryEnabled === "Yes") || isFreeDelivery === "Yes") {
    return 0
  } else {
    return OriginalAmount
  }
}

/* get subtotal value  */
export const getAdditionalDeliveryCharge = function (promotionApplied, deliveryEnabled, OriginalAmount, isFreeDelivery = null) {
  if ((promotionApplied === "Yes" && deliveryEnabled === "Yes") || isFreeDelivery === "Yes") {
    return 0
  } else {
    return OriginalAmount
  }
}

/* Show cart count  */
export const cartCount = function () {
  var count = parseInt(cookie.load("cartCount"))

  if (cookie.load("cartValue") === "Yes" && typeof cookie.load("cartCount") !== "undefined" && count > 0) {
    return htmlToReactParser.parse('<span className="count">' + count + "</span>")
  }
}

/* Show cart class   */
export const cartClass = function () {
  var count = parseInt(cookie.load("cartCount"))

  if (cookie.load("cartValue") === "Yes" && typeof cookie.load("cartCount") !== "undefined" && count > 0) {
    return "rel cart-icon bounce"
  } else {
    return "rel cart-icon"
  }
}

/* add body tag class for workspace */
export const addBodyClass = function (appId) {
  if (APPID === apps.workspace) {
    document.body.className += " " + "workspace-css"
  }
}

/* sample funtion */
export const myFunc2 = function () {
  return "wellcome1"
}

export const alphabet = function (e) {
  const re = /^[a-zA-Z. ]*$/
  if (!re.test(e.key)) {
    e.preventDefault()
  }
}

export const numariconly = function (e) {
  const re = /[0-9. ]+/g
  if (!re.test(e.key)) {
    e.preventDefault()
  }
}
/* distance part */
export const getSortedList = function (obj, coordinate) {
  let returnValue = {}
  const coordinateVal = coordinate
  if (!isEmpty(obj)) {
    let sortObject = obj.map((option) => {
      let distanceVal = distance(option.outlet_marker_latitude, option.outlet_marker_longitude, coordinateVal)
      const newPropsObj = {
        distance: distanceVal,
      }
      return Object.assign(option, newPropsObj)
    })
    returnValue = orderBy(
      sortObject,
      (data) => {
        return moment(data["distance"])
      },
      ["asc"]
    )
  }
  return returnValue
}

function distance(lat1, lon1, coordinate) {
  if (coordinate) {
    let lat2 = coordinate.latitude
    let lon2 = coordinate.longitude
    var R = 6371 // km (change this constant to get miles)
    var dLat = ((lat2 - lat1) * Math.PI) / 180
    var dLon = ((lon2 - lon1) * Math.PI) / 180
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    if (d > 1) return Math.round(d) //km
    else if (d <= 1) return Math.round(d * 1000) + "m"
    return d
  }
}

/* distance part */

/* truncate  */
export const truncate = function (input, type) {
  let windowWidth = window.innerWidth >= 480 ? 100 : type === 1 ? 40 : 40
  if (input && input.length > 0) {
    if (input.length > windowWidth) {
      return input.substring(0, windowWidth) + "..."
    }
    return input
  } else {
    return ""
  }
}

export const disableMenu = function (object, value) {
  var isAnonymousUser = parseInt(cookie.load("isAnonymousUser"))

  if (typeof object !== "undefined" && typeof object.menu_enable_settings !== "undefined" && typeof object.menu_enable_settings[value] !== "undefined") {
    return false
  } else {
    return isAnonymousUser === 1 && includes(["home_main_vouchers", "home_main_orders", "home_main_promos", "home_main_rewards", "home_main_vouchers", "home_sub_my_account", "home_sub_orders", "home_sub_rewards"], value) ? false : true
  }
}

/* sample funtion */
export const isAnonymousUserRedirect = function () {
  var isAnonymousUser = parseInt(cookie.load("isAnonymousUser"))
  console.log("isAnonymousUser", isAnonymousUser)
  if (isAnonymousUser === 1) {
    console.log("isAnonymousUser", isAnonymousUser)
    hashHistory.push("/")
    this.showInfo("You don't have access to view this pages")
  }
}

/* disable textarea for some customers*/
export const disableSpecialInstructions = function (appID) {
  let clients = ["6FCDE66B-8022-421E-A41B-34AF16B3A645"]

  return includes(clients, appID) ? false : true
}

/* sample funtion */
export const getTat = function () {}

/* show Loader */
export const showLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>')
  } else {
    $("#" + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>')
  }
}

/* hide Loader */
export const hideLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef).removeClass("loader-main-cls")
    $("." + divRef)
      .find(".loader-sub-div")
      .remove()
  } else {
    $("#" + divRef).removeClass("loader-main-cls")
    $("#" + divRef)
      .find(".loader-sub-div")
      .remove()
  }
}

/* stripslashes  */
export const stripslashes = function (str) {
  if (str !== "" && str !== null && typeof str !== undefined && typeof str !== "undefined") {
    str = str.replace(/\\'/g, "'")
    str = str.replace(/\\"/g, '"')
    str = str.replace(/\\0/g, "\0")
    str = str.replace(/\\\\/g, "\\")
    return str
  }
}
