/* eslint-disable  */
import React from "react"
import axios from "axios"
import { Link, hashHistory } from "react-router"
import getResult from "./Api"
import Distancebar from "./Distancebar"
import Outletclosedmessage from "./Outletclosedmessage"
import { isEmpty } from "lodash"
import { geolocated } from "react-geolocated"
import cookie from "react-cookie"
import DocumentMeta from "react-document-meta"
import { loadAnalytics, fbPixelCustomEvent } from "./withTracker"
import Slider from "react-slick"
import LocationSearchInput from "./LocationSearchInput"
import { API_v4_URL } from "./constants"
import Loading from "./loading"
import Overlay from "./Overlay"

var strp = require("strp")
import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APIURLv2 = config.path.apiUrlv2
const APIURLPRO = config.path.apiUrlPro
const APPID = config.ids.appId
var qs = require("qs")
var helper = require("./Helper.js")
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParser = new HtmlToReactParser()
const nl2br = require("react-nl2br")
const apps = require("./apps")

var _ = require("lodash")
//import Map from './Map';
var combo_0 = []
var combo_1 = []
var combo_2 = []
var combo_3 = []
var combo_4 = []
var combo_5 = []
var combo_6 = []
var combo_7 = []
var combo_8 = []
var combo_9 = []
var combo_10 = []
var combo_11 = []
var combo_12 = []
var combo_13 = []
var combo_14 = []
var combo_15 = []
var combo_16 = []
var combo_17 = []
var combo_18 = []
var combo_19 = []
var combo_20 = []
var combo_21 = []
var combo_22 = []
var combo_23 = []
var combo_24 = []
var combo_25 = []

/* Load meta tags */
const meta = {
  title: lang.home_meta_title,
  description: lang.home_meta_title,
  meta: {
    name: {
      keywords: lang.home_meta_title,
    },
  },
}

/* my test coding */
class Productsdetails extends React.Component {
  constructor(props) {
    super(props)

    combo_0 = []
    combo_1 = []
    combo_2 = []
    combo_3 = []
    combo_4 = []
    combo_5 = []
    combo_6 = []
    combo_7 = []
    combo_8 = []
    combo_9 = []
    combo_10 = []
    combo_11 = []
    combo_12 = []
    combo_13 = []
    combo_14 = []
    combo_15 = []
    combo_16 = []
    combo_17 = []
    combo_18 = []
    combo_19 = []
    combo_20 = []
    combo_21 = []
    combo_22 = []
    combo_23 = []
    combo_24 = []
    combo_25 = []
    /*add class */
    helper.addBodyClass()

    this.state = {
      stockEnabled: 0,
      zonePopupDisable: 0,
      zoneEnabled: 0,
      windowHeightNormal: 0,
      windowHeight: 0,
      details: [],
      orderHandled: "",
      load: "",
      Outlets: [],
      deliveryOutlets: [],
      markers: [],
      details_common: [],
      cartCount: 1,
      status: "Loading",
      allowCart: "Yes",
      productPrice: "",
      productUnitPrice: 0,
      itemType: "Simple",
      ModifierCount: "",
      windowHeight: props.height,
      secAddrData: [],
      addressField: "",
      isUpdate: "No",
      combo0: [],
      combo1: [],
      combo2: [],
      combo3: [],
      combo4: [],
      combo5: [],
      MinMaxSel: 0,
      extraComboPrice: 0,
      enableAddressSearch: false,
      outletCloseddMessage: "",
      product_type: "",
      product_voucher: "",
      productvoucher_expiry_date: "",
      product_voucher_points: "",
      product_voucher: "",
      voucher_name: "",
      voucher_email: "",
      voucher_message: "",
      error_voucher_name: "",
      error_voucher_email: "",
      error_voucher_message: "",
    }

    /* set default cookie values */
    cookie.save("modiferCount", 0)
    cookie.save("itemType", "Simple")
    this.handleChange = this.handleChange.bind(this) //handleChangeValue
    this.handleChangeValue = this.handleChangeValue.bind(this)
    this.selectOutlet = this.selectOutlet.bind(this)
  }

  componentWillMount() {
    var windowHeight = window.innerHeight
    //alert(windowHeight);
    this.setState({ windowHeight: windowHeight - 45 + "px" })

    this.setState({ windowHeightNormal: windowHeight + "px" })

    //alert(windowHeight);
    /* API CALL FOR BANNERS */
    /* set default avilablity */
    axios.get(APIURLPRO + "settings/get_common_settings?app_id=" + APPID + "&postal_code=" + cookie.load("orderPostalCode") + "&postalFilter=No").then((res) => {
      this.setState({ zoneEnabled: parseInt(res.data.result_set.client_outlet_zone) })
      this.setState({ zonePopupDisable: parseInt(res.data.result_set.client_zone_outlet_disable) })
      this.setState({ stockEnabled: parseInt(res.data.result_set.client_product_stock) })

      var applyOutletIdtxt = res.data.result_set.ninja_outletbasedproduct_enable === "1" ? "yes" : "no"
      cookie.save("applyOutletId", applyOutletIdtxt)
      var avlId = res.data.result_set.ninja_pro_default_availability_id
      var avlText = res.data.result_set.ninja_pro_default_availability_name === "Pickup" ? "Takeaway" : res.data.result_set.ninja_pro_default_availability_name
      var avlImage = "/img/icons/" + res.data.result_set.ninja_pro_default_availability_id + ".png"
      helper.setDefaultAvailability(avlId, avlText, avlImage)

      const productSlug = this.props.params.productSlug
      const CartAction = this.props.params.type

      if (CartAction === "update") {
        this.setState({ isUpdate: "Yes" })
        var cartParam = "&product_id=" + productSlug

        /* get cart itesm details */
      } else {
        cartParam = "&product_slug=" + productSlug
      }

      var orderOutletIdtext = cookie.load("orderOutletId")
      var applyOutletIdtext = cookie.load("applyOutletId")

      var custmquerytxt = ""
      if (applyOutletIdtext === "yes") {
        if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== "") {
          custmquerytxt = "&outlet=" + orderOutletIdtext + "&applyOutletId=yes"
        } else {
          hashHistory.push("/")
        }
      }

      axios.get(APIURLPRO + "productsver2/products_list?status=A&app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + cartParam + custmquerytxt).then((res) => {
        this.setState({ status: res.data.status })
        if (res.data.status === "ok") {
          console.log(res.data.result_set)
          if (res.data.result_set == "" || typeof res.data.result_set == "undefined" || res.data.result_set == null) {
            hashHistory.push("/")
          } else {
            this.setState({ status: res.data.status })
            this.setState({ details_common: res.data.common })
            this.setState({ productPrice: res.data.result_set[0].product_price, productUnitPrice: res.data.result_set[0].product_price })
            this.setState({ details: res.data.result_set[0] })
            this.setState({ MinMaxSel: parseInt(res.data.result_set[0].product_apply_minmax_select) })
            this.setState({ product_type: res.data.result_set[0].product_type, product_voucher: res.data.result_set[0].product_voucher, productvoucher_expiry_date: res.data.result_set[0].product_voucher_expiry_date, product_voucher_points: res.data.result_set[0].product_voucher_points })
            // this.setState({productPrice: res.data.result_set[0]});
            this.getExtraModifierCost()
          }
        } else if (res.data.status === "error") {
          hashHistory.push("/")
        }
      })

      //	if(cookie.load('defaultAvilablityId')===config.ids.pickupId && cookie.load('defaultAvilablity')===lang.takeaWay){ /* old condition page optimaztion */

      if ((cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay && typeof cookie.load("orderOutletId") === "undefined") || typeof cookie.load("orderPostalCode") === "undefined") {
        axios.get(API_v4_URL + "Getoutlets/pickup_outlets?app_id=" + APPID).then((res) => {
          // console.log('banner result loading done');
          if (res.data.status === "ok") {
            this.setState({ Status: res.data.status })
            this.setState({ Outlets: res.data.result_set })
            //this,loaddata.outlet_id,loaddata.outlet_name,loaddata.outlet_postal_code, loaddata.outlet_address_line1, loaddata.outlet_address_line2, loaddata.outlet_unit_number1, loaddata.outlet_unit_number2,loaddata.outlet_pickup_tat
            //this.loadMarkers();
            console.log(res.data.result_set)
            if (apps.workspace === APPID) {
              this.addWorkSpaceCookieValues(res.data.result_set)
            }
          } else {
            this.setState({ Status: res.data.status })
          }
        })
      }

      if ((this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery && typeof cookie.load("orderOutletId") === "undefined") || typeof cookie.load("orderPostalCode") === "undefined") {
        axios.get(API_v4_URL + "Getoutlets/getAllOutles/pickup_outlets?app_id=" + APPID + "&availability_id=" + config.ids.deliveryId).then((res) => {
          if (res.data.status === "ok") {
            /* this.setState({Status: res.data.status}); */
            this.setState({ deliveryOutlets: res.data.result_set })
          } else {
            /* this.setState({Status: res.data.status}); */
          }
        })
      }

      /* get secondary address lits */
      if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "" && cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
        //typeof cookie.load('orderOutletId')==="undefined" || typeof cookie.load('orderPostalCode')==="undefined"
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
          this.getSecAddr()
        }
      }
    })
  }

  componentDidMount() {
    loadAnalytics()
    /* outlet closed messages  */
    let outResult = helper.getStaticBlock("outlet-closed-message")
    outResult.then((stTes) => {
      if (stTes.data.status === "ok") {
        this.setState({ outletCloseddMessage: stTes.data.result_set[0].staticblocks_description })
      }
    })

    window.addEventListener("scroll", () => {
      let activeClass = "detail_header_scrool "
      if (window.scrollY === 0) {
        activeClass = "no_bg"
      }

      this.setState({ activeClass })
    })
  }
  /* add outlet ids work spac */
  addWorkSpaceCookieValues(data) {
    var loaddata = data[0]
    var outlet_name = loaddata.outlet_name
    var outlet_id = loaddata.outlet_id,
      loaddata
    var outlet_address_line1 = loaddata.outlet_address_line1
    var outlet_address_line2 = loaddata.outlet_address_line2
    var outlet_postal_code = loaddata.outlet_postal_code
    var outlet_pickup_tat = loaddata.outlet_postal_code

    var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code
    //console.log(outlet_id+outlet_postal_code)
    this.setState({ orderHandled: orderHandled })
    cookie.save("orderOutletId", outlet_id)
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)
    cookie.save("orderHandledByText", orderHandled)
  }

  /* CalCulate extra Amount */
  getExtraModifierCost(id = null) {
    /*// alert();
		var extrapriceval = parseFloat(0);
	
		if(id){
		//     $(".subitems").attr('checked',false);
		   //  document.getElementById(id).checked=true;
		}
	
				 var indival = []
				$.each($(".extraPrice:checked"), function(){            
	 
				   indival = $(this).val().split("~")
				   
				   console.log(indival);
				   extrapriceval += parseFloat(indival[indival.length-1]);
	
				});
				
	 
	  //alert(extrapriceval); */
    var extrapriceval = parseFloat(0)
    var simpleproExtra = parseFloat(0)

    //alert(cookie.load('itemType'));
    var comboSelction = ""
    var modifierSelction = ""
    if (cookie.load("itemType") === "Combo" && cookie.load("comboCount") > 0 && this.state.MinMaxSel === 0) {
      for (var m = 0, length = cookie.load("comboCount"); m < length; m++) {
        var inputName = "comboVal_" + m
        var comboRes = ""
        if (document.querySelector("input[name = " + inputName + "]:checked")) {
          var comboVal_ = document.querySelector("input[name = " + inputName + "]:checked").value
          if (comboVal_ !== "") {
            comboSelction = "Success"
            comboRes = comboVal_.split("~")
            var product_id = comboRes[3]
            var pro_priceAply = parseInt(comboRes[5])
            var simplepro_price = parseFloat(comboRes[6])

            /* Validate modififer selection - start */
            var comboModifier = []
            var modLength = 0
            //alert("mod count" + m);
            var modLength = document.querySelectorAll(".setModValClass" + product_id + m).length
            //alert('.setModValClass'+product_id+m)
            if (modLength > 0) {
              //  alert(modLength);
              //alert(modLength + comboRes[2] + "modifier count");
              comboModifier = []
              for (var n = 0, forLen = modLength; n < forLen; n++) {
                var inputName = "modValItems" + product_id + "-" + m + "-" + n
                if (document.querySelector("input[name = " + inputName + "]:checked")) {
                  var modComboVal = document.querySelector("input[name = " + inputName + "]:checked").value
                  if (modComboVal !== "") {
                    var comboModRes = modComboVal.split("~")
                    //alert(parseFloat(comboModRes[4]));
                    var extraComboVal = comboModRes[4] !== "null" ? parseFloat(comboModRes[4]) : 0
                    extrapriceval += extraComboVal
                  }
                }
              } /* end of second modifer for */
            } else {
              if (pro_priceAply === 1) {
                simpleproExtra += simplepro_price
              }
            }

            /* Validate modififer selection - End  */
          }
        }
      }
    }

    var newExtraPrice = parseFloat(this.state.productUnitPrice) + parseFloat(extrapriceval) + parseFloat(simpleproExtra)

    this.setState({ productPrice: newExtraPrice.toFixed(2) }) //productPrice
  }

  /* used to get all secondary address list */
  getSecAddr() {
    axios.get(APIURL + "/customer/get_user_secondary_address?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ secAddrData: res.data.result_set })
      }
    })
  }

  handleChange(event) {
    //alert(event.target.value);
    this.setState({ addressField: event.target.value })
  }

  handleChangeValue(event) {
    this.setState({ addressField: event.target.value })
  }
  /* used to load address list */
  loadAddressList() {
    return this.state.secAddrData.map((item, index) => (
      <p key={index}>
        <input name="radio" value={item.postal_code} id={item.secondary_address_id} className="css-radio" type="radio" onChange={this.handleChange} />
        <label htmlFor={item.secondary_address_id} className="css-label ">
          {" "}
          {item.address + " Singapore " + item.postal_code}
        </label>
      </p>
    ))
  }
  /* this function used to load default billing address */
  loadDefaultAddress() {
    if (typeof cookie.load("UserDefaultAddress") !== "undefined" && cookie.load("UserDefaultAddress") !== "" && cookie.load("UserDefaultAddress") !== "" && typeof cookie.load("UserDefaultPostalCode") !== "undefined" && cookie.load("UserDefaultPostalCode") !== "") {
      return (
        <p>
          <input name="radio" value={cookie.load("UserDefaultPostalCode")} id="defaultAdd" className="css-radio" type="radio" onChange={this.handleChange} />
          <label htmlFor="defaultAdd" className="css-label ">
            {" "}
            {cookie.load("UserDefaultAddress") + " Singapore " + cookie.load("UserDefaultPostalCode")}
          </label>
        </p>
      )
    }
  }

  /* this function used to load cart icon image */
  loadCartImage() {
    return getResult("cartImage", "Yes")
  }

  /* show product thump image */
  __productImage(product_thumbnail) {
    return product_thumbnail === "" ? config.noImages.product_listing : this.state.details_common.image_source + "/" + product_thumbnail
  }
  /* load product image */
  loadProductImage(product_thumbnail, image_gallery) {
    if (product_thumbnail !== "" && typeof product_thumbnail !== "undefined") {
      return (
        <div className="row no-bar-image">
          {" "}
          <img src={config.path.tinThumpUrl + this.state.details_common.image_source + "/" + product_thumbnail + "&w=768&h=264&q=80"} alt="banner" className="img-responsive" />{" "}
        </div>
      )
    }
  }

  loadMarkers() {
    var markers = []
    this.state.Outlets.map(function (data, index) {
      var markerinfo = {
        position: new google.maps.LatLng(data.outlet_marker_latitude, data.outlet_marker_longitude),
        showInfo: false,
        infoContent: <div>{data.outlet_name}</div>,
      }
      markers[index] = markerinfo
    })
    this.setState({ markers: markers })
  }

  /* checkActive */
  checkActive() {}

  /* show modifiers values */
  showModifiers(modVal) {
    //console.log(modVal);
    if (modVal) {
      var modLen = modVal.length
      return modVal.map((item, index) => (
        <div className="panel panel-default" key={index}>
          <div className="panel-heading" role="tab" id={"modHead" + index}>
            <h4 className="panel-title">
              <a className="" onClick={this.checkActive.bind()} data-toggle="collapse" data-parent="#accordion" href={"#collapse" + index} aria-expanded="true" aria-controls="collapseTwo">
                {strp.stripslashes(item.pro_modifier_name)}
              </a>
            </h4>
          </div>
          <div id={"collapse" + index} className="panel-collapse collapse in" role="tabpanel" aria-labelledby={"modHead" + index}>
            <div className="panel-body">{this.showModifierItems(item.modifiers_values, index, modLen, item.pro_modifier_name, item.pro_modifier_id)}</div>
          </div>
        </div>
      ))
    }
  }

  /*  hide minmax bar worksapce */
  showMinmaxBar() {
    return APPID === apps.workspace ? "block" : "block"
  }

  /* close toggle bar */
  gettoggleClassName() {
    return APPID === apps.workspace ? "collapsed" : ""
  }

  /* close toggle bar */
  gettoggleClassName2() {
    return APPID === apps.workspace ? "false" : "true"
  }

  /* close toggle bar */
  gettoggleClassName3() {
    return APPID === apps.workspace ? "" : "in"
  }
  /* show set products values */
  showSetMeal(isCombo, mealVal, minmaxSelect) {
    if (isCombo === "Yes") {
      var mealLength = mealVal.length
      var minmaxSelect = parseFloat(minmaxSelect)
      if (mealLength > 0) {
        if (minmaxSelect === 1) {
          return mealVal.map((item, index) => (
            <div className="panel panel-default" key={index}>
              <div className="panel-heading" role="tab" id={"headingTwo" + index}>
                <h4 className="panel-title modifier_title">
                  <a className={this.gettoggleClassName()} onClick={this.checkActive.bind()} data-toggle="collapse" data-parent="#accordion" href={"#collapse" + index} aria-expanded={this.gettoggleClassName2()} aria-controls="collapseTwo">
                    {strp.stripslashes(item.menu_set_component_name)}
                  </a>
                </h4>
              </div>

              <div id={"collapse" + index} className={"panel-collapse collapse " + this.gettoggleClassName3()} role="tabpanel" aria-labelledby={"headingTwo" + index} aria-expanded={this.gettoggleClassName2()}>
                <div className="max-min-bar" style={{ display: this.showMinmaxBar() }}>
                  You have chosen <span className={"minSele-" + index}>0</span> out of {item.menu_component_min_select}. (Max. {item.menu_component_max_select}){" "}
                </div>
                <div className="panel-body">
                  {this.showSetMealProducts(
                    item.product_details,
                    index,
                    mealLength,
                    item.menu_component_name,
                    item.menu_component_id,
                    item.menu_component_default_select,
                    item.menu_component_min_select,
                    item.menu_component_max_select,
                    minmaxSelect,
                    item.menu_component_apply_price,
                    item.menu_component_modifier_apply,
                    item.menu_component_multipleselection_apply
                  )}
                  <input type="hidden" className={"combo-min-" + index} value={item.menu_component_min_select} />
                  <input type="hidden" className={"combo-min-name" + index} value={strp.stripslashes(item.menu_set_component_name)} />
                  <input type="hidden" className={"combo-max-" + index} value={item.menu_component_max_select} />
                  {/* M od */}

                  {/* End of mod*/}
                </div>
              </div>
            </div>
          ))
        } else {
          return mealVal.map((item, index) => (
            <div className="panel panel-default" key={index}>
              <div className="panel-heading" role="tab" id={"headingTwo" + index}>
                <h4 className="panel-title">
                  <a className="" onClick={this.checkActive.bind()} data-toggle="collapse" data-parent="#accordion" href={"#collapse" + index} aria-expanded="false" aria-controls="collapseTwo">
                    {strp.stripslashes(item.menu_set_component_name)}
                  </a>
                </h4>
              </div>
              <div id={"collapse" + index} className="panel-collapse collapse in" role="tabpanel" aria-labelledby={"headingTwo" + index}>
                <div className="panel-body">
                  {this.showSetMealProducts(item.product_details, index, mealLength, item.menu_component_name, item.menu_component_id, item.menu_component_default_select, item.menu_component_min_select, item.menu_component_max_select, minmaxSelect, item.menu_component_apply_price, "0", "0")}
                  {/* M od */}

                  {/* End of mod*/}
                </div>
              </div>
            </div>
          ))
        }
      }
    }
  }

  /* show & hide set meal modifier child products */
  showModDiv(modifiers, setIndex, mindex) {
    var modLen = modifiers !== null ? modifiers.length : 0
    var elems = document.getElementsByClassName("modDiv" + setIndex)
    for (var i = 0; i < elems.length; i++) {
      elems[i].style.display = "none"
    }
    if (modLen > 0) {
      var showEle = document.getElementsByClassName("modDiv_" + setIndex + mindex)
      showEle[0].style.display = "block"
    }

    this.getExtraModifierCost()
  }

  /* if modifier products selected show modifer div */
  modifierDefault(isDefault, productId, modVal) {
    var modLen = modVal !== null ? modVal.length : 0
    return isDefault === productId && modLen > 0 ? "block" : "none"
  }

  comboMultiSelectUpdate(minSelect, maxSelect, setCount, inputId, flagTxt, unitPrice, productId, applyPrice) {
    var actionTxt = "no"
    var actionFlg = ""
    if (flagTxt === "comboSinglStVal_") {
      var $_this = this
      $(".hiddenInput_" + setCount).each(function (indx) {
        var inputIdArr = $(this).attr("id").split("~")
        var inputIdTxt = inputIdArr[0]
        var prodArr = inputIdArr[0].split("_")
        var unitPriceTxt = $(this).val()
        $_this.incComboQty(minSelect, maxSelect, setCount, inputIdTxt, "remove", unitPriceTxt, prodArr[0], applyPrice, flagTxt)
        actionTxt = "yes"
        actionFlg = "add"
      })
    } else {
      actionTxt = "yes"
      if ($("#" + flagTxt + inputId).prop("checked") == true) {
        actionFlg = "add"
      } else {
        actionFlg = "remove"
      }
    }

    if (actionTxt === "yes" && actionFlg !== "") {
      var $_this = this
      setTimeout(function () {
        $_this.incComboQty(minSelect, maxSelect, setCount, inputId, actionFlg, unitPrice, productId, applyPrice, flagTxt)
      }, 100)
    }
  }

  /* check min max setup */
  incComboQty(minSelect, maxSelect, setCount, inputId, action, unitPrice, productId, applyPrice, multiChk = null) {
    var getQty = parseInt($("#" + inputId).text())
    /* disable if click values is zero*/
    if (getQty <= 0 && action === "remove") {
      return false
    }
    /* get selected input qty values */
    var items = document.getElementsByClassName("hiddenInput_" + setCount)
    var itemCount = items.length
    var cartQty = 0
    for (var i = 0; i < itemCount; i++) {
      cartQty = cartQty + parseInt(document.getElementsByClassName("combinput_" + setCount)[i].innerHTML)
    }
    var addCartQty = cartQty + 1
    var removeCartQty = cartQty > 0 ? cartQty - 1 : 0

    /* if reached disable  add  buttoon */
    if (addCartQty > maxSelect && action === "add") {
      if (multiChk !== "") {
        $("#" + multiChk + inputId).prop("checked", false)
      }
      return false
    }

    /* chnage slected count */
    if (action === "add") {
      $(".minSele-" + setCount).html(addCartQty)
    } else if (action === "remove") {
      $(".minSele-" + setCount).html(removeCartQty)
    }

    /* show  price if choose gretter the min value  */
    if (addCartQty >= minSelect && action === "add") {
      $(".combproduct_" + setCount).fadeIn()
    }
    if (removeCartQty < minSelect && action === "remove" && applyPrice === 0) {
      $(".combproduct_" + setCount).fadeOut()
    }

    /* increase price  */
    var postArray = this.getarrayName(setCount)
    if (action === "add" && (addCartQty > minSelect || applyPrice === 1)) {
      var addedPrice = parseFloat(this.state.productPrice) + parseFloat(unitPrice)
      this.setState({ productPrice: parseFloat(addedPrice).toFixed(2) })
      this.addArryValues(postArray, productId, parseFloat(unitPrice))

      /* update min selct number */
    }
    //console.log(combo_0);
    /* Decrease price */
    if (action === "remove" && (removeCartQty >= minSelect || applyPrice === 1)) {
      this.decreasePrice(postArray, productId, parseFloat(unitPrice))
    }

    /*  increase or decrease  product item qty */
    if (action === "remove") {
      var newQty = getQty > 0 ? getQty - 1 : 0
    } else {
      newQty = getQty + 1
    }
    //document.getElementById(inputId).value = newQty;
    //$("#"+inputId).append(newQty);
    $("#" + inputId).text(parseInt(newQty))
  }

  /* set array values */
  addArryValues(postArray, productId, Price) {
    var returnData = this.addSearch(productId, postArray, "add")
    if (typeof returnData === "undefined") {
      postArray.push({ prId: productId, Qty: 1, Price: Price })
    }
  }

  /* get array name */
  getarrayName(setValue) {
    if (setValue === 0) {
      var retunArrCombo = combo_0
    } else if (setValue === 1) {
      retunArrCombo = combo_1
    } else if (setValue === 2) {
      retunArrCombo = combo_2
    } else if (setValue === 3) {
      retunArrCombo = combo_3
    } else if (setValue === 4) {
      retunArrCombo = combo_4
    } else if (setValue === 5) {
      retunArrCombo = combo_5
    } else if (setValue === 6) {
      retunArrCombo = combo_6
    } else if (setValue === 7) {
      retunArrCombo = combo_7
    } else if (setValue === 8) {
      retunArrCombo = combo_8
    } else if (setValue === 3) {
      retunArrCombo = combo_3
    } else if (setValue === 9) {
      retunArrCombo = combo_9
    } else if (setValue === 9) {
      retunArrCombo = combo_9
    } else if (setValue === 10) {
      retunArrCombo = combo_10
    } else if (setValue === 11) {
      retunArrCombo = combo_11
    } else if (setValue === 12) {
      retunArrCombo = combo_12
    } else if (setValue === 13) {
      retunArrCombo = combo_13
    } else if (setValue === 14) {
      retunArrCombo = combo_14
    } else if (setValue === 15) {
      retunArrCombo = combo_15
    } else if (setValue === 16) {
      retunArrCombo = combo_16
    } else if (setValue === 17) {
      retunArrCombo = combo_17
    } else if (setValue === 18) {
      retunArrCombo = combo_18
    } else if (setValue === 19) {
      retunArrCombo = combo_19
    } else if (setValue === 20) {
      retunArrCombo = combo_20
    } else if (setValue === 21) {
      retunArrCombo = combo_21
    } else if (setValue === 22) {
      retunArrCombo = combo_22
    } else if (setValue === 23) {
      retunArrCombo = combo_23
    } else if (setValue === 24) {
      retunArrCombo = combo_24
    } else if (setValue === 25) {
      retunArrCombo = combo_25
    }
    return retunArrCombo
  }

  /* set array values */
  decreasePrice(postArray, productId, Price) {
    // console.log(postArray);
    var returnDatadesc = this.addSearch(productId, postArray)
    if (typeof returnDatadesc === "undefined") {
      /* if product id not avilable */
      if (postArray[0]) {
        /*  update price */
        this.updatePriceCombo(postArray[0].Price)
        var cQty = parseInt(postArray[0].Qty) - 1
        if (cQty > 0) {
          postArray[0].Qty = parseInt(cQty)
        } else {
          postArray.splice(0, 1)
        }
      }
    } else {
      returnDatadesc = this.addSearch(productId, postArray, "remove")
    }

    // console.log(postArray);
  }

  /* find object exist */
  addSearch(nameKey, myArray, type) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].prId === nameKey) {
        /* add quantity */
        if (type === "add") {
          myArray[i].Qty = parseInt(myArray[i].Qty) + 1
        }
        /* remove QTY */
        if (type === "remove") {
          /* update product Price */
          this.updatePriceCombo(myArray[i].Price)
          var newQtyRes = parseInt(myArray[i].Qty) - 1
          if (newQtyRes > 0) {
            myArray[i].Qty = parseInt(newQtyRes)
          } else {
            myArray.splice(i, 1)
          }
        }
        return myArray[i]
        break
      }
    }
  }

  /* this function used to update price value */
  updatePriceCombo(unitPrice) {
    var newComboPrice = parseFloat(this.state.productPrice) - parseFloat(unitPrice)
    this.setState({ productPrice: parseFloat(newComboPrice).toFixed(2) })
  }

  showSimpleProprice(ProPrice, modifiers, mIndex, index, applyPrice) {
    var modLen = modifiers !== null ? modifiers.length : 0
    var shwPriceHtml = ""
    if (parseFloat(ProPrice) > 0 && modLen === 0 && applyPrice === 1) {
      var shwPriceHtl = "<span id='simpleproPrice_" + mIndex + "_" + index + "' className='simpleproPricecls'> (+$" + ProPrice + ")</span>"
      shwPriceHtml = htmlToReactParser.parse(shwPriceHtl)
    }
    return shwPriceHtml
  }

  showComboExtraPrice(minSel, applyPrice) {
    return parseInt(minSel) === 0 || applyPrice === 1 ? "" : "none"
  }

  showAdditionalPrice(price) {
    if (parseFloat(price) > 0) {
      return "(+ " + price + ")"
    }
  }

  showComboMultiSelect(singleSlt, mutilSlct) {
    return singleSlt === 1 || mutilSlct === 1 ? "none" : ""
  }

  /* this fucntion used to show set items list */
  showSetMealProducts(products, mIndex, length, comboName, comboId, isDefault, minSelect, maxSelect, minmaxSelect, applyPrice, modifierApply, multiSelectApply) {
    //console.log(products);
    var totalLen = products.length
    var minmaxSelect = parseInt(minmaxSelect)
    var modItemLen = totalLen - 1
    var applyPrice = parseInt(applyPrice)
    var multiSelectApply = multiSelectApply !== "" ? parseInt(multiSelectApply) : 0
    var modifierApply = modifierApply !== "" ? parseInt(modifierApply) : 0

    if (totalLen === 0) {
      helper.showInfo("Products items missing")
      hashHistory.push("/")
    }

    /*  Modifer count */
    cookie.save("comboCount", length)
    cookie.save("itemType", "Combo")

    if (minmaxSelect === 1) {
      return products.map((item, index) => (
        <div className={this.showArrow(modItemLen, index)} key={index}>
          <h1 className="bb-txt2 margin-15">
            <span className="product_name_details">
              {strp.stripslashes(helper.getAliasName(item[0].product_alias, item[0].product_name))}
              <span className={"combproduct_" + mIndex} style={{ display: this.showComboExtraPrice(minSelect, applyPrice) }}>
                {" "}
                {this.showAdditionalPrice(item[0].product_price)}{" "}
              </span>
            </span>
            <div className="radio  pull-right combo-inc-parent" style={{ display: this.showComboMultiSelect(modifierApply, multiSelectApply) }}>
              <Link className="btn btn-my combo_inc" onClick={this.incComboQty.bind(this, minSelect, maxSelect, mIndex, item[0].product_id + "_" + mIndex + "_" + index, "remove", item[0].product_price, item[0].product_id, applyPrice)}>
                <i className="material-icons">remove</i>
              </Link>
              &nbsp;
              <lable type="text" id={item[0].product_id + "_" + mIndex + "_" + index} disabled value="0" style={{ width: 40, textAlign: "center" }} className={"combo-input combinput_" + mIndex}>
                0
              </lable>
              <input type="hidden" className={"hiddenInput_" + mIndex} id={item[0].product_id + "_" + mIndex + "_" + index + "~proflg"} value={item[0].product_price} />
              <input
                className={item[0].product_id + "_" + mIndex + "_" + index + " css-radio"}
                type="hidden"
                value={comboName + "~" + comboId + "~" + helper.getAliasName(item[0].product_alias, item[0].product_name) + "~" + item[0].product_id + "~" + item[0].product_sku + "~" + item[0].product_price}
              />
              <Link className="btn btn-my combo_dec" onClick={this.incComboQty.bind(this, minSelect, maxSelect, mIndex, item[0].product_id + "_" + mIndex + "_" + index, "add", item[0].product_price, item[0].product_id, applyPrice)}>
                <i className="material-icons">add</i>
              </Link>
              {/* new product setup   <input className="css-radio" type="radio" value={comboName +"~"+ comboId +"~"+ item[0].product_name +"~"+ item[0].product_id +"~"+ item[0].product_sku} id={item[0].product_id+"_"+mIndex+"_"+index }   name={"comboVal_"+ mIndex } defaultChecked={this.defaultSetMeal(isDefault,item[0].product_id)} onChange={this.showModDiv.bind(this,item[0].modifiers,mIndex,index)}  /><label htmlFor={item[0].product_id+"_"+mIndex+"_"+index} className="css-label" ></label> */}
            </div>

            {modifierApply === 1 && (
              <div className="radio pull-right">
                <input
                  className="css-radio"
                  type="radio"
                  onChange={this.comboMultiSelectUpdate.bind(this, minSelect, maxSelect, mIndex, item[0].product_id + "_" + mIndex + "_" + index, "comboSinglStVal_", item[0].product_price, item[0].product_id, applyPrice)}
                  value={comboName + "~" + comboId + "~" + helper.getAliasName(item[0].product_alias, item[0].product_name) + "~" + item[0].product_id + "~" + item[0].product_sku + "~" + item[0].product_price}
                  name={"comboSinglStVal_" + mIndex}
                  id={"comboSinglStVal_" + item[0].product_id + "_" + mIndex + "_" + index}
                />{" "}
                <label htmlFor={"comboSinglStVal_" + item[0].product_id + "_" + mIndex + "_" + index} className="css-label"></label>
              </div>
            )}

            {multiSelectApply === 1 && (
              <div className="checkboxcls pull-right">
                <input
                  className="css-checkboxcls"
                  type="checkbox"
                  onChange={this.comboMultiSelectUpdate.bind(this, minSelect, maxSelect, mIndex, item[0].product_id + "_" + mIndex + "_" + index, "comboMultiStVal_", item[0].product_price, item[0].product_id, applyPrice)}
                  value={comboName + "~" + comboId + "~" + helper.getAliasName(item[0].product_alias, item[0].product_name) + "~" + item[0].product_id + "~" + item[0].product_sku + "~" + item[0].product_price}
                  name={"comboMultiStVal_" + mIndex}
                  id={"comboMultiStVal_" + item[0].product_id + "_" + mIndex + "_" + index}
                />{" "}
                <label htmlFor={item[0].product_id + "_" + mIndex + "_" + index} className="css-label-chkbox"></label>
              </div>
            )}
          </h1>

          {/* Mod Start */}
          {/* commented vinoth.k for new product setup 26/04/2017   <div  style={{display:this.modifierDefault(isDefault,item[0].product_id,item[0].modifiers)}} className={"modDiv"+mIndex+" modDiv_"+mIndex+index} id={"parDiv"+item[0].product_id+mIndex} >
				          {this.showSetMealModifeirs(item[0].modifiers,item[0].product_id,mIndex,index)} 
						 </div> */}
          {/* Mod End */}
        </div>
      ))
    } else {
      return products.map((item, index) => (
        <div className={this.showArrow(modItemLen, index)} key={index}>
          <h1 className="bb-txt2 margin-15">
            {strp.stripslashes(helper.getAliasName(item[0].product_alias, item[0].product_name))} {this.showSimpleProprice(item[0].product_price, item[0].modifiers, mIndex, index, applyPrice)}
            <div className="radio  pull-right">
              <input
                className="css-radio"
                type="radio"
                value={comboName + "~" + comboId + "~" + helper.getAliasName(item[0].product_alias, item[0].product_name) + "~" + item[0].product_id + "~" + item[0].product_sku + "~" + applyPrice + "~" + item[0].product_price}
                id={item[0].product_id + "_" + mIndex + "_" + index}
                name={"comboVal_" + mIndex}
                defaultChecked={this.defaultSetMeal(isDefault, item[0].product_id)}
                onChange={this.showModDiv.bind(this, item[0].modifiers, mIndex, index)}
              />{" "}
              <label htmlFor={item[0].product_id + "_" + mIndex + "_" + index} className="css-label"></label>
            </div>
          </h1>

          {/* Mod Start */}
          <div style={{ display: this.modifierDefault(isDefault, item[0].product_id, item[0].modifiers) }} className={"modDiv" + mIndex + " modDiv_" + mIndex + index} id={"parDiv" + item[0].product_id + mIndex}>
            {this.showSetMealModifeirs(item[0].modifiers, item[0].product_id, mIndex, index)}
          </div>
          {/* Mod End */}
        </div>
      ))
    }
  }

  /* load set meal modifiers items */
  showSetMealModifeirs(modifiers, productId, mIndex, pIndex) {
    var modLen = modifiers !== null ? modifiers.length : 0
    if (modLen > 0) {
      return modifiers.map((modItems, index) => (
        <div className={"fancy-collapse-panel fancy-collapse-inner" + "  setModValClass" + productId + mIndex} key={index}>
          <div className="panel-group" id={"accordion2" + index + mIndex} role="tablist" aria-multiselectable="true">
            <div className="panel panel-default">
              <div className="panel-heading" role="tab" id={"modHeadingTwo" + index + mIndex + pIndex}>
                <h4 className="panel-title">
                  <a className="" data-toggle="collapse" data-parent={"#accordion2" + index + mIndex} href={"#collapse_flavour" + index + mIndex + pIndex} aria-expanded="true" aria-controls="collapseTwo">
                    <img src="/img/share.png" />
                    {modItems.pro_modifier_name}
                  </a>
                </h4>
              </div>
              {/* mod itesm*/}
              <div id={"collapse_flavour" + index + mIndex + pIndex} className={"panel-collapse collapse in "} role="tabpanel" aria-labelledby={"modHeadingTwo" + index + mIndex + pIndex}>
                <div className="panel-body">{this.showSetMealModifierItems(modItems.modifiers_values, index, modLen, modItems.pro_modifier_name, modItems.pro_modifier_id, productId, mIndex, pIndex)}</div>
              </div>
            </div>
          </div>
        </div>
      ))
    }
  }

  /* this function used to show  set meal products modifier items */

  /* Show modifier price */
  showModifierError(price) {}

  /* combo modifier extra price */
  showModValuePrice(Price) {
    var modPrice = parseFloat(Price)
    return modPrice > 0 ? "(+$" + modPrice.toFixed(2) + ")" : ""
  }

  /* this fucntion used to show modifer items */
  showSetMealModifierItems(modVal, mIndex, length, modName, modId, productId, setIndex, pIndex) {
    //alert(setIndex);
    var totalLen = modVal.length
    var modItemLen = totalLen - 1
    if (totalLen === 0) {
      helper.showInfo("Modifier items missing")
      hashHistory.push("/")
    }

    return modVal.map((item, index) => (
      <div className={this.showArrow(modItemLen, index)} key={index}>
        <h1 className="bb-txt2 margin-15">
          {strp.stripslashes(item.pro_modifier_value_name)} {this.showModValuePrice(item.pro_modifier_value_price)}
          <div className="radio  pull-right">
            <input
              onClick={this.getExtraModifierCost.bind(this)}
              className="css-radio extraPrice subitems"
              type="radio"
              value={modName + "~" + modId + "~" + item.pro_modifier_value_name + "~" + item.pro_modifier_value_id + "~" + item.pro_modifier_value_price}
              id={item.pro_modifier_value_id + "_" + setIndex + "_" + pIndex + "_" + mIndex + "_" + index}
              name={"modValItems" + productId + "-" + setIndex + "-" + mIndex}
              defaultChecked={this.isDefault(item.pro_modifier_value_is_default)}
            />
            <label htmlFor={item.pro_modifier_value_id + "_" + setIndex + "_" + pIndex + "_" + mIndex + "_" + index} className="css-label"></label>
          </div>
        </h1>
      </div>
    ))
  }

  /* choose defaultg set meal product */
  defaultSetMeal(isDefault, productId) {
    return isDefault === productId ? true : false
  }

  /* show Address */
  showNewAddressFrmd(pstcode, address1, address2, unittxt1, unittxt2) {
    var addressLn = address2 != "" ? address1 + ", " + address2 : address1
    if (unittxt1 != "" && unittxt2 != "") {
      addressLn = addressLn + ", #" + unittxt1 + "-" + unittxt2
    } else if (unittxt1 != "" || unittxt2 != "") {
      addressLn = unittxt1 != "" ? addressLn + ", #" + unittxt1 : addressLn + ", #" + unittxt2
    }

    addressLn = addressLn + ", Singapore " + pstcode
    return addressLn
  }

  /* load outlets */
  __loadOutlets() {
    if (this.state.Outlets) {
      let list = helper.getSortedList(this.state.Outlets, this.props.coords)

      if (!isEmpty(list) && list.length > 0) {
        return list.map((loaddata, index) => (
          <div
            className="map-li"
            key={index}
            onClick={this.pickOutlet.bind(
              this,
              loaddata.outlet_id,
              loaddata.outlet_name,
              loaddata.outlet_postal_code,
              loaddata.outlet_address_line1,
              loaddata.outlet_address_line2,
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2,
              loaddata.outlet_pickup_tat,
              loaddata.outlet_tax_option,
              loaddata.outlet_tax_surcharge,
              loaddata.outlet_tax_surcharge_inclusive
            )}
          >
            <p className="map-h outlet-name">{helper.stripslashes(loaddata.outlet_name)}</p>
            <p className="map-sub">{loaddata.outlet_address_line1 + ", " + loaddata.outlet_address_line2 + " " + loaddata.outlet_postal_code}</p>
            <Distancebar minDateValue={loaddata.min_date} isOutletBased={loaddata.is_outlet_based} distance={loaddata.distance} coords={this.props.coords} tat={loaddata.outlet_pickup_tat ? loaddata.outlet_pickup_tat : loaddata.outlet_delivery_timing} />
          </div>
        ))
      } else {
        return <Outletclosedmessage messages={this.state.outletCloseddMessage} />
      }
    }
  }
  __loadDeliveryOutlets() {
    if (this.state.deliveryOutlets) {
      let list = helper.getSortedList(this.state.deliveryOutlets, this.props.coords)
      if (!isEmpty(list)) {
        return list.map((loaddata, index) => (
          <div className="map-li" key={index} onClick={this.showZonalPostalCode.bind(this, loaddata.outlet_id)}>
            <p className="map-h outlet-name">{helper.stripslashes(loaddata.outlet_name)}</p>
            <p className="map-sub">
              {this.showNewAddressFrmd(loaddata.outlet_postal_code, loaddata.outlet_address_line1, "", "", "")} {loaddata.outlet_unit_number2}
            </p>
            <Distancebar minDateValue={loaddata.min_date} isOutletBased={loaddata.is_outlet_based} distance={loaddata.distance} coords={this.props.coords} tat={loaddata.outlet_delivery_tat ? loaddata.outlet_delivery_tat : loaddata.outlet_delivery_timing} />
          </div>
        ))
      } else {
        return <Outletclosedmessage messages={this.state.outletCloseddMessage} />
      }
    }
  }

  /* this fucntion used to show modifer items */
  showModifierItems(modVal, mIndex, length, modName, modId) {
    //console.log(modVal);
    var totalLen = modVal.length
    var modItemLen = totalLen - 1
    if (totalLen === 0) {
      helper.showInfo("Modifier items missing")
      hashHistory.push("/")
    }

    /*  Modifer count */
    cookie.save("modiferCount", length)
    cookie.save("itemType", "Modifier")

    return modVal.map((item, index) => (
      <div className={this.showArrow(modItemLen, index)} key={index}>
        <h1 className="bb-txt2 margin-15">
          {strp.stripslashes(item.pro_modifier_value_name) + this.modifierPrice(item.pro_modifier_value_price)}
          <div className="radio  pull-right">
            <input
              className="css-radio"
              type="radio"
              value={modName + "~" + modId + "~" + item.pro_modifier_value_name + "~" + item.pro_modifier_value_id + "~" + item.pro_modifier_value_price}
              id={item.pro_modifier_value_id}
              name={"modVal_" + mIndex}
              defaultChecked={this.isDefault(item.pro_modifier_value_is_default, modName, modId, item.pro_modifier_value_name, item.pro_modifier_value_id, item.pro_modifier_value_price)}
              onChange={this.validateModifier.bind(this, length, modName, modId, item.pro_modifier_value_name, item.pro_modifier_value_id, item.pro_modifier_value_price, "")}
            />
            <label htmlFor={item.pro_modifier_value_id} className="css-label"></label>
          </div>
        </h1>
      </div>
    ))
  }

  /* show  modifier extra amount */
  modifierPrice(price) {
    return price > 0 ? " (+" + price + ")" : ""
  }

  /* this function used to validate modifer values */
  validateModifier(ModifierLen, modName, modId, modValName, modValId, modValPrice, productId) {
    //alert(productId);
    var allModVal = ""
    var commaVal = ModifierLen - 1
    var sts = "Fail"
    //console.log(modName + "_"+modId+" - "+modValName+"-"+modValId+"-"+modValPrice);
    for (var i = 0, length = ModifierLen; i < length; i++) {
      var inputName = "modVal_" + i
      if (document.querySelector("input[name = " + inputName + "]:checked")) {
        var modVal = document.querySelector("input[name = " + inputName + "]:checked").value
        var res = modVal.split("~")
        if (modVal === "") {
          sts = "Fail"
          document.getElementById("addToCart").disabled = true
          helper.showInfo(lang.select_all_modofier)
          break
        } else {
          sts = "Success"
          document.getElementById("addToCart").disabled = false
          var strt = i === commaVal ? res[3] : res[3] + ";"
          allModVal += strt
          /* post data */
        }
      } else {
        sts = "Fail"
        document.getElementById("addToCart").disabled = true
        helper.showInfo(lang.select_all_modofier)
        break
      }
    }
    if (sts === "Success") {
      /* for normal modifiers get state value, set meal product pass param */
      productId = productId === "" ? this.state.details.product_id : productId

      //alert(productId);
      axios.get(APIURLPRO + "products/validate_product?app_id=" + APPID + "&product_id=" + productId + "&modifier_value_id=" + allModVal).then((res) => {
        /* Success response */
        if (res.data.status === "ok") {
          this.setState({ sts: "No" })
          this.setState({ allowCart: "Yes" })
          this.setState({ productPrice: res.data.result_set[0].product_price, productUnitPrice: res.data.result_set[0].product_price })
        }
        /* Error response */
        if (res.data.status === "error") {
          helper.showInfo(res.data.message)
          this.setState({ allowCart: "No" })
          document.getElementById("addToCart").disabled = true
        }
      })
    }
  }

  /* this function used to checked radio value */
  isDefault(select) {
    return select === "Yes" ? true : false
  }

  /* this function used to show show  arrow*/
  showArrow(len, index) {
    return len === index ? "" : "acrow"
  }

  /* increase cart qty */
  iQty() {
    this.setState({ cartCount: this.state.cartCount + 1 })
  }

  /* decrease cart qty */
  dQty() {
    if (this.state.cartCount !== 1) {
      this.setState({ cartCount: this.state.cartCount - 1 })
    }
  }

  /* pick outlet */
  pickOutlet(outlet_id, outlet_name, outlet_postal_code, outlet_address_line1, outlet_address_line2, outlet_unit_number1, outlet_unit_number2, outlet_pickup_tat, outlet_tax_option, outlet_tax_surcharge, outlet_tax_surcharge_inclusive) {
    var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code
    //console.log(outlet_id+outlet_postal_code)
    this.setState({ orderHandled: orderHandled })
    cookie.save("orderOutletId", outlet_id)
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)
    cookie.save("orderHandledByText", orderHandled)

    cookie.save("outlet_tax_option", outlet_tax_option)
    cookie.save("outlet_tax_surcharge", outlet_tax_surcharge)
    cookie.save("outlet_tax_surcharge_inclusive", outlet_tax_surcharge_inclusive)

    $("#Pickup-OutletSelection").modal("toggle")
    $("#Pickup-Sucess").modal("toggle")
  }

  showZonalPostalCode(outletId) {
    $("#delivery_outlet").modal("toggle")
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      $("#AddressList").modal("toggle")
    } else {
      $("#DeliveryAddress").modal("toggle")
    }

    this.setState({ zoneDeliveryOutlet: outletId })
  }

  /* continue with pickup success */
  pickupSuccess() {
    var productId = this.state.productId
    var proName = this.state.proName
    var prosku = this.state.prosku
    var proPrice = this.state.proPrice
    var proQty = this.state.proQty
    var proImage = this.state.proImage
    var allowCart = this.state.allowCart
    var applyMinmaxSelect = this.state.applyMinmaxSelect
    $("#Pickup-Sucess").modal("hide")
    $("#pickup-outlet-sucess").modal("hide")
    this.addToCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart, applyMinmaxSelect)
  }

  /* continue with delivery success */
  deliverySuccess() {
    var productId = this.state.productId
    var proName = this.state.proName
    var prosku = this.state.prosku
    var proPrice = this.state.proPrice
    var proQty = this.state.proQty
    var proImage = this.state.proImage
    var allowCart = this.state.allowCart
    var applyMinmaxSelect = this.state.applyMinmaxSelect
    $("#DeliverySucess").modal("toggle")
    this.addToCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart, applyMinmaxSelect)
  }

  /* Del back */
  backToDeliveryBack() {
    $("#DeliveryAddress").modal("toggle")
    $("#delivery_outlet").modal("toggle")
  }

  /* Select outlet */

  selectOutlet(thisobject, retry = false, callbackPostalCode, addressObj) {
    var postalcode = ""
    let address = ""
    let googleRetryAttempt = 0
    if (retry === false) {
      if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
        postalcode = $("#postalcode").val() //"postalcode";
      } else {
        postalcode = $("#postalcode1").val() // "postalcode1";
      }
    } else {
      postalcode = callbackPostalCode
      address = addressObj
      googleRetryAttempt = 1
    }

    if (postalcode.length < 5) {
      return false
    }

    this.setState({ load: "true" })

    var findPostUrl = APIURL + "outlets/find_outlet?skip_timing=Yes&app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode
    if (this.state.zoneEnabled === 1) {
      findPostUrl =
        API_v4_URL + "test/outlets/findOutletZone?skip_timing=Yes&app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode + "&outlet_id=" + this.state.zoneDeliveryOutlet + "&googleRetryAttempt=" + googleRetryAttempt + "&addressObject=" + address
    }
    /*add loading */
    axios.get(findPostUrl).then((res) => {
      /* Success response */
      /* Success response  && Object.keys(res.data.result_set.postal_code_information).length !==0 */
      if (res.data.status === "ok") {
        this.setState({ load: "true" })
        /*if(Object.keys(res.data.result_set.postal_code_information).length !==0)
					{ */
        var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname
        var orderHandled = res.data.result_set.outlet_name + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes"
        this.setState({ orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code })
        this.setState({ orderHandled: orderHandled })
        cookie.save("orderOutletId", res.data.result_set.outlet_id)
        cookie.save("orderOutletName", res.data.result_set.outlet_name)
        cookie.save("orderPostalCode", res.data.result_set.postal_code_information.zip_code)
        cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing)
        cookie.save("orderDeliveryAddress", orderDeliveryAddress)
        cookie.save("orderHandled", orderHandled)

        cookie.save("outlet_tax_option", res.data.result_set.outlet_tax_option)
        cookie.save("outlet_tax_surcharge", res.data.result_set.outlet_tax_surcharge)
        cookie.save("outlet_tax_surcharge_inclusive", res.data.result_set.outlet_tax_surcharge_inclusive)

        var orderHandledText = res.data.result_set.outlet_name + " " + res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", Singapore " + postalcode
        cookie.save("orderHandledByText", orderHandledText)

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("hide")
        } else {
          $("#DeliveryAddress").modal("hide")
        }
        $("#address-auto").modal("hide")
        $("#DeliverySucess").modal("toggle")

        if (this.state.zoneEnabled === 1) {
          cookie.save("orderZoneID", res.data.result_set.zone_id)
        }

        /* }else{
						 $("#DeliveryAddress").modal("toggle");
						 $("#DeliveryError").modal("toggle");
					 } */
      }
      /* Error response */
      if (res.data.status === "error") {
        this.setState({ load: "false" })
        /* check google api result */
        let google_api_result = false
        if (typeof res.data.not_fount_in_google !== "undefined" && res.data.not_fount_in_google === true) {
          this.setState({ enableAddressSearch: true })
          google_api_result = true
        }

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("hide")
        } else {
          $("#DeliveryAddress").modal("hide")
        }

        if (google_api_result === false) {
          if (res.data.shop_close === "Yes") {
            $("#ShopClosedError").modal("toggle")
          } else {
            $("#DeliveryError").modal("toggle")
          }
        } else {
          $("#address-auto").modal("show")
        }
      }
    })
  }

  /* Open Zipcode popup */

  changeDelivery() {
    this.setState({ load: "false" })
    this.setState({ addressField: "" })
    $("#DeliveryError").modal("toggle")
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      $("#AddressList").modal("toggle")
    } else {
      $("#DeliveryAddress").modal("toggle")
    }

    //$("#DeliveryAddress").modal("toggle");
  }
  closeDeliveryErr() {
    this.setState({ load: "false" })
    $("#DeliveryError").modal("toggle")
  }

  closeShopClosedError() {
    this.setState({ load: "false" })
    $("#ShopClosedError").modal("toggle")
  }

  /* Validate zipcode */
  validatezip(e) {
    const re = /[0-9A-F:]+/g
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  }

  /* formated  string */
  formatString(str) {
    var stringVal = window.btoa(str)
    return stringVal.replace(/\=/g, "")
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value })
    this.setState({ ["error_" + item]: "" })
  }

  cashVocherMesumbit(productId, proName, prosku, proPrice, proQty, proImage, allowCart, applyMinmaxSelect, product_voucher_expiry_date, product_voucher, product_voucher_points) {
    $(".voucher_popup").trigger("click")
    this.addToVoucherCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart, applyMinmaxSelect, product_voucher_expiry_date, product_voucher, product_voucher_points)
  }

  cashVochersumbit(productId, proName, prosku, proPrice, proQty, proImage, allowCart, applyMinmaxSelect, product_voucher_expiry_date, product_voucher, product_voucher_points) {
    var error = 0
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++
      this.setState({ error_voucher_name: htmlToReactParser.parse('<span class="spn-error">This field is required.</span>') })
    } else {
      this.setState({ error_voucher_name: "" })
    }
    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++
      this.setState({ error_voucher_email: htmlToReactParser.parse('<span class="spn-error">This field is required.</span>') })
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.voucher_email)) {
      error++
      this.setState({ error_voucher_email: htmlToReactParser.parse('<span class="spn-error">This is not a valid email.</span>') })
    } else {
      this.setState({ error_voucher_email: "" })
    }
    if (this.state.voucher_message === null || this.state.voucher_message === "") {
      error++
      this.setState({ error_voucher_message: htmlToReactParser.parse('<span class="spn-error">This field is required.</span>') })
    } else {
      this.setState({ error_voucher_message: "" })
    }
    if (error == 0) {
      $(".voucher_popup").trigger("click")
      this.addToVoucherCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart, applyMinmaxSelect, product_voucher_expiry_date, product_voucher, product_voucher_points)
    }
  }

  /* add to cart */
  addToVoucherCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart, applyMinmaxSelect, product_voucher_expiry_date, product_voucher, product_voucher_points) {
    fbPixelCustomEvent("AddToCart", {})
    this.setState({ productId: productId })
    this.setState({ proName: proName })
    this.setState({ prosku: prosku })
    this.setState({ proPrice: proPrice })
    this.setState({ proQty: proQty })
    this.setState({ proImage: proImage })
    this.setState({ allowCart: allowCart })
    this.setState({ productvoucher_expiry_date: product_voucher_expiry_date })
    this.setState({ applyMinmaxSelect: parseInt(applyMinmaxSelect) })

    /* check add to cart permission */
    if (allowCart === "No") {
      helper.showInfo(lang.modifier_missing)
      return false
    }

    /* Post Modifier part */

    var modifier = []

    if (cookie.load("itemType") === "Modifier" && cookie.load("modiferCount") > 0) {
      for (var i = 0, lengthMod = cookie.load("modiferCount"); i < lengthMod; i++) {
        var inputName = "modVal_" + i
        if (document.querySelector("input[name = " + inputName + "]:checked")) {
          var modVal = document.querySelector("input[name = " + inputName + "]:checked").value
          if (modVal !== "") {
            var res = modVal.split("~")
            var modifierVal = []
            modifierVal.push({ modifier_value_id: res[3], modifier_value_qty: "1", modifier_value_name: res[2], modifier_value_price: res[4] })
            modifier.push({ modifier_id: res[1], modifier_name: res[0], modifiers_values: modifierVal })
          } else {
            helper.showInfo(lang.modifier_missing)
            return false
          }
        } else {
          helper.showInfo(lang.modifier_missing)
          return false
        }
      }
    }

    /* set meat products array */
    var combo = []
    var comborVal = []
    var comboModifier = []
    var comboSelction = "Fail"
    var modifierSelction = "Fail"
    var overAllQty = 0
    var totalMinSelect = 0
    if (parseInt(applyMinmaxSelect) === 1) {
      if (cookie.load("itemType") === "Combo" && cookie.load("comboCount") > 0) {
        for (var m = 0, length = cookie.load("comboCount"); m < length; m++) {
          var _items = document.getElementsByClassName("hiddenInput_" + m)
          var _itemCount = _items.length
          var _cartQty = 0
          var selMincount = 0
          var selMaxcount = 0
          var selMinSetName = ""

          var comborVal = []
          var menu_component_id = ""
          var menu_component_name = ""
          var selCurQty = 0
          for (var i = 0; i < _itemCount; i++) {
            selCurQty = parseInt(document.getElementsByClassName("combinput_" + m)[i].innerHTML)
            _cartQty = _cartQty + selCurQty
            var comboRes = ""
            var PriceArray = []
            var appliedPrice = []
            if (selCurQty > 0) {
              var comboIdVal = $(".combinput_" + m)[i].id
              var comboDatas = $("." + comboIdVal).val()
              if (comboDatas) {
                /* get extra amount applied products */
                comboRes = comboDatas.split("~")
                PriceArray = this.getarrayName(m)

                var appliedPrice = this.addSearch(comboRes[3], PriceArray, "get")
                var newQtyPrice = 0
                if (appliedPrice) {
                  newQtyPrice = appliedPrice.Price
                }

                comborVal.push({ product_id: comboRes[3], product_name: comboRes[2], product_sku: comboRes[4], product_qty: selCurQty, product_price: newQtyPrice, modifiers: comboModifier })
                menu_component_id = comboRes[1]
                menu_component_name = comboRes[0]
              }
            }

            if (i === _itemCount - 1 && menu_component_id != "" && menu_component_name != "" && comborVal) {
              combo.push({ menu_component_id: menu_component_id, menu_component_name: menu_component_name, product_details: comborVal })
            }
            /* push products to array values  */
          }

          overAllQty = overAllQty + _cartQty

          /* validate min max setup */
          selMincount = parseInt($(".combo-min-" + m).val())
          selMinSetName = $(".combo-min-name" + m).val()
          selMaxcount = parseInt($(".combo-max-" + m).val())

          totalMinSelect = totalMinSelect + selMincount
          if (_cartQty < selMincount) {
            helper.showInfo("Please select the min number of items for " + selMinSetName)
            return false
          }
          if (_cartQty > selMaxcount) {
            helper.showInfo("Maximum selection limit exceed")
            return false
          }
          /* add products  */
        }

        if (overAllQty <= 0 && totalMinSelect > 0) {
          helper.showInfo("Please choose any combo product combination")
          return false
        }
      }
    } else {
      /* OLD Combo product setup */

      if (cookie.load("itemType") === "Combo" && cookie.load("comboCount") > 0) {
        for (var m = 0, length = cookie.load("comboCount"); m < length; m++) {
          var inputName = "comboVal_" + m
          var comboRes = ""
          if (document.querySelector("input[name = " + inputName + "]:checked")) {
            var comboVal_ = document.querySelector("input[name = " + inputName + "]:checked").value
            if (comboVal_ !== "") {
              comboSelction = "Success"
              comboRes = comboVal_.split("~")
              var product_id = comboRes[3]
              var pro_priceAply = parseInt(comboRes[5])
              var simplepro_price = parseFloat(comboRes[6])
              var compoSimpleproprice = parseFloat(0)
              /* Validate modififer selection - start */
              var comboModifier = []
              var modLength = 0
              var modLength = document.querySelectorAll(".setModValClass" + product_id + m).length
              if (modLength > 0) {
                comboModifier = []
                for (var n = 0, forLen = modLength; n < forLen; n++) {
                  var inputName = "modValItems" + product_id + "-" + m + "-" + n
                  if (document.querySelector("input[name = " + inputName + "]:checked")) {
                    var modComboVal = document.querySelector("input[name = " + inputName + "]:checked").value
                    if (modComboVal !== "") {
                      modifierSelction = "success"
                      var comboModRes = modComboVal.split("~")
                      var comboModifierVal = []

                      comboModifierVal.push({ modifier_value_id: comboModRes[3], modifier_value_qty: "1", modifier_value_name: comboModRes[2], modifier_value_price: comboModRes[4] })
                      comboModifier.push({ modifier_id: comboModRes[1], modifier_name: comboModRes[0], modifiers_values: comboModifierVal })
                    } else {
                      modifierSelction = "Fail"
                    }
                  } else {
                    modifierSelction = "Fail"
                  }

                  /* if modifier selection misiing show alert */
                  if (modifierSelction === "Fail") {
                    helper.showInfo(lang.modifier_missing)
                    return false
                  }
                } /* end of second modifer for */
              } else {
                if (pro_priceAply === 1) {
                  compoSimpleproprice = simplepro_price
                }
              }

              /* Validate modififer selection - End  */

              /* object formation  - start */
              var comborVal = []
              comborVal.push({ product_id: comboRes[3], product_name: comboRes[2], product_sku: comboRes[4], product_qty: 1, product_price: compoSimpleproprice, modifiers: comboModifier })
              combo.push({ menu_component_id: comboRes[1], menu_component_name: comboRes[0], product_details: comborVal })
              /* object formation - End */
            } else {
              comboSelction = "Fail"
            }
          } else {
            comboSelction = "Fail"
          }

          /* combo selection is missing show alert */
          if (comboSelction === "Fail") {
            helper.showInfo(lang.combo_missing)
            return false
          }
        }
      }
    }

    /* outlet validation - Start */

    if (cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery) {
      if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          if (this.state.zoneEnabled === 1) {
            if (parseInt(this.state.zonePopupDisable) === 1) {
              $("#AddressList").modal("toggle")
              return false
            } else {
              $("#delivery_outlet").modal("toggle")
              return false
            }
          } else {
            $("#AddressList").modal("toggle")
            return false
          }
        } else {
          if (this.state.zoneEnabled === 1) {
            if (parseInt(this.state.zonePopupDisable) === 1) {
              $("#DeliveryAddress").modal("toggle")
              return false
            } else {
              $("#delivery_outlet").modal("toggle")
              return false
            }
          }

          $("#DeliveryAddress").modal("toggle")
          return false
        }
      }
    } else if (cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay) {
      if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
        var pickupOutlets = this.state.Outlets

        if (pickupOutlets.length === 1) {
          var orderHandled = pickupOutlets[0].outlet_name + " " + pickupOutlets[0].outlet_address_line1 + " " + pickupOutlets[0].outlet_address_line2 + ", Singapore " + pickupOutlets[0].outlet_postal_code

          this.setState({ orderHandled: orderHandled })
          cookie.save("orderOutletId", pickupOutlets[0].outlet_id)
          cookie.save("orderOutletName", pickupOutlets[0].outlet_name)
          cookie.save("orderPostalCode", pickupOutlets[0].outlet_postal_code)
          cookie.save("orderTAT", pickupOutlets[0].outlet_pickup_tat)
          cookie.save("orderHandled", orderHandled)
          cookie.save("orderHandledByText", orderHandled)

          cookie.save("outlet_tax_option", pickupOutlets[0].outlet_tax_option)
          cookie.save("outlet_tax_surcharge", pickupOutlets[0].outlet_tax_surcharge)
          cookie.save("outlet_tax_surcharge_inclusive", pickupOutlets[0].outlet_tax_surcharge_inclusive)

          cookie.save("outlet_option_single", 1)

          $("#pickup-outlet-sucess").modal("toggle")
        } else {
          $("#Pickup-OutletSelection").modal("toggle")
        }

        return false
      }
    }

    /* Outlet validation - End */

    var totalPrice = proQty * proPrice

    var postObject = {}
    postObject = {
      app_id: config.ids.appId,
      product_id: productId,
      product_name: strp.stripslashes(proName),
      product_sku: prosku,
      product_image: proImage,
      availability_id: cookie.load("defaultAvilablityId"),
      product_unit_price: proPrice,
      product_qty: proQty,
      product_total_price: totalPrice,
      voucher_gift_name: this.state.voucher_name !== "" ? this.state.voucher_name : cookie.load("UserFname"),
      voucher_gift_email: this.state.voucher_email !== "" ? this.state.voucher_email : cookie.load("UserEmail"),
      voucher_gift_message: this.state.voucher_message !== "" ? this.state.voucher_message : "",
      product_voucher_expiry_date: this.state.productvoucher_expiry_date !== "" ? this.state.productvoucher_expiry_date : "",
      product_voucher_points: this.state.product_voucher == "c" ? this.state.product_voucher_points : "",
      modifiers: JSON.stringify(modifier),
      menu_set_component: JSON.stringify(combo),
    }

    if (typeof cookie.load("UserId") === "undefined") {
      postObject["reference_id"] = getResult("referenceId")
    } else {
      postObject["customer_id"] = cookie.load("UserId")
    }

    /*add loading */
    this.setState({ status: "Loading" })

    const apiheaderauth = {
      headers: {
        Auth: cookie.load("Token"),
        "X-API-KEY": "8611E7D6-ECA7-4172-966C-4C10EA1E8C7N",
      },
    }

    axios.post(APIURLPRO + "cartv2/insert", qs.stringify(postObject), apiheaderauth).then((res) => {
      this.setState({ status: "ok" })
      if (res.data.status === "ok") {
        fbPixelCustomEvent("AddToCartSuccess", {})

        var cartCount = res.data.contents.cart_details.cart_total_items
        cookie.save("cartValue", "Yes")
        cookie.save("cartCount", cartCount)
        cookie.save("voucher_types_enable", "1")
        cookie.remove("tempPromoCode")
        cookie.remove("tempPoints")

        /* find previous page */
        var slugType = cookie.load("categoryType")
        var slugValue = cookie.load("categoryId")
        if (typeof slugType !== "undefined" && typeof slugValue !== "undefined" && slugType !== "" && slugValue !== "") {
          var keyValue = slugType === "category" ? "category" : "subcategory"
          var reDirectUrl = "/products/" + keyValue + "/" + slugValue
        } else {
          reDirectUrl = "/"
        }
        cookie.save("alertSuccess", lang.cart_success)
        hashHistory.push(reDirectUrl)
      } else if (res.data.status === "error") {
        helper.showInfo(res.data.message)
      }
    })
  }

  /* add to cart */
  addToCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart, applyMinmaxSelect, product_type = "", product_voucher = "") {
    if ((product_type == "5" && product_voucher == "c") || product_voucher == "f") {
      $("#cashVocher").modal("toggle")
    } else {
      fbPixelCustomEvent("AddToCart", {})
      // alert(applyMinmaxSelect);
      this.setState({ productId: productId })
      this.setState({ proName: proName })
      this.setState({ prosku: prosku })
      this.setState({ proPrice: proPrice })
      this.setState({ proQty: proQty })
      this.setState({ proImage: proImage })
      this.setState({ allowCart: allowCart })
      this.setState({ applyMinmaxSelect: parseInt(applyMinmaxSelect) })

      /* check add to cart permission */
      if (allowCart === "No") {
        helper.showInfo(lang.modifier_missing)
        return false
      }

      /* Post Modifier part */

      var modifier = []
      //console.log(cookie.load('itemType') +"<---->"+ cookie.load('modiferCount'));
      if (cookie.load("itemType") === "Modifier" && cookie.load("modiferCount") > 0) {
        //  console.log('is allow');
        for (var i = 0, lengthMod = cookie.load("modiferCount"); i < lengthMod; i++) {
          var inputName = "modVal_" + i
          if (document.querySelector("input[name = " + inputName + "]:checked")) {
            var modVal = document.querySelector("input[name = " + inputName + "]:checked").value
            if (modVal !== "") {
              var res = modVal.split("~")
              var modifierVal = []
              modifierVal.push({ modifier_value_id: res[3], modifier_value_qty: "1", modifier_value_name: res[2], modifier_value_price: res[4] })
              modifier.push({ modifier_id: res[1], modifier_name: res[0], modifiers_values: modifierVal })
            } else {
              helper.showInfo(lang.modifier_missing)
              return false
            }
          } else {
            helper.showInfo(lang.modifier_missing)
            return false
          }
        }
      }

      /* set meat products array */
      var combo = []
      var comborVal = []
      var comboModifier = []
      var comboSelction = "Fail"
      var modifierSelction = "Fail"
      var overAllQty = 0
      var totalMinSelect = 0
      // alert(applyMinmaxSelect);
      if (parseInt(applyMinmaxSelect) === 1) {
        if (cookie.load("itemType") === "Combo" && cookie.load("comboCount") > 0) {
          for (var m = 0, length = cookie.load("comboCount"); m < length; m++) {
            var _items = document.getElementsByClassName("hiddenInput_" + m)
            var _itemCount = _items.length
            var _cartQty = 0
            var selMincount = 0
            var selMaxcount = 0
            var selMinSetName = ""

            var comborVal = []
            var menu_component_id = ""
            var menu_component_name = ""
            var selCurQty = 0
            for (var i = 0; i < _itemCount; i++) {
              selCurQty = parseInt(document.getElementsByClassName("combinput_" + m)[i].innerHTML)
              _cartQty = _cartQty + selCurQty
              var comboRes = ""
              var PriceArray = []
              var appliedPrice = []
              if (selCurQty > 0) {
                var comboIdVal = $(".combinput_" + m)[i].id
                var comboDatas = $("." + comboIdVal).val()
                if (comboDatas) {
                  /* get extra amount applied products */
                  comboRes = comboDatas.split("~")
                  PriceArray = this.getarrayName(m)
                  //console.log(comboRes[3]);
                  //   console.log(PriceArray);
                  var appliedPrice = this.addSearch(comboRes[3], PriceArray, "get")
                  var newQtyPrice = 0
                  if (appliedPrice) {
                    newQtyPrice = appliedPrice.Price
                  }
                  // var newQtyPrice = (appliedPrice.Price ? appliedPrice.Price : 0);

                  comborVal.push({ product_id: comboRes[3], product_name: comboRes[2], product_sku: comboRes[4], product_qty: selCurQty, product_price: newQtyPrice, modifiers: comboModifier })
                  menu_component_id = comboRes[1]
                  menu_component_name = comboRes[0]
                }
              }

              if (i === _itemCount - 1 && menu_component_id != "" && menu_component_name != "" && comborVal) {
                combo.push({ menu_component_id: menu_component_id, menu_component_name: menu_component_name, product_details: comborVal })
              }
              /* push products to array values  */
            }

            //console.log(comborVal); return false;
            overAllQty = overAllQty + _cartQty

            /* validate min max setup */
            selMincount = parseInt($(".combo-min-" + m).val())
            selMinSetName = $(".combo-min-name" + m).val()
            selMaxcount = parseInt($(".combo-max-" + m).val())

            totalMinSelect = totalMinSelect + selMincount
            // console.log(_cartQty + "//" + selMincount);
            if (_cartQty < selMincount) {
              helper.showInfo("Please select the min number of items for " + selMinSetName)
              return false
            }

            if (_cartQty > selMaxcount) {
              helper.showInfo("Maximum selection limit exceed")
              return false
            }

            /* add products  */
          }

          //alert(totalMinSelect);
          if (overAllQty <= 0 && totalMinSelect > 0) {
            helper.showInfo("Please choose any combo product combination")
            return false
          }
          //console.log(combo);  return false;
        }
      } else {
        /* OLD Combo product setup */

        if (cookie.load("itemType") === "Combo" && cookie.load("comboCount") > 0) {
          // alert(cookie.load('comboCount'));
          for (var m = 0, length = cookie.load("comboCount"); m < length; m++) {
            //alert('  combo' + m);
            var inputName = "comboVal_" + m
            var comboRes = ""
            if (document.querySelector("input[name = " + inputName + "]:checked")) {
              var comboVal_ = document.querySelector("input[name = " + inputName + "]:checked").value
              if (comboVal_ !== "") {
                comboSelction = "Success"
                comboRes = comboVal_.split("~")
                var product_id = comboRes[3]
                var pro_priceAply = parseInt(comboRes[5])
                var simplepro_price = parseFloat(comboRes[6])
                var compoSimpleproprice = parseFloat(0)
                /* Validate modififer selection - start */
                var comboModifier = []
                var modLength = 0
                //alert("mod count" + m);
                var modLength = document.querySelectorAll(".setModValClass" + product_id + m).length
                //alert('.setModValClass'+product_id+m)
                if (modLength > 0) {
                  //  alert(modLength);
                  //alert(modLength + comboRes[2] + "modifier count");
                  comboModifier = []
                  for (var n = 0, forLen = modLength; n < forLen; n++) {
                    var inputName = "modValItems" + product_id + "-" + m + "-" + n
                    if (document.querySelector("input[name = " + inputName + "]:checked")) {
                      var modComboVal = document.querySelector("input[name = " + inputName + "]:checked").value
                      if (modComboVal !== "") {
                        modifierSelction = "success"
                        var comboModRes = modComboVal.split("~")
                        var comboModifierVal = []
                        // alert(comboModRes[4]);
                        comboModifierVal.push({ modifier_value_id: comboModRes[3], modifier_value_qty: "1", modifier_value_name: comboModRes[2], modifier_value_price: comboModRes[4] })
                        comboModifier.push({ modifier_id: comboModRes[1], modifier_name: comboModRes[0], modifiers_values: comboModifierVal })
                      } else {
                        modifierSelction = "Fail"
                      }
                    } else {
                      modifierSelction = "Fail"
                    }

                    /* if modifier selection misiing show alert */
                    if (modifierSelction === "Fail") {
                      helper.showInfo(lang.modifier_missing)
                      return false
                    }
                  } /* end of second modifer for */
                } else {
                  if (pro_priceAply === 1) {
                    compoSimpleproprice = simplepro_price
                  }
                }

                /* Validate modififer selection - End  */

                /* object formation  - start */
                var comborVal = []
                comborVal.push({ product_id: comboRes[3], product_name: comboRes[2], product_sku: comboRes[4], product_qty: 1, product_price: compoSimpleproprice, modifiers: comboModifier })
                combo.push({ menu_component_id: comboRes[1], menu_component_name: comboRes[0], product_details: comborVal })
                /* object formation - End */
              } else {
                comboSelction = "Fail"
              }
            } else {
              comboSelction = "Fail"
            }

            /* combo selection is missing show alert */
            if (comboSelction === "Fail") {
              helper.showInfo(lang.combo_missing)
              return false
            }
          }
        }
      }

      //console.log(combo);  return false;

      /* outlet validation - Start */

      if (cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery) {
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
          if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
            if (this.state.zoneEnabled === 1) {
              if (parseInt(this.state.zonePopupDisable) === 1) {
                $("#AddressList").modal("toggle")
                return false
              } else {
                $("#delivery_outlet").modal("toggle")
                return false
              }
            } else {
              $("#AddressList").modal("toggle")
              return false
            }
          } else {
            if (this.state.zoneEnabled === 1) {
              if (parseInt(this.state.zonePopupDisable) === 1) {
                $("#DeliveryAddress").modal("toggle")
                return false
              } else {
                $("#delivery_outlet").modal("toggle")
                return false
              }
            }

            $("#DeliveryAddress").modal("toggle")
            return false
          }
        }
      } else if (cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay) {
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
          var pickupOutlets = this.state.Outlets

          if (pickupOutlets.length === 1) {
            var orderHandled = pickupOutlets[0].outlet_name + " " + pickupOutlets[0].outlet_address_line1 + " " + pickupOutlets[0].outlet_address_line2 + ", Singapore " + pickupOutlets[0].outlet_postal_code

            this.setState({ orderHandled: orderHandled })
            cookie.save("orderOutletId", pickupOutlets[0].outlet_id)
            cookie.save("orderOutletName", pickupOutlets[0].outlet_name)
            cookie.save("orderPostalCode", pickupOutlets[0].outlet_postal_code)
            cookie.save("orderTAT", pickupOutlets[0].outlet_pickup_tat)
            cookie.save("orderHandled", orderHandled)
            cookie.save("orderHandledByText", orderHandled)

            cookie.save("outlet_tax_option", pickupOutlets[0].outlet_tax_option)
            cookie.save("outlet_tax_surcharge", pickupOutlets[0].outlet_tax_surcharge)
            cookie.save("outlet_tax_surcharge_inclusive", pickupOutlets[0].outlet_tax_surcharge_inclusive)

            cookie.save("outlet_option_single", 1)

            $("#pickup-outlet-sucess").modal("toggle")
          } else {
            $("#Pickup-OutletSelection").modal("toggle")
          }

          return false
        }
      }

      /* Outlet validation - End */

      var totalPrice = proQty * proPrice

      var postObject = {}
      postObject = {
        app_id: config.ids.appId,
        product_id: productId,
        product_name: strp.stripslashes(proName),
        product_sku: prosku,
        product_image: proImage,
        availability_id: cookie.load("defaultAvilablityId"),
        product_unit_price: proPrice,
        product_qty: proQty,
        product_total_price: totalPrice,
        voucher_gift_name: this.state.voucher_name !== "" ? this.state.voucher_name : cookie.load("UserFname"),
        voucher_gift_email: this.state.voucher_email !== "" ? this.state.voucher_email : cookie.load("UserEmail"),
        voucher_gift_message: this.state.voucher_message !== "" ? this.state.voucher_message : "",
        product_voucher_expiry_date: this.state.product_voucher_expiry_date !== "" ? this.state.product_voucher_expiry_date : "",
        product_voucher_points: this.state.product_voucher == "c" ? this.state.product_voucher_points : "",
        modifiers: JSON.stringify(modifier),
        menu_set_component: JSON.stringify(combo),
      }

      if (typeof cookie.load("UserId") === "undefined") {
        postObject["reference_id"] = getResult("referenceId")
      } else {
        postObject["customer_id"] = cookie.load("UserId")
      }

      //console.log(postObject);
      /*add loading */
      this.setState({ status: "Loading" })
      axios.post(APIURLPRO + "cartv2/insert", qs.stringify(postObject)).then((res) => {
        this.setState({ status: "ok" })
        if (res.data.status === "ok") {
          fbPixelCustomEvent("AddToCartSuccess", {})

          var cartCount = res.data.contents.cart_details.cart_total_items
          cookie.save("cartValue", "Yes")
          cookie.save("cartCount", cartCount)
          cookie.remove("tempPromoCode")
          cookie.remove("tempPoints")

          /* find previous page */
          var slugType = cookie.load("categoryType")
          var slugValue = cookie.load("categoryId")
          if (typeof slugType !== "undefined" && typeof slugValue !== "undefined" && slugType !== "" && slugValue !== "") {
            var keyValue = slugType === "category" ? "category" : "subcategory"
            var reDirectUrl = "/products/" + keyValue + "/" + slugValue
          } else {
            reDirectUrl = "/"
          }
          cookie.save("alertSuccess", lang.cart_success)
          hashHistory.push(reDirectUrl)
        } else if (res.data.status === "error") {
          helper.showInfo(res.data.message)
        }
      })
    }
  }

  /* use to  back URL */
  goBack() {
    var slugType = cookie.load("categoryType")
    var slugValue = cookie.load("categoryId")
    if (typeof slugType !== "undefined" && typeof slugValue !== "undefined" && slugType !== "" && slugValue !== "") {
      var keyValue = slugType === "category" ? "category" : "subcategory"
      var reDirectUrl = "/products/" + keyValue + "/" + slugValue
    } else {
      reDirectUrl = "/"
    }

    return reDirectUrl
  }
  /* add desc */
  addDescClass(desc) {
    return desc !== "" ? "subh product_points" : "subh"
  }

  /* delivery timing */
  showDeliveryTiming() {
    if (APPID === apps.leanbento) {
      return (
        <p>
          <b>Your Order is handled by:</b>
          <br />
          {this.state.orderHandled}
        </p>
      )
    }
  }
  /* show padding set combo product */
  showPadding(product_is_combo, modVal = null) {
    //var modLen = modVal.length;

    var modLen = modVal !== null ? modVal.length : 0

    if (product_is_combo === "Yes" || modLen > 0) {
      return <div className=""> </div>
    }
  }

  /* Show desc */
  showDesc(desc = null) {
    if (desc !== null) {
      return nl2br(strp.stripslashes(desc))
    }
  }

  /* show product name */
  showProductName(name = null) {
    if (name !== null) {
      return nl2br(strp.stripslashes(name))
    }
  }

  /* work space class */
  workspaceClass() {
    var retunClass = ""
    if (APPID === apps.workspace) {
      retunClass = "workspace_css "
    }

    return retunClass
  }
  showZonalBackButton() {
    if (this.state.zoneEnabled === 1 && this.state.zonePopupDisable === 0) {
      return (
        <div className="col-sm-6 popup-back-btn">
          <button type="button" onClick={this.backToDeliveryBack.bind(this)} className="btn btn-raised btn-info ">
            Go Back
          </button>
        </div>
      )
    }
  }
  menuChangeAccess(e, type) {
    e.preventDefault()
    if (type == "Me") {
      $("#gust_checkout_form").addClass("show_me")
      $("#two-panel").css("display", "block")
      $("#one-panel").css("display", "none")
      $(".radio_one").removeAttr("id")
      $(".radio_two").attr("id", "two")
      $("#two-tab").addClass("tab_voucher_two")
      $("#one-tab").removeClass("tab_voucher_one")
    } else {
      $("#gust_checkout_form").removeClass("show_me")
      $("#two-panel").css("display", "none")
      $("#one-panel").css("display", "block")
      $(".radio_one").attr("id", "one")
      $(".radio_two").removeAttr("id")
      $("#two-tab").removeClass("tab_voucher_two")
    }
  }

  loadProductDate(date) {
    var product_date = new Date(date).toString().split(" ")
    return product_date[2] + "-" + product_date[1] + "-" + product_date[3]
  }

  render() {
    const productMoreSliderSettings = {
      dots: false,
      className: "product-detail-slider category_slider row no-bar-image",
      infinite: true,
      speed: 500,
      autoplay: true,
      arrows: true,
    }

    var proImg = this.__productImage(this.state.details.product_thumbnail)
    if (this.state.status === "Loading") {
      return (
        <div className="container-fluid desktop-container position-rel" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    } else if (this.state.status === "ok") {
      return (
        <div>
          <DocumentMeta {...meta} />
          <LocationSearchInput loading={this.state.status} open={this.state.enableAddressSearch} parentCallback={this.selectOutlet} postalcode={this.state.addressField} />
          <div className="innerheader-fixed ">
            <div className="container-fluid desktop-container">
              <div className={this.state.activeClass + " row "}>
                <div className={this.workspaceClass() + " col-xs-12 top-nav inner-top-nav head-main"}>
                  <div className="col-xs-2 nav-head pd-l-10 head-child">
                    <Link to={this.goBack()} style={{ cursor: "pointer" }} className="backarrow">
                      <img src="/img/icons/arrow-right.png" className="left_arr icon-img-small-3" alt="left-arrow" />
                    </Link>
                  </div>

                  <div className="col-xs-6 nav-head head-child">
                    <h1 className="main-title"></h1>
                  </div>
                  <div className="col-xs-4 nopadding head-child">
                    <p className="b-txt text-right pd-l-10">
                      <Link to={"/cart"} className={helper.cartClass()}>
                        {" "}
                        {helper.cartCount()} <img src={this.loadCartImage()} className="icon-img-small-1 carticon" alt="shopping-cart" />{" "}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my_height_class product-detailas-body" style={{ minHeight: this.state.windowHeight }}>
            <div className="container-fluid desktop-container product-details">
              {_.isEmpty(this.state.details.image_gallery) === false && (
                <Slider {...productMoreSliderSettings}>
                  {this.state.details.image_gallery.map((slider, sIndex) => (
                    <div className="product-banner">
                      <img src={this.state.details_common.product_gallery_image_source + "/" + slider.pro_gallery_image} alt="" className="img-responsive" />
                    </div>
                  ))}
                </Slider>
              )}
              {_.isEmpty(this.state.details.image_gallery) === true && this.loadProductImage(this.state.details.product_thumbnail, this.state.details.image_gallery)}

              <div className="row ">
                <div className="col-lg-12 col-md-12 col-sm-12 new-changes-voucher">
                  <h1 className="head2 ">{this.showProductName(helper.getAliasName(this.state.details.product_alias, this.state.details.product_name))} </h1>
                  <p className={this.addDescClass(this.state.details.product_long_description)}>{this.showDesc(this.state.details.product_long_description)}</p>
                  {this.state.product_type === "5" ? (
                    <div className="expiry-available">
                      <span>Expiry : {this.loadProductDate(this.state.details.product_voucher_expiry_date)}</span> | <span>{this.state.details.product_stock} Available</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/*<div className="row ">

				<div className="col-lg-12 col-md-12 col-sm-12 ">
					<div className="" >
						<div className="btn-div">
							<Link  className="btn btn-group-mini"  ><i className="material-icons" onClick={this.dQty.bind(this)}> remove</i></Link>
							&nbsp; {this.state.cartCount} &nbsp;
							<Link  className="btn btn-group-sm "  > <i className="material-icons" onClick={this.iQty.bind(this)}>add</i></Link>
							<div className="clearfix"></div>
						</div>
					</div>
				</div>

			</div> */}
              <br />
            </div>

            <div className="container-fluid desktop-container">
              <div className="row ">
                <div className="col-lg-12 col-md-12 col-sm-12 nopadding my-bg">
                  <div className="fancy-collapse-panel">
                    <div className="panel-group" role="tablist" aria-multiselectable="true">
                      {this.showModifiers(this.state.details.modifiers)}
                      {this.showSetMeal(this.state.details.product_is_combo, this.state.details.set_menu_component, parseInt(this.state.details.product_apply_minmax_select))}
                      {this.showPadding(this.state.details.product_is_combo, this.state.details.modifiers)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="container-fluid desktop-container">
              <div style={{ height: this.newMethod() }}></div>
              <div className="row itemsread-sec01">
                <div className={this.state.stockEnabled === 1 && this.state.details.product_stock === "0" ? "col-md-12 col-sm-12 col-xs-12 plus-minus-section greyed text-center" : "col-md-12 col-sm-12 col-xs-12 plus-minus-section text-center"}>
                  <Link className="btn btn-group-mini">
                    {this.state.stockEnabled === 1 && this.state.details.product_stock === "0" ? (
                      <i className="material-icons"> remove</i>
                    ) : (
                      <i className="material-icons" onClick={this.dQty.bind(this)}>
                        {" "}
                        remove
                      </i>
                    )}
                  </Link>
                  <span className="cartitem-txt">&nbsp; {this.state.cartCount} &nbsp;</span>
                  <Link className="btn btn-group-sm ">
                    {this.state.stockEnabled === 1 && this.state.details.product_stock === "0" ? (
                      <i className="material-icons">add</i>
                    ) : (
                      <i className="material-icons" onClick={this.iQty.bind(this)}>
                        add
                      </i>
                    )}
                  </Link>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-8 hide">
                  <p className={this.state.stockEnabled === 1 && this.state.details.product_stock === "0" ? "bb-txt pull-right no-margin pd-r-25 itemtotal-txt greyed" : "bb-txt pull-right no-margin pd-r-25 itemtotal-txt"}>
                    <span className={this.state.stockEnabled === 1 && this.state.details.product_stock === "0" ? "greyed totaltxt" : "totaltxt"}>ITEM TOTAL: </span> &nbsp;{" "}
                    <span className="total-price-01">
                      <strong>
                        <sup>$</sup>
                        {(this.state.productPrice * this.state.cartCount).toFixed(2)}
                      </strong>
                    </span>{" "}
                  </p>
                </div>
                <div className="clearfix "></div>
                <div className="col-lg-12 col-md-12 col-sm-12 ">
                  {this.state.stockEnabled === 1 && this.state.details.product_stock === "0" ? (
                    <button className="itemcart-btn-btm01 soldout">SOLD OUT</button>
                  ) : (
                    <button
                      id="addToCart"
                      className="itemcart-btn-btm01"
                      onClick={this.addToCart.bind(
                        this,
                        this.state.details.product_id,
                        helper.getAliasName(this.state.details.product_alias, this.state.details.product_name),
                        this.state.details.product_sku,
                        this.state.productPrice,
                        this.state.cartCount,
                        proImg,
                        this.state.allowCart,
                        parseInt(this.state.details.product_apply_minmax_select),
                        parseInt(this.state.details.product_type),
                        this.state.details.product_voucher
                      )}
                    >
                      {lang.add_to_cart} - <sup>$</sup>
                      {(this.state.productPrice * this.state.cartCount).toFixed(2)}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>

          {/* For Pickup Success */}

          <div className="modal" id="Pickup-Sucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.gif" className="img-responsive" alt="" />
                    Awesome, you can pickup !
                  </h4>
                </div>
                <div className="modal-body text-center">
                  {this.showDeliveryTiming}
                  <br />
                  <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          <div className="modal" id="pickup-outlet-sucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.gif" className="img-responsive" alt="" />
                    Awesome, you can pickup !
                  </h4>
                  <p>{cookie.load("orderHandled")}</p>
                </div>
                <div className="modal-body text-center">
                  {this.showDeliveryTiming}
                  <br />
                  <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Delivery Success */}

          <div className="modal" id="DeliverySucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.gif" className="img-responsive" alt="thumb-up" />
                    Awesome, we can deliver!
                  </h4>
                </div>
                <div className="modal-body text-center">
                  <p>
                    <b>Your Delivery Address: </b>
                    <br />
                    {this.state.orderDeliveryAddress}
                  </p>
                  {this.showdeliveryTiming}
                  <br />
                  <button type="button" onClick={this.deliverySuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Delivery error */}

          <div className="modal" id="DeliveryError">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/Cant_Deliver.gif" className="img-responsive" alt="" />
                    Sorry !
                  </h4>
                </div>
                <div className="modal-body">
                  <p>
                    Sorry we cant deliver to your address <br />
                    Do you want to change your address or check again later ?
                  </p>
                  <br />
                  <div className="col-sm-6">
                    <button type="button" onClick={this.closeDeliveryErr.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                      Check Later
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <button type="button" onClick={this.changeDelivery.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                      Change Address
                    </button>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
          {/* For shop closed error */}

          <div className="modal" id="ShopClosedError">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/Cant_Deliver.gif" className="img-responsive" alt="" />
                    Sorry !
                  </h4>
                </div>
                <div className="modal-body">
                  <p>
                    <b>Outlet Closed Temporarily. Please Try again later</b>{" "}
                  </p>
                  <br />
                  <div className="col-sm-12">
                    <button type="button" onClick={this.closeShopClosedError.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                      Check Later
                    </button>
                  </div>

                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For takeaway popup */}

          <div className="modal common-distance" id="Pickup-OutletSelection">
            <div className="modal-dialog locWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">Select Your Pickup Location</h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body">
                  <div className="map-loc">
                    {/* <Map markers={this.state.markers} /> */}

                    <div className="map-box scrollbar" id="style-3">
                      {this.__loadOutlets()}
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
          <div className="modal common-distance" id="delivery_outlet">
            <div className="modal-dialog locWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">PLEASE SELECT WHICH OUTLET DO YOU PREFER</h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body">
                  <div className="map-loc">
                    {/* <Map markers={this.state.markers} /> */}

                    <div className="map-box scrollbar" id="style-3">
                      {this.__loadDeliveryOutlets()}
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
          {/* For delivery popup */}
          <div className="modal" id="DeliveryAddress">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <div className="space-50" style={{ padding: "22px" }}></div>
                  <h4 className="modal-title">LET US KNOW YOUR DELIVERY LOCATION</h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body">
                  <div className="form-group label-floating is-empty card-no">
                    <div className="input-group">
                      <label className="control-label">Enter Your Postal Code</label>
                      <input value={this.state.addressField} id="postalcode1" maxLength="6" className="form-control" onKeyPress={(e) => this.validatezip(e)} onChange={this.handleChangeValue} />
                    </div>
                  </div>
                  <br />
                  <br />
                  {this.showZonalBackButton()}
                  <div className={this.state.zoneEnabled === 1 && this.state.zonePopupDisable === 0 ? "col-sm-6 popup-back-btn" : "col-sm-12"}>
                    <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                      Continue
                    </button>
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* after login user popup */}

          <div className="modal" id="AddressList">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.gif" className="img-responsive" alt />
                    Your Delivery Address
                  </h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body" style={{ textAlign: "left" }}>
                  {/* {this.loadDefaultAddress()} */}
                  {this.loadAddressList()}

                  <p>
                    <input value={this.state.addressField} onChange={this.handleChangeValue} id="postalcode" maxLength="6" placeholder="Enter Your Postal Code" className="add_list" onKeyPress={(e) => this.validatezip(e)} />
                  </p>
                  <br />

                  <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* end of after login popup*/}

          {/* Start voucher Git Popup */}

          <div className="modal" id="cashVocher">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close voucher_popup" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt />
                  </button>
                  <h4 className="modal-title">Im Purchasing This</h4>
                </div>

                <div className="modal-body" style={{ textAlign: "left" }}>
                  <div className="voucher_warpper">
                    <input className="radio_cash radio_one" id="one" name="group" type="radio" checked />
                    <input className="radio_cash radio_two" id="two" name="group" type="radio" />

                    <div className="tabs_voucher">
                      <label
                        className="tab_voucher"
                        onClick={(e) => {
                          this.menuChangeAccess(e, "Gift")
                        }}
                        id="one-tab"
                      >
                        For Gift
                      </label>
                      <label
                        className="tab_voucher second_label"
                        id="two-tab"
                        onClick={(e) => {
                          this.menuChangeAccess(e, "Me")
                        }}
                      >
                        For Me
                      </label>
                    </div>

                    <div className="panels_voucher">
                      <div className="panel_voucher" id="one-panel">
                        <div className="panel-title_voucher">
                          <div className="gust_checkout_form" id="gust_checkout_form">
                            <h4 className="vouchers-modal-title">Gift Receiver's Information</h4>

                            <div className="row">
                              <form action method>
                                <div className="form-group label-floating is-empty card-no is-exist">
                                  <div className="input-group">
                                    <label className="control-label">Name</label>
                                    <input id="guest-name" name="guest-name" value={this.state.voucher_name} onChange={this.handleChangeTxt.bind(this, "voucher_name")} className="form-control" type="text" maxLength="20" />
                                    {this.state.error_voucher_name}
                                  </div>
                                </div>
                                <span className="help-text guest-error"></span>
                                <div id="otp_error_msg"></div>
                                <div className="form-group label-floating is-empty card-no is-exist">
                                  <div className="input-group">
                                    <label className="control-label">Email</label>
                                    <input type="email" className="form-control" onChange={this.handleChangeTxt.bind(this, "voucher_email")} value={this.state.voucher_email} />
                                    {this.state.error_voucher_email}
                                  </div>
                                </div>

                                <span className="help-text guest-error"></span>

                                <div className="form-group label-floating is-empty card-no is-exist">
                                  <div className="input-group">
                                    <label className="control-label">Message</label>
                                    <textarea type="text" name="comment" placeholder="" rows="5" id="guest-cemail" className="form-control" onChange={this.handleChangeTxt.bind(this, "voucher_message")} value={this.state.voucher_message} />
                                    {this.state.error_voucher_message}
                                  </div>
                                </div>
                                <span className="help-text guest-error"></span>

                                <div className="text-center voucher_button">
                                  <button
                                    type="button"
                                    className="itemcart-btn-btm01 voucher"
                                    onClick={this.cashVochersumbit.bind(
                                      this,
                                      this.state.details.product_id,
                                      helper.getAliasName(this.state.details.product_alias, this.state.details.product_name),
                                      this.state.details.product_sku,
                                      this.state.productPrice,
                                      this.state.cartCount,
                                      proImg,
                                      this.state.allowCart,
                                      parseInt(this.state.details.product_apply_minmax_select),
                                      this.state.details.product_voucher_expiry_date,
                                      this.state.details.product_voucher,
                                      this.state.details.product_voucher_points
                                    )}
                                  >
                                    Continue
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="panel_voucher" id="two-panel">
                        <div className="panel-title_voucher">
                          <div className="col-lg-12 col-md-12 col-sm-12 ">
                            <button
                              type="button"
                              className="itemcart-btn-btm01"
                              onClick={this.cashVocherMesumbit.bind(
                                this,
                                this.state.details.product_id,
                                helper.getAliasName(this.state.details.product_alias, this.state.details.product_name),
                                this.state.details.product_sku,
                                this.state.productPrice,
                                this.state.cartCount,
                                proImg,
                                this.state.allowCart,
                                parseInt(this.state.details.product_apply_minmax_select),
                                this.state.details.product_voucher_expiry_date,
                                this.state.details.product_voucher,
                                this.state.details.product_voucher_points
                              )}
                            >
                              {lang.add_to_cart}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
          {/* Start voucher Git Popup */}
        </div>
      )
    } else {
      return <div>{lang.no_products_found} </div>
    }
  }

  newMethod() {
    return "132px"
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Productsdetails)
