const hostname = window.location.hostname;

const protocoltxt = "https:"; // window.location.protocol
/*if (location.protocol != 'https:')
{
 location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}*/

var domainValues = {
  "ninjaprodemo.jankosoftworks.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F77D467A-F552-4BB3-ABBF-C491DB6661A0",
    fbAppId: "273665979745325",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyKzY1OTAwMTMxNTclMjIsJTIyZmFjZWJvb2slMjI6JTIyMTUxODI0NzE5MTc4MTg1NCUyMiU3RA==",
    stripeKey: "pk_test_NcgEfVqBVIEN2vUSDpC5wfT2",
    stripeCompany: "Lean Bento",
    stripeImage: "https://leanbento.ninjapro.co/img/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ninjaprodemo",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://leanbento.ninjapro.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "http://leanbento.ninjapro.co/",
      fbImage: "http://bigberns.ninjapro.co/img/logo.png",
      fbTitle: "Lean Bento",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : http://leanbento.ninjapro.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "http://leanbento.ninjapro.co/",
      twTitile: "Lean Bento",
      twvia: "Lean Bento",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://leanbento.ninjapro.co/",
    },
    /* Whatsapp data   */
    wa: {
      waURL: "http://leanbento.ninjapro.co/",
      waSeparator: "Lean Bento",
    },
    site_title: "Lean Bento",
    site_title_with_separator: " | Lean Bento",
    home_meta_title: "Welcome to Lean Bento",
  },
  "leanbento.promobuddy.asia": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F77D467A-F552-4BB3-ABBF-C491DB6661A0",
    fbAppId: "273665979745325",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyKzY1OTAwMTMxNTclMjIsJTIyZmFjZWJvb2slMjI6JTIyMTUxODI0NzE5MTc4MTg1NCUyMiU3RA==",
    stripeKey: "pk_test_NcgEfVqBVIEN2vUSDpC5wfT2",
    stripeCompany: "Lean Bento",
    stripeImage: "https://leanbento.promobuddy.asia/img/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "leanbento",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://leanbento.promobuddy.asia/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "http://leanbento.promobuddy.asia/",
      fbImage: "http://bigberns.ninjapro.co/img/logo.png",
      fbTitle: "Lean Bento",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : http://leanbento.ninjapro.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "http://leanbento.promobuddy.asia/",
      twTitile: "Lean Bento",
      twvia: "Lean Bento",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://leanbento.promobuddy.asia/",
    },
    /* Whatsapp data   */
    wa: {
      waURL: "http://leanbento.promobuddy.asia/",
      waSeparator: "Lean Bento",
    },
    site_title: "Lean Bento",
    site_title_with_separator: " | Lean Bento",
    home_meta_title: "Welcome to Lean Bento",
  },
  "leanbento.ninjapro.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F77D467A-F552-4BB3-ABBF-C491DB6661A0",
    fbAppId: "273665979745325",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyKzY1OTAwMTMxNTclMjIsJTIyZmFjZWJvb2slMjI6JTIyMTUxODI0NzE5MTc4MTg1NCUyMiU3RA==",
    stripeKey: "pk_test_NcgEfVqBVIEN2vUSDpC5wfT2",
    stripeCompany: "Lean Bento",
    stripeImage: "https://leanbento.ninjapro.co/img/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "leanbento",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://leanbento.ninjapro.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "http://leanbento.ninjapro.co/",
      fbImage: "http://bigberns.ninjapro.co/img/logo.png",
      fbTitle: "Lean Bento",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : http://leanbento.ninjapro.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "http://leanbento.ninjapro.co/",
      twTitile: "Lean Bento",
      twvia: "Lean Bento",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://leanbento.ninjapro.co/",
    },
    /* Whatsapp data   */
    wa: {
      waURL: "http://leanbento.ninjapro.co/",
      waSeparator: "Lean Bento",
    },
    site_title: "Lean Bento",
    site_title_with_separator: " | Lean Bento",
    home_meta_title: "Welcome to Lean Bento",
  },
  "192.168.2.6a9": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F77D467A-F552-4BB3-ABBF-C491DB6661A0",
    fbAppId: "273665979745325",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyKzY1OTAwMTMxNTclMjIsJTIyZmFjZWJvb2slMjI6JTIyMTUxODI0NzE5MTc4MTg1NCUyMiU3RA==",
    stripeKey: "pk_test_NcgEfVqBVIEN2vUSDpC5wfT2",
    stripeCompany: "Lean Bento",
    stripeImage: "http://leanbento.ninjapro.co/img/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "leanbento-test",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "http://leanbento.ninjapro.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "http://leanbento.ninjapro.co/",
      fbImage: "http://bigberns.ninjapro.co/img/logo.png",
      fbTitle: "Lean Bento",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : http://leanbento.ninjapro.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "http://leanbento.ninjapro.co/",
      twTitile: "Lean Bento",
      twvia: "Lean Bento",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://leanbento.ninjapro.co/",
    },
    /* Whatsapp data   */
    wa: {
      waURL: "http://leanbento.ninjapro.co/",
      waSeparator: "Lean Bento",
    },
    site_title: "Lean Bento Test",
    site_title_with_separator: " | Lean Bento Test",
    home_meta_title: "Welcome to Lean Bento Test",
  },
  localhost: {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "48084EE2-3BE1-421B-94DE-232B4F460C09",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "GOPIZZA",
    stripeImage:
      "https://order.gopizza.sg/img/ninjaprocmn/gopizzaadmin/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gopizzaadmin",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.gopizza.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.gopizza.sg/#/",
      fbImage: "https://order.gopizza.sg/img/gopizzaadmin/logo.png",
      fbTitle: "GOPIZZA",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.gopizza.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.gopizza.sg/#/",
      twTitile: "GOPIZZA",
      twvia: "GOPIZZA",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.gopizza.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.gopizza.sg/#/",
      waSeparator: "GOPIZZA",
    },
    site_title: "GOPIZZA",
    site_title_with_separator: " | GOPIZZA",
    home_meta_title: "Welcome to GOPIZZA",
    bgimage: "/img/ninjaprocmn/gopizzaadmin/body_bg.jpg",
  },
  localhost3: {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F77D467A-F552-4BB3-ABBF-C491DB6661A0",
    fbAppId: "834478453372307",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyKzY1OTAwMTMxNTclMjIsJTIyZmFjZWJvb2slMjI6JTIyMTUxODI0NzE5MTc4MTg1NCUyMiU3RA==",
    stripeKey: "pk_test_f5ROLHULd4UlQF59L24mdDBV",
    stripeCompany: "Ninjapro Demo",
    stripeImage: "http://ninjaprocmn.jankosoftworks.com/img/logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "leanbento-3",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "http://ninjaprocmn.jankosoftworks.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "http://ninjaprocmn.jankosoftworks.com/#/",
      fbImage: "http://ninjaprocmn.jankosoftworks.com/img/logo.png",
      fbTitle: "Demo Site",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : http://ninjaprocmn.jankosoftworks.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "http://ninjaprocmn.jankosoftworks.com/#/",
      twTitile: "Demo site",
      twvia: "Demo site",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://ninjaprocmn.jankosoftworks.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://ninjaprocmn.jankosoftworks.com/#/",
      waSeparator: "Demo site",
    },
    site_title: "Demo site",
    site_title_with_separator: " | Demo site",
    home_meta_title: "Welcome to Demo site",
    bgimage: "/img/ninjaprocmn/body_bg.jpg",
  },
  "order.evertop.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5F2E6A5D-25B6-498F-AEBA-6EC8171F9625",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "JTdCJTIycGhvbmUlMjI6JTIyKzY1Njc3NDEzNTMlMjIlN0Q=",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Evertop Chicken Rice",
    stripeImage:
      "https://order.evertop.sg/img/ninjaprocmn/evertop/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "evertop",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.evertop.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.evertop.sg/#/",
      fbImage: "https://order.evertop.sg/img/evertop/logo.png",
      fbTitle: "Evertop Chicken Rice",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.evertop.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.evertop.sg/#/",
      twTitile: "Evertop Chicken Rice",
      twvia: "Evertop Chicken Rice",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.evertop.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.evertop.sg/#/",
      waSeparator: "Evertop Chicken Rice",
    },
    site_title: "Evertop Chicken Rice",
    site_title_with_separator: " | Evertop Chicken Rice",
    home_meta_title: "Welcome to Evertop Chicken Rice",
    bgimage: "/img/ninjaprocmn/evertop/body_bg.jpg",
  },
  "order.thesaladbox.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C2533ED5-3B72-41B4-91AC-F57E7DE5422B",
    fbAppId: "834478453372307",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyMDY1OTAzMDUzMTYlMjIsJTIyZmFjZWJvb2slMjI6JTIyMTQ0NDA1MjMxOTE2MDY2MiUyMiU3RA==",
    stripeKey: "pk_live_OqBVS2Aa11U7EZbsY2gJcer5",
    stripeCompany: "The SaladBox",
    stripeImage:
      "https://order.thesaladbox.com.sg/img/ninjaprocmn/saladbox/stripe_logo.png",
    stripeEmail: "thesaladbox@hotmail.com",
    stripeReference: "saladbox",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.thesaladbox.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.thesaladbox.com.sg/#/",
      fbImage: "https://order.thesaladbox.com.sg/img/saladbox/logo.png",
      fbTitle: "The SaladBox",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.thesaladbox.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.thesaladbox.com.sg/#/",
      twTitile: "The SaladBox",
      twvia: "The SaladBox",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.thesaladbox.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.thesaladbox.com.sg/#/",
      waSeparator: "The SaladBox",
    },
    site_title: "The SaladBox",
    site_title_with_separator: " | The SaladBox",
    home_meta_title: "Welcome to The SaladBox",
    bgimage: "/img/ninjaprocmn/saladbox/body_bg.jpg",
  },
  "www.alhussainrestaurant.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B254C662-6518-4B4D-BE5F-30ADFD38FBA9",
    fbAppId: "273850896575817",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "JTdCJTIyZmFjZWJvb2slMjI6JTIyNTcxODYxMzk5ODM4NzU5JTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Al Hussain Restaurant",
    stripeImage:
      "https://www.alhussainrestaurant.com/img/ninjaprocmn/alhussain/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "alhussain",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.alhussainrestaurant.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.alhussainrestaurant.com/#/",
      fbImage: "https://www.alhussainrestaurant.com/img/alhussain/logo.png",
      fbTitle: "Al Hussain Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.alhussainrestaurant.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.alhussainrestaurant.com/#/",
      twTitile: "Al Hussain Restaurant",
      twvia: "Al Hussain Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.alhussainrestaurant.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.alhussainrestaurant.com/#/",
      waSeparator: "Al Hussain Restaurant",
    },
    site_title: "Al Hussain Restaurant",
    forcewww: "YES",
    site_title_with_separator: " | Al Hussain Restaurant",
    home_meta_title: "Welcome to Al Hussain Restaurant",
    bgimage: "/img/ninjaprocmn/alhussain/body_bg.jpg",
  },
  "order.mrprata.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2E5B22E3-D685-4693-AD4C-B1765A80EA75",
    fbAppId: "288627768562027",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "JTdCJTIyd2hhdHNhcHAlMjI6JTIyKzY1OTE0Mzc2ODglMjIlN0Q=",
    stripeKey: "pk_live_VYh72LUoep6GSlU7ZnuFLBIB",
    stripeCompany: "Mr. Prata",
    stripeImage:
      "https://order.mrprata.com.sg/img/ninjaprocmn/mrprata/stripe_logo.png",
    stripeEmail: "thanuja@jankosoft.com",
    stripeReference: "mrprata",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.mrprata.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.mrprata.com.sg/#/",
      fbImage: "https://order.mrprata.com.sg/img/mrprata/preview.png",
      fbTitle: "Mr. Prata",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.mrprata.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.mrprata.com.sg/#/",
      twTitile: "Mr. Prata",
      twvia: "Mr. Prata",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.mrprata.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.mrprata.com.sg/#/",
      waSeparator: "Mr. Prata",
    },
    site_title: "Mr. Prata",
    site_title_with_separator: " | Mr. Prata",
    home_meta_title: "Welcome to Mr. Prata",
    bgimage: "/img/ninjaprocmn/mrprata/body_bg.jpg",
  },
  "order.pitatree.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1E7CC2BB-01F7-47A7-B128-404EECDECE0C",
    fbAppId: "335207373897794",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyKzY1NjUzMjIwOTglMjIsJTIyZmFjZWJvb2slMjI6JTIyMjA0NTg5MzgzNTg1ODY0JTIyLCUyMmVtYWlsJTIyOiUyMmJvYXRxdWF5QHBpdGF0cmVlLmNvbSUyMiU3RA==",
    stripeKey: "pk_live_8EOxPtyezZRh1dFarEQIUJB7",
    stripeCompany: "Pita Tree",
    stripeImage:
      "https://order.pitatree.com/img/ninjaprocmn/pitatree/stripe_logo.png",
    stripeEmail: "hello@pitatree.com",
    stripeReference: "pitatree",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.pitatree.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.pitatree.com/#/",
      fbImage: "https://order.pitatree.com/img/pitatree/preview.jpg",
      fbTitle: "Pita Tree",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.pitatree.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.pitatree.com/#/",
      twTitile: "Pita Tree",
      twvia: "Pita Tree",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.pitatree.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.pitatree.com/#/",
      waSeparator: "Pita Tree",
    },
    site_title: "Pita Tree",
    site_title_with_separator: " | Pita Tree",
    home_meta_title: "Welcome to Pita Tree",
    bgimage: "/img/ninjaprocmn/pitatree/body_bg.jpg",
  },
  "bushido.ninjapro.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AD8BDEE2-92AA-461A-AEAF-47E921C99235",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_live_bldDQUj98zmY7mc3dTZDpr6W",
    stripeCompany: "Bushido",
    stripeImage:
      "https://bushido.ninjapro.co/img/ninjaprocmn/Bushido/stripe_logo.png",
    stripeEmail: "info@bushidoasia.com",
    stripeReference: "bushido",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://bushido.ninjapro.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://bushido.ninjapro.co/#/",
      fbImage: "https://bushido.ninjapro.co/img/Bushido/preview.jpg",
      fbTitle: "Bushido",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://bushido.ninjapro.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://bushido.ninjapro.co/#/",
      twTitile: "Bushido",
      twvia: "Bushido",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://bushido.ninjapro.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://bushido.ninjapro.co/#/",
      waSeparator: "Bushido",
    },
    site_title: "Bushido",
    site_title_with_separator: " | Bushido",
    home_meta_title: "Welcome to Bushido",
    bgimage: "/img/ninjaprocmn/Bushido/body_bg.jpg",
  },
  "order.konarmess.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9F55B4B0-A88F-4074-9598-D0AD61F22985",
    fbAppId: "584916035262204",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyKzY1OTYzNTY2MjclMjIsJTIyZmFjZWJvb2slMjI6JTIyMTc5OTY3OTQyODA1MjIzJTIyLCUyMmVtYWlsJTIyOiUyMmtvbmFybWVzcy5zZ0BnbWFpbC5jb20lMjIlN0Q=",
    stripeKey: "pk_live_mnmrho4ERX8wFpZX42pDN6v0",
    stripeCompany: "Konar Mess",
    stripeImage:
      "https://order.konarmess.com.sg/img/ninjaprocmn/konarmess/stripe_logo.png",
    stripeEmail: "konarmess.sg@gmail.com",
    stripeReference: "konarmess",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.konarmess.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.konarmess.com.sg/#/",
      fbImage: "https://order.konarmess.com.sg/img/Bushido/preview.jpg",
      fbTitle: "Konar Mess",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.konarmess.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.konarmess.com.sg/#/",
      twTitile: "Konar Mess",
      twvia: "Konar Mess",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.konarmess.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.konarmess.com.sg/#/",
      waSeparator: "Konar Mess",
    },
    site_title: "Konar Mess",
    site_title_with_separator: " | Konar Mess",
    home_meta_title: "Welcome to Konar Mess",
    bgimage: "/img/ninjaprocmn/konarmess/body_bg.jpg",
  },
  "www.tiptopbriyani.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6665D5E0-F716-4208-85AD-D062AE89B61B",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyKzY1OTAxMDMxMTklMjIsJTIyZmFjZWJvb2slMjI6JTIyNDg1NTc0NTU1Mjk4NTc3JTIyLCUyMmVtYWlsJTIyOiUyMnRpcHRvcGJyaXlhbmlAZ21haWwuY29tJTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tip Top Briyani",
    stripeImage:
      "https://www.tiptopbriyani.com/img/ninjaprocmn/tiptopbriyani/stripe_logo.png",
    stripeEmail: "tiptopbriyani@gmail.com",
    stripeReference: "tiptopbriyani",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.tiptopbriyani.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.tiptopbriyani.com/#/",
      fbImage: "https://www.tiptopbriyani.com/img/tiptopbriyani/preview.jpg",
      fbTitle: "Tip Top Briyani",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.tiptopbriyani.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.tiptopbriyani.com/#/",
      twTitile: "Tip Top Briyani",
      twvia: "Tip Top Briyani",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.tiptopbriyani.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.tiptopbriyani.com/#/",
      waSeparator: "Tip Top Briyani",
    },
    site_title: "Tip Top Briyani",
    forcewww: "YES",
    site_title_with_separator: " | Tip Top Briyani",
    home_meta_title: "Welcome to Tip Top Briyani",
    bgimage: "/img/ninjaprocmn/tiptopbriyani/body_bg.jpg",
  },
  "order.brownieboys.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "59ADA2AD-499C-4B0B-841F-452B754324ED",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_live_oT18rOGN2qxTzE0nO2s9DXCx",
    stripeCompany: "Brownie Boys",
    stripeImage:
      "https://order.brownieboys.com.sg/img/ninjaprocmn/brownieboys/stripe_logo.png",
    stripeEmail: "brownieboys@gmail.com",
    stripeReference: "brownie",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.brownieboys.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.brownieboys.com.sg/#/",
      fbImage: "https://order.brownieboys.com.sg/img/brownieboys/preview.jpg",
      fbTitle: "Brownie Boys",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.brownieboys.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.brownieboys.com.sg/#/",
      twTitile: "Brownie Boys",
      twvia: "Brownie Boys",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.brownieboys.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.brownieboys.com.sg/#/",
      waSeparator: "Brownie Boys",
    },
    site_title: "Brownie Boys",
    site_title_with_separator: " | Brownie Boys",
    home_meta_title: "Welcome to Brownie Boys",
    bgimage: "/img/ninjaprocmn/brownieboys/body_bg.jpg",
  },
  "orderonline.cucina.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0A3FDFD4-07AD-44BB-8D86-1988AA200D97",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyKzY1NjcwMjI2MjklMjIsJTIyZmFjZWJvb2slMjI6JTIyNDYxOTIxMDU3NDk4MjYzJTIyLCUyMmVtYWlsJTIyOiUyMnJlc2VydmF0aW9uc0BjdWNpbmEuc2clMjIlN0Q=",
    stripeKey: "pk_live_qNEGnqCIRg3Uqu6BrA7g1NE3",
    stripeCompany: "Cucina",
    stripeImage:
      "https://orderonline.cucina.sg/img/ninjaprocmn/cucina/stripe_logo.png",
    stripeEmail: "joyce@cucina.sg",
    stripeReference: "cucina",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orderonline.cucina.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orderonline.cucina.sg/#/",
      fbImage: "https://orderonline.cucina.sg/img/cucina/preview.jpg",
      fbTitle: "Cucina",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orderonline.cucina.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orderonline.cucina.sg/#/",
      twTitile: "Cucina",
      twvia: "Cucina",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://orderonline.cucina.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://orderonline.cucina.sg/#/",
      waSeparator: "Cucina",
    },
    site_title: "Cucina",
    site_title_with_separator: " | Cucina",
    home_meta_title: "Welcome to Cucina",
    bgimage: "/img/ninjaprocmn/cucina/body_bg.jpg",
  },
  "staging.udders.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "80D71B04-C58D-4D3A-8619-687DDBC382FE",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyKzY1NjY2Mjk0MTAlMjIsJTIyZmFjZWJvb2slMjI6JTIyMTM2NjI0OTI5ODIxJTIyLCUyMmVtYWlsJTIyOiUyMm1vb0B1ZGRlcnMuY29tLnNnJTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "udders",
    stripeImage:
      "https://staging.udders.com.sg/img/ninjaprocmn/udders/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "udders",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://staging.udders.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://staging.udders.com.sg/#/",
      fbImage: "https://staging.udders.com.sg/img/udders/preview.jpg",
      fbTitle: "Udders",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://staging.udders.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://staging.udders.com.sg/#/",
      twTitile: "udders",
      twvia: "udders",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://staging.udders.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://staging.udders.com.sg/#/",
      waSeparator: "udders",
    },
    site_title: "Udders",
    site_title_with_separator: " | Udders",
    home_meta_title: "Welcome to Udders",
    bgimage: "/img/ninjaprocmn/udders/body_bg.jpg",
  },
  "order.everestkitchensg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E5381958-8EF3-4971-A845-87BFDC94C604",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyKzY1NjY2Mjk0MTAlMjIsJTIyZmFjZWJvb2slMjI6JTIyMTM2NjI0OTI5ODIxJTIyLCUyMmVtYWlsJTIyOiUyMm1vb0B1ZGRlcnMuY29tLnNnJTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Everest Kitchen",
    stripeImage:
      "https://order.everestkitchensg.com/img/ninjaprocmn/everestkitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "everestkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.everestkitchensg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.everestkitchensg.com/#/",
      fbImage: "https://order.everestkitchensg.com/img/udders/preview.jpg",
      fbTitle: "Everest Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.everestkitchensg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.everestkitchensg.com/#/",
      twTitile: "everestkitchen",
      twvia: "everestkitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.everestkitchensg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.everestkitchensg.com/#/",
      waSeparator: "everestkitchen",
    },
    site_title: "Udders",
    site_title_with_separator: " | Everest Kitchen",
    home_meta_title: "Welcome to Everest Kitchen",
    bgimage: "/img/ninjaprocmn/everestkitchen/body_bg.jpg",
  },
  "www.yishikitchen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0DD7BB89-B71D-4A81-B253-65CB90DBF08B",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyKzY1ODExMTM1OTAlMjIsJTIyZmFjZWJvb2slMjI6JTIyMjAwNzYxNzEzNzQyMDQ5JTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "yishi",
    stripeImage:
      "https://www.yishikitchen.com/img/ninjaprocmn/yishi/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yishi",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.yishikitchen.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.yishikitchen.com/#/",
      fbImage: "https://www.yishikitchen.com/img/yishi/preview.jpg",
      fbTitle: "Yi Shi Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.yishikitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.yishikitchen.com/#/",
      twTitile: "Yi Shi Kitchen",
      twvia: "Yi Shi Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.yishikitchen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.yishikitchen.com/#/",
      waSeparator: "Yi Shi Kitchen",
    },
    site_title: "Yi Shi Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Yi Shi Kitchen",
    home_meta_title: "Welcome to Yi Shi Kitchen",
    bgimage: "/img/ninjaprocmn/yishi/body_bg.jpg",
  },
  "www.khanrestaurants.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A9F98030-897B-4057-BD62-33883AA65FF0",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Khan Kitchen",
    stripeImage:
      "https://www.khanrestaurants.com/img/ninjaprocmn/khan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "khan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.khanrestaurants.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.khanrestaurants.com/#/",
      fbImage: "https://www.khanrestaurants.com/img/khan/preview.jpg",
      fbTitle: "Khan Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.khanrestaurants.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.khanrestaurants.com/#/",
      twTitile: "Khan Kitchen",
      twvia: "Khan Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.khanrestaurants.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.khanrestaurants.com/#/",
      waSeparator: "Khan Kitchen",
    },
    site_title: "Khan Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Khan Kitchen",
    home_meta_title: "Welcome to Khan Kitchen",
    bgimage: "/img/ninjaprocmn/khan/body_bg.jpg",
  },
  "www.tabboulehrestaurant.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "67A7C5CF-6EA2-4AF7-9414-BB4E2CE689C2",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyJTIwKzY1NjI5MjUyMzUlMjIsJTIyZmFjZWJvb2slMjI6JTIyNTM3NTA5MjU2NDQyNjkyJTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tabbouleh",
    stripeImage:
      "https://www.tabboulehrestaurant.com/img/ninjaprocmn/tabbouleh/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tabbouleh",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.tabboulehrestaurant.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.tabboulehrestaurant.com/#/",
      fbImage: "https://www.tabboulehrestaurant.com/img/tabbouleh/preview.jpg",
      fbTitle: "Tabbouleh",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.tabboulehrestaurant.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.tabboulehrestaurant.com/#/",
      twTitile: "Tabbouleh",
      twvia: "Tabbouleh",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.tabboulehrestaurant.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.tabboulehrestaurant.com/#/",
      waSeparator: "Tabbouleh",
    },
    site_title: "Tabbouleh",
    forcewww: "YES",
    site_title_with_separator: " | Tabbouleh",
    home_meta_title: "Welcome to Tabbouleh",
    bgimage: "/img/ninjaprocmn/tabbouleh/body_bg.jpg",
  },
  "order.ahhuakelong.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "EBE69618-422B-4B43-ABB1-A23CDC59E9FE",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyMDY1OTIzMzMzNzElMjIsJTIycGhvbmUlMjI6JTIyMDY1OTIzMzMzNzElMjIsJTIyZmFjZWJvb2slMjI6JTIyMTQ1NjE2MTg0MTI5Mjk1MiUyMiU3RA==",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ah Hua Kelong",
    stripeImage:
      "https://order.ahhuakelong.com/img/ninjaprocmn/ahhuakelong/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ahhuakelong",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ahhuakelong.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ahhuakelong.com/#/",
      fbImage: "https://order.ahhuakelong.com/img/ahhuakelong/preview.jpg",
      fbTitle: "Ah Hua Kelong",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ahhuakelong.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ahhuakelong.com/#/",
      twTitile: "Ah Hua Kelong",
      twvia: "Ah Hua Kelong",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.ahhuakelong.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.ahhuakelong.com/#/",
      waSeparator: "Ah Hua Kelong",
    },
    site_title: "Ah Hua Kelong",
    site_title_with_separator: " | Ah Hua Kelong",
    home_meta_title: "Welcome to Ah Hua Kelong",
    bgimage: "/img/ninjaprocmn/ahhuakelong/body_bg.jpg",
  },
  "order.saladchef.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "329428F3-CB23-4961-968C-68775ADD8ACE",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyMDY1ODQ1NzM5NjklMjIsJTIyZmFjZWJvb2slMjI6JTIyNTAzMDQ5Njg2NDYxOTgxJTIyLCUyMmVtYWlsJTIyOiUyMmluZm9Ac2FsYWRjaGVmLnNnJTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Salad Chef",
    stripeImage:
      "https://order.saladchef.sg/img/ninjaprocmn/saladchef/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "saladchef",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.saladchef.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.saladchef.sg/#/",
      fbImage: "https://order.saladchef.sg/img/saladchef/preview.jpg",
      fbTitle: "Salad Chef",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.saladchef.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.saladchef.sg/#/",
      twTitile: "Salad Chef",
      twvia: "Salad Chef",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.saladchef.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.saladchef.sg/#/",
      waSeparator: "Salad Chef",
    },
    site_title: "Salad Chef",
    site_title_with_separator: " | Salad Chef",
    home_meta_title: "Welcome to Salad Chef",
    bgimage: "/img/ninjaprocmn/saladchef/body_bg.jpg",
  },
  "www.machanskitchensg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9571C6D1-6D44-4888-9DF1-F048650B4881",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Machans Kitchen",
    stripeImage:
      "https://www.machanskitchensg.com/img/ninjaprocmn/machans/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "machans",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.machanskitchensg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.machanskitchensg.com/#/",
      fbImage: "https://www.machanskitchensg.com/img/machans/preview.jpg",
      fbTitle: "Machans Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.machanskitchensg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.machanskitchensg.com/#/",
      twTitile: "Machans Kitchen",
      twvia: "Machans Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.machanskitchensg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.machanskitchensg.com/#/",
      waSeparator: "Machans Kitchen",
    },
    site_title: "Machans Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Machans Kitchen",
    home_meta_title: "Welcome to Machans Kitchen",
    bgimage: "/img/ninjaprocmn/machans/body_bg.jpg",
  },
  "www.gokulrestaurantsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F189BE15-851E-42F8-9750-45D13C2CC1B7",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Gokul Restaurant",
    stripeImage:
      "https://www.gokulrestaurantsg.com/img/ninjaprocmn/gokul/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gokul",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.gokulrestaurantsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.gokulrestaurantsg.com/#/",
      fbImage: "https://www.gokulrestaurantsg.com/img/gokul/preview.jpg",
      fbTitle: "Gokul Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.gokulrestaurantsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.gokulrestaurantsg.com/#/",
      twTitile: "Gokul Restaurant",
      twvia: "Gokul Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.gokulrestaurantsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.gokulrestaurantsg.com/#/",
      waSeparator: "Gokul Restaurant",
    },
    site_title: "Gokul Restaurant",
    forcewww: "YES",
    site_title_with_separator: " | Gokul Restaurant",
    home_meta_title: "Welcome to Gokul Restaurant",
    bgimage: "/img/ninjaprocmn/gokul/body_bg.jpg",
  },
  "www.deannaskitchensg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "30E6FB69-4E50-4331-9BE8-883D413CA457",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyKzY1ODI5ODI3MzAlMjIsJTIycGhvbmUlMjI6JTIyKzY1ODI5ODI3MzAlMjIsJTIyZmFjZWJvb2slMjI6JTIyNDczNTA0NDQ2MzMxMTYzJTIyLCUyMmVtYWlsJTIyOiUyMmRlYW5uYXNraXRjaGVuLnNnQGdtYWlsLmNvbSUyMiU3RA==",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Deannas Kitchen",
    stripeImage:
      "https://www.deannaskitchensg.com/img/ninjaprocmn/deannas/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "deannas",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.deannaskitchensg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.deannaskitchensg.com/#/",
      fbImage: "https://www.deannaskitchensg.com/img/deannas/preview.jpg",
      fbTitle: "Deannas Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.deannaskitchensg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.deannaskitchensg.com/#/",
      twTitile: "Deannas Kitchen",
      twvia: "Deannas Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.deannaskitchensg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.deannaskitchensg.com/#/",
      waSeparator: "Deannas Kitchen",
    },
    site_title: "Deannas Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Deannas Kitchen",
    home_meta_title: "Welcome to Deannas Kitchen",
    bgimage: "/img/ninjaprocmn/deannas/body_bg.jpg",
  },
  "order.bbqtonight.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "100F50EB-E1D5-4035-8C74-06FE9399FDFE",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Bar BQ Tonight",
    stripeImage:
      "https://order.bbqtonight.com.sg/img/ninjaprocmn/barbqtonight/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "barbqtonight",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.bbqtonight.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.bbqtonight.com.sg/#/",
      fbImage: "https://order.bbqtonight.com.sg/img/barbqtonight/preview.jpg",
      fbTitle: "Bar BQ Tonight",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.bbqtonight.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.bbqtonight.com.sg/#/",
      twTitile: "Bar BQ Tonight",
      twvia: "Bar BQ Tonight",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.bbqtonight.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.bbqtonight.com.sg/#/",
      waSeparator: "Bar BQ Tonight",
    },
    site_title: "Bar BQ Tonight",
    site_title_with_separator: " | Bar BQ Tonight",
    home_meta_title: "Welcome to Bar BQ Tonight",
    bgimage: "/img/ninjaprocmn/barbqtonight/body_bg.jpg",
  },
  "www.hongkongstreetjiakee.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "64AB8EB8-3E53-4516-AFF8-CD197643DD15",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Hong Kong Street",
    stripeImage:
      "https://www.hongkongstreetjiakee.com/img/ninjaprocmn/hongkong/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hongkong",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.hongkongstreetjiakee.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.hongkongstreetjiakee.com/#/",
      fbImage: "https://www.hongkongstreetjiakee.com/img/hongkong/preview.jpg",
      fbTitle: "Hong Kong Street",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.hongkongstreetjiakee.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.hongkongstreetjiakee.com/#/",
      twTitile: "Hong Kong Street",
      twvia: "Hong Kong Street",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.hongkongstreetjiakee.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.hongkongstreetjiakee.com/#/",
      waSeparator: "Hong Kong Street",
    },
    site_title: "Hong Kong Street",
    forcewww: "YES",
    site_title_with_separator: " | Hong Kong Street",
    home_meta_title: "Welcome to Hong Kong Street",
    bgimage: "/img/ninjaprocmn/hongkong/body_bg.jpg",
  },
  "hungrythai.ninjapro.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "80449D55-AB58-47C3-A054-A016C50512EE",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Hong Kong Street",
    stripeImage:
      "https://www.hongkongstreetjiakee.com/img/ninjaprocmn/hongkong/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hongkong",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.hongkongstreetjiakee.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.hongkongstreetjiakee.com/#/",
      fbImage: "https://www.hongkongstreetjiakee.com/img/hongkong/preview.jpg",
      fbTitle: "Hong Kong Street",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.hongkongstreetjiakee.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.hongkongstreetjiakee.com/#/",
      twTitile: "Hong Kong Street",
      twvia: "Hong Kong Street",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.hongkongstreetjiakee.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.hongkongstreetjiakee.com/#/",
      waSeparator: "Hong Kong Street",
    },
    site_title: "Hong Kong Street",
    site_title_with_separator: " | Hong Kong Street",
    home_meta_title: "Welcome to Hong Kong Street",
    bgimage: "/img/ninjaprocmn/hongkong/body_bg.jpg",
  },
  "www.melben440.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "583EB2C1-9FBA-4E0B-B655-20A96507647B",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyNjU4MjAzOTclMjIsJTIyZmFjZWJvb2slMjI6JTIyMTMzOTg5NDYwMTA1ODQ1JTIyLCUyMmVtYWlsJTIyOiUyMm1lbGxiZW40NDBAaG90bWFpbC5jb20lMjIlN0Q=",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Melben440",
    stripeImage:
      "https://www.melben440.com/img/ninjaprocmn/melben/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "melben",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.melben440.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.melben440.com/#/",
      fbImage: "https://www.melben440.com/img/melben/preview.jpg",
      fbTitle: "Melben440",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.melben440.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.melben440.com/#/",
      twTitile: "Melben440",
      twvia: "Melben440",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.melben440.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.melben440.com/#/",
      waSeparator: "Melben440",
    },
    site_title: "Melben440",
    forcewww: "YES",
    site_title_with_separator: " | Melben440",
    home_meta_title: "Welcome to Melben440",
    bgimage: "/img/ninjaprocmn/melben/body_bg.jpg",
  },
  "www.hungrythai.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "80449D55-AB58-47C3-A054-A016C50512EE",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyODE4NjgwNzglMjIsJTIyZmFjZWJvb2slMjI6JTIyMTcwNzQ0NjQ2NTk4MTIwNyUyMiU3RA==",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Hungry Thai",
    stripeImage:
      "https://www.hungrythai.sg/img/ninjaprocmn/hungrythai/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hungrythai",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.hungrythai.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.hungrythai.sg/#/",
      fbImage: "https://www.hungrythai.sg/img/hungrythai/preview.jpg",
      fbTitle: "Hungry Thai",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.hungrythai.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.hungrythai.sg/#/",
      twTitile: "Hungry Thai",
      twvia: "Hungry Thai",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.hungrythai.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.hungrythai.sg/#/",
      waSeparator: "Hungry Thai",
    },
    site_title: "Hungry Thai",
    forcewww: "YES",
    site_title_with_separator: " | Hungry Thai",
    home_meta_title: "Welcome to Hungry Thai",
    bgimage: "/img/ninjaprocmn/hungrythai/body_bg.jpg",
  },
  "orderonline.chillas.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DB08BAAE-4F7A-4F90-B3B5-AC7CBF001B88",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyKzY1NjcxMDQxMDQlMjIsJTIyZmFjZWJvb2slMjI6JTIyODMzOTEwMjYwMDYwMjcxJTIyLCUyMmVtYWlsJTIyOiUyMmNoaWxsYXMyMDE1QGdtYWlsLmNvbSUyMiU3RA==",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Chillas",
    stripeImage:
      "https://orderonline.chillas.com.sg/img/ninjaprocmn/chillas/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "chillas",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orderonline.chillas.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orderonline.chillas.com.sg/#/",
      fbImage: "https://orderonline.chillas.com.sg/img/chillas/preview.jpg",
      fbTitle: "Chillas",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orderonline.chillas.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orderonline.chillas.com.sg/#/",
      twTitile: "Chillas",
      twvia: "Chillas",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://orderonline.chillas.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://orderonline.chillas.com.sg/#/",
      waSeparator: "Chillas",
    },
    site_title: "Chillas",
    site_title_with_separator: " | Chillas",
    home_meta_title: "Welcome to Chillas",
    bgimage: "/img/ninjaprocmn/chillas/body_bg.jpg",
  },
  "order.egajuiceclinic.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "CC11FCFA-3F4D-4AA9-9082-665BC5E7C669",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyMDY1NjY5NDE5MDAlMjIsJTIyZmFjZWJvb2slMjI6JTIyMTg2MzQ3NjMyMDU0OTI5OCUyMiwlMjJlbWFpbCUyMjolMjJvcmRlcnNAZWdhanVpY2VjbGluaWMuY29tJTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ega Juice",
    stripeImage:
      "https://order.egajuiceclinic.com/img/ninjaprocmn/egajuice/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "egajuice",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.egajuiceclinic.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.egajuiceclinic.com/#/",
      fbImage: "https://order.egajuiceclinic.com/img/egajuice/preview.jpg",
      fbTitle: "Ega Juice",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.egajuiceclinic.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.egajuiceclinic.com/#/",
      twTitile: "Ega Juice",
      twvia: "Ega Juice",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.egajuiceclinic.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.egajuiceclinic.com/#/",
      waSeparator: "Ega Juice",
    },
    site_title: "Ega Juice",
    forcewww: "YES",
    site_title_with_separator: " | Ega Juice",
    home_meta_title: "Welcome to Ega Juice",
    bgimage: "/img/ninjaprocmn/egajuice/body_bg.jpg",
    enablesccan: "Yes",
  },
  "order.greenhousecafe.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0BD8D167-6DDA-4DCE-A063-2F440A15397D",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyKzY1OTA4NzU5NDElMjIsJTIycGhvbmUlMjI6JTIyKzY1Njg2MzAxMTAlMjIsJTIyZmFjZWJvb2slMjI6JTIyNTcyNTM5MjE5NTU3ODc4JTIyLCUyMmVtYWlsJTIyOiUyMmNvbnRhY3R1c0BncmVlbmhvdXNlY2FmZS5jb20uc2clMjIlN0Q=",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Green House Cafe",
    stripeImage:
      "https://order.greenhousecafe.com.sg/img/ninjaprocmn/greenhouse/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "greenhouse",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.greenhousecafe.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.greenhousecafe.com.sg/#/",
      fbImage: "https://order.greenhousecafe.com.sg/img/greenhouse/preview.jpg",
      fbTitle: "Green House Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.greenhousecafe.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.greenhousecafe.com.sg/#/",
      twTitile: "Green House Cafe",
      twvia: "Green House Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.greenhousecafe.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.greenhousecafe.com.sg/#/",
      waSeparator: "Green House Cafe",
    },
    site_title: "Green House Cafe",
    site_title_with_separator: " | Green House Cafe",
    home_meta_title: "Welcome to Green House Cafe",
    bgimage: "/img/ninjaprocmn/greenhouse/body_bg.jpg",
  },
  "order.keto-indulgence.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3904C55A-6013-469A-8735-72EF19F38DDC",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Elyon Cafe",
    stripeImage:
      "https://order.keto-indulgence.com/img/ninjaprocmn/elyoncafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "elyoncafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.keto-indulgence.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.keto-indulgence.com/#/",
      fbImage: "https://order.keto-indulgence.com/img/elyoncafe/preview.jpg",
      fbTitle: "Elyon Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.keto-indulgence.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.keto-indulgence.com/#/",
      twTitile: "Elyon Cafe",
      twvia: "Elyon Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.keto-indulgence.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.keto-indulgence.com/#/",
      waSeparator: "Elyon Cafe",
    },
    site_title: "Elyon Cafe",
    site_title_with_separator: " | Elyon Cafe",
    home_meta_title: "Welcome to Elyon Cafe",
    bgimage: "/img/ninjaprocmn/elyoncafe/body_bg.jpg",
  },
  "www.dynamicdiningsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0C81A804-F745-4D69-9DC6-E609A719ED0A",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Dynamic Dining",
    stripeImage:
      "https://www.dynamicdiningsg.com/img/ninjaprocmn/dynamic/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "dynamic",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.dynamicdiningsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.dynamicdiningsg.com/#/",
      fbImage: "https://www.dynamicdiningsg.com/img/dynamic/preview.jpg",
      fbTitle: "Dynamic Dining",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.dynamicdiningsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.dynamicdiningsg.com/#/",
      twTitile: "Dynamic Dining",
      twvia: "Dynamic Dining",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.dynamicdiningsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.dynamicdiningsg.com/#/",
      waSeparator: "Dynamic Dining",
    },
    site_title: "Dynamic Dining",
    forcewww: "YES",
    site_title_with_separator: " | Dynamic Dining",
    home_meta_title: "Welcome to Dynamic Dining",
    bgimage: "/img/ninjaprocmn/dynamic/body_bg.jpg",
  },
  "order.inspirithouse.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "55E5FFE8-05D9-42AC-A661-C5E0F747B485",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Inspirit House",
    stripeImage:
      "https://order.inspirithouse.com/img/ninjaprocmn/inspirithouse/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "inspirithouse",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.inspirithouse.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.inspirithouse.com/#/",
      fbImage: "https://order.inspirithouse.com/img/inspirithouse/preview.jpg",
      fbTitle: "Inspirit House",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.inspirithouse.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.inspirithouse.com/#/",
      twTitile: "Inspirit House",
      twvia: "Inspirit House",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.inspirithouse.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.inspirithouse.com/#/",
      waSeparator: "Inspirit House",
    },
    site_title: "Inspirit House",
    site_title_with_separator: " | Inspirit House",
    home_meta_title: "Welcome to Inspirit House",
    bgimage: "/img/ninjaprocmn/inspirithouse/body_bg.jpg",
  },
  "order.eighteenchefs.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "57D02BBE-A453-4F97-9D79-DB3CA9C27525",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Eighteen Chefs",
    stripeImage:
      "https://order.eighteenchefs.com/img/ninjaprocmn/eighteenchefs/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "eighteenchefs",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.eighteenchefs.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.eighteenchefs.com/#/",
      fbImage: "https://order.eighteenchefs.com/img/eighteenchefs/preview.jpg",
      fbTitle: "Eighteen Chefs",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.eighteenchefs.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.eighteenchefs.com/#/",
      twTitile: "Eighteen Chefs",
      twvia: "Eighteen Chefs",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.eighteenchefs.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.eighteenchefs.com/#/",
      waSeparator: "Eighteen Chefs",
    },
    site_title: "Eighteen Chefs",
    site_title_with_separator: " | Eighteen Chefs",
    home_meta_title: "Welcome to Eighteen Chefs",
    bgimage: "/img/ninjaprocmn/eighteenchefs/body_bg.jpg",
  },
  "order.currynations.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B7F75474-F5F3-4657-AE64-A8E0D43C100A",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Curry Nations",
    stripeImage:
      "https://order.currynations.com/img/ninjaprocmn/currynations/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "currynations",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.currynations.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.currynations.com/#/",
      fbImage: "https://order.currynations.com/img/currynations/preview.jpg",
      fbTitle: "Curry Nations",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.currynations.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.currynations.com/#/",
      twTitile: "Curry Nations",
      twvia: "Curry Nations",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.currynations.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.currynations.com/#/",
      waSeparator: "Curry Nations",
    },
    site_title: "Curry Nations",
    site_title_with_separator: " | Curry Nations",
    home_meta_title: "Welcome to Curry Nations",
    bgimage: "/img/ninjaprocmn/currynations/body_bg.jpg",
  },
  "order.717trading.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AD716160-DE4D-4BFF-9EEC-B7148F91FBC5",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "717 Trading",
    stripeImage:
      "https://order.717trading.com.sg/img/ninjaprocmn/freshdurian/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "freshdurian",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.717trading.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.717trading.com.sg/#/",
      fbImage: "https://order.717trading.com.sg/img/freshdurian/preview.jpg",
      fbTitle: "717 Trading",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.717trading.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.717trading.com.sg/#/",
      twTitile: "717 Trading",
      twvia: "717 Trading",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.717trading.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.717trading.com.sg/#/",
      waSeparator: "717 Trading",
    },
    site_title: "717 Trading",
    site_title_with_separator: " | 717 Trading",
    home_meta_title: "Welcome to 717 Trading",
    bgimage: "/img/ninjaprocmn/freshdurian/body_bg.jpg",
  },
  "www.blackboyscubanos.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A4785C91-2610-43E4-BA48-92F79CECB50C",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Black Boys Cubanos",
    stripeImage:
      "https://www.blackboyscubanos.com/img/ninjaprocmn/blackboys/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "blackboys",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.blackboyscubanos.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.blackboyscubanos.com/#/",
      fbImage: "https://www.blackboyscubanos.com/img/blackboys/preview.jpg",
      fbTitle: "Black Boys Cubanos",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.blackboyscubanos.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.blackboyscubanos.com/#/",
      twTitile: "Black Boys Cubanos",
      twvia: "Black Boys Cubanos",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.blackboyscubanos.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.blackboyscubanos.com/#/",
      waSeparator: "Black Boys Cubanos",
    },
    site_title: "Black Boys Cubanos",
    forcewww: "YES",
    site_title_with_separator: " | Black Boys Cubanos",
    home_meta_title: "Welcome to Black Boys Cubanos",
    bgimage: "/img/ninjaprocmn/blackboys/body_bg.jpg",
  },
  "order.greenleafcafe.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A8362694-E4F3-4A0B-A157-0F7FBCF82502",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Greenleaf Cafe",
    stripeImage:
      "https://order.greenleafcafe.com.sg/img/ninjaprocmn/greenleaf/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "greenleaf",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.greenleafcafe.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.greenleafcafe.com.sg/#/",
      fbImage: "https://order.greenleafcafe.com.sg/img/greenleaf/preview.jpg",
      fbTitle: "Greenleaf Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.greenleafcafe.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.greenleafcafe.com.sg/#/",
      twTitile: "Greenleaf Cafe",
      twvia: "Greenleaf Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.greenleafcafe.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.greenleafcafe.com.sg/#/",
      waSeparator: "Greenleaf Cafe",
    },
    site_title: "Greenleaf Cafe",
    site_title_with_separator: " | Greenleaf Cafe",
    home_meta_title: "Welcome to Greenleaf Cafe",
    bgimage: "/img/ninjaprocmn/greenleaf/body_bg.jpg",
  },
  "www.nawabsbriyani.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D2630350-4A81-4D4D-AF7D-D7D1656C34DA",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Nawabs Kitchen",
    stripeImage:
      "https://www.nawabsbriyani.com/img/ninjaprocmn/nawabskitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "nawabskitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.nawabsbriyani.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.nawabsbriyani.com/#/",
      fbImage: "https://www.nawabsbriyani.com/img/nawabskitchen/preview.jpg",
      fbTitle: "Nawabs Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.nawabsbriyani.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.nawabsbriyani.com/#/",
      twTitile: "Nawabs Kitchen",
      twvia: "Nawabs Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.nawabsbriyani.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.nawabsbriyani.com/#/",
      waSeparator: "Nawabs Kitchen",
    },
    site_title: "Nawabs Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Nawabs Kitchen",
    home_meta_title: "Welcome to Nawabs Kitchen",
    bgimage: "/img/ninjaprocmn/nawabskitchen/body_bg.jpg",
  },
  "order.pizzaboysg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "961EAB20-EA57-4D91-9F04-265ACE34BD05",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "JTdCJTIyZmFjZWJvb2slMjI6JTIyODMxNjEzMzE2OTQxOTA4JTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Pizzaboy",
    stripeImage:
      "https://order.pizzaboysg.com/img/ninjaprocmn/pizzaboy/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pizzaboy",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.pizzaboysg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.pizzaboysg.com/#/",
      fbImage: "https://order.pizzaboysg.com/img/pizzaboy/preview.jpg",
      fbTitle: "Pizzaboy",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.pizzaboysg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.pizzaboysg.com/#/",
      twTitile: "Pizzaboy",
      twvia: "Pizzaboy",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.pizzaboysg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.pizzaboysg.com/#/",
      waSeparator: "Pizzaboy",
    },
    site_title: "Pizzaboy",
    site_title_with_separator: " | Pizzaboy",
    home_meta_title: "Welcome to Pizzaboy",
    bgimage: "/img/ninjaprocmn/pizzaboy/body_bg.jpg",
  },
  "order.zazzpizza.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DDAA254C-BD1E-49F0-AD1E-164A140421D3",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Zazz Pizza",
    stripeImage:
      "https://order.zazzpizza.sg/img/ninjaprocmn/zazzpizza/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "zazzpizza",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.zazzpizza.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.zazzpizza.sg/#/",
      fbImage: "https://order.zazzpizza.sg/img/zazzpizza/preview.jpg",
      fbTitle: "Zazz Pizza",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.zazzpizza.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.zazzpizza.sg/#/",
      twTitile: "Zazz Pizza",
      twvia: "Zazz Pizza",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.zazzpizza.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.zazzpizza.sg/#/",
      waSeparator: "Zazz Pizza",
    },
    site_title: "Zazz Pizza",
    site_title_with_separator: " | Zazz Pizza",
    home_meta_title: "Welcome to Zazz Pizza",
    bgimage: "/img/ninjaprocmn/zazzpizza/body_bg.jpg",
  },
  "kiddypalace.ninjapro.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C3A3A5E8-FAAA-41BB-A73F-13D44F391976",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kiddy Palace",
    stripeImage:
      "https://kiddypalace.ninjapro.co/img/ninjaprocmn/kiddypalace/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kiddypalace",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://kiddypalace.ninjapro.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://kiddypalace.ninjapro.co/#/",
      fbImage: "https://kiddypalace.ninjapro.co/img/kiddypalace/preview.jpg",
      fbTitle: "Kiddy Palace",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://kiddypalace.ninjapro.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://kiddypalace.ninjapro.co/#/",
      twTitile: "Kiddy Palace",
      twvia: "Kiddy Palace",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://kiddypalace.ninjapro.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://kiddypalace.ninjapro.co/#/",
      waSeparator: "Kiddy Palace",
    },
    site_title: "Kiddy Palace",
    site_title_with_separator: " | Kiddy Palace",
    home_meta_title: "Welcome to Kiddy Palace",
    bgimage: "/img/ninjaprocmn/kiddypalace/body_bg.jpg",
    enablesccan: "Yes",
  },
  "www.bikanervalasg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9DED92D4-FED9-4AE7-8866-86A030EB6A4B",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Bikanervala",
    stripeImage:
      "https://www.bikanervalasg.com/img/ninjaprocmn/bikanervala/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bikanervala",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.bikanervalasg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.bikanervalasg.com/#/",
      fbImage: "https://www.bikanervalasg.com/img/bikanervala/preview.jpg",
      fbTitle: "Bikanervala",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.bikanervalasg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.bikanervalasg.com/#/",
      twTitile: "Bikanervala",
      twvia: "Bikanervala",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.bikanervalasg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.bikanervalasg.com/#/",
      waSeparator: "Bikanervala",
    },
    site_title: "Bikanervala",
    forcewww: "YES",
    site_title_with_separator: " | Bikanervala",
    home_meta_title: "Welcome to Bikanervala",
    bgimage: "/img/ninjaprocmn/bikanervala/body_bg.jpg",
  },
  "order.vatossg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1EEBEE8F-D7A4-4B56-A040-44AE76FE4A25",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Vatos",
    stripeImage:
      "https://order.vatossg.com/img/ninjaprocmn/vatos/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "vatos",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.vatossg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.vatossg.com/#/",
      fbImage: "https://order.vatossg.com/img/vatos/preview.jpg",
      fbTitle: "Vatos",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.vatossg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.vatossg.com/#/",
      twTitile: "Vatos",
      twvia: "Vatos",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.vatossg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.vatossg.com/#/",
      waSeparator: "Vatos",
    },
    site_title: "Vatos",
    site_title_with_separator: " | Vatosa",
    home_meta_title: "Welcome to Vatos",
    bgimage: "/img/ninjaprocmn/vatos/body_bg.jpg",
  },
  "order.themeatery.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "68009D56-9386-4ECE-92D8-6F81E11B8742",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIyd2hhdHNhcHAlMjI6JTIyMDY1ODg5Mzc1NjElMjIsJTIyZmFjZWJvb2slMjI6JTIyMjA3NTA0MjIxOTQ3Njc4OCUyMiwlMjJlbWFpbCUyMjolMjJyZW5nYUB0aGVtZWF0ZXJ5LnNnJTIyJTdE",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Meatery",
    stripeImage:
      "https://order.themeatery.sg/img/ninjaprocmn/meatery/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "meatery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.themeatery.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.themeatery.sg/#/",
      fbImage: "https://order.themeatery.sg/img/meatery/preview.jpg",
      fbTitle: "The Meatery",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.themeatery.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.themeatery.sg/#/",
      twTitile: "The Meatery",
      twvia: "The Meatery",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.themeatery.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.themeatery.sg/#/",
      waSeparator: "The Meatery",
    },
    site_title: "The Meatery",
    site_title_with_separator: " | The Meatery",
    home_meta_title: "Welcome to The Meatery",
    bgimage: "/img/ninjaprocmn/meatery/body_bg.jpg",
  },
  "www.wangyuanfishsoup.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0895EF10-4D64-4791-8EA6-CA447C8DC768",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Wang Yuan Fish Soup",
    stripeImage:
      "https://www.wangyuanfishsoup.com/img/ninjaprocmn/wangyuan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "wangyuan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.wangyuanfishsoup.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.wangyuanfishsoup.com/#/",
      fbImage: "https://www.wangyuanfishsoup.com/img/wangyuan/preview.jpg",
      fbTitle: "Wang Yuan Fish Soup",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.wangyuanfishsoup.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.wangyuanfishsoup.com/#/",
      twTitile: "Wang Yuan Fish Soup",
      twvia: "Wang Yuan Fish Soup",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.wangyuanfishsoup.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.wangyuanfishsoup.com/#/",
      waSeparator: "Wang Yuan Fish Soup",
    },
    site_title: "Wang Yuan Fish Soup",
    forcewww: "YES",
    site_title_with_separator: " | Wang Yuan Fish Soup",
    home_meta_title: "Welcome to Wang Yuan Fish Soup",
    bgimage: "/img/ninjaprocmn/wangyuan/body_bg.jpg",
  },
  "www.dashengsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "09D52521-8F4D-4E7B-A99D-5BD9E603D921",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Da Sheng Minced Pork Noodles",
    stripeImage:
      "https://www.dashengsg.com/img/ninjaprocmn/dasheng/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "dasheng",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.dashengsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.dashengsg.com/#/",
      fbImage: "https://www.dashengsg.com/img/dasheng/preview.jpg",
      fbTitle: "Da Sheng Minced Pork Noodles",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.dashengsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.dashengsg.com/#/",
      twTitile: "Da Sheng Minced Pork Noodles",
      twvia: "Da Sheng Minced Pork Noodles",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.dashengsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.dashengsg.com/#/",
      waSeparator: "Da Sheng Minced Pork Noodles",
    },
    site_title: "Da Sheng Minced Pork Noodles",
    forcewww: "YES",
    site_title_with_separator: " | Da Sheng Minced Pork Noodles",
    home_meta_title: "Welcome to Da Sheng Minced Pork Noodles",
    bgimage: "/img/ninjaprocmn/dasheng/body_bg.jpg",
  },
  "order.camaca.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0CBD3191-08D0-4A58-9541-53A0923D0800",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Camaca",
    stripeImage:
      "https://order.camaca.sg/img/ninjaprocmn/camaca/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "camaca",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.camaca.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.camaca.sg/#/",
      fbImage: "https://order.camaca.sg/img/dasheng/preview.jpg",
      fbTitle: "Camaca",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.camaca.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.camaca.sg/#/",
      twTitile: "Camaca",
      twvia: "Camaca",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.camaca.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.camaca.sg/#/",
      waSeparator: "Camaca",
    },
    site_title: "Camaca",
    site_title_with_separator: " | Camaca",
    home_meta_title: "Welcome to Camaca",
    bgimage: "/img/ninjaprocmn/camaca/body_bg.jpg",
  },
  "www.abumubarakmandirice.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AF75C2CD-90D2-4EFF-A1EE-C5AF199BC874",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Abumubarak Mandi Rice",
    stripeImage:
      "https://www.abumubarakmandirice.com/img/ninjaprocmn/abumubarak/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "abumubarak",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.abumubarakmandirice.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.abumubarakmandirice.com/#/",
      fbImage: "https://www.abumubarakmandirice.com/img/abumubarak/preview.jpg",
      fbTitle: "Abumubarak Mandi Rice",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.abumubarakmandirice.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.abumubarakmandirice.com/#/",
      twTitile: "Abumubarak Mandi Rice",
      twvia: "Abumubarak Mandi Rice",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.abumubarakmandirice.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.abumubarakmandirice.com/#/",
      waSeparator: "Abumubarak Mandi Rice",
    },
    site_title: "Abumubarak Mandi Rice",
    forcewww: "YES",
    site_title_with_separator: " | Abumubarak Mandi Rice",
    home_meta_title: "Welcome to Abumubarak Mandi Rice",
    bgimage: "/img/ninjaprocmn/abumubarak/body_bg.jpg",
  },
  "order.luckycafe.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "FAE285F1-9DBD-48DD-976F-3CEEF4F7F8AE",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Lucky Cafe",
    stripeImage:
      "https://order.luckycafe.com.sg/img/ninjaprocmn/luckycafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "luckycafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.luckycafe.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.luckycafe.com.sg/#/",
      fbImage: "https://order.luckycafe.com.sg/img/luckycafe/preview.jpg",
      fbTitle: "Lucky Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.luckycafe.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.luckycafe.com.sg/#/",
      twTitile: "Lucky Cafe",
      twvia: "Lucky Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.luckycafe.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.luckycafe.com.sg/#/",
      waSeparator: "Lucky Cafe",
    },
    site_title: "Lucky Cafe",
    site_title_with_separator: " | Lucky Cafe",
    home_meta_title: "Welcome to Lucky Cafe",
    bgimage: "/img/ninjaprocmn/luckycafe/body_bg.jpg",
  },
  "order.chatramuesg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F1ED4F6E-CFC1-40E7-AC7D-70AE54316761",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Cha Tra Mue",
    stripeImage:
      "https://order.chatramuesg.com/img/ninjaprocmn/chatramue/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "chatramue",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.chatramuesg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.chatramuesg.com/#/",
      fbImage: "https://order.chatramuesg.com/img/chatramue/preview.jpg",
      fbTitle: "Cha Tra Mue",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.chatramuesg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.chatramuesg.com/#/",
      twTitile: "Cha Tra Mue",
      twvia: "Cha Tra Mue",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.chatramuesg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.chatramuesg.com/#/",
      waSeparator: "Cha Tra Mue",
    },
    site_title: "Cha Tra Mue",
    site_title_with_separator: " | Cha Tra Mue",
    home_meta_title: "Welcome to Cha Tra Mue",
    bgimage: "/img/ninjaprocmn/chatramue/body_bg.jpg",
  },
  "www.cohaibanhmi.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "590C8489-82FA-4A58-AC47-D9F938DD226B",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Co Hai Banh Mi",
    stripeImage:
      "https://www.cohaibanhmi.com/img/ninjaprocmn/cohaibanhmi/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "cohaibanhmi",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.cohaibanhmi.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.cohaibanhmi.com/#/",
      fbImage: "https://www.cohaibanhmi.com/img/cohaibanhmi/preview.jpg",
      fbTitle: "Co Hai Banh Mi",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.cohaibanhmi.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.cohaibanhmi.com/#/",
      twTitile: "Co Hai Banh Mi",
      twvia: "Co Hai Banh Mi",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.cohaibanhmi.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.cohaibanhmi.com/#/",
      waSeparator: "Co Hai Banh Mi",
    },
    site_title: "Co Hai Banh Mi",
    forcewww: "YES",
    site_title_with_separator: " | Co Hai Banh Mi",
    home_meta_title: "Welcome to Co Hai Banh Mi",
    bgimage: "/img/ninjaprocmn/cohaibanhmi/body_bg.jpg",
  },
  "www.edenfoodhall.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Eden Food Hall",
    stripeImage:
      "https://www.edenfoodhall.com/img/ninjaprocmn/edenfoodhall/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "edenfoodhall",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.edenfoodhall.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.edenfoodhall.com/#/",
      fbImage: "https://www.edenfoodhall.com/img/edenfoodhall/preview.jpg",
      fbTitle: "Eden Food Hall",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.edenfoodhall.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.edenfoodhall.com/#/",
      twTitile: "Eden Food Hall",
      twvia: "Eden Food Hall",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.edenfoodhall.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.edenfoodhall.com/#/",
      waSeparator: "Eden Food Hall",
    },
    site_title: "Eden Food Hall",
    forcewww: "YES",
    site_title_with_separator: " | Eden Food Hall",
    home_meta_title: "Welcome to Eden Food Hall",
    bgimage: "/img/ninjaprocmn/edenfoodhall/body_bg.jpg",
  },
  "order.kailashparbat.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A6AE2DA0-031E-41EB-B92F-984663170B43",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kailashparbat",
    stripeImage:
      "https://order.kailashparbat.com.sg/img/ninjaprocmn/kailashparbat/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kailashparbat",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.kailashparbat.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.kailashparbat.com.sg/#/",
      fbImage:
        "https://order.kailashparbat.com.sg/img/kailashparbat/preview.jpg",
      fbTitle: "Kailashparbat",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.kailashparbat.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.kailashparbat.com.sg/#/",
      twTitile: "Kailashparbat",
      twvia: "Kailashparbat",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.kailashparbat.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.kailashparbat.com.sg/#/",
      waSeparator: "Kailashparbat",
    },
    site_title: "Kailashparbat",
    site_title_with_separator: " | Kailashparbat",
    home_meta_title: "Welcome to Kailashparbat",
    bgimage: "/img/ninjaprocmn/kailashparbat/body_bg.jpg",
  },
  "www.koedosg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1E28AF0F-29F8-4BC5-9B47-8BA1F8199E5B",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Demae Sushi Koedo",
    stripeImage:
      "https://www.koedosg.com/img/ninjaprocmn/koedosg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "koedosg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.koedosg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.koedosg.com/#/",
      fbImage: "https://www.koedosg.com/img/koedosg/preview.jpg",
      fbTitle: "Demae Sushi Koedo",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.koedosg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.koedosg.com/#/",
      twTitile: "Demae Sushi Koedo",
      twvia: "Demae Sushi Koedo",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.koedosg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.koedosg.com/#/",
      waSeparator: "Demae Sushi Koedo",
    },
    site_title: "Demae Sushi Koedo",
    forcewww: "YES",
    site_title_with_separator: " | Demae Sushi Koedo",
    home_meta_title: "Welcome to Demae Sushi Koedo",
    bgimage: "/img/ninjaprocmn/koedosg/body_bg.jpg",
  },
  "order.wholesomesavour.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DA2E4426-0FC5-4A47-BBED-26AD217F2BA6",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Wholesome Savour",
    stripeImage:
      "https://order.wholesomesavour.com/img/ninjaprocmn/wholesome/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "wholesome",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.wholesomesavour.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.wholesomesavour.com/#/",
      fbImage: "https://order.wholesomesavour.com/img/wholesome/preview.jpg",
      fbTitle: "Wholesome Savour",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.wholesomesavour.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.wholesomesavour.com/#/",
      twTitile: "Wholesome Savour",
      twvia: "Wholesome Savour",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.wholesomesavour.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.wholesomesavour.com/#/",
      waSeparator: "Wholesome Savour",
    },
    site_title: "Wholesome Savour",
    site_title_with_separator: " | Wholesome Savour",
    home_meta_title: "Welcome to Wholesome Savour",
    bgimage: "/img/ninjaprocmn/wholesome/body_bg.jpg",
  },
  "www.doesingapore.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0E498BC1-88F5-44E3-B5BB-CCE4E8AFC3A6",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "DOE",
    stripeImage:
      "https://www.doesingapore.com/img/ninjaprocmn/doesingapore/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "doesingapore",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.doesingapore.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.doesingapore.com/#/",
      fbImage: "https://www.doesingapore.com/img/doesingapore/preview.jpg",
      fbTitle: "DOE",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.doesingapore.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.doesingapore.com/#/",
      twTitile: "DOE",
      twvia: "DOE",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.doesingapore.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.doesingapore.com/#/",
      waSeparator: "DOE",
    },
    site_title: "DOE",
    forcewww: "YES",
    site_title_with_separator: " | DOE",
    home_meta_title: "Welcome to DOE",
    bgimage: "/img/ninjaprocmn/doesingapore/body_bg.jpg",
  },
  "order.bakedtoorder.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "320BAB66-2B77-47F3-980C-82F8B4AF1940",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget:
      "JTdCJTIycGhvbmUlMjI6JTIyKzY1NjgwMzk0MjclMjIsJTIyZW1haWwlMjI6JTIyZnJlc2hseUBiYWtlZHRvb3JkZXIuc2clMjIlN0Q=",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Baked To Order",
    stripeImage:
      "https://order.bakedtoorder.sg/img/ninjaprocmn/bakedtoorder/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bakedtoorder",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.bakedtoorder.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.bakedtoorder.sg/#/",
      fbImage: "https://order.bakedtoorder.sg/img/bakedtoorder/preview.jpg",
      fbTitle: "Baked To Order",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.bakedtoorder.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.bakedtoorder.sg/#/",
      twTitile: "Baked To Order",
      twvia: "Baked To Order",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.bakedtoorder.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.bakedtoorder.sg/#/",
      waSeparator: "Baked To Order",
    },
    site_title: "Baked To Order",
    site_title_with_separator: " | Baked To Order",
    home_meta_title: "Welcome to Baked To Order",
    bgimage: "/img/ninjaprocmn/bakedtoorder/body_bg.jpg",
  },
  "www.tribedcubelounge.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "782D2401-5C31-43E9-83CF-B3AB5A394E66",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Tribe",
    stripeImage:
      "https://www.tribedcubelounge.com/img/ninjaprocmn/thetribe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thetribe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.tribedcubelounge.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.tribedcubelounge.com/#/",
      fbImage: "https://www.tribedcubelounge.com/img/thetribe/preview.jpg",
      fbTitle: "The Tribe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.tribedcubelounge.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.tribedcubelounge.com/#/",
      twTitile: "The Tribe",
      twvia: "The Tribe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.tribedcubelounge.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.tribedcubelounge.com/#/",
      waSeparator: "The Tribe",
    },
    site_title: "The Tribe",
    forcewww: "YES",
    site_title_with_separator: " | The Tribe",
    home_meta_title: "Welcome to The Tribe",
    bgimage: "/img/ninjaprocmn/thetribe/body_bg.jpg",
  },
  "www.lotusthaisingapore.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "FD1B35F9-A38A-4212-BCB2-63175CDC22F9",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Lotus Thai",
    stripeImage:
      "https://www.lotusthaisingapore.com/img/ninjaprocmn/lotusthai/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lotusthai",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.lotusthaisingapore.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.lotusthaisingapore.com/#/",
      fbImage: "https://www.lotusthaisingapore.com/img/lotusthai/preview.jpg",
      fbTitle: "Lotus Thai",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.lotusthaisingapore.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.lotusthaisingapore.com/#/",
      twTitile: "Lotus Thai",
      twvia: "Lotus Thai",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.lotusthaisingapore.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.lotusthaisingapore.com/#/",
      waSeparator: "Lotus Thai",
    },
    site_title: "Lotus Thai",
    forcewww: "YES",
    site_title_with_separator: " | Lotus Thai",
    home_meta_title: "Welcome to Lotus Thai",
    bgimage: "/img/ninjaprocmn/lotusthai/body_bg.jpg",
  },
  "www.grainsandgreens.online": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "324274F9-A091-40E4-8EBA-002E5048667E",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Grains and Greens",
    stripeImage:
      "https://www.grainsandgreens.online/img/ninjaprocmn/grainsandgreens/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "grainsandgreens",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.grainsandgreens.online/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.grainsandgreens.online/#/",
      fbImage:
        "https://www.grainsandgreens.online/img/grainsandgreens/preview.jpg",
      fbTitle: "Grains and Greens",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.grainsandgreens.online/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.grainsandgreens.online/#/",
      twTitile: "Grains and Greens",
      twvia: "Grains and Greens",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.grainsandgreens.online/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.grainsandgreens.online/#/",
      waSeparator: "Grains and Greens",
    },
    site_title: "Grains and Greens",
    forcewww: "YES",
    site_title_with_separator: " | Grains and Greens",
    home_meta_title: "Welcome to Grains and Greens",
    bgimage: "/img/ninjaprocmn/grainsandgreens/body_bg.jpg",
  },
  "order.tandoorlounge.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A641208A-D15B-45A0-8076-63BE57C4928A",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tandoor Lounge",
    stripeImage:
      "https://order.tandoorlounge.com/img/ninjaprocmn/tandoorlounge/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tandoorlounge",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.tandoorlounge.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.tandoorlounge.com/#/",
      fbImage: "https://order.tandoorlounge.com/img/tandoorlounge/preview.jpg",
      fbTitle: "Tandoor Lounge",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.tandoorlounge.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.tandoorlounge.com/#/",
      twTitile: "Tandoor Lounge",
      twvia: "Tandoor Lounge",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.tandoorlounge.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.tandoorlounge.com/#/",
      waSeparator: "Tandoor Lounge",
    },
    site_title: "Tandoor Lounge",
    site_title_with_separator: " | Tandoor Lounge",
    home_meta_title: "Welcome to Tandoor Lounge",
    bgimage: "/img/ninjaprocmn/tandoorlounge/body_bg.jpg",
  },
  "www.ollella.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D89F3695-82D5-4105-991A-7A373AEBEABF",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ollella",
    stripeImage:
      "https://www.ollella.com/img/ninjaprocmn/ollella/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ollella",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ollella.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.www.ollella.com/#/",
      fbImage: "https://www.www.ollella.com/img/ollella/preview.jpg",
      fbTitle: "Ollella",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ollella.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ollella.com/#/",
      twTitile: "Ollella",
      twvia: "Ollella",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.ollella.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.ollella.com/#/",
      waSeparator: "Ollella",
    },
    site_title: "Ollella",
    forcewww: "YES",
    site_title_with_separator: " | Ollella",
    home_meta_title: "Welcome to Ollella",
    bgimage: "/img/ninjaprocmn/ollella/body_bg.jpg",
  },
  "www.ayamtaliwangsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DD85398E-1DD3-4EFF-9613-AC79A3A4C7D6",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ayam Taliwang",
    stripeImage:
      "https://www.ayamtaliwangsg.com/img/ninjaprocmn/ayamtaliwang/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ayamtaliwang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ayamtaliwangsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ayamtaliwangsg.com/#/",
      fbImage: "https://www.ayamtaliwangsg.com/img/ayamtaliwang/preview.jpg",
      fbTitle: "Ayam Taliwang",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ayamtaliwangsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ayamtaliwangsg.com/#/",
      twTitile: "Ayam Taliwang",
      twvia: "Ayam Taliwang",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.ayamtaliwangsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.ayamtaliwangsg.com/#/",
      waSeparator: "Ayam Taliwang",
    },
    site_title: "Ayam Taliwang",
    forcewww: "YES",
    site_title_with_separator: " | Ayam Taliwang",
    home_meta_title: "Welcome to Ayam Taliwang",
    bgimage: "/img/ninjaprocmn/ayamtaliwang/body_bg.jpg",
  },
  "www.portmakan.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "CD8D5FE3-0586-44DD-9360-EE9DEF351223",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Portmakan",
    stripeImage:
      "https://www.portmakan.sg/img/ninjaprocmn/portmakan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "portmakan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.portmakan.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.portmakan.sg/#/",
      fbImage: "https://www.portmakan.sg/img/portmakan/preview.jpg",
      fbTitle: "Portmakan",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.portmakan.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.portmakan.sg/#/",
      twTitile: "Portmakan",
      twvia: "Portmakan",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.portmakan.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.portmakan.sg/#/",
      waSeparator: "Portmakan",
    },
    site_title: "Portmakan",
    forcewww: "YES",
    site_title_with_separator: " | Portmakan",
    home_meta_title: "Welcome to Portmakan",
    bgimage: "/img/ninjaprocmn/portmakan/body_bg.jpg",
  },
  "order.ainice.biz": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9DA50C3E-F970-4E68-BB5F-AEE25CF345BC",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kookery Nook Cafe",
    stripeImage:
      "https://order.ainice.biz/img/ninjaprocmn/kookery/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kookery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ainice.biz/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ainice.biz/#/",
      fbImage: "https://order.ainice.biz/img/kookery/preview.jpg",
      fbTitle: "Kookery Nook Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ainice.biz/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ainice.biz/#/",
      twTitile: "Kookery Nook Cafe",
      twvia: "Kookery Nook Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.ainice.biz/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.ainice.biz/#/",
      waSeparator: "Kookery Nook Cafe",
    },
    site_title: "Kookery Nook Cafe",
    site_title_with_separator: " | Kookery Nook Cafe",
    home_meta_title: "Kookery Nook Cafe",
    bgimage: "/img/ninjaprocmn/kookery/body_bg.jpg",
  },
  "reservation.mrbiryanisg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "8862E1FC-5327-424D-8246-11A3408C0CAF",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mr Biryani",
    stripeImage:
      "https://reservation.mrbiryanisg.com/img/ninjaprocmn/mrbiryani/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mrbiryani",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://reservation.mrbiryanisg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://reservation.mrbiryanisg.com/#/",
      fbImage: "https://reservation.mrbiryanisg.com/img/mrbiryani/preview.jpg",
      fbTitle: "Mr Biryani",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://reservation.mrbiryanisg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://reservation.mrbiryanisg.com/#/",
      twTitile: "Mr Biryani",
      twvia: "Mr Biryani",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://reservation.mrbiryanisg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://reservation.mrbiryanisg.com/#/",
      waSeparator: "Mr Biryani",
    },
    site_title: "Mr Biryani",
    site_title_with_separator: " | Mr Biryani",
    home_meta_title: "Mr Biryani",
    bgimage: "/img/ninjaprocmn/mrbiryani/body_bg.jpg",
  },
  "order.kudos.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1239E773-EAD2-4831-BDB9-8BD390EE1916",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kudos",
    stripeImage: "https://order.kudos.sg/img/ninjaprocmn/kudos/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kudos",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.kudos.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.kudos.sg/#/",
      fbImage: "https://order.kudos.sg/img/kudos/preview.jpg",
      fbTitle: "Kudos",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.kudos.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.kudos.sg/#/",
      twTitile: "Kudos",
      twvia: "Kudos",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.kudos.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.kudos.sg/#/",
      waSeparator: "Kudos",
    },
    site_title: "Kudos",
    site_title_with_separator: " | Kudos",
    home_meta_title: "Kudos",
    bgimage: "/img/ninjaprocmn/kudos/body_bg.jpg",
  },
  "order.greendot.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F09B5ED3-2E64-4B8D-AB02-D201BAA65737",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Greendot",
    stripeImage:
      "https://order.greendot.sg/img/ninjaprocmn/greendot/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "greendot",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.greendot.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.greendot.sg/#/",
      fbImage: "https://order.greendot.sg/img/greendot/preview.jpg",
      fbTitle: "Greendot",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.greendot.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.greendot.sg/#/",
      twTitile: "Greendot",
      twvia: "Greendot",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.greendot.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.greendot.sg/#/",
      waSeparator: "Greendot",
    },
    site_title: "Greendot",
    site_title_with_separator: " | Greendot",
    home_meta_title: "Greendot",
    bgimage: "/img/ninjaprocmn/greendot/body_bg.jpg",
  },
  "www.halal-delicacies.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "28B90522-E3D3-459A-B6F5-CDE3CAF436D6",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Halal Delicacies",
    stripeImage:
      "https://www.halal-delicacies.com/img/ninjaprocmn/halaldelicacies/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "halaldelicacies",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.halal-delicacies.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.halal-delicacies.com/#/",
      fbImage:
        "https://www.halal-delicacies.com/img/halaldelicacies/preview.jpg",
      fbTitle: "Halal Delicacies",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.portmakan.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.halal-delicacies.com/#/",
      twTitile: "Halal Delicacies",
      twvia: "Halal Delicacies",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.halal-delicacies.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.halal-delicacies.com/#/",
      waSeparator: "Halal Delicacies",
    },
    site_title: "Halal Delicacies",
    forcewww: "YES",
    site_title_with_separator: " | Halal Delicacies",
    home_meta_title: "Welcome to Halal Delicacies",
    bgimage: "/img/ninjaprocmn/halaldelicacies/body_bg.jpg",
  },
  "www.bedok511bbq.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "011601A7-A075-4C1E-9913-CF7A5ED21130",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Bedok 511 BBQ",
    stripeImage:
      "https://www.bedok511bbq.com/img/ninjaprocmn/bedok511bbq/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bedok511bbq",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.bedok511bbq.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.bedok511bbq.com/#/",
      fbImage: "https://www.bedok511bbq.com/img/bedok511bbq/preview.jpg",
      fbTitle: "Bedok 511 BBQ",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.bedok511bbq.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.bedok511bbq.com/#/",
      twTitile: "Bedok 511 BBQ",
      twvia: "Bedok 511 BBQ",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.bedok511bbq.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.bedok511bbq.com/#/",
      waSeparator: "Bedok 511 BBQ",
    },
    site_title: "Bedok 511 BBQ",
    forcewww: "YES",
    site_title_with_separator: " | Bedok 511 BBQ",
    home_meta_title: "Welcome to Bedok 511 BBQ",
    bgimage: "/img/ninjaprocmn/bedok511bbq/body_bg.jpg",
  },
  "order.makcikepok2.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "97347EE4-2845-4011-BCE6-9C0B806DA437",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Makcikepok2",
    stripeImage:
      "https://order.makcikepok2.com/img/ninjaprocmn/makcikepok2/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "makcikepok2",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.makcikepok2.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.makcikepok2.com/#/",
      fbImage: "https://order.makcikepok2.com/img/makcikepok2/preview.jpg",
      fbTitle: "Makcikepok2",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.makcikepok2.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.makcikepok2.com/#/",
      twTitile: "Makcikepok2",
      twvia: "Makcikepok2",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.makcikepok2.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.makcikepok2.com/#/",
      waSeparator: "Makcikepok2",
    },
    site_title: "Makcikepok2",
    site_title_with_separator: " | Makcikepok2",
    home_meta_title: "Welcome to Makcikepok2",
    bgimage: "/img/ninjaprocmn/makcikepok2/body_bg.jpg",
  },
  "order.provence.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3039AB57-0894-4128-A921-F2D832B28902",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Provence",
    stripeImage:
      "https://order.provence.com.sg/img/ninjaprocmn/provence/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "provence",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.provence.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.provence.com.sg/#/",
      fbImage: "https://order.provence.com.sg/img/provence/preview.jpg",
      fbTitle: "Provence",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.provence.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.provence.com.sg/#/",
      twTitile: "Provence",
      twvia: "Provence",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.provence.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.provence.com.sg/#/",
      waSeparator: "Provence",
    },
    site_title: "Provence",
    site_title_with_separator: " | Provence",
    home_meta_title: "Welcome to Provence",
    bgimage: "/img/ninjaprocmn/provence/body_bg.jpg",
  },
  "order.nasilemakindulgence.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4609FA79-DBD8-4AD0-BFA4-E0F4CFFBF987",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Nasi Lemak",
    stripeImage:
      "https://order.nasilemakindulgence.com/img/ninjaprocmn/nasilemak/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "nasilemak",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.nasilemakindulgence.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.nasilemakindulgence.com/#/",
      fbImage: "https://order.nasilemakindulgence.com/img/nasilemak/logo.png",
      fbTitle: "Nasi Lemak",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.nasilemakindulgence.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.nasilemakindulgence.com/#/",
      twTitile: "Nasi Lemak",
      twvia: "Nasi Lemak",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.nasilemakindulgence.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.nasilemakindulgence.com/#/",
      waSeparator: "Nasi Lemak",
    },
    site_title: "Nasi Lemak",
    site_title_with_separator: " | Nasi Lemak",
    home_meta_title: "Welcome to Nasi Lemak",
    bgimage: "/img/ninjaprocmn/nasilemak/body_bg.jpg",
  },
  "order.blimpiesg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AA850867-4108-4EB8-872B-024903608E9F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Blimpie",
    stripeImage:
      "https://order.blimpiesg.com/img/ninjaprocmn/blimpie/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "blimpie",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.blimpiesg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.blimpiesg.com/#/",
      fbImage: "https://order.blimpiesg.com/img/blimpie/logo.png",
      fbTitle: "Blimpie",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.blimpiesg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.blimpiesg.com/#/",
      twTitile: "Blimpie",
      twvia: "Blimpie",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.blimpiesg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.blimpiesg.com/#/",
      waSeparator: "Blimpie",
    },
    site_title: "Blimpie",
    site_title_with_separator: " | Blimpie",
    home_meta_title: "Welcome to Blimpie",
    bgimage: "/img/ninjaprocmn/blimpie/body_bg.jpg",
  },
  "www.bbhqpl.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "69089BA4-D3EE-4A99-895E-0879850CA863",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "BBHQ",
    stripeImage: "https://www.bbhqpl.com/img/ninjaprocmn/bbhq/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bbhq",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.bbhqpl.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.bbhqpl.com/#/",
      fbImage: "https://www.bbhqpl.com/img/bbhq/preview.jpg",
      fbTitle: "BBHQ",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.bbhqpl.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.bbhqpl.com/#/",
      twTitile: "BBHQ",
      twvia: "BBHQ",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.bbhqpl.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.bbhqpl.com/#/",
      waSeparator: "BBHQ",
    },
    site_title: "BBHQ",
    forcewww: "YES",
    site_title_with_separator: " | BBHQ",
    home_meta_title: "Welcome to BBHQ",
    bgimage: "/img/ninjaprocmn/bbhq/body_bg.jpg",
  },
  "orderonline.ayambakarojolali.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "59DDF345-6FDA-4A7D-81D7-270635D19394",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "AYAM BAKAR OJOLALI",
    stripeImage:
      "https://orderonline.ayambakarojolali.com/img/ninjaprocmn/ojolali/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ojolali",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orderonline.ayambakarojolali.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orderonline.ayambakarojolali.com/#/",
      fbImage:
        "https://orderonline.ayambakarojolali.com/img/ojolali/preview.jpg",
      fbTitle: "AYAM BAKAR OJOLALI",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orderonline.ayambakarojolali.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orderonline.ayambakarojolali.com/#/",
      twTitile: "AYAM BAKAR OJOLALI",
      twvia: "AYAM BAKAR OJOLALI",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://orderonline.ayambakarojolali.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://orderonline.ayambakarojolali.com/#/",
      waSeparator: "AYAM BAKAR OJOLALI",
    },
    site_title: "AYAM BAKAR OJOLALI",
    site_title_with_separator: " | AYAM BAKAR OJOLALI",
    home_meta_title: "Welcome to AYAM BAKAR OJOLALI",
    bgimage: "/img/ninjaprocmn/ojolali/body_bg.jpg",
  },
  "www.youtiaomei.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1B146D63-0219-4417-9E68-5E2325D29982",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "You Tiao Mei",
    stripeImage:
      "https://www.youtiaomei.com/img/ninjaprocmn/youtiaomei/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "youtiaomei",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.youtiaomei.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.youtiaomei.com/#/",
      fbImage: "https://www.youtiaomei.com/img/youtiaomei/preview.jpg",
      fbTitle: "You Tiao Mei",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.youtiaomei.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.youtiaomei.com/#/",
      twTitile: "You Tiao Mei",
      twvia: "You Tiao Mei",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.youtiaomei.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.youtiaomei.com/#/",
      waSeparator: "You Tiao Mei",
    },
    site_title: "You Tiao Mei",
    forcewww: "YES",
    site_title_with_separator: " | You Tiao Mei",
    home_meta_title: "Welcome to You Tiao Mei",
    bgimage: "/img/ninjaprocmn/youtiaomei/body_bg.jpg",
  },
  "order.lasaigon.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "CC804508-EB5E-4148-BF76-8A9D40D945FF",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "La Saigon",
    stripeImage:
      "https://order.lasaigon.com/img/ninjaprocmn/lasaigon/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lasaigon",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.lasaigon.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.lasaigon.com/#/",
      fbImage: "https://order.lasaigon.com/img/lasaigon/preview.jpg",
      fbTitle: "La Saigon",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.lasaigon.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.lasaigon.com/#/",
      twTitile: "La Saigon",
      twvia: "La Saigon",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.lasaigon.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.lasaigon.com/#/",
      waSeparator: "La Saigon",
    },
    site_title: "La Saigon",
    site_title_with_separator: " | La Saigon",
    home_meta_title: "Welcome to La Saigon",
    bgimage: "/img/ninjaprocmn/lasaigon/body_bg.jpg",
  },
  "order.xingfutangsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AFA6F84D-423C-44AB-A47A-A1AF8813B387",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Xing Fu Tang",
    stripeImage:
      "https://order.xingfutangsg.com/img/ninjaprocmn/xingfutang/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "xingfutang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.xingfutangsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.xingfutangsg.com/#/",
      fbImage: "https://order.xingfutangsg.com/img/xingfutang/preview.jpg",
      fbTitle: "Xing Fu Tang",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.xingfutangsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.xingfutangsg.com/#/",
      twTitile: "Xing Fu Tang",
      twvia: "Xing Fu Tang",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.xingfutangsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.xingfutangsg.com/#/",
      waSeparator: "Xing Fu Tang",
    },
    site_title: "Xing Fu Tang",
    site_title_with_separator: " | Xing Fu Tang",
    home_meta_title: "Welcome to Xing Fu Tang",
    bgimage: "/img/ninjaprocmn/xingfutang/body_bg.jpg",
  },
  "order.mrspho.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "45AD857A-CE25-4E9F-A37B-BA27FCD8DF14",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mrs Pho",
    stripeImage:
      "https://order.mrspho.com/img/ninjaprocmn/mrspho/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mrspho",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.mrspho.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.mrspho.com/#/",
      fbImage: "https://order.mrspho.com/img/mrspho/preview.jpg",
      fbTitle: "Mrs Pho",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.mrspho.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.mrspho.com/#/",
      twTitile: "Mrs Pho",
      twvia: "Mrs Pho",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.mrspho.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.mrspho.com/#/",
      waSeparator: "Mrs Pho",
    },
    site_title: "Mrs Pho",
    site_title_with_separator: " | Mrs Pho",
    home_meta_title: "Welcome to Mrs Pho",
    bgimage: "/img/ninjaprocmn/mrspho/body_bg.jpg",
  },
  "order.goodoldtaste.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "EA3688FD-DF96-4159-9414-84E74CD5782A",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Good Old Taste",
    stripeImage:
      "https://order.goodoldtaste.com.sg/img/ninjaprocmn/goodoldtaste/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "goodoldtaste",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.goodoldtaste.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.goodoldtaste.com.sg/#/",
      fbImage: "https://order.goodoldtaste.com.sg/img/goodoldtaste/preview.jpg",
      fbTitle: "Good Old Taste",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.goodoldtaste.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.goodoldtaste.com.sg/#/",
      twTitile: "Good Old Taste",
      twvia: "Good Old Taste",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.goodoldtaste.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.goodoldtaste.com.sg/#/",
      waSeparator: "Good Old Taste",
    },
    site_title: "Good Old Taste",
    site_title_with_separator: " | Good Old Taste",
    home_meta_title: "Welcome to Good Old Taste",
    bgimage: "/img/ninjaprocmn/goodoldtaste/body_bg.jpg",
  },
  "order.limaa51hajilane.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D84863F1-DD6F-4E21-A26E-0D8F989CCDD5",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Limaa",
    stripeImage:
      "https://order.limaa51hajilane.com/img/ninjaprocmn/limaa/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "limaa",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.limaa51hajilane.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.limaa51hajilane.com/#/",
      fbImage: "https://order.limaa51hajilane.com/img/limaa/preview.jpg",
      fbTitle: "Limaa",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.limaa51hajilane.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.limaa51hajilane.com/#/",
      twTitile: "Limaa",
      twvia: "Limaa",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.limaa51hajilane.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.limaa51hajilane.com/#/",
      waSeparator: "Limaa",
    },
    site_title: "Limaa",
    site_title_with_separator: " | Limaa",
    home_meta_title: "Welcome to Limaa",
    bgimage: "/img/ninjaprocmn/limaa/body_bg.jpg",
  },
  "www.pokpokthai.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "720DFBEA-A0E6-4BC0-9B6E-859DB7195A3D",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Pok Pok Thai",
    stripeImage:
      "https://www.pokpokthai.com/img/ninjaprocmn/pokpokthai/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pokpokthai",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.pokpokthai.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.pokpokthai.com/#/",
      fbImage: "https://www.pokpokthai.com/img/pokpokthai/preview.jpg",
      fbTitle: "Pok Pok Thai",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.pokpokthai.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.pokpokthai.com/#/",
      twTitile: "Pok Pok Thai",
      twvia: "Pok Pok Thai",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.pokpokthai.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.pokpokthai.com/#/",
      waSeparator: "Pok Pok Thai",
    },
    site_title: "Pok Pok Thai",
    forcewww: "YES",
    site_title_with_separator: " | Pok Pok Thai",
    home_meta_title: "Welcome to Pok Pok Thai",
    bgimage: "/img/ninjaprocmn/pokpokthai/body_bg.jpg",
  },
  "order.gochiso-bento.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3CEDCD40-D9CB-49D1-BD52-3DDBAB6CB70E",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "GOCHISO BENTO",
    stripeImage:
      "https://order.gochiso-bento.com/img/ninjaprocmn/gochisobento/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gochisobento",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.gochiso-bento.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.gochiso-bento.com/#/",
      fbImage: "https://order.gochiso-bento.com/img/gochisobento/preview.jpg",
      fbTitle: "GOCHISO BENTO",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.gochiso-bento.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.gochiso-bento.com/#/",
      twTitile: "GOCHISO BENTO",
      twvia: "GOCHISO BENTO",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.gochiso-bento.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.gochiso-bento.com/#/",
      waSeparator: "GOCHISO BENTO",
    },
    site_title: "GOCHISO BENTO",
    site_title_with_separator: " | GOCHISO BENTO",
    home_meta_title: "Welcome to GOCHISO BENTO",
    bgimage: "/img/ninjaprocmn/gochisobento/body_bg.jpg",
  },
  "www.winecityglobal.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2AE048E7-2D84-42BE-9BB5-22016FC4DFBC",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "WINE CITY ",
    stripeImage:
      "https://www.winecityglobal.com/img/ninjaprocmn/winecity/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "winecity",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.winecityglobal.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.winecityglobal.com/#/",
      fbImage: "https://www.winecityglobal.com/img/winecity/preview.jpg",
      fbTitle: "WINE CITY",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.winecityglobal.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.winecityglobal.com/#/",
      twTitile: "WINE CITY ",
      twvia: "WINE CITY ",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.winecityglobal.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.winecityglobal.com/#/",
      waSeparator: "WINE CITY ",
    },
    site_title: "WINE CITY ",
    forcewww: "YES",
    site_title_with_separator: " | WINE CITY ",
    home_meta_title: "Welcome to WINE CITY ",
    bgimage: "/img/ninjaprocmn/winecity/body_bg.jpg",
  },
  "www.thaibaang.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "ACE159CB-01A6-4B09-A94B-38A8385DBBF7",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Thai baang ",
    stripeImage:
      "https://www.thaibaang.com/img/ninjaprocmn/thaibaang/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thaibaang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.thaibaang.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.thaibaang.com/#/",
      fbImage: "https://www.thaibaang.com/img/thaibaang/preview.jpg",
      fbTitle: "Thai baang ",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.thaibaang.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.thaibaang.com/#/",
      twTitile: "Thai baang ",
      twvia: "Thai baang ",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.thaibaang.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.thaibaang.com/#/",
      waSeparator: "Thai baang ",
    },
    site_title: "Thai baang ",
    forcewww: "YES",
    site_title_with_separator: " | Thai baang ",
    home_meta_title: "Welcome to Thai baang ",
    bgimage: "/img/ninjaprocmn/thaibaang/body_bg.jpg",
  },
  "order.thewhaletea.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "44DF417C-A1F0-404C-BDD8-FB077C3BEFA8",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Whale Tea",
    stripeImage:
      "https://order.thewhaletea.sg/img/ninjaprocmn/thewhaletea/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thewhaletea",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.thewhaletea.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.thewhaletea.sg/#/",
      fbImage: "https://order.thewhaletea.sg/img/thewhaletea/preview.jpg",
      fbTitle: "The Whale Tea",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.thewhaletea.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.thewhaletea.sg/#/",
      twTitile: "The Whale Tea",
      twvia: "The Whale Tea",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.thewhaletea.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.thewhaletea.sg/#/",
      waSeparator: "The Whale Tea",
    },
    site_title: "The Whale Tea",
    site_title_with_separator: " | The Whale Tea",
    home_meta_title: "Welcome to The Whale Tea",
    bgimage: "/img/ninjaprocmn/thewhaletea/body_bg.jpg",
  },
  "order.yummypalace.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D5588172-6216-4908-88B7-ABCA75D50A79",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Yummy Palace",
    stripeImage:
      "https://order.yummypalace.com.sg/img/ninjaprocmn/yummypalace/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yummypalace",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.yummypalace.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.yummypalace.com.sg/#/",
      fbImage: "https://order.yummypalace.com.sg/img/yummypalace/preview.jpg",
      fbTitle: "Yummy Palace",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.yummypalace.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.yummypalace.com.sg/#/",
      twTitile: "Yummy Palace",
      twvia: "Yummy Palace",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.yummypalace.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.yummypalace.com.sg/#/",
      waSeparator: "Yummy Palace",
    },
    site_title: "Yummy Palace",
    site_title_with_separator: " | Yummy Palace",
    home_meta_title: "Welcome to Yummy Palace",
    bgimage: "/img/ninjaprocmn/yummypalace/body_bg.jpg",
  },
  "order.grillninetynine.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F2D3DA83-F5DC-4C09-B571-F2DD63E590BB",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Grill Ninety Nine",
    stripeImage:
      "https://order.grillninetynine.com.sg/img/ninjaprocmn/grillninetynine/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "grillninetynine",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.grillninetynine.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.grillninetynine.com.sg/#/",
      fbImage:
        "https://order.grillninetynine.com.sg/img/grillninetynine/preview.jpg",
      fbTitle: "Grill Ninety Nine",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.grillninetynine.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.grillninetynine.com.sg/#/",
      twTitile: "Grill Ninety Nine",
      twvia: "Grill Ninety Nine",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.grillninetynine.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.grillninetynine.com.sg/#/",
      waSeparator: "Grill Ninety Nine",
    },
    site_title: "Grill Ninety Nine",
    site_title_with_separator: " | Grill Ninety Nine",
    home_meta_title: "Welcome to Grill Ninety Nine",
    bgimage: "/img/ninjaprocmn/grillninetynine/body_bg.jpg",
  },
  "order.vatanse.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "8F8FE4E6-88C9-4071-9215-312E0F50016A",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Vatan Se",
    stripeImage:
      "https://order.vatanse.com/img/ninjaprocmn/vatanse/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "vatanse",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.vatanse.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.vatanse.com/#/",
      fbImage: "https://order.vatanse.com/img/vatanse/preview.jpg",
      fbTitle: "Vatan Se",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.vatanse.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.vatanse.com/#/",
      twTitile: "Vatan Se",
      twvia: "Vatan Se",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.vatanse.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.vatanse.com/#/",
      waSeparator: "Vatan Se",
    },
    site_title: "Vatan Se",
    site_title_with_separator: " | Vatan Se",
    home_meta_title: "Vatan Se",
    bgimage: "/img/ninjaprocmn/vatanse/body_bg.jpg",
  },
  "www.azmirestaurant.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "48720C8A-D6BE-42E5-9426-73A406B5CED5",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Azmi Restaurant",
    stripeImage:
      "https://www.azmirestaurant.com/img/ninjaprocmn/azmirestaurant/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "azmirestaurant",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.azmirestaurant.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.azmirestaurant.com/#/",
      fbImage: "https://www.azmirestaurant.com/img/azmirestaurant/preview.jpg",
      fbTitle: "Azmi Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.azmirestaurant.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.azmirestaurant.com/#/",
      twTitile: "Azmi Restaurant",
      twvia: "Azmi Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.azmirestaurant.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.azmirestaurant.com/#/",
      waSeparator: "Azmi Restaurant",
    },
    site_title: "Azmi Restaurant",
    forcewww: "YES",
    site_title_with_separator: " | Azmi Restaurant",
    home_meta_title: "Welcome to Azmi Restaurant ",
    bgimage: "/img/ninjaprocmn/azmirestaurant/body_bg.jpg",
  },
  "order.sgfooddelivery.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E9798A3B-8D30-4E2F-A078-8D7E68225736",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Laifaba",
    stripeImage:
      "https://order.sgfooddelivery.com/img/ninjaprocmn/laifaba/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "laifaba",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.sgfooddelivery.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.sgfooddelivery.com/#/",
      fbImage: "https://order.sgfooddelivery.com/img/laifaba/preview.jpg",
      fbTitle: "Laifaba",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.sgfooddelivery.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.sgfooddelivery.com/#/",
      twTitile: "Laifaba",
      twvia: "Laifaba",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.sgfooddelivery.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.sgfooddelivery.com/#/",
      waSeparator: "Laifaba",
    },
    site_title: "Laifaba",
    site_title_with_separator: " | Laifaba",
    home_meta_title: "Welcome to Laifaba",
    bgimage: "/img/ninjaprocmn/laifaba/body_bg.jpg",
  },
  "order.liulian.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AFA91493-EA0C-4A55-8BB8-F63D4FABE065",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Savorition Recipes",
    stripeImage:
      "https://order.liulian.com.sg/img/ninjaprocmn/savorition/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "savorition",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.liulian.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.liulian.com.sg/#/",
      fbImage: "https://order.liulian.com.sg/img/savorition/preview.jpg",
      fbTitle: "Savorition Recipes",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.liulian.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.liulian.com.sg/#/",
      twTitile: "Savorition Recipes",
      twvia: "Savorition Recipes",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.liulian.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.liulian.com.sg/#/",
      waSeparator: "Savorition Recipes",
    },
    site_title: "Savorition Recipes",
    site_title_with_separator: " | Savorition Recipes",
    home_meta_title: "Welcome to Savorition Recipes",
    bgimage: "/img/ninjaprocmn/savorition/body_bg.jpg",
  },
  "www.kinkaomai.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F829A001-0900-4035-BB87-C11A6DF83425",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kin Kao Mai",
    stripeImage:
      "https://www.kinkaomai.com/img/ninjaprocmn/kinkao/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kinkao",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kinkaomai.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kinkaomai.com/#/",
      fbImage: "https://www.kinkaomai.com/img/kinkao/preview.jpg",
      fbTitle: "Kin Kao Mai",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kinkaomai.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kinkaomai.com/#/",
      twTitile: "Kin Kao Mai",
      twvia: "Kin Kao Mai",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.kinkaomai.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.kinkaomai.com/#/",
      waSeparator: "Kin Kao Mai",
    },
    site_title: "Kin Kao Mai",
    forcewww: "YES",
    site_title_with_separator: " | Kin Kao Mai",
    home_meta_title: "Welcome to Kin Kao Mai",
    bgimage: "/img/ninjaprocmn/kinkao/body_bg.jpg",
  },
  "order.podiandporiyal.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "ACD3183F-F85F-4B14-9042-02270AA19B06",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Podi & Poriyal",
    stripeImage:
      "https://order.podiandporiyal.com/img/ninjaprocmn/podiporiyal/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "podiporiyal",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.podiandporiyal.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.podiandporiyal.com/#/",
      fbImage: "https://order.podiandporiyal.com/img/podiporiyal/preview.jpg",
      fbTitle: "Podi & Poriyal",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.podiandporiyal.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.podiandporiyal.com/#/",
      twTitile: "Podi & Poriyal",
      twvia: "Podi & Poriyal",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.podiandporiyal.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.podiandporiyal.com/#/",
      waSeparator: "Podi & Poriyal",
    },
    site_title: "Podi & Poriyal",
    site_title_with_separator: " | Podi & Poriyal",
    home_meta_title: "Welcome to Podi & Poriyal",
    bgimage: "/img/ninjaprocmn/podiporiyal/body_bg.jpg",
  },
  "order.zagufood.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "FEEFDB18-A9DA-41B8-9391-DFD8E468CD47",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Zagu",
    stripeImage:
      "https://order.zagufood.com/img/ninjaprocmn/zagufood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "zagufood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.zagufood.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.zagufood.com/#/",
      fbImage: "https://order.zagufood.com/img/zagufood/preview.jpg",
      fbTitle: "Zagu",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.zagufood.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.zagufood.com/#/",
      twTitile: "Zagu",
      twvia: "Zagu",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.zagufood.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.zagufood.com/#/",
      waSeparator: "Zagu",
    },
    site_title: "Zagu",
    site_title_with_separator: " | Zagu",
    home_meta_title: "Welcome to Zagu",
    bgimage: "/img/ninjaprocmn/zagufood/body_bg.jpg",
  },
  "order.empressporridge.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B2C7873B-FF2B-4913-9E38-EF5D10F05DFE",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Empress Porridge",
    stripeImage:
      "https://order.empressporridge.com/img/ninjaprocmn/empress/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "empress",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.empressporridge.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.empressporridge.com/#/",
      fbImage: "https://order.empressporridge.com/img/empress/preview.jpg",
      fbTitle: "Empress Porridge",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.empressporridge.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.empressporridge.com/#/",
      twTitile: "Empress Porridge",
      twvia: "Empress Porridge",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.empressporridge.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.empressporridge.com/#/",
      waSeparator: "Empress Porridge",
    },
    site_title: "Empress Porridge",
    site_title_with_separator: " | Empress Porridge",
    home_meta_title: "Welcome to Empress Porridge",
    bgimage: "/img/ninjaprocmn/empress/body_bg.jpg",
  },
  "order.taliwangsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1A3FC392-8E34-49ED-A6C8-5B4D5C46DEF8",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Taliwang Restaurant",
    stripeImage:
      "https://order.taliwangsg.com/img/ninjaprocmn/taliwang/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "taliwang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.taliwangsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.taliwangsg.com/#/",
      fbImage: "https://order.taliwangsg.com/img/taliwang/preview.jpg",
      fbTitle: "Taliwang Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.taliwangsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.taliwangsg.com/#/",
      twTitile: "Taliwang Restaurant",
      twvia: "Taliwang Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.taliwangsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.taliwangsg.com/#/",
      waSeparator: "Taliwang Restaurant",
    },
    site_title: "Taliwang Restaurant",
    site_title_with_separator: " | Taliwang Restaurant",
    home_meta_title: "Welcome to Taliwang Restaurant",
    bgimage: "/img/ninjaprocmn/taliwang/body_bg.jpg",
  },
  "www.oceanseafoodsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5F6CA945-118A-4185-B1B2-C0DA1F3A9A18",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ocean Sea Food",
    stripeImage:
      "https://www.oceanseafoodsg.com/img/ninjaprocmn/oceanseafood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "oceanseafood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.oceanseafoodsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.oceanseafoodsg.com/#/",
      fbImage: "https://www.oceanseafoodsg.com/img/oceanseafood/preview.jpg",
      fbTitle: "Ocean Sea Food",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.oceanseafoodsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.oceanseafoodsg.com/#/",
      twTitile: "Ocean Sea Food",
      twvia: "Ocean Sea Food",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.oceanseafoodsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.oceanseafoodsg.com/#/",
      waSeparator: "Ocean Sea Food",
    },
    site_title: "Ocean Sea Food",
    forcewww: "YES",
    site_title_with_separator: " | Ocean Sea Food",
    home_meta_title: "Welcome to Ocean Sea Food",
    bgimage: "/img/ninjaprocmn/oceanseafood/body_bg.jpg",
  },
  "order.pastaria-abate.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "93F54B1A-2A05-4136-B047-257453EF41C3",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Pastaria",
    stripeImage:
      "https://order.pastaria-abate.com/img/ninjaprocmn/pastaria/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pastaria",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.pastaria-abate.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.pastaria-abate.com/#/",
      fbImage: "https://order.pastaria-abate.com/img/pastaria/preview.jpg",
      fbTitle: "Pastaria",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.pastaria-abate.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.pastaria-abate.com/#/",
      twTitile: "Pastaria",
      twvia: "Pastaria",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.pastaria-abate.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.pastaria-abate.com/#/",
      waSeparator: "Pastaria",
    },
    site_title: "Pastaria",
    site_title_with_separator: " | Pastaria",
    home_meta_title: "Welcome to Pastaria",
    bgimage: "/img/ninjaprocmn/pastaria/body_bg.jpg",
  },
  "www.trungnguyencoffeesg.com-grant": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "92C82689-93D3-4777-B5C5-6E868CB0227E",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Trung Nguyen Coffee By Cafe De Saigon",
    stripeImage:
      "https://www.trungnguyencoffeesg.com/img/ninjaprocmn/trungnguyen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "trungnguyen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.trungnguyencoffeesg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.trungnguyencoffeesg.com/#/",
      fbImage:
        "https://www.trungnguyencoffeesg.com/img/trungnguyen/preview.jpg",
      fbTitle: "Trung Nguyen Coffee By Cafe De Saigon",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.trungnguyencoffeesg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.trungnguyencoffeesg.com/#/",
      twTitile: "Trung Nguyen Coffee By Cafe De Saigon",
      twvia: "Trung Nguyen Coffee By Cafe De Saigon",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.trungnguyencoffeesg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.trungnguyencoffeesg.com/#/",
      waSeparator: "Trung Nguyen Coffee By Cafe De Saigon",
    },
    site_title: "Trung Nguyen Coffee By Cafe De Saigon",
    forcewww: "YES",
    site_title_with_separator: " | Trung Nguyen Coffee By Cafe De Saigon",
    home_meta_title: "Welcome to Trung Nguyen Coffee By Cafe De Saigon",
    bgimage: "/img/ninjaprocmn/trungnguyen/body_bg.jpg",
  },
  "order.unigallery.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "03847B53-0141-4414-99EA-18B687C8B220",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Uni Gallery",
    stripeImage:
      "https://order.unigallery.sg/img/ninjaprocmn/unigallery/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "unigallery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.unigallery.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.unigallery.sg/#/",
      fbImage: "https://order.unigallery.sg/img/unigallery/preview.jpg",
      fbTitle: "Uni Gallery",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.unigallery.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.unigallery.sg/#/",
      twTitile: "Uni Gallery",
      twvia: "Uni Gallery",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.unigallery.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.unigallery.sg/#/",
      waSeparator: "Uni Gallery",
    },
    site_title: "Uni Gallery",
    site_title_with_separator: " | Uni Gallery",
    home_meta_title: "Welcome to Uni Gallery",
    bgimage: "/img/ninjaprocmn/unigallery/body_bg.jpg",
  },
  "order.theassemblyground.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "944F718E-F5D9-48A0-B047-7E2D01E62CD3",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Assembly Ground",
    stripeImage:
      "https://order.theassemblyground.com/img/ninjaprocmn/assembly/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "assembly",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.theassemblyground.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.theassemblyground.com/#/",
      fbImage: "https://order.theassemblyground.com/img/assembly/preview.jpg",
      fbTitle: "The Assembly Ground",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.theassemblyground.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.theassemblyground.com/#/",
      twTitile: "The Assembly Ground",
      twvia: "The Assembly Ground",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.theassemblyground.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.theassemblyground.com/#/",
      waSeparator: "The Assembly Ground",
    },
    site_title: "The Assembly Ground",
    site_title_with_separator: " | The Assembly Ground",
    home_meta_title: "Welcome to The Assembly Ground",
    bgimage: "/img/ninjaprocmn/assembly/body_bg.jpg",
  },
  "order.lagnaa.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E4C16739-3FF3-4B0F-AD5B-91D3331A4DEC",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Lagnaa",
    stripeImage:
      "https://order.lagnaa.com/img/ninjaprocmn/lagnaa/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lagnaa",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.lagnaa.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.lagnaa.com/#/",
      fbImage: "https://order.lagnaa.com/img/lagnaa/preview.jpg",
      fbTitle: "Lagnaa",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.lagnaa.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.lagnaa.com/#/",
      twTitile: "Lagnaa",
      twvia: "Lagnaa",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.lagnaa.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.lagnaa.com/#/",
      waSeparator: "Lagnaa",
    },
    site_title: "Laganaa",
    site_title_with_separator: " | Lagnaa",
    home_meta_title: "Welcome to Lagnaa",
    bgimage: "/img/ninjaprocmn/lagnaa/body_bg.jpg",
  },
  "order.elemen.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1631B8E5-75D7-428F-A20F-028E04A86F8C",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Elemen",
    stripeImage:
      "https://order.elemen.com.sg/img/ninjaprocmn/elemen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "elemen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.elemen.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.elemen.com.sg/#/",
      fbImage: "https://order.elemen.com.sg/img/elemen/preview.jpg",
      fbTitle: "Elemen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.elemen.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.elemen.com.sg/#/",
      twTitile: "Elemen",
      twvia: "Elemen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.elemen.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.elemen.com.sg/#/",
      waSeparator: "Elemen",
    },
    site_title: "Elemen",
    site_title_with_separator: " | Elemen",
    home_meta_title: "Welcome to Elemen",
    bgimage: "/img/ninjaprocmn/elemen/body_bg.jpg",
  },
  "order.tsuta.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "13EDCB94-B360-4046-8BDC-423FED10A0BB",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tsuta",
    stripeImage:
      "https://order.tsuta.com/img/ninjaprocmn/tsuta/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tsuta",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.tsuta.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.tsuta.com/#/",
      fbImage: "https://order.tsuta.com/img/tsuta/preview.jpg",
      fbTitle: "Tsuta",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.tsuta.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.tsuta.com/#/",
      twTitile: "Tsuta",
      twvia: "Tsuta",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.tsuta.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.tsuta.com/#/",
      waSeparator: "Tsuta",
    },
    site_title: "Elemen",
    site_title_with_separator: " | Tsuta",
    home_meta_title: "Welcome to Tsuta",
    bgimage: "/img/ninjaprocmn/tsuta/body_bg.jpg",
  },
  "www.ohanaxadams.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "47E73E3D-119A-44D1-9962-8EC7E13C9F4B",
    fbAppId: "306833739927522",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ohana by Adam’s corner",
    stripeImage:
      "https://www.ohanaxadams.com/img/ninjaprocmn/ohana/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ohana",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ohanaxadams.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ohanaxadams.com/#/",
      fbImage: "https://www.ohanaxadams.com/img/ohana/preview.jpg",
      fbTitle: "Ohana by Adam’s corner",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ohanaxadams.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ohanaxadams.com/#/",
      twTitile: "Ohana by Adam’s corner",
      twvia: "Ohana by Adam’s corner",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.ohanaxadams.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.ohanaxadams.com/#/",
      waSeparator: "Ohana by Adam’s corner",
    },
    site_title: "Ohana by Adam’s corner",
    forcewww: "YES",
    site_title_with_separator: " | Ohana by Adam’s corner",
    home_meta_title: "Welcome to Ohana by Adam’s corner",
    bgimage: "/img/ninjaprocmn/ohana/body_bg.jpg",
  },
  "orderonline.springcourt.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "82D314C2-3A88-47C1-827E-454D5A5CCF53",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "JTdCJTIyd2hhdHNhcHAlMjI6JTIyMDY1OTQ2MTU4MjglMjIlN0Q=",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Spring Court",
    stripeImage:
      "https://orderonline.springcourt.com.sg/img/ninjaprocmn/springcourt/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "springcourt",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orderonline.springcourt.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orderonline.springcourt.com.sg/#/",
      fbImage:
        "https://orderonline.springcourt.com.sg/img/springcourt/logo.png",
      fbTitle: "Spring Court",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orderonline.springcourt.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orderonline.springcourt.com.sg/#/",
      twTitile: "Spring Court",
      twvia: "Spring Court",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://orderonline.springcourt.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://orderonline.springcourt.com.sg/#/",
      waSeparator: "Spring Court",
    },
    site_title: "Spring Court",
    site_title_with_separator: " | Spring Court",
    home_meta_title: "Welcome to Spring Court",
    bgimage: "/img/ninjaprocmn/springcourt/body_bg.jpg",
  },
  "order.theawadh.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "79AB2892-FC11-4FDA-935B-4A37701C4953",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Awadh",
    stripeImage:
      "https://order.theawadh.com/img/ninjaprocmn/theawadh/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "theawadh",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.theawadh.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.theawadh.com/#/",
      fbImage: "https://order.theawadh.com/img/theawadh/logo.png",
      fbTitle: "The Awadh",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.theawadh.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.theawadh.com/#/",
      twTitile: "The Awadh",
      twvia: "The Awadh",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.theawadh.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.theawadh.com/#/",
      waSeparator: "The Awadh",
    },
    site_title: "The Awadh",
    site_title_with_separator: " | The Awadh",
    home_meta_title: "Welcome to The Awadh",
    bgimage: "/img/ninjaprocmn/theawadh/body_bg.jpg",
  },
  "order.al-azhar.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1E8C044F-E42B-4BDA-8B8B-62AC4E76FF70",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Al-Azhar Restaurant",
    stripeImage:
      "https://order.al-azhar.com/img/ninjaprocmn/alazhar/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "alazhar",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.al-azhar.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.al-azhar.com/#/",
      fbImage: "https://order.al-azhar.com/img/alazhar/logo.png",
      fbTitle: "Al-Azhar Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.al-azhar.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.al-azhar.com/#/",
      twTitile: "Al-Azhar Restaurant",
      twvia: "Al-Azhar Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.al-azhar.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.al-azhar.com/#/",
      waSeparator: "Al-Azhar Restaurant",
    },
    site_title: "Al-Azhar Restaurant",
    site_title_with_separator: " | Al-Azhar Restaurant",
    home_meta_title: "Welcome to Al-Azhar Restaurant",
    bgimage: "/img/ninjaprocmn/alazhar/body_bg.jpg",
  },
  "order.justlazzat.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "CE8155DC-6B8A-4ACC-A853-AEAED6EC3789",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Justlazzat",
    stripeImage:
      "https://order.justlazzat.sg/img/ninjaprocmn/justlazzat/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "justlazzat",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.justlazzat.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.justlazzat.sg/#/",
      fbImage: "https://order.justlazzat.sg/img/justlazzat/logo.png",
      fbTitle: "Justlazzat",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.justlazzat.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.justlazzat.sg/#/",
      twTitile: "Justlazzat",
      twvia: "Justlazzat",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.justlazzat.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.justlazzat.sg/#/",
      waSeparator: "Justlazzat",
    },
    site_title: "Justlazzat",
    site_title_with_separator: " | Justlazzat",
    home_meta_title: "Welcome to Justlazzat",
    bgimage: "/img/ninjaprocmn/justlazzat/body_bg.jpg",
  },
  "www.boulangerieaccueil.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3ACD7D3E-70B2-429E-9334-747F26EAAAC8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Boulangerie Accueil",
    stripeImage:
      "https://www.boulangerieaccueil.com/img/ninjaprocmn/boulangerieaccueil/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "boulangerieaccueil",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.boulangerieaccueil.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.boulangerieaccueil.com/#/",
      fbImage:
        "https://www.boulangerieaccueil.com/img/boulangerieaccueil/logo.png",
      fbTitle: "Boulangerie Accueil",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.boulangerieaccueil.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.boulangerieaccueil.com/#/",
      twTitile: "Boulangerie Accueil",
      twvia: "Boulangerie Accueil",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.boulangerieaccueil.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.boulangerieaccueil.com/#/",
      waSeparator: "Boulangerie Accueil",
    },
    site_title: "Boulangerie Accueil",
    forcewww: "YES",
    site_title_with_separator: " | Boulangerie Accueil",
    home_meta_title: "Welcome to Boulangerie Accueil",
    bgimage: "/img/ninjaprocmn/boulangerieaccueil/body_bg.jpg",
  },
  "order.arroz.today": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "7E6A4907-F4D3-48DB-BB99-4F111503BA41",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Arroz",
    stripeImage:
      "https://order.arroz.today/img/ninjaprocmn/arroz/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "arroz",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.arroz.today/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.arroz.today/#/",
      fbImage: "https://order.arroz.today/img/arroz/logo.png",
      fbTitle: "Arroz",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.arroz.today/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.arroz.today/#/",
      twTitile: "Arroz",
      twvia: "Arroz",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.arroz.today/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.arroz.today/#/",
      waSeparator: "Arroz",
    },
    site_title: "Arroz",
    site_title_with_separator: " | Arroz",
    home_meta_title: "Welcome to Arroz",
    bgimage: "/img/ninjaprocmn/arroz/body_bg.jpg",
  },
  "order.thesocialoutcast.com-canceled": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "501908B9-A9D0-4BEF-A131-5D1FBBFDE2BE-cancled",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Social Outcast",
    stripeImage:
      "https://order.thesocialoutcast.com/img/ninjaprocmn/thesocialoutcast/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thesocialoutcast",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.thesocialoutcast.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.thesocialoutcast.com/#/",
      fbImage:
        "https://order.thesocialoutcast.com/img/thesocialoutcast/logo.png",
      fbTitle: "Social Outcast",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.thesocialoutcast.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.thesocialoutcast.com/#/",
      twTitile: "Social Outcast",
      twvia: "Social Outcast",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.thesocialoutcast.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.thesocialoutcast.com/#/",
      waSeparator: "Social Outcast",
    },
    site_title: "Social Outcast",
    site_title_with_separator: " | Social Outcast",
    home_meta_title: "Welcome to Social Outcast",
    bgimage: "/img/ninjaprocmn/thesocialoutcast/body_bg.jpg",
  },
  "order.amclub.org.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B401FC82-8E39-4076-838A-55192F765004",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The American Club",
    stripeImage:
      "https://order.amclub.org.sg/img/ninjaprocmn/americanclub/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "americanclub",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.amclub.org.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.amclub.org.sg/#/",
      fbImage: "https://order.amclub.org.sg/img/americanclub/logo.png",
      fbTitle: "The American Club",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.amclub.org.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.amclub.org.sg/#/",
      twTitile: "The American Club",
      twvia: "The American Club",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.amclub.org.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.amclub.org.sg/#/",
      waSeparator: "The American Club",
    },
    site_title: "The American Club",
    site_title_with_separator: " | The American Club",
    home_meta_title: "Welcome to The American Club",
    bgimage: "/img/ninjaprocmn/americanclub/body_bg.jpg",
  },
  "order.saltedplum.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D568F3C0-8C03-498E-AAC1-83B3AB8E1977",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Salted Plum",
    stripeImage:
      "https://order.saltedplum.co/img/ninjaprocmn/saltedplum/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "saltedplum",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.saltedplum.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.saltedplum.co/#/",
      fbImage: "https://order.saltedplum.co/img/saltedplum/logo.png",
      fbTitle: "The Salted Plum",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.saltedplum.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.saltedplum.co/#/",
      twTitile: "The Salted Plum",
      twvia: "The Salted Plum",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.saltedplum.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.saltedplum.co/#/",
      waSeparator: "The Salted Plum",
    },
    site_title: "The Salted Plum",
    site_title_with_separator: " | The Salted Plum",
    home_meta_title: "Welcome to The Salted Plum",
    bgimage: "/img/ninjaprocmn/saltedplum/body_bg.jpg",
  },
  "www.tigafolks.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "136ADFCE-B7EA-4D30-B393-9A6317C5EBF8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "TIGAfolks & Co",
    stripeImage:
      "https://www.tigafolks.co/img/ninjaprocmn/tigafolks/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tigafolks",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.tigafolks.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.tigafolks.co/#/",
      fbImage: "https://www.tigafolks.co/img/tigafolks/logo.png",
      fbTitle: "TIGAfolks & Co",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.tigafolks.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.tigafolks.co/#/",
      twTitile: "TIGAfolks & Co",
      twvia: "TIGAfolks & Co",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.tigafolks.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.tigafolks.co/#/",
      waSeparator: "TIGAfolks & Co",
    },
    site_title: "TIGAfolks & Co",
    forcewww: "YES",
    site_title_with_separator: " | TIGAfolks & Co",
    home_meta_title: "Welcome to TIGAfolks & Co",
    bgimage: "/img/ninjaprocmn/tigafolks/body_bg.jpg",
  },
  "order.qi-philosophy.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E857D7D1-B2CA-468E-A8C2-609106835941",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Qi Philosophy Cafe",
    stripeImage:
      "https://order.qi-philosophy.com/img/ninjaprocmn/qiphilosophy/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "qiphilosophy",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.qi-philosophy.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.qi-philosophy.com/#/",
      fbImage: "https://order.qi-philosophy.com/img/qiphilosophy/logo.png",
      fbTitle: "Qi Philosophy Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.qi-philosophy.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.qi-philosophy.com/#/",
      twTitile: "Qi Philosophy Cafe",
      twvia: "Qi Philosophy Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.qi-philosophy.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.qi-philosophy.com/#/",
      waSeparator: "Qi Philosophy Cafe",
    },
    site_title: "Qi Philosophy Cafe",
    site_title_with_separator: " | Qi Philosophy Cafe",
    home_meta_title: "Welcome to Qi Philosophy Cafe",
    bgimage: "/img/ninjaprocmn/qiphilosophy/body_bg.jpg",
  },
  "order.kkokkonara.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3429C9CF-F91D-4C5F-A963-363E51847726",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "KKO KKO NA RA",
    stripeImage:
      "https://order.kkokkonara.com/img/ninjaprocmn/kkokkonara/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kkokkonara",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.kkokkonara.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.kkokkonara.com/#/",
      fbImage: "https://order.kkokkonara.com/img/kkokkonara/logo.png",
      fbTitle: "KKO KKO NA RA",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.kkokkonara.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.kkokkonara.com/#/",
      twTitile: "KKO KKO NA RA",
      twvia: "KKO KKO NA RA",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.kkokkonara.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.kkokkonara.com/#/",
      waSeparator: "KKO KKO NA RA",
    },
    site_title: "KKO KKO NA RA",
    site_title_with_separator: " | KKO KKO NA RA",
    home_meta_title: "Welcome to KKO KKO NA RA",
    bgimage: "/img/ninjaprocmn/kkokkonara/body_bg.jpg",
  },
  "www.madrasnewwoodlands.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0D63D63E-4A28-483C-B455-A8A90FF9B68F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Madras New Woodlands",
    stripeImage:
      "https://www.madrasnewwoodlands.com/img/ninjaprocmn/madrasnew/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "madrasnew",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.madrasnewwoodlands.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.madrasnewwoodlands.com/#/",
      fbImage: "https://www.madrasnewwoodlands.com/img/madrasnew/logo.png",
      fbTitle: "Madras New Woodlands",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.madrasnewwoodlands.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.madrasnewwoodlands.com/#/",
      twTitile: "Madras New Woodlands",
      twvia: "Madras New Woodlands",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.madrasnewwoodlands.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.madrasnewwoodlands.com/#/",
      waSeparator: "Madras New Woodlands",
    },
    site_title: "Madras New Woodlands",
    forcewww: "YES",
    site_title_with_separator: " | Madras New Woodlands",
    home_meta_title: "Welcome to Madras New Woodlands",
    bgimage: "/img/ninjaprocmn/madrasnew/body_bg.jpg",
  },
  "pro.baoshimart.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "37D59EAD-2E8F-4F8F-A07E-51E5B9EE818F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Bao Shi",
    stripeImage:
      "https://pro.baoshimart.com/img/ninjaprocmn/baoshi/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "baoshi",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://pro.baoshimart.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://pro.baoshimart.com/#/",
      fbImage: "https://pro.baoshimart.com/img/baoshi/logo.png",
      fbTitle: "Bao Shi",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://pro.baoshimart.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://pro.baoshimart.com/#/",
      twTitile: "Bao Shi",
      twvia: "Bao Shi",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://pro.baoshimart.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://pro.baoshimart.com/#/",
      waSeparator: "Bao Shi",
    },
    site_title: "Bao Shi",
    forcewww: "YES",
    site_title_with_separator: " | Bao Shi",
    home_meta_title: "Welcome to Bao Shi",
    bgimage: "/img/ninjaprocmn/baoshi/body_bg.jpg",
  },
  "www.qsataycorner.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2C1939DC-F138-4186-A619-00E196AAFC75",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Qsatay Corner",
    stripeImage:
      "https://www.qsataycorner.com/img/ninjaprocmn/qataycorner/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "qataycorner",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.qsataycorner.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.qsataycorner.com/#/",
      fbImage: "https://www.qsataycorner.com/img/qataycorner/logo.png",
      fbTitle: "Qsatay Corner",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.qsataycorner.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.qsataycorner.com/#/",
      twTitile: "Qsatay Corner",
      twvia: "Qsatay Corner",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.qsataycorner.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.qsataycorner.com/#/",
      waSeparator: "Qsatay Corner",
    },
    site_title: "Qsatay Corner",
    forcewww: "YES",
    site_title_with_separator: " | Qsatay Corner",
    home_meta_title: "Welcome to Qsatay Corner",
    bgimage: "/img/ninjaprocmn/qataycorner/body_bg.jpg",
  },
  "order.district10.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9F890676-9B83-4B65-B10B-8286259CF0C0",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "District 10 Bar & Restaurant",
    stripeImage:
      "https://order.district10.com.sg/img/ninjaprocmn/district10/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "district10",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.district10.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.district10.com.sg/#/",
      fbImage: "https://order.district10.com.sg/img/district10/logo.png",
      fbTitle: "District 10 Bar & Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.district10.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.district10.com.sg/#/",
      twTitile: "District 10 Bar & Restaurant",
      twvia: "District 10 Bar & Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.district10.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.district10.com.sg/#/",
      waSeparator: "District 10 Bar & Restaurant",
    },
    site_title: "District 10 Bar & Restaurant",
    site_title_with_separator: " | District 10 Bar & Restaurant",
    home_meta_title: "Welcome to District 10 Bar & Restaurant",
    bgimage: "/img/ninjaprocmn/district10/body_bg.jpg",
  },
  "order.anjanakitchen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AF234469-80CF-42F9-BD87-B69BD7AC515D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Anjana Kitchen",
    stripeImage:
      "https://order.anjanakitchen.com/img/ninjaprocmn/anjanakitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "anjanakitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.anjanakitchen.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.anjanakitchen.com/#/",
      fbImage: "https://order.anjanakitchen.com/img/anjanakitchen/logo.png",
      fbTitle: "Anjana Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.anjanakitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.anjanakitchen.com/#/",
      twTitile: "Anjana Kitchen",
      twvia: "Anjana Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.anjanakitchen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.anjanakitchen.com/#/",
      waSeparator: "Anjana Kitchen",
    },
    site_title: "Anjana Kitchen",
    site_title_with_separator: " | Anjana Kitchen",
    home_meta_title: "Welcome to Anjana Kitchen",
    bgimage: "/img/ninjaprocmn/anjanakitchen/body_bg.jpg",
  },
  "www.goburgersg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2B8A54EB-1524-4F34-BBAB-0F61C2B4F2E9",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Goburger",
    stripeImage:
      "https://www.goburgersg.com/img/ninjaprocmn/goburger/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "goburger",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.goburgersg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.goburgersg.com/#/",
      fbImage: "https://www.goburgersg.com/img/goburger/logo.png",
      fbTitle: "Goburger",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.goburgersg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.goburgersg.com/#/",
      twTitile: "Goburger",
      twvia: "Goburger",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.goburgersg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.goburgersg.com/#/",
      waSeparator: "Goburger",
    },
    site_title: "Goburger",
    forcewww: "YES",
    site_title_with_separator: " | Goburger",
    home_meta_title: "Welcome to Goburger",
    bgimage: "/img/ninjaprocmn/goburger/body_bg.jpg",
  },
  "www.primegrillsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D3519D6C-7D05-4947-B54E-E6441BD1E1D8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Prime Grill",
    stripeImage:
      "https://www.primegrillsg.com/img/ninjaprocmn/primegrill/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "primegrill",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.primegrillsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.primegrillsg.com/#/",
      fbImage: "https://www.primegrillsg.com/img/primegrill/logo.png",
      fbTitle: "Prime Grill",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.primegrillsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.primegrillsg.com/#/",
      twTitile: "Prime Grill",
      twvia: "Prime Grill",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.primegrillsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.primegrillsg.com/#/",
      waSeparator: "Prime Grill",
    },
    site_title: "Prime Grill",
    forcewww: "YES",
    site_title_with_separator: " | Prime Grill",
    home_meta_title: "Welcome to Prime Grill",
    bgimage: "/img/ninjaprocmn/primegrill/body_bg.jpg",
  },
  "order.gorojapcuisine.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B25AFE67-84CB-4E0A-AAB1-787EFF72469A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Goro Japanese Cuisine",
    stripeImage:
      "https://order.gorojapcuisine.com/img/ninjaprocmn/gorocuisine/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gorocuisine",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.gorojapcuisine.com//#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.gorojapcuisine.com/#/",
      fbImage: "https://order.gorojapcuisine.com/img/gorocuisine/logo.png",
      fbTitle: "Goro Japanese Cuisine",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.gorojapcuisine.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.gorojapcuisine.com/#/",
      twTitile: "Goro Japanese Cuisine",
      twvia: "Goro Japanese Cuisine",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.gorojapcuisine.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.gorojapcuisine.com/#/",
      waSeparator: "Goro Japanese Cuisine",
    },
    site_title: "Goro Japanese Cuisine",
    site_title_with_separator: " | Goro Japanese Cuisine",
    home_meta_title: "Welcome to Goro Japanese Cuisine",
    bgimage: "/img/ninjaprocmn/gorocuisine/body_bg.jpg",
  },
  "www.ps1965.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F7F546C4-E115-43ED-8280-654D8A72AFEF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Pump Station 1965",
    stripeImage:
      "https://www.ps1965.com.sg/img/ninjaprocmn/pumpstation/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pumpstation",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ps1965.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ps1965.com.sg/#/",
      fbImage: "https://www.ps1965.com.sg/img/pumpstation/logo.png",
      fbTitle: "Pump Station 1965",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ps1965.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ps1965.com.sg/#/",
      twTitile: "Pump Station 1965",
      twvia: "Pump Station 1965",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.ps1965.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.ps1965.com.sg/#/",
      waSeparator: "Pump Station 1965",
    },
    site_title: "Pump Station 1965",
    forcewww: "YES",
    site_title_with_separator: " | Pump Station 1965",
    home_meta_title: "Welcome to Pump Station 1965",
    bgimage: "/img/ninjaprocmn/pumpstation/body_bg.jpg",
  },
  "order.cafemelba.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A93FA41B-2E53-4FA6-9752-F5D29D2F85C4",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Cafe Melba",
    stripeImage:
      "https://order.cafemelba.com.sg/img/ninjaprocmn/cafemelba/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "cafemelba",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.cafemelba.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.cafemelba.com.sg/#/",
      fbImage: "https://order.cafemelba.com.sg/img/cafemelba/logo.png",
      fbTitle: "Cafe Melba",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.cafemelba.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.cafemelba.com.sg/#/",
      twTitile: "Cafe Melba",
      twvia: "Cafe Melba",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.cafemelba.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.cafemelba.com.sg/#/",
      waSeparator: "Cafe Melba",
    },
    site_title: "Cafe Melba",
    site_title_with_separator: " | Cafe Melba",
    home_meta_title: "Welcome to Cafe Melba",
    bgimage: "/img/ninjaprocmn/cafemelba/body_bg.jpg",
  },
  "order.thenomads.vip": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D84D8AC3-0B45-456D-865C-5D465CB904F2",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Nomads",
    stripeImage:
      "https://order.thenomads.vip/img/ninjaprocmn/nomads/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "nomads",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.thenomads.vip/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.thenomads.vip/#/",
      fbImage: "https://order.thenomads.vip/img/nomads/logo.png",
      fbTitle: "The Nomads",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.thenomads.vip/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.thenomads.vip/#/",
      twTitile: "The Nomads",
      twvia: "The Nomads",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.thenomads.vip/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.thenomads.vip/#/",
      waSeparator: "The Nomads",
    },
    site_title: "The Nomads",
    site_title_with_separator: " | The Nomads",
    home_meta_title: "Welcome to The Nomads",
    bgimage: "/img/ninjaprocmn/nomads/body_bg.jpg",
  },
  "order.thesocialalley.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "44275190-B355-4486-8083-EE1C63607916",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "SOCIAL ALLEY",
    stripeImage:
      "https://order.thesocialalley.com/img/ninjaprocmn/socialalley/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "socialalley",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.thesocialalley.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.thesocialalley.com/#/",
      fbImage: "https://order.thesocialalley.com/img/socialalley/logo.png",
      fbTitle: "SOCIAL ALLEY",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.thesocialalley.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.thesocialalley.com/#/",
      twTitile: "SOCIAL ALLEY",
      twvia: "SOCIAL ALLEY",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.thesocialalley.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.thesocialalley.com/#/",
      waSeparator: "SOCIAL ALLEY",
    },
    site_title: "SOCIAL ALLEY",
    site_title_with_separator: " | SOCIAL ALLEY",
    home_meta_title: "Welcome to SOCIAL ALLEY",
    bgimage: "/img/ninjaprocmn/socialalley/body_bg.jpg",
  },
  "order.mavi.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "FEC5D252-440B-40CF-B23B-A69907BB3496",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mavi",
    stripeImage:
      "https://order.mavi.sg/img/ninjaprocmn/istanablue/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "istanablue",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.mavi.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.mavi.sg/#/",
      fbImage: "https://order.mavi.sg/img/istanablue/logo.png",
      fbTitle: "Mavi",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.mavi.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.mavi.sg/#/",
      twTitile: "Mavi",
      twvia: "Mavi",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.mavi.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.mavi.sg/#/",
      waSeparator: "Mavi",
    },
    site_title: "Mavi",
    site_title_with_separator: " | Mavi",
    home_meta_title: "Welcome to Mavi",
    bgimage: "/img/ninjaprocmn/istanablue/body_bg.jpg",
  },
  "order.theexchange.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D9200105-CE08-4371-A669-7B4915849CEA",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Exchange Asia Square",
    stripeImage:
      "https://order.theexchange.com.sg/img/ninjaprocmn/theexchange/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "theexchange",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.theexchange.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.theexchange.com.sg/#/",
      fbImage: "https://order.theexchange.com.sg/img/theexchange/logo.png",
      fbTitle: "The Exchange Asia Square",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.theexchange.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.theexchange.com.sg/#/",
      twTitile: "The Exchange Asia Square",
      twvia: "The Exchange Asia Square",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.theexchange.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.theexchange.com.sg/#/",
      waSeparator: "The Exchange Asia Square",
    },
    site_title: "The Exchange Asia Square",
    site_title_with_separator: " | The Exchange Asia Square",
    home_meta_title: "Welcome to The Exchange Asia Square",
    bgimage: "/img/ninjaprocmn/theexchange/body_bg.jpg",
  },
  "order.toribiru.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D75A75B6-EC64-451F-B522-5328DDD8F636",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Toribiru",
    stripeImage:
      "https://www.toribiru.sg/img/ninjaprocmn/toribiru/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "toribiru",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.toribiru.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.toribiru.sg/#/",
      fbImage: "https://www.toribiru.sg/img/toribiru/logo.png",
      fbTitle: "Toribiru",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.toribiru.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.toribiru.sg/#/",
      twTitile: "Toribiru",
      twvia: "Toribiru",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.toribiru.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.toribiru.sg/#/",
      waSeparator: "Toribiru",
    },
    site_title: "Toribiru",
    site_title_with_separator: " | Toribiru",
    home_meta_title: "Welcome to Toribiru",
    bgimage: "/img/ninjaprocmn/toribiru/body_bg.jpg",
  },
  "order.ourboss.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5E9E2C14-F364-4575-B8FB-AE99DBB4D95B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "OurBoss.sg",
    stripeImage:
      "https://order.ourboss.sg/img/ninjaprocmn/ourboss/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ourboss",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ourboss.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ourboss.sg/#/",
      fbImage: "https://order.ourboss.sg/img/ourboss/logo.png",
      fbTitle: "OurBoss.sg",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ourboss.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ourboss.sg/#/",
      twTitile: "OurBoss.sg",
      twvia: "OurBoss.sg",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ourboss.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ourboss.sg/#/",
      waSeparator: "OurBoss.sg",
    },
    site_title: "OurBoss.sg",
    forcewww: "YES",
    site_title_with_separator: " | OurBoss.sg",
    home_meta_title: "Welcome to OurBoss.sg",
    bgimage: "/img/ninjaprocmn/ourboss/body_bg.jpg",
  },
  "www.rempahratusbya2catering.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "729A09D6-2A38-4E1C-ADB1-67A9242D2158",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Rempah Ratus by A2 Catering",
    stripeImage:
      "https://www.rempahratusbya2catering.com/img/ninjaprocmn/a2catering/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "a2catering",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.rempahratusbya2catering.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.rempahratusbya2catering.com/#/",
      fbImage:
        "https://www.rempahratusbya2catering.com/img/a2catering/logo.png",
      fbTitle: "Rempah Ratus by A2 Catering",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.rempahratusbya2catering.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.rempahratusbya2catering.com/#/",
      twTitile: "Rempah Ratus by A2 Catering",
      twvia: "Rempah Ratus by A2 Catering",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.rempahratusbya2catering.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.rempahratusbya2catering.com/#/",
      waSeparator: "Rempah Ratus by A2 Catering",
    },
    site_title: "Rempah Ratus by A2 Catering",
    forcewww: "YES",
    site_title_with_separator: " | Rempah Ratus by A2 Catering",
    home_meta_title: "Welcome to Rempah Ratus by A2 Catering",
    bgimage: "/img/ninjaprocmn/a2catering/body_bg.jpg",
  },
  "order.zenbu.co.id": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "60A81B14-07D2-4E09-8255-60746D0C242A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Zenbu",
    stripeImage:
      "https://order.zenbu.co.id/img/ninjaprocmn/zenbu/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "zenbu",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.zenbu.co.id/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.zenbu.co.id/#/",
      fbImage: "https://order.zenbu.co.id/img/zenbu/logo.png",
      fbTitle: "Zenbu",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.zenbu.co.id/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.zenbu.co.id/#/",
      twTitile: "Zenbu",
      twvia: "Zenbu",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.zenbu.co.id/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.zenbu.co.id/#/",
      waSeparator: "Zenbu",
    },
    site_title: "Zenbu",
    site_title_with_separator: " | Zenbu",
    home_meta_title: "Welcome to Zenbu",
    bgimage: "/img/ninjaprocmn/zenbu/body_bg.jpg",
  },
  "www.finestsongkeefishballnoodles.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6800B976-C082-44F2-8D5E-299988E5FCB0",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Finest Songkees",
    stripeImage:
      "https://www.finestsongkeefishballnoodles.com.sg/img/ninjaprocmn/finestsongkees/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "finestsongkees",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.finestsongkeefishballnoodles.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.finestsongkeefishballnoodles.com.sg/#/",
      fbImage:
        "https://www.finestsongkeefishballnoodles.com.sg/img/finestsongkees/logo.png",
      fbTitle: "Finest Songkees",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.finestsongkeefishballnoodles.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.finestsongkeefishballnoodles.com.sg/#/",
      twTitile: "Finest Songkees",
      twvia: "Finest Songkees",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.finestsongkeefishballnoodles.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.finestsongkeefishballnoodles.com.sg/#/",
      waSeparator: "Finest Songkees",
    },
    site_title: "Finest Songkees",
    forcewww: "YES",
    site_title_with_separator: " | Finest Songkees",
    home_meta_title: "Welcome to Finest Songkees",
    bgimage: "/img/ninjaprocmn/finestsongkees/body_bg.jpg",
  },
  "www.almahboobrojak.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "90CD20F4-DB68-4C35-91C0-438B454FD583",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Al Mahboob Rojak",
    stripeImage:
      "https://www.almahboobrojak.com/img/ninjaprocmn/almaboobrojak/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "almaboobrojak",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.almaboobrojak.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.almahboobrojak.com/#/",
      fbImage: "https://www.almahboobrojak.com/img/almaboobrojak/logo.png",
      fbTitle: "Al Mahboob Rojak",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.almahboobrojak.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.almahboobrojak.com/#/",
      twTitile: "Al Mahboob Rojak",
      twvia: "Al Mahboob Rojak",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.almahboobrojak.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.almahboobrojak.com/#/",
      waSeparator: "Al Mahboob Rojak",
    },
    site_title: "Al Mahboob Rojak",
    forcewww: "YES",
    site_title_with_separator: " | Al Mahboob Rojak",
    home_meta_title: "Welcome to Al Mahboob Rojak",
    bgimage: "/img/ninjaprocmn/almaboobrojak/body_bg.jpg",
  },
  "order.nelsonbar.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0458B75A-5B08-46BB-8DAE-DEE7508C758E",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Nelson Bar",
    stripeImage:
      "https://order.nelsonbar.com/img/ninjaprocmn/nelson/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "nelson",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.nelsonbar.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.nelsonbar.com/#/",
      fbImage: "https://order.nelsonbar.com/img/nelson/logo.png",
      fbTitle: "Nelson Bar",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.nelsonbar.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.nelsonbar.com/#/",
      twTitile: "Nelson Bar",
      twvia: "Nelson Bar",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.nelsonbar.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.nelsonbar.com/#/",
      waSeparator: "Nelson Bar",
    },
    site_title: "Nelson Bar",
    forcewww: "YES",
    site_title_with_separator: " | Nelson Bar",
    home_meta_title: "Welcome to Nelson Bar",
    bgimage: "/img/ninjaprocmn/nelson/body_bg.jpg",
  },
  "order.tulasi.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "32E5004A-B304-47CC-9150-F543DF91D895",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tulasi",
    stripeImage:
      "https://order.tulasi.sg/img/ninjaprocmn/tulasi/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tulasi",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.tulasi.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.tulasi.sg/#/",
      fbImage: "https://order.tulasi.sg/img/tulasi/logo.png",
      fbTitle: "Tulasi",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.tulasi.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.tulasi.sg/#/",
      twTitile: "Tulasi",
      twvia: "Tulasi",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.tulasi.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.tulasi.sg/#/",
      waSeparator: "Tulasi",
    },
    site_title: "Tulasi",
    site_title_with_separator: " | Tulasi",
    home_meta_title: "Welcome to Tulasi",
    bgimage: "/img/ninjaprocmn/tulasi/body_bg.jpg",
  },
  "www.aroydeesedap.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "77773849-99C0-45A7-B1AD-38DA2BCA127D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Aroydee Sedap",
    stripeImage:
      "https://www.aroydeesedap.com/img/ninjaprocmn/aroydee/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "aroydee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.aroydeesedap.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.aroydeesedap.com/#/",
      fbImage: "https://www.aroydeesedap.com/img/aroydee/logo.png",
      fbTitle: "Aroydee Sedap",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.aroydeesedap.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.aroydeesedap.com/#/",
      twTitile: "Aroydee Sedap",
      twvia: "Aroydee Sedap",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.aroydeesedap.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.aroydeesedap.com/#/",
      waSeparator: "Aroydee Sedap",
    },
    site_title: "Aroydee Sedap",
    forcewww: "YES",
    site_title_with_separator: " | Aroydee Sedap",
    home_meta_title: "Welcome to Aroydee Sedap",
    bgimage: "/img/ninjaprocmn/aroydee/body_bg.jpg",
  },
  "delivery.zafferano.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "95AD803A-748B-4C1D-AE86-D7BD28B0A574",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Zafferano",
    stripeImage:
      "https://delivery.zafferano.sg/img/ninjaprocmn/zafferano/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "zafferano",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://delivery.zafferano.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://delivery.zafferano.sg/#/",
      fbImage: "https://delivery.zafferano.sg/img/zafferano/logo.png",
      fbTitle: "Zafferano",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://delivery.zafferano.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://delivery.zafferano.sg/#/",
      twTitile: "Zafferano",
      twvia: "Zafferano",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://delivery.zafferano.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://delivery.zafferano.sg/#/",
      waSeparator: "Zafferano",
    },
    site_title: "Zafferano",
    site_title_with_separator: " | Zafferano",
    home_meta_title: "Welcome to Zafferano",
    bgimage: "/img/ninjaprocmn/zafferano/body_bg.jpg",
  },
  "order.zoukclub.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "671432D9-9D92-4229-AAF1-571C87A6779F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "ZoukSHOP",
    stripeImage:
      "https://order.zoukclub.com/img/ninjaprocmn/redtail/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "redtail",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.zoukclub.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.zoukclub.com/#/",
      fbImage: "https://order.zoukclub.com/img/redtail/logo.png",
      fbTitle: "ZoukSHOP",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.zoukclub.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.zoukclub.com/#/",
      twTitile: "ZoukSHOP",
      twvia: "ZoukSHOP",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.zoukclub.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.zoukclub.com/#/",
      waSeparator: "ZoukSHOP",
    },
    site_title: "ZoukSHOP",
    site_title_with_separator: " | ZoukSHOP",
    home_meta_title: "Welcome to ZoukSHOP",
    bgimage: "/img/ninjaprocmn/redtail/body_bg.jpg",
  },
  "order.morganfields.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "CD9D3E7B-C4E4-4FF0-B751-A7FE97524F53",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Morganfields",
    stripeImage:
      "https://order.morganfields.com.sg/img/ninjaprocmn/morganfields/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "morganfields",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.morganfields.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.morganfields.com.sg/#/",
      fbImage: "https://order.morganfields.com.sg/img/morganfields/logo.png",
      fbTitle: "Morganfields",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.morganfields.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.morganfields.com.sg/#/",
      twTitile: "Morganfields",
      twvia: "Morganfields",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.morganfields.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.morganfields.com.sg/#/",
      waSeparator: "Morganfields",
    },
    site_title: "Morganfields",
    site_title_with_separator: " | Morganfields",
    home_meta_title: "Welcome to Morganfields",
    bgimage: "/img/ninjaprocmn/morganfields/body_bg.jpg",
  },
  "www.mrsoats.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "479259DA-32BF-461F-B6DD-BD306BEE7B63",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mrs Oats",
    stripeImage:
      "https://www.mrsoats.com/img/ninjaprocmn/mrsoats/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mrsoats",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.mrsoats.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.mrsoats.com/#/",
      fbImage: "https://www.mrsoats.com/img/mrsoats/logo.png",
      fbTitle: "Mrs Oats",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.mrsoats.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.mrsoats.com/#/",
      twTitile: "Mrs Oats",
      twvia: "Mrs Oats",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.mrsoats.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.mrsoats.com/#/",
      waSeparator: "Mrs Oats",
    },
    site_title: "Mrs Oats",
    forcewww: "YES",
    site_title_with_separator: " | Mrs Oats",
    home_meta_title: "Welcome to Mrs Oats",
    bgimage: "/img/ninjaprocmn/mrsoats/body_bg.jpg",
  },
  "order.angmohdumplings.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "86B86621-C403-4779-80A5-01116AFCB93D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Angmoh Dumplings",
    stripeImage:
      "https://order.angmohdumplings.com/img/ninjaprocmn/angmohdumplings/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "angmohdumplings",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.angmohdumplings.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.angmohdumplings.com/#/",
      fbImage: "https://order.angmohdumplings.com/img/angmohdumplings/logo.png",
      fbTitle: "Angmoh Dumplings",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.angmohdumplings.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.angmohdumplings.com/#/",
      twTitile: "Angmoh Dumplings",
      twvia: "Angmoh Dumplings",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.angmohdumplings.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.angmohdumplings.com/#/",
      waSeparator: "Angmoh Dumplings",
    },
    site_title: "Angmoh Dumplings",
    site_title_with_separator: " | Angmoh Dumplings",
    home_meta_title: "Welcome to Angmoh Dumplings",
    bgimage: "/img/ninjaprocmn/angmohdumplings/body_bg.jpg",
  },
  "order.handlebaroriginal.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0FCD06D7-B5C8-4399-B413-42C64413A0FC",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Handlebar",
    stripeImage:
      "https://order.handlebaroriginal.com/img/ninjaprocmn/handlebar/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "handlebar",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.handlebaroriginal.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.handlebaroriginal.com/#/",
      fbImage: "https://order.handlebaroriginal.com/img/handlebar/logo.png",
      fbTitle: "Handlebar",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.handlebaroriginal.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.handlebaroriginal.com/#/",
      twTitile: "Handlebar",
      twvia: "Handlebar",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.handlebaroriginal.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.handlebaroriginal.com/#/",
      waSeparator: "Handlebar",
    },
    site_title: "Handlebar",
    site_title_with_separator: " | Handlebar",
    home_meta_title: "Welcome to Handlebar",
    bgimage: "/img/ninjaprocmn/handlebar/body_bg.jpg",
  },
  "www.gopizzasg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2D7ED505-0372-4A46-8980-E6CC15897000",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Gopizza",
    stripeImage:
      "https://www.gopizzasg.com/img/ninjaprocmn/gopizza/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gopizza",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.gopizzasg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.gopizzasg.com/#/",
      fbImage: "https://www.gopizzasg.com/img/gopizza/logo.png",
      fbTitle: "Gopizza",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.gopizzasg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.gopizzasg.com/#/",
      twTitile: "Gopizza",
      twvia: "Gopizza",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.gopizzasg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.gopizzasg.com/#/",
      waSeparator: "Gopizza",
    },
    site_title: "Gopizza",
    site_title_with_separator: " | Gopizza",
    home_meta_title: "Welcome to Gopizza",
    bgimage: "/img/ninjaprocmn/gopizza/body_bg.jpg",
  },
  "order.kontiki.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "8C1681DB-1596-43D7-BE72-40B8B48A455A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kontiki",
    stripeImage:
      "https://order.kontiki.sg/img/ninjaprocmn/kontiki/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kontiki",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.kontiki.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.kontiki.sg/#/",
      fbImage: "https://order.kontiki.sg/img/kontiki/logo.png",
      fbTitle: "Kontiki",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.kontiki.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.kontiki.sg/#/",
      twTitile: "Kontiki",
      twvia: "Kontiki",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.kontiki.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.kontiki.sg/#/",
      waSeparator: "Kontiki",
    },
    site_title: "Kontiki",
    site_title_with_separator: " | Kontiki",
    home_meta_title: "Welcome to Kontiki",
    bgimage: "/img/ninjaprocmn/kontiki/body_bg.jpg",
  },
  "www.asiahsrecipe.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A15C4B42-E9B5-446D-B04C-5EA6B247625C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Asiahs Recipe",
    stripeImage:
      "https://www.asiahsrecipe.com/img/ninjaprocmn/asiahsrecipe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "asiahsrecipe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.asiahsrecipe.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.asiahsrecipe.com/#/",
      fbImage: "https://www.asiahsrecipe.com/img/asiahsrecipe/logo.png",
      fbTitle: "Asiahs Recipe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.asiahsrecipe.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.asiahsrecipe.com/#/",
      twTitile: "Asiahs Recipe",
      twvia: "Asiahs Recipe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.asiahsrecipe.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.asiahsrecipe.com/#/",
      waSeparator: "Asiahs Recipe",
    },
    site_title: "Asiahs Recipe",
    forcewww: "YES",
    site_title_with_separator: " | Asiahs Recipe",
    home_meta_title: "Welcome to Asiahs Recipe",
    bgimage: "/img/ninjaprocmn/asiahsrecipe/body_bg.jpg",
  },
  "www.smoodsmoothies.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E14C262B-9C32-4830-A7F2-660506C2C32F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "SMOOD SMOOTHIES",
    stripeImage:
      "https://www.smoodsmoothies.com/img/ninjaprocmn/smood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "smood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.smoodsmoothies.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.smoodsmoothies.com/#/",
      fbImage: "https://www.smoodsmoothies.com/img/smood/logo.png",
      fbTitle: "SMOOD SMOOTHIES",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.smoodsmoothies.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.smoodsmoothies.com/#/",
      twTitile: "SMOOD SMOOTHIES",
      twvia: "SMOOD SMOOTHIES",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.smoodsmoothies.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.smoodsmoothies.com/#/",
      waSeparator: "SMOOD SMOOTHIES",
    },
    site_title: "SMOOD SMOOTHIES",
    forcewww: "YES",
    site_title_with_separator: " | SMOOD SMOOTHIES",
    home_meta_title: "Welcome to SMOOD SMOOTHIES",
    bgimage: "/img/ninjaprocmn/smood/body_bg.jpg",
  },
  "www.pastaexpressdelivery.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "291B6AEE-2684-4D64-8243-A1481536D932",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Pasta Express",
    stripeImage:
      "https://www.pastaexpressdelivery.com/img/ninjaprocmn/pastaexpress/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pastaexpress",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.almaboobrojak.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.pastaexpressdelivery.com/#/",
      fbImage: "https://www.pastaexpressdelivery.com/img/pastaexpress/logo.png",
      fbTitle: "Pasta Express",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.pastaexpressdelivery.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.pastaexpressdelivery.com/#/",
      twTitile: "Pasta Express",
      twvia: "Pasta Express",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.pastaexpressdelivery.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.pastaexpressdelivery.com/#/",
      waSeparator: "Pasta Express",
    },
    site_title: "Pasta Express",
    forcewww: "YES",
    site_title_with_separator: " | Pasta Express",
    home_meta_title: "Welcome to Pasta Express",
    bgimage: "/img/ninjaprocmn/pastaexpress/body_bg.jpg",
  },
  "www.mruncle.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "7A1B942A-D0D8-416A-BE85-A22F91689E8C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mr Uncle",
    stripeImage:
      "https://www.mruncle.sg/img/ninjaprocmn/mruncle/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mruncle",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.mruncle.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.mruncle.sg/#/",
      fbImage: "https://www.mruncle.sg/img/mruncle/logo.png",
      fbTitle: "Mr Uncle",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.mruncle.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.mruncle.sg/#/",
      twTitile: "Mr Uncle",
      twvia: "Mr Uncle",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.mruncle.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.mruncle.sg/#/",
      waSeparator: "Mr Uncle",
    },
    site_title: "Mr Uncle",
    forcewww: "YES",
    site_title_with_separator: " | Mr Uncle",
    home_meta_title: "Welcome to Mr Uncle",
    bgimage: "/img/ninjaprocmn/mruncle/body_bg.jpg",
  },
  "order.maxwellteochewporridge.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "060A7EC3-77F8-44E6-8F57-5144A3D15938",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Teochew Rice and Porridge",
    stripeImage:
      "https://order.maxwellteochewporridge.com/img/ninjaprocmn/teochew/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "teochew",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.maxwellteochewporridge.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.maxwellteochewporridge.com/#/",
      fbImage: "https://order.maxwellteochewporridge.com/img/teochew/logo.png",
      fbTitle: "Teochew Rice and Porridge",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.maxwellteochewporridge.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.maxwellteochewporridge.com/#/",
      twTitile: "Teochew Rice and Porridge",
      twvia: "Teochew Rice and Porridge",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.maxwellteochewporridge.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.maxwellteochewporridge.com/#/",
      waSeparator: "Teochew Rice and Porridge",
    },
    site_title: "Teochew Rice and Porridge",
    site_title_with_separator: " | Teochew Rice and Porridge",
    home_meta_title: "Welcome to Teochew Rice and Porridge",
    bgimage: "/img/ninjaprocmn/teochew/body_bg.jpg",
  },
  "www.minoracuisine.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "18C6010E-24F1-4186-A666-E76909D10A15",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Minora Cuisine",
    stripeImage:
      "https://www.minoracuisine.com/img/ninjaprocmn/minora/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "minora",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.minoracuisine.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.minoracuisine.com/#/",
      fbImage: "https://www.minoracuisine.com/img/minora/logo.png",
      fbTitle: "Minora Cuisine",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.minoracuisine.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.minoracuisine.com/#/",
      twTitile: "Minora Cuisine",
      twvia: "Minora Cuisine",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.minoracuisine.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.minoracuisine.com/#/",
      waSeparator: "Minora Cuisine",
    },
    site_title: "Minora Cuisine",
    forcewww: "YES",
    site_title_with_separator: " | Minora Cuisine",
    home_meta_title: "Welcome to Minora Cuisine",
    bgimage: "/img/ninjaprocmn/minora/body_bg.jpg",
  },
  "whipped.ninjapro.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "386D2334-BE66-43BA-B059-83EC37238C7C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Whipped",
    stripeImage:
      "https://whipped.ninjapro.co/img/ninjaprocmn/whipped/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "whipped",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://whipped.ninjapro.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://whipped.ninjapro.co/#/",
      fbImage: "https://whipped.ninjapro.co/img/whipped/logo.png",
      fbTitle: "Whipped",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://whipped.ninjapro.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://whipped.ninjapro.co/#/",
      twTitile: "Whipped",
      twvia: "Whipped",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://whipped.ninjapro.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://whipped.ninjapro.co/#/",
      waSeparator: "Whipped",
    },
    site_title: "Whipped",
    site_title_with_separator: " | Whipped",
    home_meta_title: "Welcome to Whipped",
    bgimage: "/img/ninjaprocmn/whipped/body_bg.jpg",
  },
  "www.xiangdessertfood.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0B0E3C46-2233-4D14-A138-F64BD4BF2EA4",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Xiang Authentic Taiwanese Dessert and Food",
    stripeImage:
      "https://www.xiangdessertfood.com/img/ninjaprocmn/xiang/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "xiang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.xiangdessertfood.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.xiangdessertfood.com/#/",
      fbImage: "https://www.xiangdessertfood.com/img/xiang/logo.png",
      fbTitle: "Xiang Authentic Taiwanese Dessert and Food",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.xiangdessertfood.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.xiangdessertfood.com/#/",
      twTitile: "Xiang Authentic Taiwanese Dessert and Food",
      twvia: "Xiang Authentic Taiwanese Dessert and Food",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.xiangdessertfood.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.xiangdessertfood.com/#/",
      waSeparator: "Xiang Authentic Taiwanese Dessert and Food",
    },
    site_title: "Xiang Authentic Taiwanese Dessert and Food",
    forcewww: "YES",
    site_title_with_separator: " | Xiang Authentic Taiwanese Dessert and Food",
    home_meta_title: "Welcome to Xiang Authentic Taiwanese Dessert and Food",
    bgimage: "/img/ninjaprocmn/xiang/body_bg.jpg",
  },
  "sgunited.gayatrirestaurant.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C0C8EB6D-0F3D-4F10-AA3F-4CC8990A87A9",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Gayatri Restaurant",
    stripeImage:
      "https://sgunited.gayatrirestaurant.com/img/ninjaprocmn/andra/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "andra",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://sgunited.gayatrirestaurant.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://sgunited.gayatrirestaurant.com/#/",
      fbImage: "https://sgunited.gayatrirestaurant.com/img/andra/logo.png",
      fbTitle: "Gayatri Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://sgunited.gayatrirestaurant.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://sgunited.gayatrirestaurant.com/#/",
      twTitile: "Gayatri Restaurant",
      twvia: "Gayatri Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://sgunited.gayatrirestaurant.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://sgunited.gayatrirestaurant.com/#/",
      waSeparator: "Gayatri Restaurant",
    },
    site_title: "Gayatri Restaurant",
    site_title_with_separator: " | Gayatri Restaurant",
    home_meta_title: "Welcome to Gayatri Restaurant",
    bgimage: "/img/ninjaprocmn/andra/body_bg.jpg",
  },
  "order.tembusuprojects.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "25321897-C0EC-42A4-A84A-7F668F031950",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tembusu",
    stripeImage:
      "https://order.tembusuprojects.com/img/ninjaprocmn/tembusu/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tembusu",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.tembusuprojects.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.tembusuprojects.com/#/",
      fbImage: "https://order.tembusuprojects.com/img/tembusu/logo.png",
      fbTitle: "Tembusu",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.tembusuprojects.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.tembusuprojects.com/#/",
      twTitile: "Tembusu",
      twvia: "Tembusu",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.tembusuprojects.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.tembusuprojects.com/#/",
      waSeparator: "Tembusu",
    },
    site_title: "Tembusu",
    site_title_with_separator: " | Tembusu",
    home_meta_title: "Welcome to Tembusu",
    bgimage: "/img/ninjaprocmn/tembusu/body_bg.jpg",
  },
  "order.yummypunjaby.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "42E1C07C-9095-449E-A1EE-2EE497C44FB0",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Yummy Punjaby",
    stripeImage:
      "https://order.yummypunjaby.com/img/ninjaprocmn/yummypunjaby/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yummypunjaby",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.yummypunjaby.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.yummypunjaby.com/#/",
      fbImage: "https://order.yummypunjaby.com/img/yummypunjaby/logo.png",
      fbTitle: "Yummy Punjaby",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.yummypunjaby.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.yummypunjaby.com/#/",
      twTitile: "Yummy Punjaby",
      twvia: "Yummy Punjaby",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.yummypunjaby.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.yummypunjaby.com/#/",
      waSeparator: "Yummy Punjaby",
    },
    site_title: "Yummy Punjaby",
    site_title_with_separator: " | Yummy Punjaby",
    home_meta_title: "Welcome to Yummy Punjaby",
    bgimage: "/img/ninjaprocmn/yummypunjaby/body_bg.jpg",
  },
  "order.scc.org.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6C0280A0-B655-4E0F-A43F-041C189683BA",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Singapore Cricket Club",
    stripeImage:
      "https://order.scc.org.sg/img/ninjaprocmn/sccadmin/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sccadmin",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.scc.org.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.scc.org.sg/#/",
      fbImage: "https://order.scc.org.sg/img/sccadmin/logo.png",
      fbTitle: "Singapore Cricket Club",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.scc.org.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.scc.org.sg/#/",
      twTitile: "Singapore Cricket Club",
      twvia: "Singapore Cricket Club",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.scc.org.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.scc.org.sg/#/",
      waSeparator: "Singapore Cricket Club",
    },
    site_title: "Singapore Cricket Club",
    site_title_with_separator: " | Singapore Cricket Club",
    home_meta_title: "Welcome to Singapore Cricket Club",
    bgimage: "/img/ninjaprocmn/sccadmin/body_bg.jpg",
  },
  "www.thehwangsil.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B77DBBA2-34B6-4153-9001-FEC3F62D70B6",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Hwangsil",
    stripeImage:
      "https://www.thehwangsil.com/img/ninjaprocmn/hwangsil/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hwangsil",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.thehwangsil.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.thehwangsil.com/#/",
      fbImage: "https://www.thehwangsil.com/img/hwangsil/logo.png",
      fbTitle: "The Hwangsil",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.thehwangsil.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.thehwangsil.com/#/",
      twTitile: "The Hwangsil",
      twvia: "The Hwangsil",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.thehwangsil.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.thehwangsil.com/#/",
      waSeparator: "The Hwangsil",
    },
    site_title: "The Hwangsil",
    forcewww: "YES",
    site_title_with_separator: " | The Hwangsil",
    home_meta_title: "Welcome to The Hwangsil",
    bgimage: "/img/ninjaprocmn/hwangsil/body_bg.jpg",
  },
  "order.drdurian.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AC8AA9F5-F20D-49C0-AF12-7D47F066A160",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Dr Durian",
    stripeImage:
      "https://order.drdurian.com.sg/img/ninjaprocmn/drdurian/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "drdurian",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.drdurian.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.drdurian.com.sg/#/",
      fbImage: "https://order.drdurian.com.sg/img/drdurian/logo.png",
      fbTitle: "Dr Durian",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.drdurian.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.drdurian.com.sg/#/",
      twTitile: "Dr Durian",
      twvia: "Dr Durian",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.drdurian.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.drdurian.com.sg/#/",
      waSeparator: "Dr Durian",
    },
    site_title: "Dr Durian",
    forcewww: "YES",
    site_title_with_separator: " | Dr Durian",
    home_meta_title: "Welcome to Dr Durian",
    bgimage: "/img/ninjaprocmn/drdurian/body_bg.jpg",
  },
  "www.thecurryclub.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5C28CCD6-6A1F-44AD-BE9A-0765B906BDB8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Curry Club",
    stripeImage:
      "https://www.thecurryclub.sg/img/ninjaprocmn/curryclub/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "curryclub",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.thecurryclub.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.thecurryclub.sg/#/",
      fbImage: "https://www.thecurryclub.sg/img/curryclub/logo.png",
      fbTitle: "The Curry Club",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.thecurryclub.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.thecurryclub.sg/#/",
      twTitile: "The Curry Club",
      twvia: "The Curry Club",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.thecurryclub.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.thecurryclub.sg/#/",
      waSeparator: "The Curry Club",
    },
    site_title: "The Curry Club",
    forcewww: "YES",
    site_title_with_separator: " | The Curry Club",
    home_meta_title: "Welcome to The Curry Club",
    bgimage: "/img/ninjaprocmn/curryclub/body_bg.jpg",
  },
  "order.ministryofrojak.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E02013C3-C733-49C5-ADAA-5EE1DA42EB93",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ministry of Rojak",
    stripeImage:
      "https://order.ministryofrojak.com/img/ninjaprocmn/ministryofrojak/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ministryofrojak",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ministryofrojak.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ministryofrojak.com/#/",
      fbImage: "https://order.ministryofrojak.com/img/ministryofrojak/logo.png",
      fbTitle: "Ministry of Rojak",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ministryofrojak.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ministryofrojak.com/#/",
      twTitile: "Ministry of Rojak",
      twvia: "Ministry of Rojak",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ministryofrojak.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ministryofrojak.com/#/",
      waSeparator: "Ministry of Rojak",
    },
    site_title: "Ministry of Rojak",
    site_title_with_separator: " | Ministry of Rojak",
    home_meta_title: "Welcome to Ministry of Rojak",
    bgimage: "/img/ninjaprocmn/ministryofrojak/body_bg.jpg",
  },
  "order.saffrons.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "76DABFCC-0A47-40C7-8588-E3C32F87B3C8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Saffrons",
    stripeImage:
      "https://order.saffrons.com.sg/img/ninjaprocmn/saffrons/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "saffrons",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.saffrons.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.saffrons.com.sg/#/",
      fbImage: "https://order.saffrons.com.sg/img/saffrons/logo.png",
      fbTitle: "Saffrons",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.saffrons.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.saffrons.com.sg/#/",
      twTitile: "Saffrons",
      twvia: "Saffrons",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.saffrons.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.saffrons.com.sg/#/",
      waSeparator: "Saffrons",
    },
    site_title: "Saffrons",
    site_title_with_separator: " | Saffrons",
    home_meta_title: "Welcome to Saffrons",
    bgimage: "/img/ninjaprocmn/saffrons/body_bg.jpg",
  },
  "www.esync.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6171DF9A-7946-4EEA-85A7-8EDF3C70990A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "ESync",
    stripeImage:
      "https://www.esync.sg/img/ninjaprocmn/mealcaterer/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mealcaterer",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.esync.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.esync.sg/#/",
      fbImage: "https://www.esync.sg/img/mealcaterer/logo.png",
      fbTitle: "ESync",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.esync.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.esync.sg/#/",
      twTitile: "ESync",
      twvia: "ESync",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.esync.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.esync.sg/#/",
      waSeparator: "ESync",
    },
    site_title: "ESync",
    site_title_with_separator: " | ESync",
    home_meta_title: "ESync",
    bgimage: "/img/ninjaprocmn/mealcaterer/body_bg.jpg",
  },
  "www.goldensandstreetkitchen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "7DF70096-44E7-4072-97C3-E83562DEBF13",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Golden Sands Street Kitchen",
    stripeImage:
      "https://www.goldensandstreetkitchen.com/img/ninjaprocmn/goldensands/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "goldensands",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.goldensandstreetkitchen.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.goldensandstreetkitchen.com/#/",
      fbImage:
        "https://www.goldensandstreetkitchen.com/img/goldensands/logo.png",
      fbTitle: "Golden Sands Street Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.goldensandstreetkitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.goldensandstreetkitchen.com/#/",
      twTitile: "Golden Sands Street Kitchen",
      twvia: "Golden Sands Street Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.goldensandstreetkitchen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.goldensandstreetkitchen.com/#/",
      waSeparator: "Golden Sands Street Kitchen",
    },
    site_title: "Golden Sands Street Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Golden Sands Street Kitchen",
    home_meta_title: "Golden Sands Street Kitchen",
    bgimage: "/img/ninjaprocmn/goldensands/body_bg.jpg",
  },
  "www.sghappypot.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "81FEE5A7-2AEE-4CD9-8BF5-372DE9A822FF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "SG Happy Pot",
    stripeImage:
      "https://www.sghappypot.com/img/ninjaprocmn/sghappypot/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sghappypot",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.sghappypot.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.sghappypot.com/#/",
      fbImage: "https://www.sghappypot.com/img/sghappypot/logo.png",
      fbTitle: "SG Happy Pot",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.sghappypot.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.sghappypot.com/#/",
      twTitile: "SG Happy Pot",
      twvia: "SG Happy Pot",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.sghappypot.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.sghappypot.com/#/",
      waSeparator: "SG Happy Pot",
    },
    site_title: "SG Happy Pot",
    forcewww: "YES",
    site_title_with_separator: " | SG Happy Pot",
    home_meta_title: "SG Happy Pot",
    bgimage: "/img/ninjaprocmn/sghappypot/body_bg.jpg",
  },
  "order.pastabrava.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0D33B481-DCEC-47D8-BEE6-FBE5A9A4D5C7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Pasta Brava",
    stripeImage:
      "https://order.pastabrava.com.sg/img/ninjaprocmn/pastabrava/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pastabrava",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.pastabrava.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.pastabrava.com.sg/#/",
      fbImage: "https://order.pastabrava.com.sg/img/pastabrava/logo.png",
      fbTitle: "Pasta Brava",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.pastabrava.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.pastabrava.com.sg/#/",
      twTitile: "Pasta Brava",
      twvia: "Pasta Brava",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.pastabrava.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.pastabrava.com.sg/#/",
      waSeparator: "Pasta Brava",
    },
    site_title: "Pasta Brava",
    site_title_with_separator: " | Pasta Brava",
    home_meta_title: "Pasta Brava",
    bgimage: "/img/ninjaprocmn/pastabrava/body_bg.jpg",
  },
  "orderonline.theboiler.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "33843AC9-EB47-42EE-8AAA-14CEA187D85A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Boiler",
    stripeImage:
      "https://orderonline.theboiler.com.sg/img/ninjaprocmn/theboiler/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "theboiler",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orderonline.theboiler.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orderonline.theboiler.com.sg/#/",
      fbImage: "https://orderonline.theboiler.com.sg/img/theboiler/logo.png",
      fbTitle: "The Boiler",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orderonline.theboiler.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orderonline.theboiler.com.sg/#/",
      twTitile: "The Boiler",
      twvia: "The Boiler",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://orderonline.theboiler.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://orderonline.theboiler.com.sg/#/",
      waSeparator: "The Boiler",
    },
    site_title: "The Boiler",
    site_title_with_separator: " | The Boiler",
    home_meta_title: "The Boiler",
    bgimage: "/img/ninjaprocmn/theboiler/body_bg.jpg",
  },
  "order.mala.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4750A971-EC66-49E5-956A-9F9D2271FAB2",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Xiao Man Niu Mala Xiang Guo",
    stripeImage:
      "https://order.mala.com.sg/img/ninjaprocmn/xiaoman/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "xiaoman",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.mala.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.mala.com.sg/#/",
      fbImage: "https://order.mala.com.sg/img/xiaoman/logo.png",
      fbTitle: "Xiao Man Niu Mala Xiang Guo",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.mala.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.mala.com.sg/#/",
      twTitile: "Xiao Man Niu Mala Xiang Guo",
      twvia: "Xiao Man Niu Mala Xiang Guo",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.mala.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.mala.com.sg/#/",
      waSeparator: "Xiao Man Niu Mala Xiang Guo",
    },
    site_title: "Xiao Man Niu Mala Xiang Guo",
    site_title_with_separator: " | Xiao Man Niu Mala Xiang Guo",
    home_meta_title: "Xiao Man Niu Mala Xiang Guo",
    bgimage: "/img/ninjaprocmn/xiaoman/body_bg.jpg",
  },
  "order.oystercosg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "150A49CA-6982-4AFA-95B8-B3AE1F01677C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Oyster Co",
    stripeImage:
      "https://order.oystercosg.com/img/ninjaprocmn/oysterco/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "oysterco",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.oystercosg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.oystercosg.com/#/",
      fbImage: "https://order.oystercosg.com/img/oysterco/logo.png",
      fbTitle: "Oyster Co",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.oystercosg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.oystercosg.com/#/",
      twTitile: "Oyster Co",
      twvia: "Oyster Co",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.oystercosg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.oystercosg.com/#/",
      waSeparator: "Oyster Co",
    },
    site_title: "Oyster Co",
    site_title_with_separator: " | Oyster Co",
    home_meta_title: "Oyster Co",
    bgimage: "/img/ninjaprocmn/oysterco/body_bg.jpg",
  },
  "order.deetonguethai.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2D749004-5E7F-4DCA-9F89-40BCE80BD085",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Dee Tongue Thai By Rung Mama",
    stripeImage:
      "https://order.deetonguethai.com.sg/img/ninjaprocmn/deetonguethai/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "deetonguethai",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.deetonguethai.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.deetonguethai.com.sg/#/",
      fbImage: "https://order.deetonguethai.com.sg/img/deetonguethai/logo.png",
      fbTitle: "Dee Tongue Thai By Rung Mama",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.deetonguethai.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.deetonguethai.com.sg/#/",
      twTitile: "Dee Tongue Thai By Rung Mama",
      twvia: "Dee Tongue Thai By Rung Mama",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.deetonguethai.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.deetonguethai.com.sg/#/",
      waSeparator: "Dee Tongue Thai By Rung Mama",
    },
    site_title: "Dee Tongue Thai By Rung Mama",
    site_title_with_separator: " | Dee Tongue Thai By Rung Mama",
    home_meta_title: "Dee Tongue Thai By Rung Mama",
    bgimage: "/img/ninjaprocmn/deetonguethai/body_bg.jpg",
  },
  "www.grainsandco.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "27B89C4A-49DF-4492-B3D6-1168871611CF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Grains & Co",
    stripeImage:
      "https://www.grainsandco.sg/img/ninjaprocmn/mixconnection/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mixconnection",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.grainsandco.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.grainsandco.sg/#/",
      fbImage: "https://www.grainsandco.sg/img/mixconnection/logo.png",
      fbTitle: "Grains & Co",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.grainsandco.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.grainsandco.sg/#/",
      twTitile: "Grains & Co",
      twvia: "Grains & Co",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.grainsandco.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.grainsandco.sg/#/",
      waSeparator: "Grains & Co",
    },
    site_title: "Grains & Co",
    site_title_with_separator: " | Grains & Co",
    home_meta_title: "Grains & Co",
    bgimage: "/img/ninjaprocmn/mixconnection/body_bg.jpg",
  },
  "www.mrdonerkebabsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C0DB3D3C-8A00-4F11-BB9D-515A46BF3FC7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mr Doner Kebab",
    stripeImage:
      "https://www.mrdonerkebabsg.com/img/ninjaprocmn/curryspize/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "curryspize",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.mrdonerkebabsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.mrdonerkebabsg.com/#/",
      fbImage: "https://www.mrdonerkebabsg.com/img/curryspize/logo.png",
      fbTitle: "Mr Doner Kebab",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.mrdonerkebabsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.mrdonerkebabsg.com/#/",
      twTitile: "Mr Doner Kebab",
      twvia: "Mr Doner Kebab",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.mrdonerkebabsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.mrdonerkebabsg.com/#/",
      waSeparator: "Mr Doner Kebab ",
    },
    site_title: "Mr Doner Kebab",
    forcewww: "YES",
    site_title_with_separator: " | Mr Doner Kebab",
    home_meta_title: "Mr Doner Kebab",
    bgimage: "/img/ninjaprocmn/curryspize/body_bg.jpg",
  },
  "www.kotobukibentosg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A732D7B1-5FBC-4DB1-BC3B-1750617AC480",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "KOTOBUKI",
    stripeImage:
      "https://www.kotobukibentosg.com/img/ninjaprocmn/kotobuki/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kotobuki",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kotobukibentosg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kotobukibentosg.com/#/",
      fbImage: "https://www.kotobukibentosg.com/img/kotobuki/logo.png",
      fbTitle: "KOTOBUKI",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kotobukibentosg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kotobukibentosg.com/#/",
      twTitile: "KOTOBUKI",
      twvia: "KOTOBUKI",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.kotobukibentosg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.kotobukibentosg.com/#/",
      waSeparator: "KOTOBUKI",
    },
    site_title: "KOTOBUKI",
    forcewww: "YES",
    site_title_with_separator: " | KOTOBUKI",
    home_meta_title: "KOTOBUKI",
    bgimage: "/img/ninjaprocmn/kotobuki/body_bg.jpg",
  },
  "order.platethora.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C08D07DE-1F10-4A7A-8F60-C39B20194D81",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Platethora",
    stripeImage:
      "https://order.platethora.com.sg/img/ninjaprocmn/platethora/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "platethora",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.platethora.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.platethora.com.sg/#/",
      fbImage: "https://order.platethora.com.sg/img/platethora/logo.png",
      fbTitle: "Platethora",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.platethora.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.platethora.com.sg/#/",
      twTitile: "Platethora",
      twvia: "Platethora",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.platethora.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.platethora.com.sg/#/",
      waSeparator: "Platethora",
    },
    site_title: "Platethora",
    site_title_with_separator: " | Platethora",
    home_meta_title: "Platethora",
    bgimage: "/img/ninjaprocmn/platethora/body_bg.jpg",
  },
  "ninjaorder.gourmetz.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6BA585FF-8494-4393-8FCF-D8A876E40538",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Gourmetz",
    stripeImage:
      "https://ninjaorder.gourmetz.com.sg/img/ninjaprocmn/gourmetz/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gourmetz",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://ninjaorder.gourmetz.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://ninjaorder.gourmetz.com.sg/#/",
      fbImage: "https://ninjaorder.gourmetz.com.sg/img/gourmetz/logo.png",
      fbTitle: "Gourmetz",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://ninjaorder.gourmetz.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://ninjaorder.gourmetz.com.sg/#/",
      twTitile: "Gourmetz",
      twvia: "Gourmetz",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://ninjaorder.gourmetz.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://ninjaorder.gourmetz.com.sg/#/",
      waSeparator: "Gourmetz",
    },
    site_title: "Gourmetz",
    site_title_with_separator: " | Gourmetz",
    home_meta_title: "Gourmetz",
    bgimage: "/img/ninjaprocmn/gourmetz/body_bg.jpg",
  },
  "order.makanmate.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A25899B9-E00B-4B08-B068-01EE55529BB1",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Makan Mate Catering",
    stripeImage:
      "https://order.makanmate.com/img/ninjaprocmn/makanmate/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "makanmate",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.makanmate.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.makanmate.com/#/",
      fbImage: "https://order.makanmate.com/img/makanmate/logo.png",
      fbTitle: "Makan Mate Catering",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.makanmate.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.makanmate.com/#/",
      twTitile: "Makan Mate Catering",
      twvia: "Makan Mate Catering",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.makanmate.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.makanmate.com/#/",
      waSeparator: "Makan Mate Catering",
    },
    site_title: "Makan Mate Catering",
    site_title_with_separator: " | Makan Mate Catering",
    home_meta_title: "Makan Mate Catering",
    bgimage: "/img/ninjaprocmn/makanmate/body_bg.jpg",
  },
  "order.therefinery.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "61ACAD41-E48E-4F3F-A71C-7913BA965CDF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Refinery",
    stripeImage:
      "https://order.therefinery.sg/img/ninjaprocmn/refinery/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "refinery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.therefinery.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.therefinery.sg/#/",
      fbImage: "https://order.therefinery.sg/img/refinery/logo.png",
      fbTitle: "The Refinery",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.therefinery.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.therefinery.sg/#/",
      twTitile: "The Refinery",
      twvia: "The Refinery",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.therefinery.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.therefinery.sg/#/",
      waSeparator: "The Refinery",
    },
    site_title: "The Refinery",
    site_title_with_separator: " | The Refinery",
    home_meta_title: "The Refinery",
    bgimage: "/img/ninjaprocmn/refinery/body_bg.jpg",
  },
  "order.tomahawk.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "EF790149-70A0-4250-9481-8BAEC710EDE3",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tomahawk",
    stripeImage:
      "https://order.tomahawk.com.sg/img/ninjaprocmn/omajadmin/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "omajadmin",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.tomahawk.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.tomahawk.com.sg/#/",
      fbImage: "https://order.tomahawk.com.sg/img/omajadmin/logo.png",
      fbTitle: "Tomahawk",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.tomahawk.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.tomahawk.com.sg/#/",
      twTitile: "Tomahawk",
      twvia: "Tomahawk",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.tomahawk.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.tomahawk.com.sg/#/",
      waSeparator: "Tomahawk",
    },
    site_title: "Tomahawk",
    site_title_with_separator: " | Tomahawk",
    home_meta_title: "Tomahawk",
    bgimage: "/img/ninjaprocmn/omajadmin/body_bg.jpg",
  },
  "order.pickapastasg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A19B2637-E952-4ABD-BBC0-BE4B441ABE60",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Pick-A-Pasta",
    stripeImage:
      "https://order.pickapastasg.com/img/ninjaprocmn/pickapastasg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pickapastasg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.pickapastasg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.pickapastasg.com/#/",
      fbImage: "https://order.pickapastasg.com/img/pickapastasg/logo.png",
      fbTitle: "Pick-A-Pasta",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.pickapastasg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.pickapastasg.com/#/",
      twTitile: "Pick-A-Pasta",
      twvia: "Pick-A-Pasta",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.pickapastasg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.pickapastasg.com/#/",
      waSeparator: "Pick-A-Pasta",
    },
    site_title: "Pick-A-Pasta",
    site_title_with_separator: " | Pick-A-Pasta",
    home_meta_title: "Pick-A-Pasta",
    bgimage: "/img/ninjaprocmn/pickapastasg/body_bg.jpg",
  },
  "order.ricebowlgold.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "53A5C4BB-5119-4EA6-A5F1-8450138CF162",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Rice Bowl Gold",
    stripeImage:
      "https://order.ricebowlgold.com/img/ninjaprocmn/ricebowlgold/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ricebowlgold",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ricebowlgold.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ricebowlgold.com/#/",
      fbImage: "https://order.ricebowlgold.com/img/ricebowlgold/logo.png",
      fbTitle: "Rice Bowl Gold",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ricebowlgold.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ricebowlgold.com/#/",
      twTitile: "Rice Bowl Gold",
      twvia: "Rice Bowl Gold",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ricebowlgold.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ricebowlgold.com/#/",
      waSeparator: "Rice Bowl Gold",
    },
    site_title: "Rice Bowl Gold",
    site_title_with_separator: " | Rice Bowl Gold",
    home_meta_title: "Rice Bowl Gold",
    bgimage: "/img/ninjaprocmn/ricebowlgold/body_bg.jpg",
  },
  "www.suriafood.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E74DA3C5-BE62-471B-8949-3A8EB9F622A7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Suria Food",
    stripeImage:
      "https://www.suriafood.com.sg/img/ninjaprocmn/suriafood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "suriafood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.suriafood.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.suriafood.com.sg/#/",
      fbImage: "https://www.suriafood.com.sg/img/suriafood/logo.png",
      fbTitle: "Suria Food",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.suriafood.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.suriafood.com.sg/#/",
      twTitile: "Suria Food",
      twvia: "Suria Food",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.suriafood.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.suriafood.com.sg/#/",
      waSeparator: "Suria Food",
    },
    site_title: "Suria Food",
    forcewww: "YES",
    site_title_with_separator: " | Suria Food",
    home_meta_title: "Suria Food",
    bgimage: "/img/ninjaprocmn/suriafood/body_bg.jpg",
  },
  "order.hawkerchan.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A1DDCC23-D7DF-4F1C-A59C-ECD6AF1C37FA",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Hawker Chan",
    stripeImage:
      "https://order.hawkerchan.com/img/ninjaprocmn/hawkerchan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hawkerchan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.hawkerchan.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.hawkerchan.com/#/",
      fbImage: "https://order.hawkerchan.com/img/hawkerchan/logo.png",
      fbTitle: "Hawker Chan",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.hawkerchan.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.hawkerchan.com/#/",
      twTitile: "Hawker Chan",
      twvia: "Hawker Chan",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.hawkerchan.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.hawkerchan.com/#/",
      waSeparator: "Hawker Chan",
    },
    site_title: "Hawker Chan",
    site_title_with_separator: " | Hawker Chan",
    home_meta_title: "Hawker Chan",
    bgimage: "/img/ninjaprocmn/hawkerchan/body_bg.jpg",
  },
  "www.kimotogastrobar.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6991DFCE-7B67-4528-AA4C-9B7331848346",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "KIMOTO GASTROBAR",
    stripeImage:
      "https://www.kimotogastrobar.com/img/ninjaprocmn/kimoto/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kimoto",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kimotogastrobar.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kimotogastrobar.com/#/",
      fbImage: "https://www.kimotogastrobar.com/img/kimoto/logo.png",
      fbTitle: "KIMOTO GASTROBAR",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kimotogastrobar.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kimotogastrobar.com/#/",
      twTitile: "KIMOTO GASTROBAR",
      twvia: "KIMOTO GASTROBAR",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.kimotogastrobar.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.kimotogastrobar.com/#/",
      waSeparator: "KIMOTO GASTROBAR",
    },
    site_title: "KIMOTO GASTROBAR",
    forcewww: "YES",
    site_title_with_separator: " | KIMOTO GASTROBAR",
    home_meta_title: "KIMOTO GASTROBAR",
    bgimage: "/img/ninjaprocmn/kimoto/body_bg.jpg",
  },
  "www.kacacoffee.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2E6757CD-8E20-4454-9FC5-B3721277612D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kaca Coffee ",
    stripeImage:
      "https://www.kacacoffee.com/img/ninjaprocmn/kacacoffee/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kacacoffee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kacacoffee.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kacacoffee.com/#/",
      fbImage: "https://www.kacacoffee.com/img/kacacoffee/logo.png",
      fbTitle: "Kaca Coffee ",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kacacoffee.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kacacoffee.com/#/",
      twTitile: "Kaca Coffee ",
      twvia: "Kaca Coffee ",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.kacacoffee.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.kacacoffee.com/#/",
      waSeparator: "Kaca Coffee ",
    },
    site_title: "Kaca Coffee ",
    forcewww: "YES",
    site_title_with_separator: " | Kaca Coffee ",
    home_meta_title: "Kaca Coffee ",
    bgimage: "/img/ninjaprocmn/kacacoffee/body_bg.jpg",
  },
  "coba.cobacoba.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A2924058-9DEC-4A6B-A92C-F762D2C1A7E3",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Coba Coba",
    stripeImage:
      "https://coba.cobacoba.sg/img/ninjaprocmn/cobacoba/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "cobacoba",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://coba.cobacoba.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://coba.cobacoba.sg/#/",
      fbImage: "https://coba.cobacoba.sg/img/cobacoba/logo.png",
      fbTitle: "Coba Coba",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://coba.cobacoba.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://coba.cobacoba.sg/#/",
      twTitile: "Coba Coba",
      twvia: "Coba Coba",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://coba.cobacoba.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://coba.cobacoba.sg/#/",
      waSeparator: "Coba Coba",
    },
    site_title: "Coba Coba",
    site_title_with_separator: " | Coba Coba",
    home_meta_title: "Coba Coba",
    bgimage: "/img/ninjaprocmn/cobacoba/body_bg.jpg",
  },
  "www.hansimglucksg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C69911CC-C6B9-46D0-9A15-5DF5A201B3CF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "HANS IM GLUCK GERMAN BURGERGRILL",
    stripeImage:
      "https://www.hansimglucksg.com/img/ninjaprocmn/hansimgluck/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hansimgluck",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.hansimglucksg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.hansimglucksg.com/#/",
      fbImage: "https://www.hansimglucksg.com/img/hansimgluck/logo.png",
      fbTitle: "HANS IM GLUCK GERMAN BURGERGRILL",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.hansimglucksg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.hansimglucksg.com/#/",
      twTitile: "HANS IM GLUCK GERMAN BURGERGRILL",
      twvia: "HANS IM GLUCK GERMAN BURGERGRILL",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.hansimglucksg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.hansimglucksg.com/#/",
      waSeparator: "HANS IM GLUCK GERMAN BURGERGRILL",
    },
    site_title: "HANS IM GLUCK GERMAN BURGERGRILL",
    forcewww: "YES",
    site_title_with_separator: " | HANS IM GLUCK GERMAN BURGERGRILL",
    home_meta_title: "HANS IM GLUCK GERMAN BURGERGRILL",
    bgimage: "/img/ninjaprocmn/hansimgluck/body_bg.jpg",
  },
  "www.tasteofbiriyani.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4C2828D1-54B1-4957-AF53-B2BEF0A19E41",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Taste of Biriyani",
    stripeImage:
      "https://www.tasteofbiriyani.com/img/ninjaprocmn/tasteofbiriyani/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tasteofbiriyani",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.tasteofbiriyani.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.tasteofbiriyani.com/#/",
      fbImage: "https://www.tasteofbiriyani.com/img/tasteofbiriyani/logo.png",
      fbTitle: "Taste of Biriyani",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.tasteofbiriyani.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.tasteofbiriyani.com/#/",
      twTitile: "Taste of Biriyani",
      twvia: "Taste of Biriyani",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.tasteofbiriyani.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.tasteofbiriyani.com/#/",
      waSeparator: "Taste of Biriyani",
    },
    site_title: "Taste of Biriyani",
    forcewww: "YES",
    site_title_with_separator: " | Taste of Biriyani",
    home_meta_title: "Taste of Biriyani",
    bgimage: "/img/ninjaprocmn/tasteofbiriyani/body_bg.jpg",
  },
  "order.juewei.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F0A5CE81-2070-4A4A-A74D-D57968FFDB3C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "JUEWEI",
    stripeImage:
      "https://order.juewei.com.sg/img/ninjaprocmn/juweiadmin/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "juweiadmin",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.juewei.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.juewei.com.sg/#/",
      fbImage: "https://order.juewei.com.sg/img/juweiadmin/logo.png",
      fbTitle: "JUEWEI",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.juewei.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.juewei.com.sg/#/",
      twTitile: "JUEWEI",
      twvia: "JUEWEI",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.juewei.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.juewei.com.sg/#/",
      waSeparator: "JUEWEI",
    },
    site_title: "JUEWEI",
    site_title_with_separator: " | JUEWEI",
    home_meta_title: "JUEWEI",
    bgimage: "/img/ninjaprocmn/juweiadmin/body_bg.jpg",
  },
  "order.fuelbeast.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6067687F-3151-4F7A-920C-B4C51DEC29C1",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Fuel Beast",
    stripeImage:
      "https://order.fuelbeast.com.sg/img/ninjaprocmn/fuelbeast/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fuelbeast",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.fuelbeast.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.fuelbeast.com.sg/#/",
      fbImage: "https://order.fuelbeast.com.sg/img/fuelbeast/logo.png",
      fbTitle: "Fuel Beast",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.fuelbeast.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.fuelbeast.com.sg/#/",
      twTitile: "Fuel Beast",
      twvia: "Fuel Beast",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.fuelbeast.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.fuelbeast.com.sg/#/",
      waSeparator: "Fuel Beast",
    },
    site_title: "Fuel Beast",
    forcewww: "YES",
    site_title_with_separator: " | Fuel Beast",
    home_meta_title: "Fuel Beast",
    bgimage: "/img/ninjaprocmn/fuelbeast/body_bg.jpg",
  },
  "gourmetpantry.amclub.org.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Gourmet Pantry",
    stripeImage:
      "https://gourmetpantry.amclub.org.sg/img/ninjaprocmn/gourmet/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gourmet",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://gourmetpantry.amclub.org.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://gourmetpantry.amclub.org.sg/#/",
      fbImage: "https://gourmetpantry.amclub.org.sg/img/gourmet/logo.png",
      fbTitle: "Gourmet Pantry",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://gourmetpantry.amclub.org.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://gourmetpantry.amclub.org.sg/#/",
      twTitile: "Gourmet Pantry",
      twvia: "Gourmet Pantry",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://gourmetpantry.amclub.org.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://gourmetpantry.amclub.org.sg/#/",
      waSeparator: "Gourmet Pantry",
    },
    site_title: "Gourmet Pantry",
    site_title_with_separator: " | Gourmet Pantry",
    home_meta_title: "Gourmet Pantry",
    bgimage: "/img/ninjaprocmn/gourmet/body_bg.jpg",
  },
  "www.mumbaidelights.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F2A38D8E-892E-4ABD-9543-0961F528F0CA",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mumbai Delights",
    stripeImage:
      "https://www.mumbaidelights.com/img/ninjaprocmn/mumbaidelights/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mumbaidelights",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.mumbaidelights.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.mumbaidelights.com/#/",
      fbImage: "https://www.mumbaidelights.com/img/mumbaidelights/logo.png",
      fbTitle: "Mumbai Delights",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.mumbaidelights.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.mumbaidelights.com/#/",
      twTitile: "Mumbai Delights",
      twvia: "Mumbai Delights",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.mumbaidelights.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.mumbaidelights.com/#/",
      waSeparator: "Mumbai Delights",
    },
    site_title: "Mumbai Delights",
    forcewww: "YES",
    site_title_with_separator: " | Mumbai Delights",
    home_meta_title: "Mumbai Delights",
    bgimage: "/img/ninjaprocmn/mumbaidelights/body_bg.jpg",
  },
  "order.homeofseafood.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F6580CC4-91DA-4AEF-BD83-AE545869A774",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Home Of Seafood",
    stripeImage:
      "https://order.homeofseafood.com/img/ninjaprocmn/homeofseafood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "homeofseafood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.homeofseafood.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.homeofseafood.com/#/",
      fbImage: "https://order.homeofseafood.com/img/homeofseafood/logo.png",
      fbTitle: "Home Of Seafood",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.homeofseafood.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.homeofseafood.com/#/",
      twTitile: "Home Of Seafood",
      twvia: "Home Of Seafood",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.homeofseafood.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.homeofseafood.com/#/",
      waSeparator: "Home Of Seafood",
    },
    site_title: "Home Of Seafood",
    site_title_with_separator: " | Home Of Seafood",
    home_meta_title: "Home Of Seafood",
    bgimage: "/img/ninjaprocmn/homeofseafood/body_bg.jpg",
  },
  "order.keefthebeef.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "88E232C9-8943-4042-B4D4-3413E394007B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Keef The Beef",
    stripeImage:
      "https://order.keefthebeef.com/img/ninjaprocmn/keefthebeef/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "keefthebeef",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.keefthebeef.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.keefthebeef.com/#/",
      fbImage: "https://order.keefthebeef.com/img/keefthebeef/logo.png",
      fbTitle: "Keef The Beef",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.keefthebeef.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.keefthebeef.com/#/",
      twTitile: "Keef The Beef",
      twvia: "Keef The Beef",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.keefthebeef.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.keefthebeef.com/#/",
      waSeparator: "Keef The Beef",
    },
    site_title: "Keef The Beef",
    site_title_with_separator: " | Keef The Beef",
    home_meta_title: "Keef The Beef",
    bgimage: "/img/ninjaprocmn/keefthebeef/body_bg.jpg",
  },
  "order.randyindulgence.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "86DF58AF-FF24-4F28-AFA1-A0440F2B352F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Randy Indulgence",
    stripeImage:
      "https://order.randyindulgence.com/img/ninjaprocmn/randyindulgence/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "randyindulgence",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.randyindulgence.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.randyindulgence.com/#/",
      fbImage: "https://order.randyindulgence.com/img/randyindulgence/logo.png",
      fbTitle: "Randy Indulgence",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.randyindulgence.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.randyindulgence.com/#/",
      twTitile: "Randy Indulgence",
      twvia: "Randy Indulgence",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.randyindulgence.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.randyindulgence.com/#/",
      waSeparator: "Randy Indulgence",
    },
    site_title: "Randy Indulgence",
    site_title_with_separator: " | Randy Indulgence",
    home_meta_title: "Randy Indulgence",
    bgimage: "/img/ninjaprocmn/randyindulgence/body_bg.jpg",
  },
  "order.xiyanseafood.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "57D53B2C-83D6-41E2-A819-A7E9286B548A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Xiyan Sea Food",
    stripeImage:
      "https://order.xiyanseafood.com/img/ninjaprocmn/xiyanseafood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "xiyanseafood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.xiyanseafood.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.xiyanseafood.com/#/",
      fbImage: "https://order.xiyanseafood.com/img/xiyanseafood/logo.png",
      fbTitle: "Xiyan Sea Food",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.xiyanseafood.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.xiyanseafood.com/#/",
      twTitile: "Xiyan Sea Food",
      twvia: "Xiyan Sea Food",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.xiyanseafood.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.xiyanseafood.com/#/",
      waSeparator: "Xiyan Sea Food",
    },
    site_title: "Xiyan Sea Food",
    site_title_with_separator: " | Xiyan Sea Food",
    home_meta_title: "Xiyan Sea Food",
    bgimage: "/img/ninjaprocmn/xiyanseafood/body_bg.jpg",
  },
  "order.pennyuniversitysg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "709477D0-BC39-4F2B-BC2D-9FB4E71D5FEC",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Flat White",
    stripeImage:
      "https://order.pennyuniversitysg.com/img/ninjaprocmn/flatwhite/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "flatwhite",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.pennyuniversitysg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.pennyuniversitysg.com/#/",
      fbImage: "https://order.pennyuniversitysg.com/img/flatwhite/logo.png",
      fbTitle: "Flat White",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.pennyuniversitysg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.pennyuniversitysg.com/#/",
      twTitile: "Flat White",
      twvia: "Flat White",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.pennyuniversitysg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.pennyuniversitysg.com/#/",
      waSeparator: "Flat White",
    },
    site_title: "Flat White",
    site_title_with_separator: " | Flat White",
    home_meta_title: "Flat White",
    bgimage: "/img/ninjaprocmn/flatwhite/body_bg.jpg",
  },
  "order.wangdaebakbbq.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0F2CF5CD-8291-4A87-86AE-537BE58D4739",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Wang Dae Bak Pocha BBQ",
    stripeImage:
      "https://order.wangdaebakbbq.sg/img/ninjaprocmn/wangdaebak/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "wangdaebak",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.wangdaebakbbq.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.wangdaebakbbq.sg/#/",
      fbImage: "https://order.wangdaebakbbq.sg/img/wangdaebak/logo.png",
      fbTitle: "Wang Dae Bak Pocha BBQ",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.wangdaebakbbq.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.wangdaebakbbq.sg/#/",
      twTitile: "Wang Dae Bak Pocha BBQ",
      twvia: "Wang Dae Bak Pocha BBQ",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.wangdaebakbbq.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.wangdaebakbbq.sg/#/",
      waSeparator: "Wang Dae Bak Pocha BBQ",
    },
    site_title: "Wang Dae Bak Pocha BBQ",
    site_title_with_separator: " | Wang Dae Bak Pocha BBQ",
    home_meta_title: "Wang Dae Bak Pocha BBQ",
    bgimage: "/img/ninjaprocmn/wangdaebak/body_bg.jpg",
  },
  "order.getthislive.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "EDEE0B9B-353B-476C-9ECE-2881D11AA2B7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Get This Live",
    stripeImage:
      "https://order.getthislive.com/img/ninjaprocmn/getthislive/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "getthislive",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.getthislive.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.getthislive.com/#/",
      fbImage: "https://order.getthislive.com/img/getthislive/logo.png",
      fbTitle: "Get This Live",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.getthislive.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.getthislive.com/#/",
      twTitile: "Get This Live",
      twvia: "Get This Live",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.getthislive.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.getthislive.com/#/",
      waSeparator: "Get This Live",
    },
    site_title: "Get This Live",
    site_title_with_separator: " | Get This Live",
    home_meta_title: "Get This Live",
    bgimage: "/img/ninjaprocmn/getthislive/body_bg.jpg",
  },
  "www.easternhouseofseafood.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "06283C83-FD4F-4E71-8012-840420DF0556",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Eastern House of Seafood",
    stripeImage:
      "https://www.easternhouseofseafood.com/img/ninjaprocmn/easternhouse/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "easternhouse",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.easternhouseofseafood.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.easternhouseofseafood.com/#/",
      fbImage:
        "https://www.easternhouseofseafood.com/img/easternhouse/logo.png",
      fbTitle: "Eastern House of Seafood",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.easternhouseofseafood.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.easternhouseofseafood.com/#/",
      twTitile: "Eastern House of Seafood",
      twvia: "Eastern House of Seafood",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.easternhouseofseafood.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.easternhouseofseafood.com/#/",
      waSeparator: "Eastern House of Seafood",
    },
    site_title: "Eastern House of Seafood",
    forcewww: "YES",
    site_title_with_separator: " | Eastern House of Seafood",
    home_meta_title: "Eastern House of Seafood",
    bgimage: "/img/ninjaprocmn/easternhouse/body_bg.jpg",
  },
  "www.suthascafe.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B8817E3D-1D19-4858-A0C5-565F4519B477",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Suthas Cafe",
    stripeImage:
      "https://www.suthascafe.com/img/ninjaprocmn/suthascafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "suthascafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.suthascafe.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.suthascafe.com/#/",
      fbImage: "https://www.suthascafe.com/img/suthascafe/logo.png",
      fbTitle: "Suthas Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.suthascafe.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.suthascafe.com/#/",
      twTitile: "Suthas Cafe",
      twvia: "Suthas Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.suthascafe.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.suthascafe.com/#/",
      waSeparator: "Suthas Cafe",
    },
    site_title: "Suthas Cafe",
    forcewww: "YES",
    site_title_with_separator: " | Suthas Cafe",
    home_meta_title: "Suthas Cafe",
    bgimage: "/img/ninjaprocmn/suthascafe/body_bg.jpg",
  },
  "www.happycupfamily.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E2698A9B-672C-4232-9A0C-27BBC329E7C2",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Happy Cup Family",
    stripeImage:
      "https://www.happycupfamily.com/img/ninjaprocmn/happycup/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "happycup",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.happycupfamily.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.happycupfamily.com/#/",
      fbImage: "https://www.happycupfamily.com/img/happycup/logo.png",
      fbTitle: "Happy Cup Family",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.happycupfamily.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.happycupfamily.com/#/",
      twTitile: "Happy Cup Family",
      twvia: "Happy Cup Family",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.happycupfamily.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.happycupfamily.com/#/",
      waSeparator: "Happy Cup Family",
    },
    site_title: "Happy Cup Family",
    forcewww: "YES",
    site_title_with_separator: " | Happy Cup Family",
    home_meta_title: "Happy Cup Family",
    bgimage: "/img/ninjaprocmn/happycup/body_bg.jpg",
  },
  "order.ikki.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B74440B9-2129-4C91-A578-A02C646C5157",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "IKKI IZAKAYA",
    stripeImage:
      "https://order.ikki.com.sg/img/ninjaprocmn/la-cantina/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "la-cantina",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ikki.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ikki.com.sg/#/",
      fbImage: "https://order.ikki.com.sg/img/la-cantina/logo.png",
      fbTitle: "IKKI IZAKAYA",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ikki.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ikki.com.sg/#/",
      twTitile: "IKKI IZAKAYA",
      twvia: "IKKI IZAKAYA",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ikki.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ikki.com.sg/#/",
      waSeparator: "IKKI IZAKAYA",
    },
    site_title: "IKKI IZAKAYA",
    site_title_with_separator: " | IKKI IZAKAYA",
    home_meta_title: "IKKI IZAKAYA",
    bgimage: "/img/ninjaprocmn/la-cantina/body_bg.jpg",
  },
  "order.rollieolie.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F557E2F0-6A9D-4B1F-B772-E4830505384B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Rollie Olie",
    stripeImage:
      "https://order.rollieolie.com/img/ninjaprocmn/rollieolie/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "rollieolie",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.rollieolie.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.rollieolie.com/#/",
      fbImage: "https://order.rollieolie.com/img/rollieolie/logo.png",
      fbTitle: "Rollie Olie",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.rollieolie.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.rollieolie.com/#/",
      twTitile: "Rollie Olie",
      twvia: "Rollie Olie",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.rollieolie.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.rollieolie.com/#/",
      waSeparator: "Rollie Olie",
    },
    site_title: "Rollie Olie",
    site_title_with_separator: " | Rollie Olie",
    home_meta_title: "Rollie Olie",
    bgimage: "/img/ninjaprocmn/rollieolie/body_bg.jpg",
  },
  "www.timikacuisine.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "78106897-0612-45BB-8592-A65EBC7DCD6B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Timika Cuisine ",
    stripeImage:
      "https://www.timikacuisine.com/img/ninjaprocmn/timikacuisine/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "timikacuisine",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.timikacuisine.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.timikacuisine.com/#/",
      fbImage: "https://www.timikacuisine.com/img/timikacuisine/logo.png",
      fbTitle: "Timika Cuisine ",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.timikacuisine.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.timikacuisine.com/#/",
      twTitile: "Timika Cuisine ",
      twvia: "Timika Cuisine ",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.timikacuisine.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.timikacuisine.com/#/",
      waSeparator: "Timika Cuisine ",
    },
    site_title: "Timika Cuisine ",
    forcewww: "YES",
    site_title_with_separator: " | Timika Cuisine ",
    home_meta_title: "Timika Cuisine ",
    bgimage: "/img/ninjaprocmn/timikacuisine/body_bg.jpg",
  },
  "www.kingoflobster.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "38AA0470-F298-4E72-8955-B98A0AEAB6DA",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "King of Lobster",
    stripeImage:
      "https://www.kingoflobster.com.sg/img/ninjaprocmn/kingoflobster/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kingoflobster",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kingoflobster.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kingoflobster.com.sg/#/",
      fbImage: "https://www.kingoflobster.com.sg/img/kingoflobster/logo.png",
      fbTitle: "King of Lobster",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kingoflobster.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kingoflobster.com.sg/#/",
      twTitile: "King of Lobster",
      twvia: "King of Lobster",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.kingoflobster.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.kingoflobster.com.sg/#/",
      waSeparator: "King of Lobster",
    },
    site_title: "King of Lobster",
    forcewww: "YES",
    site_title_with_separator: " | King of Lobster",
    home_meta_title: "King of Lobster",
    bgimage: "/img/ninjaprocmn/kingoflobster/body_bg.jpg",
  },
  "www.icgchickenandburger.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "87E64237-E8D7-4CB6-ADC0-3E124D858641",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "ICG Chicken & Burger",
    stripeImage:
      "https://www.icgchickenandburger.com/img/ninjaprocmn/icgchicken/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "icgchicken",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.icgchickenandburger.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.icgchickenandburger.com/#/",
      fbImage: "https://www.icgchickenandburger.com/img/icgchicken/logo.png",
      fbTitle: "ICG Chicken & Burger",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.icgchickenandburger.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.icgchickenandburger.com/#/",
      twTitile: "ICG Chicken & Burger",
      twvia: "ICG Chicken & Burger",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.icgchickenandburger.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.icgchickenandburger.com/#/",
      waSeparator: "ICG Chicken & Burger",
    },
    site_title: "ICG Chicken & Burger",
    forcewww: "YES",
    site_title_with_separator: " | ICG Chicken & Burger",
    home_meta_title: "ICG Chicken & Burger",
    bgimage: "/img/ninjaprocmn/icgchicken/body_bg.jpg",
  },
  "www.janggutlaksa.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1DAE4692-4358-4CBC-B534-B95BB017C706",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Janggut Laksa",
    stripeImage:
      "https://www.janggutlaksa.com/img/ninjaprocmn/janggutlaksa/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "janggutlaksa",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.janggutlaksa.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.janggutlaksa.com/#/",
      fbImage: "https://www.janggutlaksa.com/img/janggutlaksa/logo.png",
      fbTitle: "Janggut Laksa",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.janggutlaksa.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.janggutlaksa.com/#/",
      twTitile: "Janggut Laksa",
      twvia: "Janggut Laksa",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.janggutlaksa.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.janggutlaksa.com/#/",
      waSeparator: "Janggut Laksa",
    },
    site_title: "Janggut Laksa",
    forcewww: "YES",
    site_title_with_separator: " | Janggut Laksa",
    home_meta_title: "Janggut Laksa",
    bgimage: "/img/ninjaprocmn/janggutlaksa/body_bg.jpg",
  },
  "www.petracafe95.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F10D730F-A3B1-4F02-A116-3EDAD9524A04",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Petra Cafe",
    stripeImage:
      "https://www.petracafe95.com/img/ninjaprocmn/petracafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "petracafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.petracafe95.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.petracafe95.com/#/",
      fbImage: "https://www.petracafe95.com/img/petracafe/logo.png",
      fbTitle: "Petra Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.petracafe95.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.petracafe95.com/#/",
      twTitile: "Petra Cafe",
      twvia: "Petra Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.petracafe95.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.petracafe95.com/#/",
      waSeparator: "Petra Cafe",
    },
    site_title: "Petra Cafe",
    forcewww: "YES",
    site_title_with_separator: " | Petra Cafe",
    home_meta_title: "Petra Cafe",
    bgimage: "/img/ninjaprocmn/petracafe/body_bg.jpg",
  },
  "order.jinglongseafood.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9DA31D19-0099-4D2C-8DF8-7F8B916CA536",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Jing Long Seafood Restaurant",
    stripeImage:
      "https://order.jinglongseafood.com.sg/img/ninjaprocmn/jinglongseafood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "jinglongseafood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.jinglongseafood.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.jinglongseafood.com.sg/#/",
      fbImage:
        "https://order.jinglongseafood.com.sg/img/jinglongseafood/logo.png",
      fbTitle: "Jing Long Seafood Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.jinglongseafood.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.jinglongseafood.com.sg/#/",
      twTitile: "Jing Long Seafood Restaurant",
      twvia: "Jing Long Seafood Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.jinglongseafood.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.jinglongseafood.com.sg/#/",
      waSeparator: "Jing Long Seafood Restaurant",
    },
    site_title: "Jing Long Seafood Restaurant",
    site_title_with_separator: " | Jing Long Seafood Restaurant",
    home_meta_title: "Jing Long Seafood Restaurant",
    bgimage: "/img/ninjaprocmn/jinglongseafood/body_bg.jpg",
  },
  "order.dbestfishing.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E8BB11AB-FD8A-4CB3-A5C0-71F90C9CAA42",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "D Best Recreation",
    stripeImage:
      "https://order.dbestfishing.com.sg/img/ninjaprocmn/dbestone/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "dbestone",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.dbestfishing.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.dbestfishing.com.sg/#/",
      fbImage: "https://order.dbestfishing.com.sg/img/dbestone/logo.png",
      fbTitle: "D Best Recreation",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.dbestfishing.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.dbestfishing.com.sg/#/",
      twTitile: "D Best Recreation",
      twvia: "D Best Recreation",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.dbestfishing.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.dbestfishing.com.sg/#/",
      waSeparator: "D Best Recreation",
    },
    site_title: "D Best Recreation",
    site_title_with_separator: " | D Best Recreation",
    home_meta_title: "D Best Recreation",
    bgimage: "/img/ninjaprocmn/dbestone/body_bg.jpg",
  },
  "order.char.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "205A3AF1-7155-4D04-9424-F4F524473EFC",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "charadmin",
    stripeImage:
      "https://order.char.com.sg/img/ninjaprocmn/charadmin/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "charadmin",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.char.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.char.com.sg/#/",
      fbImage: "https://order.char.com.sg/img/charadmin/logo.png",
      fbTitle: "Char",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.char.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.char.com.sg/#/",
      twTitile: "Char",
      twvia: "Char",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.char.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.char.com.sg/#/",
      waSeparator: "Char",
    },
    site_title: "Char",
    site_title_with_separator: " | Char",
    home_meta_title: "Char",
    bgimage: "/img/ninjaprocmn/charadmin/body_bg.jpg",
  },
  "shop.onefiftypeel.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C655A75D-2E68-4E31-BCE1-58F533E0D1A7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "One Fifty Peel",
    stripeImage:
      "https://shop.onefiftypeel.com/img/ninjaprocmn/onefiftypeel/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "onefiftypeel",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://shop.onefiftypeel.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://shop.onefiftypeel.com/#/",
      fbImage: "https://shop.onefiftypeel.com/img/onefiftypeel/logo.png",
      fbTitle: "One Fifty Peel",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://shop.onefiftypeel.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://shop.onefiftypeel.com/#/",
      twTitile: "One Fifty Peel",
      twvia: "One Fifty Peel",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://shop.onefiftypeel.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://shop.onefiftypeel.com/#/",
      waSeparator: "One Fifty Peel",
    },
    site_title: "One Fifty Peel",
    site_title_with_separator: " | One Fifty Peel",
    home_meta_title: "One Fifty Peel",
    bgimage: "/img/ninjaprocmn/onefiftypeel/body_bg.jpg",
  },
  "order.gotluckcafe.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "BD919956-9E02-4CB9-831F-D5450F51F4B4",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Got Luck Cafe",
    stripeImage:
      "https://order.gotluckcafe.sg/img/ninjaprocmn/gotluckcafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gotluckcafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.gotluckcafe.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.gotluckcafe.sg/#/",
      fbImage: "https://order.gotluckcafe.sg/img/gotluckcafe/logo.png",
      fbTitle: "Got Luck Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.gotluckcafe.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.gotluckcafe.sg/#/",
      twTitile: "Got Luck Cafe",
      twvia: "Got Luck Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.gotluckcafe.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.gotluckcafe.sg/#/",
      waSeparator: "Got Luck Cafe",
    },
    site_title: "Got Luck Cafe",
    site_title_with_separator: " | Got Luck Cafe",
    home_meta_title: "Got Luck Cafe",
    bgimage: "/img/ninjaprocmn/gotluckcafe/body_bg.jpg",
  },
  "prodemo.ninjapro.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6023698C-B0E7-4F2F-9DEA-76E9686D8D76",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "prodemo",
    stripeImage:
      "https://prodemo.ninjapro.co/img/ninjaprocmn/prodemo/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "prodemo",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://prodemo.ninjapro.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://prodemo.ninjapro.co/#/",
      fbImage: "https://prodemo.ninjapro.co/img/prodemo/logo.png",
      fbTitle: "prodemo",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://prodemo.ninjapro.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://prodemo.ninjapro.co/#/",
      twTitile: "prodemo",
      twvia: "prodemo",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://prodemo.ninjapro.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://prodemo.ninjapro.co/#/",
      waSeparator: "prodemo",
    },
    site_title: "prodemo",
    site_title_with_separator: " | prodemo",
    home_meta_title: "prodemo",
    bgimage: "/img/ninjaprocmn/prodemo/body_bg.jpg",
  },
  "www.wheatsoba.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E31DCFC8-6685-4F30-A70A-6BF4CF21FB0E",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "WHEAT BAUMKUCHEN",
    stripeImage:
      "https://www.wheatsoba.com/img/ninjaprocmn/wheatsoba/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "wheatsoba",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.wheatsoba.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.wheatsoba.com/#/",
      fbImage: "https://www.wheatsoba.com/img/wheatsoba/logo.png",
      fbTitle: "WHEAT BAUMKUCHEN",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.wheatsoba.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.wheatsoba.com/#/",
      twTitile: "WHEAT BAUMKUCHEN",
      twvia: "WHEAT BAUMKUCHEN",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.wheatsoba.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.wheatsoba.com/#/",
      waSeparator: "WHEAT BAUMKUCHEN",
    },
    site_title: "WHEAT BAUMKUCHEN",
    forcewww: "YES",
    site_title_with_separator: " | WHEAT BAUMKUCHEN",
    home_meta_title: "WHEAT BAUMKUCHEN",
    bgimage: "/img/ninjaprocmn/wheatsoba/body_bg.jpg",
  },
  "order.thefoodbarn.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2A7F12CD-E994-40C3-9F5C-A4C3A4FECDFE",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The FoodBarn",
    stripeImage:
      "https://order.thefoodbarn.com.sg/img/ninjaprocmn/foodbarn/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "foodbarn",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.thefoodbarn.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.thefoodbarn.com.sg/#/",
      fbImage: "https://order.thefoodbarn.com.sg/img/foodbarn/logo.png",
      fbTitle: "The FoodBarn",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.thefoodbarn.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.thefoodbarn.com.sg/#/",
      twTitile: "The FoodBarn",
      twvia: "The FoodBarn",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.thefoodbarn.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.thefoodbarn.com.sg/#/",
      waSeparator: "The FoodBarn",
    },
    site_title: "The FoodBarn",
    site_title_with_separator: " | The FoodBarn",
    home_meta_title: "The FoodBarn",
    bgimage: "/img/ninjaprocmn/foodbarn/body_bg.jpg",
  },
  "order.winehours.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "51362470-0A08-4A56-AD2C-7F815EC9A90C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Wine Hours",
    stripeImage:
      "https://order.winehours.com/img/ninjaprocmn/winehours/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "winehours",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.winehours.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.winehours.com/#/",
      fbImage: "https://order.winehours.com/img/winehours/logo.png",
      fbTitle: "Wine Hours",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.winehours.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.winehours.com/#/",
      twTitile: "Wine Hours",
      twvia: "Wine Hours",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.winehours.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.winehours.com/#/",
      waSeparator: "Wine Hours",
    },
    site_title: "Wine Hours",
    site_title_with_separator: " | Wine Hours",
    home_meta_title: "Wine Hours",
    bgimage: "/img/ninjaprocmn/winehours/body_bg.jpg",
  },
  "order.kinhoi.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4EDE9A25-89AA-4200-848D-AC4A5A54B7BC",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "KIN HOI",
    stripeImage:
      "https://order.kinhoi.sg/img/ninjaprocmn/kinhoi/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kinhoi",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.kinhoi.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.kinhoi.sg/#/",
      fbImage: "https://order.kinhoi.sg/img/kinhoi/logo.png",
      fbTitle: "KIN HOI",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.kinhoi.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.kinhoi.sg/#/",
      twTitile: "KIN HOI",
      twvia: "KIN HOI",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.kinhoi.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.kinhoi.sg/#/",
      waSeparator: "KIN HOI",
    },
    site_title: "KIN HOI",
    site_title_with_separator: " | KIN HOI",
    home_meta_title: "KIN HOI",
    bgimage: "/img/ninjaprocmn/kinhoi/body_bg.jpg",
  },
  "order.thetiramisuhero.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F45D6DB1-0D27-4E28-BF95-C5EBBE9B3DB4",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Tiramisu Hero",
    stripeImage:
      "https://order.thetiramisuhero.com/img/ninjaprocmn/tiramisu/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tiramisu",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.thetiramisuhero.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.thetiramisuhero.com/#/",
      fbImage: "https://order.thetiramisuhero.com/img/tiramisu/logo.png",
      fbTitle: "The Tiramisu Hero",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.thetiramisuhero.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.thetiramisuhero.com/#/",
      twTitile: "The Tiramisu Hero",
      twvia: "The Tiramisu Hero",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.thetiramisuhero.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.thetiramisuhero.com/#/",
      waSeparator: "The Tiramisu Hero",
    },
    site_title: "The Tiramisu Hero",
    site_title_with_separator: " | The Tiramisu Hero",
    home_meta_title: "The Tiramisu Hero",
    bgimage: "/img/ninjaprocmn/tiramisu/body_bg.jpg",
  },
  "www.f1rstgrillgastro.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E4C204F7-67A3-4EDF-BD5F-199AAB36481D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "F1rstgrill Gastronomic",
    stripeImage:
      "https://www.f1rstgrillgastro.com/img/ninjaprocmn/f1rstgrill/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "f1rstgrill",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.f1rstgrillgastro.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.f1rstgrillgastro.com/#/",
      fbImage: "https://www.f1rstgrillgastro.com/img/f1rstgrill/logo.png",
      fbTitle: "F1rstgrill Gastronomic",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.f1rstgrillgastro.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.f1rstgrillgastro.com/#/",
      twTitile: "F1rstgrill Gastronomic",
      twvia: "F1rstgrill Gastronomic",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.f1rstgrillgastro.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.f1rstgrillgastro.com/#/",
      waSeparator: "F1rstgrill Gastronomic",
    },
    site_title: "F1rstgrill Gastronomic",
    forcewww: "YES",
    site_title_with_separator: " | F1rstgrill Gastronomic",
    home_meta_title: "F1rstgrill Gastronomic",
    bgimage: "/img/ninjaprocmn/f1rstgrill/body_bg.jpg",
  },
  "order.indiantandoor.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9E19E513-F3D0-48BB-8D35-91DD424D2E26",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Indian Tandoor",
    stripeImage:
      "https://order.indiantandoor.com.sg/img/ninjaprocmn/indiantandoor/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "indiantandoor",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.indiantandoor.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.indiantandoor.com.sg/#/",
      fbImage: "https://order.indiantandoor.com.sg/img/indiantandoor/logo.png",
      fbTitle: "Indian Tandoor",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.indiantandoor.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.indiantandoor.com.sg/#/",
      twTitile: "Indian Tandoor",
      twvia: "Indian Tandoor",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.indiantandoor.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.indiantandoor.com.sg/#/",
      waSeparator: "Indian Tandoor",
    },
    site_title: "Indian Tandoor",
    site_title_with_separator: " | Indian Tandoor",
    home_meta_title: "Indian Tandoor",
    bgimage: "/img/ninjaprocmn/indiantandoor/body_bg.jpg",
  },
  "www.cantolicious.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DE70C3B3-FA36-482B-AC28-8652880AF20D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Cantolicious",
    stripeImage:
      "https://www.cantolicious.com/img/ninjaprocmn/cantolicious/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "cantolicious",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.cantolicious.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.cantolicious.com/#/",
      fbImage: "https://www.cantolicious.com/img/cantolicious/logo.png",
      fbTitle: "Cantolicious",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.cantolicious.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.cantolicious.com/#/",
      twTitile: "Cantolicious",
      twvia: "Cantolicious",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.cantolicious.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.cantolicious.com/#/",
      waSeparator: "Cantolicious",
    },
    site_title: "Cantolicious",
    forcewww: "YES",
    site_title_with_separator: " | Cantolicious",
    home_meta_title: "Cantolicious",
    bgimage: "/img/ninjaprocmn/cantolicious/body_bg.jpg",
  },
  "orderonline.chanung.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6281EA4D-CE95-4AE0-9187-0DFE1BE09D79",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "CHA NUNG SG",
    stripeImage:
      "https://orderonline.chanung.com.sg/img/ninjaprocmn/chanung/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "chanung",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orderonline.chanung.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orderonline.chanung.com.sg/#/",
      fbImage: "https://orderonline.chanung.com.sg/img/chanung/logo.png",
      fbTitle: "CHA NUNG SG",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orderonline.chanung.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orderonline.chanung.com.sg/#/",
      twTitile: "CHA NUNG SG",
      twvia: "CHA NUNG SG",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://orderonline.chanung.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://orderonline.chanung.com.sg/#/",
      waSeparator: "CHA NUNG SG",
    },
    site_title: "Indian Tandoor",
    site_title_with_separator: " | CHA NUNG SG",
    home_meta_title: "CHA NUNG SG",
    bgimage: "/img/ninjaprocmn/chanung/body_bg.jpg",
  },
  "order.carraracafesg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1FBFFCCF-921C-47F2-AC46-04B048D78132",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Carrara",
    stripeImage:
      "https://order.carraracafesg.com/img/ninjaprocmn/carrara/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "carrara",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.carraracafesg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.carraracafesg.com/#/",
      fbImage: "https://order.carraracafesg.com/img/carrara/logo.png",
      fbTitle: "Carrara",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.carraracafesg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.carraracafesg.com/#/",
      twTitile: "Carrara",
      twvia: "Carrara",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.carraracafesg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.carraracafesg.com/#/",
      waSeparator: "Carrara",
    },
    site_title: "Carrara",
    site_title_with_separator: " | Carrara",
    home_meta_title: "Carrara",
    bgimage: "/img/ninjaprocmn/carrara/body_bg.jpg",
  },
  "www.ordersingsweekee.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "430B7778-6299-4827-9DDF-C684E81729A5",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sing Swee Kee",
    stripeImage:
      "https://www.ordersingsweekee.com/img/ninjaprocmn/singswee/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "singswee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ordersingsweekee.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ordersingsweekee.com/#/",
      fbImage: "https://www.ordersingsweekee.com/img/singswee/logo.png",
      fbTitle: "Sing Swee Kee",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ordersingsweekee.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ordersingsweekee.com/#/",
      twTitile: "Sing Swee Kee",
      twvia: "Sing Swee Kee",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.ordersingsweekee.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.ordersingsweekee.com/#/",
      waSeparator: "Sing Swee Kee",
    },
    site_title: "Sing Swee Kee",
    site_title_with_separator: " | Sing Swee Kee",
    home_meta_title: "Sing Swee Kee",
    bgimage: "/img/ninjaprocmn/singswee/body_bg.jpg",
  },
  "orderonline.soonhuat.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3292DD26-CAEF-421D-81AF-72218513B3EE",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "SOON HUAT",
    stripeImage:
      "https://orderonline.soonhuat.sg/img/ninjaprocmn/soonhuat/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "soonhuat",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orderonline.soonhuat.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orderonline.soonhuat.sg/#/",
      fbImage: "https://orderonline.soonhuat.sg/img/soonhuat/logo.png",
      fbTitle: "SOON HUAT",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orderonline.soonhuat.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orderonline.soonhuat.sg/#/",
      twTitile: "SOON HUAT",
      twvia: "SOON HUAT",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://orderonline.soonhuat.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://orderonline.soonhuat.sg/#/",
      waSeparator: "SOON HUAT",
    },
    site_title: "SOON HUAT",
    site_title_with_separator: " | SOON HUAT",
    home_meta_title: "SOON HUAT",
    bgimage: "/img/ninjaprocmn/soonhuat/body_bg.jpg",
  },
  "www.sumosalad.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "27F0698D-98F8-4E34-B8D3-DC1921D53CE8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sumo Salad",
    stripeImage:
      "https://www.sumosalad.sg/img/ninjaprocmn/sumosalad/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sumosalad",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.sumosalad.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.sumosalad.sg/#/",
      fbImage: "https://www.sumosalad.sg/img/ninjaprocmn/sumosalad/logo.png",
      fbTitle: "Sumo Salad",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.sumosalad.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.sumosalad.sg/#/",
      twTitile: "Sumo Salad",
      twvia: "Sumo Salad",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.sumosalad.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.sumosalad.sg/#/",
      waSeparator: "Sumo Salad",
    },
    site_title: "Sumo Salad",
    forcewww: "YES",
    site_title_with_separator: " | Sumo Salad",
    home_meta_title: "Sumo Salad",
    bgimage: "/img/ninjaprocmn/sumosalad/body_bg.jpg",
  },
  "www.briyani47.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F3A90347-7ABC-42D1-8685-A8170A2C6CE4",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Briyani 47",
    stripeImage:
      "https://www.briyani47.com/img/ninjaprocmn/briyani47/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "briyani47",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.briyani47.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.briyani47.com/#/",
      fbImage: "https://www.briyani47.com/img/briyani47/logo.png",
      fbTitle: "Briyani 47",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.briyani47.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.briyani47.com/#/",
      twTitile: "Briyani 47",
      twvia: "Briyani 47",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.briyani47.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.briyani47.com/#/",
      waSeparator: "Briyani 47",
    },
    site_title: "Briyani 47",
    forcewww: "YES",
    site_title_with_separator: " | Briyani 47",
    home_meta_title: "Welcome to Briyani 47",
    bgimage: "/img/ninjaprocmn/briyani47/body_bg.jpg",
  },
  "order.aoi.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4D60440C-1D4E-4E0D-8558-37BCABE899D9",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Aromas of India",
    stripeImage:
      "https://order.aoi.com.sg/img/ninjaprocmn/aromas/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "aromas",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.aoi.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.aoi.com.sg/#/",
      fbImage: "https://order.aoi.com.sg/img/aromas/logo.png",
      fbTitle: "Aromas of India",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.aoi.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.aoi.com.sg/#/",
      twTitile: "Aromas of India",
      twvia: "Aromas of India",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.aoi.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.aoi.com.sg/#/",
      waSeparator: "Aromas of India",
    },
    site_title: "Aromas of India",
    site_title_with_separator: " | Aromas of India",
    home_meta_title: "Welcome to Aromas of India",
    bgimage: "/img/ninjaprocmn/aromas/body_bg.jpg",
  },
  "www.madnest.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "BFC17245-A2AB-40C6-9E3C-BB7030236DCF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "MADNEST",
    stripeImage:
      "https://www.madnest.co/img/ninjaprocmn/madnest/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "madnest",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.madnest.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.madnest.co/#/",
      fbImage: "https://www.madnest.co/img/madnest/logo.png",
      fbTitle: "MADNEST",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.madnest.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.madnest.co/#/",
      twTitile: "MADNEST",
      twvia: "MADNEST",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.madnest.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.madnest.co/#/",
      waSeparator: "MADNEST",
    },
    site_title: "MADNEST",
    forcewww: "YES",
    site_title_with_separator: " | MADNEST",
    home_meta_title: "Welcome to MADNEST",
    bgimage: "/img/ninjaprocmn/madnest/body_bg.jpg",
  },
  "www.lovefoodclubsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D00DFFC4-F616-4B59-9411-B0EFD359E632",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Love Food Club",
    stripeImage:
      "https://www.lovefoodclubsg.com/img/ninjaprocmn/lovefoodclub/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lovefoodclub",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.lovefoodclubsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.lovefoodclubsg.com/#/",
      fbImage: "https://www.lovefoodclubsg.com/img/lovefoodclub/logo.png",
      fbTitle: "Love Food Club",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.lovefoodclubsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.lovefoodclubsg.com/#/",
      twTitile: "Love Food Club",
      twvia: "Love Food Club",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.lovefoodclubsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.lovefoodclubsg.com/#/",
      waSeparator: "Love Food Club",
    },
    site_title: "Love Food Club",
    forcewww: "YES",
    site_title_with_separator: " | Love Food Club",
    home_meta_title: "Welcome to Love Food Club",
    bgimage: "/img/ninjaprocmn/lovefoodclub/body_bg.jpg",
  },
  "www.alphakrust.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "043CF8B4-9CFD-4A30-B49A-E21667FEAF52",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Alphakrust",
    stripeImage:
      "https://www.alphakrust.com/img/ninjaprocmn/alphakrust/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "alphakrust",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.alphakrust.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.alphakrust.com/#/",
      fbImage: "https://www.alphakrust.com/img/alphakrust/logo.png",
      fbTitle: "Alphakrust",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.alphakrust.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.alphakrust.com/#/",
      twTitile: "Alphakrust",
      twvia: "Alphakrust",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.alphakrust.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.alphakrust.com/#/",
      waSeparator: "Alphakrust",
    },
    site_title: "Alphakrust",
    forcewww: "YES",
    site_title_with_separator: " | Alphakrust",
    home_meta_title: "Best hot pizzas in Singapore | Alphakrust",
    home_meta_description:
      "Best affordable pizzas available in Singapore. At alphaKrust, every pizza is handcrafted with the freshest premium ingredients with no added MSG and preservatives.",
    home_meta_keywords: "pizzas, best pizzas, singapore pizza",
    bgimage: "/img/ninjaprocmn/alphakrust/body_bg.jpg",
  },
  "order.promonade.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "65829C8E-D5CE-40B9-A519-F91D10D639E4",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Promonade",
    stripeImage:
      "https://order.promonade.sg/img/ninjaprocmn/promonade/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "promonade",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.promonade.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.promonade.sg/#/",
      fbImage: "https://order.promonade.sg/img/promonade/logo.png",
      fbTitle: "Promonade",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.promonade.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.promonade.sg/#/",
      twTitile: "Promonade",
      twvia: "Promonade",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.promonade.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.promonade.sg/#/",
      waSeparator: "Promonade",
    },
    site_title: "Promonade",
    site_title_with_separator: " | Promonade",
    home_meta_title: "Welcome to Promonade",
    bgimage: "/img/ninjaprocmn/promonade/body_bg.jpg",
  },
  "order.houseofperanakan.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "932D4271-78D7-42C4-9BE7-F932748D2E2F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "House of Peranakan",
    stripeImage:
      "https://order.houseofperanakan.com.sg/img/ninjaprocmn/houseofperanakan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "houseofperanakan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.houseofperanakan.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.houseofperanakan.com.sg/#/",
      fbImage:
        "https://order.houseofperanakan.com.sg/img/houseofperanakan/logo.png",
      fbTitle: "House of Peranakan",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.houseofperanakan.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.houseofperanakan.com.sg/#/",
      twTitile: "House of Peranakan",
      twvia: "House of Peranakan",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.houseofperanakan.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.houseofperanakan.com.sg/#/",
      waSeparator: "House of Peranakan",
    },
    site_title: "House of Peranakan",
    site_title_with_separator: " | House of Peranakan",
    home_meta_title: "Welcome to House of Peranakan",
    bgimage: "/img/ninjaprocmn/houseofperanakan/body_bg.jpg",
  },
  "www.shiokt.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "52D42907-CB13-4213-A6C4-D851CC3A18F8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Shiokt",
    stripeImage:
      "https://www.shiokt.com/img/ninjaprocmn/shiokt/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "shiokt",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.shiokt.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.shiokt.com/#/",
      fbImage: "https://www.shiokt.com/img/shiokt/logo.png",
      fbTitle: "Shiokt",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.shiokt.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.shiokt.com/#/",
      twTitile: "Shiokt",
      twvia: "Shiokt",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.shiokt.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.shiokt.com/#/",
      waSeparator: "Shiokt",
    },
    site_title: "Shiokt",
    forcewww: "YES",
    site_title_with_separator: " | Shiokt",
    home_meta_title: "Welcome to Shiokt",
    bgimage: "/img/ninjaprocmn/shiokt/body_bg.jpg",
  },
  "order.tigerlilypatisserie.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "70AB707E-15B3-4DC5-986C-0BCD550CF304",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tigerlily Patisserie",
    stripeImage:
      "https://order.tigerlilypatisserie.com/img/ninjaprocmn/tigerlilypatisserie/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tigerlilypatisserie",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.tigerlilypatisserie.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.tigerlilypatisserie.com/#/",
      fbImage:
        "https://order.tigerlilypatisserie.com/img/tigerlilypatisserie/logo.png",
      fbTitle: "Tigerlily Patisserie",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.tigerlilypatisserie.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.tigerlilypatisserie.com/#/",
      twTitile: "Tigerlily Patisserie",
      twvia: "Tigerlily Patisserie",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.tigerlilypatisserie.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.tigerlilypatisserie.com/#/",
      waSeparator: "Tigerlily Patisserie",
    },
    site_title: "Tigerlily Patisserie",
    forcewww: "YES",
    site_title_with_separator: " | Tigerlily Patisserie",
    home_meta_title: "Tigerlily Patisserie",
    bgimage: "/img/ninjaprocmn/tigerlilypatisserie/body_bg.jpg",
  },
  "menu.straitschinese.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "06B88300-674B-47B4-B0A5-15C297B0C7E7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Straits Chinese",
    stripeImage:
      "https://menu.straitschinese.com.sg/img/ninjaprocmn/straitschinese/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "straitschinese",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://menu.straitschinese.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://menu.straitschinese.com.sg/#/",
      fbImage: "https://menu.straitschinese.com.sg/img/straitschinese/logo.png",
      fbTitle: "Straits Chinese",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://menu.straitschinese.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://menu.straitschinese.com.sg/#/",
      twTitile: "Straits Chinese",
      twvia: "Straits Chinese",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://menu.straitschinese.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://menu.straitschinese.com.sg/#/",
      waSeparator: "Straits Chinese",
    },
    site_title: "Straits Chinese",
    forcewww: "YES",
    site_title_with_separator: " | Straits Chinese",
    home_meta_title: "Straits Chinese",
    bgimage: "/img/ninjaprocmn/straitschinese/body_bg.jpg",
  },
  "www.changisatay.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "894313A0-4C95-4676-A4AF-05CA42B55080",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Changi Satay",
    stripeImage:
      "https://www.changisatay.com/img/ninjaprocmn/changisatay/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "changisatay",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.changisatay.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.changisatay.com/#/",
      fbImage: "https://www.changisatay.com/img/changisatay/logo.png",
      fbTitle: "Changi Satay",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.changisatay.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.changisatay.com/#/",
      twTitile: "Changi Satay",
      twvia: "Changi Satay",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.changisatay.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.changisatay.com/#/",
      waSeparator: "Changi Satay",
    },
    site_title: "Changi Satay",
    forcewww: "YES",
    site_title_with_separator: " | Changi Satay",
    home_meta_title: "Changi Satay",
    bgimage: "/img/ninjaprocmn/changisatay/body_bg.jpg",
  },
  "order.livingwholesome.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DD9A6F53-5A85-4ED4-9E92-E693EDC68C1B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Living Wholesome Vegetarian",
    stripeImage:
      "https://order.livingwholesome.sg/img/ninjaprocmn/livingwholesome/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "livingwholesome",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.livingwholesome.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.livingwholesome.sg/#/",
      fbImage: "https://order.livingwholesome.sg/img/livingwholesome/logo.png",
      fbTitle: "Living Wholesome Vegetarian",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.livingwholesome.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.livingwholesome.sg/#/",
      twTitile: "Living Wholesome Vegetarian",
      twvia: "Living Wholesome Vegetarian",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.livingwholesome.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.livingwholesome.sg/#/",
      waSeparator: "Living Wholesome Vegetarian",
    },
    site_title: "Living Wholesome Vegetarian",
    site_title_with_separator: " | Living Wholesome Vegetarian",
    home_meta_title: "Living Wholesome Vegetarian",
    bgimage: "/img/ninjaprocmn/livingwholesome/body_bg.jpg",
  },
  "www.emicakeskovan.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AF62C48A-20E5-4994-94A7-633B06275311",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Emicakes",
    stripeImage:
      "https://www.emicakeskovan.com/img/ninjaprocmn/emicakeskovan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "emicakeskovan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.emicakeskovan.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.emicakeskovan.com/#/",
      fbImage: "https://www.emicakeskovan.com/img/emicakeskovan/logo.png",
      fbTitle: "Emicakes",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.emicakeskovan.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.emicakeskovan.com/#/",
      twTitile: "Emicakes",
      twvia: "Emicakes",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.emicakeskovan.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.emicakeskovan.com/#/",
      waSeparator: "Emicakes",
    },
    site_title: "Emicakes",
    forcewww: "YES",
    site_title_with_separator: " | Emicakes",
    home_meta_title: "Emicakes",
    bgimage: "/img/ninjaprocmn/emicakeskovan/body_bg.jpg",
  },
  "orderonline.ayshacatering.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5F496164-AF56-4EB9-8C2E-61D7261B4585",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "AYSHA CATERING",
    stripeImage:
      "https://orderonline.ayshacatering.com/img/ninjaprocmn/ayshacatering/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ayshacatering",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orderonline.ayshacatering.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orderonline.ayshacatering.com/#/",
      fbImage:
        "https://orderonline.ayshacatering.com/img/ayshacatering/logo.png",
      fbTitle: "AYSHA CATERING",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orderonline.ayshacatering.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orderonline.ayshacatering.com/#/",
      twTitile: "AYSHA CATERING",
      twvia: "AYSHA CATERING",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://orderonline.ayshacatering.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://orderonline.ayshacatering.com/#/",
      waSeparator: "AYSHA CATERING",
    },
    site_title: "AYSHA CATERING",
    site_title_with_separator: " | AYSHA CATERING",
    home_meta_title: "AYSHA CATERING",
    bgimage: "/img/ninjaprocmn/ayshacatering/body_bg.jpg",
  },
  "www.santai.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F3004C88-701F-4FCD-83D2-F956DE3C8917",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "RM Santai",
    stripeImage: "https://www.santai.sg/img/ninjaprocmn/santai/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "santai",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.santai.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.santai.sg/#/",
      fbImage: "https://www.santai.sg/img/santai/logo.png",
      fbTitle: "RM Santai",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.santai.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.santai.sg/#/",
      twTitile: "RM Santai",
      twvia: "RM Santai",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.santai.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.santai.sg/#/",
      waSeparator: "RM Santai",
    },
    site_title: "RM Santai",
    forcewww: "YES",
    site_title_with_separator: " | RM Santai",
    home_meta_title: "RM Santai",
    bgimage: "/img/ninjaprocmn/santai/body_bg.jpg",
  },
  "www.deliciouslynostalgic.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A6F9CA1B-0EF8-4749-B569-AE3A9923A219",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Deliciously Nostalgic",
    stripeImage:
      "https://www.deliciouslynostalgic.com/img/ninjaprocmn/deliciouslynostalgic/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "deliciouslynostalgic",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.deliciouslynostalgic.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.deliciouslynostalgic.com/#/",
      fbImage:
        "https://www.deliciouslynostalgic.com/img/deliciouslynostalgic/logo.png",
      fbTitle: "Deliciously Nostalgic",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.deliciouslynostalgic.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.deliciouslynostalgic.com/#/",
      twTitile: "Deliciously Nostalgic",
      twvia: "Deliciously Nostalgic",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.deliciouslynostalgic.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.deliciouslynostalgic.com/#/",
      waSeparator: "Deliciously Nostalgic",
    },
    site_title: "Deliciously Nostalgic",
    forcewww: "YES",
    site_title_with_separator: " | Deliciously Nostalgic",
    home_meta_title: "Deliciously Nostalgic",
    bgimage: "/img/ninjaprocmn/deliciouslynostalgic/body_bg.jpg",
  },
  "www.firmanshah.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "202C9EE4-5ECE-4AAA-BC64-AEC066F17063",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Firkopi",
    stripeImage:
      "https://www.firmanshah.com/img/ninjaprocmn/firmanshah/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "firmanshah",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.firmanshah.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.firmanshah.com/#/",
      fbImage: "https://www.firmanshah.com/img/ninjaprocmn/firmanshah/logo.png",
      fbTitle: "Firkopi",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.firmanshah.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.firmanshah.com/#/",
      twTitile: "Firkopi",
      twvia: "Firkopi",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.firmanshah.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.firmanshah.com/#/",
      waSeparator: "Firkopi",
    },
    site_title: "Firkopi",
    forcewww: "YES",
    site_title_with_separator: " | Firkopi",
    home_meta_title: "Firkopi",
    bgimage: "/img/ninjaprocmn/firmanshah/body_bg.jpg",
  },
  "www.littlecakebites.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "CCBE56AE-91FC-48E4-BD38-676A43669BB7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Little Cake Bites",
    stripeImage:
      "https://www.littlecakebites.com/img/ninjaprocmn/littlecakebites/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "littlecakebites",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.littlecakebites.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.littlecakebites.com/#/",
      fbImage: "https://www.littlecakebites.com/img/littlecakebites/logo.png",
      fbTitle: "Little Cake Bites",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.littlecakebites.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.littlecakebites.com/#/",
      twTitile: "Little Cake Bites",
      twvia: "Little Cake Bites",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.littlecakebites.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.littlecakebites.com/#/",
      waSeparator: "Little Cake Bites",
    },
    site_title: "Little Cake Bites",
    forcewww: "YES",
    site_title_with_separator: " | Little Cake Bites",
    home_meta_title: "Little Cake Bites",
    bgimage: "/img/ninjaprocmn/littlecakebites/body_bg.jpg",
  },
  "order.takeshisan.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "48B66ACA-7490-4645-A003-74C8AEC20BFC",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Takeshi-San",
    stripeImage:
      "https://order.takeshisan.sg/img/ninjaprocmn/takeshisan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "takeshisan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.takeshisan.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.takeshisan.sg/#/",
      fbImage: "https://order.takeshisan.sg/img/takeshisan/logo.png",
      fbTitle: "Takeshi-San",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.takeshisan.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.takeshisan.sg/#/",
      twTitile: "Takeshi-San",
      twvia: "Takeshi-San",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.takeshisan.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.takeshisan.sg/#/",
      waSeparator: "Takeshi-San",
    },
    site_title: "Takeshi-San",
    site_title_with_separator: " | Takeshi-San",
    home_meta_title: "Takeshi-San",
    bgimage: "/img/ninjaprocmn/takeshisan/body_bg.jpg",
  },
  "www.kuyaskusinangpinoy.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C15DF7E1-5CE8-4B5F-B18D-5039DD2B5776",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kuyas Kusinang Pinoy",
    stripeImage:
      "https://www.kuyaskusinangpinoy.com.sg/img/ninjaprocmn/kuyaskusinang/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kuyaskusinang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kuyaskusinangpinoy.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kuyaskusinangpinoy.com.sg/#/",
      fbImage:
        "https://www.kuyaskusinangpinoy.com.sg/img/kuyaskusinang/logo.png",
      fbTitle: "Kuyas Kusinang Pinoy",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kuyaskusinangpinoy.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kuyaskusinangpinoy.com.sg/#/",
      twTitile: "Kuyas Kusinang Pinoy",
      twvia: "Kuyas Kusinang Pinoy",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.kuyaskusinangpinoy.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.kuyaskusinangpinoy.com.sg/#/",
      waSeparator: "Kuyas Kusinang Pinoy",
    },
    site_title: "Kuyas Kusinang Pinoy",
    forcewww: "YES",
    site_title_with_separator: " | Kuyas Kusinang Pinoy",
    home_meta_title: "Kuyas Kusinang Pinoy",
    bgimage: "/img/ninjaprocmn/kuyaskusinang/body_bg.jpg",
  },
  "www.gomartstop.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F4AA7F6B-4814-47B4-ABDB-FC7E9EDFF3B3",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Hao Guan Minimart",
    stripeImage:
      "https://www.gomartstop.com/img/ninjaprocmn/haoguan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "haoguan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.gomartstop.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.gomartstop.com/#/",
      fbImage: "https://www.gomartstop.com/img/haoguan/logo.png",
      fbTitle: "Hao Guan Minimart",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.gomartstop.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.gomartstop.com/#/",
      twTitile: "Hao Guan Minimart",
      twvia: "Hao Guan Minimart",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.gomartstop.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.gomartstop.com/#/",
      waSeparator: "Hao Guan Minimart",
    },
    site_title: "Hao Guan Minimart",
    forcewww: "YES",
    site_title_with_separator: " | Hao Guan Minimart",
    home_meta_title: "Hao Guan Minimart",
    bgimage: "/img/ninjaprocmn/haoguan/body_bg.jpg",
  },
  "www.familymartstop.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E44CC702-DC8A-44F0-A285-1F096D1AD817",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Family Grocermart",
    stripeImage:
      "https://www.familymartstop.com/img/ninjaprocmn/familygrocermart/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "familygrocermart",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.familymartstop.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.familymartstop.com/#/",
      fbImage: "https://www.familymartstop.com/img/familygrocermart/logo.png",
      fbTitle: "Family Grocermart",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.familymartstop.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.familymartstop.com/#/",
      twTitile: "Family Grocermart",
      twvia: "Family Grocermart",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.familymartstop.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.familymartstop.com/#/",
      waSeparator: "Family Grocermart",
    },
    site_title: "Hao Guan Minimart",
    forcewww: "YES",
    site_title_with_separator: " | Family Grocermart",
    home_meta_title: "Family Grocermart",
    bgimage: "/img/ninjaprocmn/familygrocermart/body_bg.jpg",
  },
  "www.bestmartstop.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A7064639-38AA-4A74-97E4-2EBB1FAF790D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Best Supermart",
    stripeImage:
      "https://www.bestmartstop.com/img/ninjaprocmn/bestsupermart/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bestsupermart",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.bestmartstop.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.bestmartstop.com/#/",
      fbImage: "https://www.bestmartstop.com/img/bestsupermart/logo.png",
      fbTitle: "Best Supermart",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.bestmartstop.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.bestmartstop.com/#/",
      twTitile: "Best Supermart",
      twvia: "Best Supermart",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.bestmartstop.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.bestmartstop.com/#/",
      waSeparator: "Best Supermart",
    },
    site_title: "Best Supermart",
    forcewww: "YES",
    site_title_with_separator: " | Best Supermart",
    home_meta_title: "Best Supermart",
    bgimage: "/img/ninjaprocmn/bestsupermart/body_bg.jpg",
  },
  "orderonline.westlake.com.sg - moved to enterprise": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "627EBD75-9DCE-48B2-B4C5-88E71A9B49C7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Westlake",
    stripeImage:
      "https://orderonline.westlake.com.sg/img/ninjaprocmn/westlake/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "westlake",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orderonline.westlake.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orderonline.westlake.com.sg/#/",
      fbImage: "https://orderonline.westlake.com.sg/img/westlake/logo.png",
      fbTitle: "Westlake",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orderonline.westlake.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orderonline.westlake.com.sg/#/",
      twTitile: "Westlake",
      twvia: "Westlake",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://orderonline.westlake.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://orderonline.westlake.com.sg/#/",
      waSeparator: "Westlake",
    },
    site_title: "Westlake",
    site_title_with_separator: " | Westlake",
    home_meta_title: "Westlake",
    bgimage: "/img/ninjaprocmn/westlake/body_bg.jpg",
  },
  "order.yoonstraditionalteochewkueh.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6FB06618-7D23-49B1-BD10-D12DB8488A1A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Yoons Holdings",
    stripeImage:
      "https://order.yoonstraditionalteochewkueh.com.sg/img/ninjaprocmn/yoons/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yoons",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.yoonstraditionalteochewkueh.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.yoonstraditionalteochewkueh.com.sg/#/",
      fbImage:
        "https://order.yoonstraditionalteochewkueh.com.sg/img/yoons/logo.png",
      fbTitle: "Yoons Holdings",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.yoonstraditionalteochewkueh.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.yoonstraditionalteochewkueh.com.sg/#/",
      twTitile: "Yoons Holdings",
      twvia: "Yoons Holdings",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.yoonstraditionalteochewkueh.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.yoonstraditionalteochewkueh.com.sg/#/",
      waSeparator: "Yoons Holdings",
    },
    site_title: "Yoons Holdings",
    site_title_with_separator: " | Yoons Holdings",
    home_meta_title: "Yoons Holdings",
    bgimage: "/img/ninjaprocmn/yoons/body_bg.jpg",
  },
  "order.misskefir.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "60B08C4E-56F3-4770-A535-898F7966BC5D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Miss Kefir",
    stripeImage:
      "https://order.misskefir.com/img/ninjaprocmn/misskefir/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "misskefir",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.misskefir.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.misskefir.com/#/",
      fbImage: "https://order.misskefir.com/img/misskefir/logo.png",
      fbTitle: "Miss Kefir",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.misskefir.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.misskefir.com/#/",
      twTitile: "Miss Kefir",
      twvia: "Miss Kefir",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.misskefir.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.misskefir.com/#/",
      waSeparator: "Miss Kefir",
    },
    site_title: "Miss Kefir",
    site_title_with_separator: " | Miss Kefir",
    home_meta_title: "Miss Kefir",
    bgimage: "/img/ninjaprocmn/misskefir/body_bg.jpg",
  },
  "www.bbburgers.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "FF30FE53-79E7-4F0C-AD27-4DF55EC95B99",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "BBBURGERS",
    stripeImage:
      "https://www.bbburgers.sg/img/ninjaprocmn/bbburgers/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bbburgers",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.misskefir.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.bbburgers.sg/#/",
      fbImage: "https://www.bbburgers.sg/img/bbburgers/logo.png",
      fbTitle: "BBBURGERS",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.bbburgers.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.bbburgers.sg/#/",
      twTitile: "BBBURGERS",
      twvia: "BBBURGERS",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://www.bbburgers.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://www.bbburgers.sg/#/",
      waSeparator: "BBBURGERS",
    },
    site_title: "BBBURGERS",
    forcewww: "YES",
    site_title_with_separator: " | BBBURGERS",
    home_meta_title: "BBBURGERS",
    bgimage: "/img/ninjaprocmn/bbburgers/body_bg.jpg",
  },
  "order.fortsanctuary.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D52B0F7C-912E-408E-90DB-B85E272447AD",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Fort Sanctuary",
    stripeImage:
      "https://order.fortsanctuary.com/img/ninjaprocmn/fortsanctuary/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fortsanctuary",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.misskefir.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.fortsanctuary.com/#/",
      fbImage: "https://order.fortsanctuary.com/img/fortsanctuary/logo.png",
      fbTitle: "Fort Sanctuary",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.fortsanctuary.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.fortsanctuary.com/#/",
      twTitile: "Fort Sanctuary",
      twvia: "Fort Sanctuary",
    },

    /* google Plus data   */
    gp: {
      gpURL: "http://order.fortsanctuary.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "http://order.fortsanctuary.com/#/",
      waSeparator: "Fort Sanctuary",
    },
    site_title: "Fort Sanctuary",
    site_title_with_separator: " | Fort Sanctuary",
    home_meta_title: "Fort Sanctuary",
    bgimage: "/img/ninjaprocmn/fortsanctuary/body_bg.jpg",
  },
  "order.wineconnection.com.sg-removed": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6023698C-B0E7-4F2F-9DEA-76E9686D8D76",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Wine Connection",
    stripeImage:
      "https://order.wineconnection.com.sg/img/ninjaprocmn/wineconnection/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "wineconnection",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.wineconnection.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.wineconnection.com.sg/#/",
      fbImage:
        "https://order.wineconnection.com.sg/img/wineconnection/logo.png",
      fbTitle: "Wine Connection",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.wineconnection.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.wineconnection.com.sg/#/",
      twTitile: "Wine Connection",
      twvia: "Wine Connection",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.wineconnection.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.wineconnection.com.sg/#/",
      waSeparator: "Wine Connection",
    },
    site_title: "Wine Connection",
    site_title_with_separator: " | Wine Connection",
    home_meta_title: "Wine Connection",
    bgimage: "/img/ninjaprocmn/wineconnection/body_bg.jpg",
  },
  "reservation.wineconnection.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "6FCDE66B-8022-421E-A41B-34AF16B3A645",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Wine Connection",
    stripeImage:
      "https://reservation.wineconnection.com.sg/img/ninjaprocmn/wineconnection/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "wineconnection",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://reservation.wineconnection.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://reservation.wineconnection.com.sg/#/",
      fbImage:
        "https://reservation.wineconnection.com.sg/img/wineconnection/logo.png",
      fbTitle: "Wine Connection",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://reservation.wineconnection.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://reservation.wineconnection.com.sg/#/",
      twTitile: "Wine Connection",
      twvia: "Wine Connection",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://reservation.wineconnection.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://reservation.wineconnection.com.sg/#/",
      waSeparator: "Wine Connection",
    },
    site_title: "Wine Connection",
    site_title_with_separator: " | Wine Connection",
    home_meta_title: "Wine Connection",
    bgimage: "/img/ninjaprocmn/wineconnection/body_bg.jpg",
  },
  "www.popiahoriginal.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "694F4A9E-B491-4D7D-AEC4-ABDF5E5FD821",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Popiah Original",
    stripeImage:
      "https://www.popiahoriginal.sg/img/ninjaprocmn/popiahoriginal/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "popiahoriginal",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.popiahoriginal.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.popiahoriginal.sg/#/",
      fbImage: "https://www.popiahoriginal.sg/img/popiahoriginal/logo.png",
      fbTitle: "Popiah Original",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.popiahoriginal.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.popiahoriginal.sg/#/",
      twTitile: "Popiah Original",
      twvia: "Popiah Original",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.popiahoriginal.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.popiahoriginal.sg/#/",
      waSeparator: "Popiah Original",
    },
    site_title: "Popiah Original",
    forcewww: "YES",
    site_title_with_separator: " | Popiah Original",
    home_meta_title: "Popiah Original",
    bgimage: "/img/ninjaprocmn/popiahoriginal/body_bg.jpg",
  },
  "order.backalley.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F34559CA-596A-4F2E-8547-17B2AEBB3326",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Back Alley Cafe",
    stripeImage:
      "https://order.backalley.com.sg/img/ninjaprocmn/backalley/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "backalley",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.backalley.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.backalley.com.sg/#/",
      fbImage: "https://order.backalley.com.sg/img/backalley/logo.png",
      fbTitle: "Back Alley Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.backalley.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.backalley.com.sg/#/",
      twTitile: "Back Alley Cafe",
      twvia: "Back Alley Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.backalley.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.backalley.com.sg/#/",
      waSeparator: "Back Alley Cafe",
    },
    site_title: "Back Alley Cafe",
    site_title_with_separator: " | Back Alley Cafe",
    home_meta_title: "Back Alley Cafe",
    bgimage: "/img/ninjaprocmn/backalley/body_bg.jpg",
  },
  "order.tehadventures.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "857D66FF-E308-4372-BF65-4C9F5654A675",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Teh Adventures",
    stripeImage:
      "https://order.tehadventures.com/img/ninjaprocmn/zook/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "zook",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.tehadventures.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.tehadventures.com/#/",
      fbImage: "https://order.tehadventures.com/img/zook/logo.png",
      fbTitle: "Teh Adventures",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.tehadventures.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.tehadventures.com/#/",
      twTitile: "Teh Adventures",
      twvia: "Teh Adventures",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.tehadventures.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.tehadventures.com/#/",
      waSeparator: "Teh Adventures",
    },
    site_title: "Teh Adventures",
    site_title_with_separator: " | Teh Adventures",
    home_meta_title: "Teh Adventures",
    bgimage: "/img/ninjaprocmn/zook/body_bg.jpg",
  },
  "order.17teas.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "431F97A9-6CA0-4316-BCD6-CAC5C5922A5A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "No.17 Tea",
    stripeImage:
      "https://order.17teas.com/img/ninjaprocmn/17tea/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "17tea",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.17teas.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.17teas.com/#/",
      fbImage: "https://order.17teas.com/img/17tea/logo.png",
      fbTitle: "No.17 Tea",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.17teas.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.17teas.com/#/",
      twTitile: "No.17 Tea",
      twvia: "No.17 Tea",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.17teas.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.17teas.com/#/",
      waSeparator: "No.17 Tea",
    },
    site_title: "No.17 Tea",
    site_title_with_separator: " | No.17 Tea",
    home_meta_title: "No.17 Tea",
    bgimage: "/img/ninjaprocmn/17tea/body_bg.jpg",
  },
  "order.kloudkitchen.sg moved to enterprise": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4D1C050A-29CD-4B79-8607-F05ADE966B2B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kloudkitchen",
    stripeImage:
      "https://order.kloudkitchen.sg/img/ninjaprocmn/kloudkitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kloudkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.kloudkitchen.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.kloudkitchen.sg/#/",
      fbImage: "https://order.kloudkitchen.sg/img/kloudkitchen/logo.png",
      fbTitle: "Kloudkitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.kloudkitchen.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.kloudkitchen.sg/#/",
      twTitile: "Kloudkitchen",
      twvia: "Kloudkitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.kloudkitchen.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.kloudkitchen.sg/#/",
      waSeparator: "Kloudkitchen",
    },
    site_title: "Kloudkitchen",
    site_title_with_separator: " | Kloudkitchen",
    home_meta_title: "Kloudkitchen",
    bgimage: "/img/ninjaprocmn/kloudkitchen/body_bg.jpg",
  },
  "www.ajmirbriyani.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D4870FF9-134C-4A80-9C97-323CA6C6A4F7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ajmir Briyani",
    stripeImage:
      "https://www.ajmirbriyani.com/img/ninjaprocmn/ajmirbriyani/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ajmirbriyani",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ajmirbriyani.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ajmirbriyani.com/#/",
      fbImage: "https://www.ajmirbriyani.com/img/ajmirbriyani/logo.png",
      fbTitle: "Ajmir Briyani",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ajmirbriyani.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ajmirbriyani.com/#/",
      twTitile: "Ajmir Briyani",
      twvia: "Ajmir Briyani",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.ajmirbriyani.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.ajmirbriyani.com/#/",
      waSeparator: "Ajmir Briyani",
    },
    site_title: "Ajmir Briyani",
    site_title_with_separator: " | Ajmir Briyani",
    home_meta_title: "Ajmir Briyani",
    bgimage: "/img/ninjaprocmn/ajmirbriyani/body_bg.jpg",
  },
  "www.taliwang.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "61AED792-381F-4AEC-B37E-F573A06CB9BF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Nasi Lemak Ayam Taliwang",
    stripeImage:
      "https://www.taliwang.sg/img/ninjaprocmn/ayamtaliwang-admin/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ayamtaliwang-admin",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.taliwang.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.taliwang.sg/#/",
      fbImage: "https://www.taliwang.sg/img/ayamtaliwang-admin/logo.png",
      fbTitle: "Nasi Lemak Ayam Taliwang",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.taliwang.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.taliwang.sg/#/",
      twTitile: "Nasi Lemak Ayam Taliwang",
      twvia: "Nasi Lemak Ayam Taliwang",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.taliwang.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.taliwang.sg/#/",
      waSeparator: "Nasi Lemak Ayam Taliwang",
    },
    site_title: "Nasi Lemak Ayam Taliwang",
    forcewww: "YES",
    site_title_with_separator: " | Nasi Lemak Ayam Taliwang",
    home_meta_title: "Nasi Lemak Ayam Taliwang",
    bgimage: "/img/ninjaprocmn/ayamtaliwang-admin/body_bg.jpg",
  },
  "www.mummathaikitchen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "31D15779-1796-40C1-80CA-D1A0CF1B62D7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mummas Thai Kitchen",
    stripeImage:
      "https://www.mummathaikitchen.com/img/ninjaprocmn/mummasthaikitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mummasthaikitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.mummathaikitchen.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.mummathaikitchen.com/#/",
      fbImage:
        "https://www.mummathaikitchen.com/img/mummasthaikitchen/logo.png",
      fbTitle: "Mummas Thai Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.mummathaikitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.mummathaikitchen.com/#/",
      twTitile: "Mummas Thai Kitchen",
      twvia: "Mummas Thai Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.mummathaikitchen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.mummathaikitchen.com/#/",
      waSeparator: "Mummas Thai Kitchen",
    },
    site_title: "Mummas Thai Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Mummas Thai Kitchen",
    home_meta_title: "Mummas Thai Kitchen",
    bgimage: "/img/ninjaprocmn/mummasthaikitchen/body_bg.jpg",
  },
  "www.meitoythaifood.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9A87DB60-6E73-4E33-B2BC-1DD74367504F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mei Toy Thai Food",
    stripeImage:
      "https://www.meitoythaifood.com/img/ninjaprocmn/meitoythaifood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "meitoythaifood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.meitoythaifood.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.meitoythaifood.com/#/",
      fbImage: "https://www.meitoythaifood.com/img/meitoythaifood/logo.png",
      fbTitle: "Mei Toy Thai Food",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.meitoythaifood.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.meitoythaifood.com/#/",
      twTitile: "Mei Toy Thai Food",
      twvia: "Mei Toy Thai Food",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.meitoythaifood.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.meitoythaifood.com/#/",
      waSeparator: "Mei Toy Thai Food",
    },
    site_title: "Mei Toy Thai Food",
    forcewww: "YES",
    site_title_with_separator: " | Mei Toy Thai Food",
    home_meta_title: "Mei Toy Thai Food",
    bgimage: "/img/ninjaprocmn/meitoythaifood/body_bg.jpg",
  },
  "www.ohsomebowls.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "025D9F2B-0D16-479C-B47D-1445F68E54FD",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "OH SOME BOWLS",
    stripeImage:
      "https://www.ohsomebowls.com/img/ninjaprocmn/ohsomebowls/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ohsomebowls",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ohsomebowls.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ohsomebowls.com/#/",
      fbImage: "https://www.ohsomebowls.com/img/ohsomebowls/logo.png",
      fbTitle: "OH SOME BOWLS",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ohsomebowls.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ohsomebowls.com/#/",
      twTitile: "OH SOME BOWLS",
      twvia: "OH SOME BOWLS",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.ohsomebowls.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.ohsomebowls.com/#/",
      waSeparator: "OH SOME BOWLS",
    },
    site_title: "OH SOME BOWLS",
    forcewww: "YES",
    site_title_with_separator: " | OH SOME BOWLS",
    home_meta_title: "OH SOME BOWLS",
    bgimage: "/img/ninjaprocmn/ohsomebowls/body_bg.jpg",
  },
  "order.qbreadtampines.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2F182BAF-41EC-45FC-AF6F-1E5A159FBB10",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Q Bread Tampines",
    stripeImage:
      "https://order.qbreadtampines.com/img/ninjaprocmn/qbreadtampines/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "qbreadtampines",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.qbreadtampines.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.qbreadtampines.com/#/",
      fbImage: "https://order.qbreadtampines.com/img/qbreadtampines/logo.png",
      fbTitle: "Q Bread Tampines",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.qbreadtampines.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.qbreadtampines.com/#/",
      twTitile: "Q Bread Tampines",
      twvia: "Q Bread Tampines",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.qbreadtampines.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.qbreadtampines.com/#/",
      waSeparator: "Q Bread Tampines",
    },
    site_title: "Q Bread Tampines",
    forcewww: "YES",
    site_title_with_separator: " Q Bread Tampines",
    home_meta_title: "Q Bread Tampines",
    bgimage: "/img/ninjaprocmn/qbreadtampines/body_bg.jpg",
  },
  "order.tooklaedee.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "EC060123-6053-4635-8784-6BD72118F2AF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Took Lae Dee",
    stripeImage:
      "https://order.tooklaedee.com.sg/img/ninjaprocmn/tooklaedee/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tooklaedee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.tooklaedee.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.tooklaedee.com.sg/#/",
      fbImage: "https://order.tooklaedee.com.sg/img/tooklaedee/logo.png",
      fbTitle: "Took Lae Dee",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.tooklaedee.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.tooklaedee.com.sg/#/",
      twTitile: "Took Lae Dee",
      twvia: "Took Lae Dee",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.tooklaedee.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.tooklaedee.com.sg/#/",
      waSeparator: "Took Lae Dee",
    },
    site_title: "Took Lae Dee",
    forcewww: "YES",
    site_title_with_separator: "Took Lae Dee",
    home_meta_title: "Took Lae Dee",
    bgimage: "/img/ninjaprocmn/tooklaedee/body_bg.jpg",
  },
  "order.greenkitchen.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "2F49E72A-D007-4458-B525-D80CC65641FE",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Green Kitchen",
    stripeImage:
      "https://order.greenkitchen.co/img/ninjaprocmn/greenkitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "greenkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.greenkitchen.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.greenkitchen.co/#/",
      fbImage: "https://order.greenkitchen.co/img/greenkitchen/logo.png",
      fbTitle: "Took Lae Dee",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.greenkitchen.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.greenkitchen.co/#/",
      twTitile: "Green Kitchen",
      twvia: "Green Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.greenkitchen.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.greenkitchen.co/#/",
      waSeparator: "Green Kitchen",
    },
    site_title: "Green Kitchen",
    site_title_with_separator: "Green Kitchen",
    home_meta_title: "Green Kitchen",
    bgimage: "/img/ninjaprocmn/greenkitchen/body_bg.jpg",
  },
  "www.annkalsri.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C5D95E86-0D1A-46DC-BDEB-9D14F2E04F1F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "ANN & KALSRI",
    stripeImage:
      "https://www.annkalsri.com/img/ninjaprocmn/annkalsri/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "annkalsri",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.annkalsri.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.annkalsri.com/#/",
      fbImage: "https://www.annkalsri.com/img/annkalsri/logo.png",
      fbTitle: "ANN & KALSRI",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.annkalsri.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.annkalsri.com/#/",
      twTitile: "ANN & KALSRI",
      twvia: "ANN & KALSRI",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.annkalsri.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.annkalsri.com/#/",
      waSeparator: "ANN & KALSRI",
    },
    site_title: "ANN & KALSRI",
    forcewww: "YES",
    site_title_with_separator: "ANN & KALSRI",
    home_meta_title: "ANN & KALSRI",
    bgimage: "/img/ninjaprocmn/annkalsri/body_bg.jpg",
  },
  "www.dapuryummy.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "8F658C8A-FF24-4DB9-BC84-D868698FBCD5",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Dapur Yummy",
    stripeImage:
      "https://www.dapuryummy.com/img/ninjaprocmn/dapuryummy/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "dapuryummy",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.dapuryummy.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.dapuryummy.com/#/",
      fbImage: "https://www.dapuryummy.com/img/dapuryummy/logo.png",
      fbTitle: "Dapur Yummy",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.dapuryummy.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.dapuryummy.com/#/",
      twTitile: "Dapur Yummy",
      twvia: "Dapur Yummy",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.dapuryummy.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.dapuryummy.com/#/",
      waSeparator: "Dapur Yummy",
    },
    site_title: "Dapur Yummy",
    forcewww: "YES",
    site_title_with_separator: "Dapur Yummy",
    home_meta_title: "Dapur Yummy",
    bgimage: "/img/ninjaprocmn/dapuryummy/body_bg.jpg",
  },
  "www.thegoodfatsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "7849A48A-EA9D-48F2-8202-9E44322AFBCE",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Good Fat",
    stripeImage:
      "https://www.thegoodfatsg.com/img/ninjaprocmn/thegoodfatsg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thegoodfatsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.thegoodfatsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.thegoodfatsg.com/#/",
      fbImage: "https://www.thegoodfatsg.com/img/thegoodfatsg/logo.png",
      fbTitle: "The Good Fat",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.thegoodfatsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.thegoodfatsg.com/#/",
      twTitile: "The Good Fat",
      twvia: "The Good Fat",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.thegoodfatsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.thegoodfatsg.com/#/",
      waSeparator: "The Good Fat",
    },
    site_title: "The Good Fat",
    forcewww: "YES",
    site_title_with_separator: "The Good Fat",
    home_meta_title: "The Good Fat",
    bgimage: "/img/ninjaprocmn/thegoodfatsg/body_bg.jpg",
  },
  "order.bedokbakchormee.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "AAEE8C75-72DD-43A5-B11C-AD906CCBB3AC",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Bedok Bak Chor Mee",
    stripeImage:
      "https://order.bedokbakchormee.com/img/ninjaprocmn/bedokbak/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bedokbak",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.bedokbakchormee.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.bedokbakchormee.com/#/",
      fbImage: "https://order.bedokbakchormee.com/img/bedokbak/logo.png",
      fbTitle: "Bedok Bak Chor Mee",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.bedokbakchormee.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.bedokbakchormee.com/#/",
      twTitile: "Bedok Bak Chor Mee",
      twvia: "Bedok Bak Chor Mee",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.bedokbakchormee.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.bedokbakchormee.com/#/",
      waSeparator: "Bedok Bak Chor Mee",
    },
    site_title: "Bedok Bak Chor Mee",
    forcewww: "YES",
    site_title_with_separator: "Bedok Bak Chor Mee",
    home_meta_title: "Bedok Bak Chor Mee",
    bgimage: "/img/ninjaprocmn/bedokbak/body_bg.jpg",
  },
  "order.thegogijip.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3361972F-C12A-483A-8F64-DA92E4C5E2CE",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Gogijip",
    stripeImage:
      "https://order.thegogijip.com/img/ninjaprocmn/gogijip/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gogijip",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.thegogijip.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.thegogijip.com/#/",
      fbImage: "https://order.thegogijip.com/img/gogijip/logo.png",
      fbTitle: "The Gogijip",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.thegogijip.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.thegogijip.com/#/",
      twTitile: "The Gogijip",
      twvia: "The Gogijip",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.thegogijip.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.thegogijip.com/#/",
      waSeparator: "The Gogijip",
    },
    site_title: "The Gogijip",
    forcewww: "YES",
    site_title_with_separator: "The Gogijip",
    home_meta_title: "The Gogijip",
    bgimage: "/img/ninjaprocmn/gogijip/body_bg.jpg",
  },
  "order.lavendernasilemak.sg-removed": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "7F424A7C-7225-4809-87AB-E787C96D9B89",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Lavender Nasi Lemak",
    stripeImage:
      "https://order.lavendernasilemak.sg/img/ninjaprocmn/lavendernasilemak/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lavendernasilemak",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.lavendernasilemak.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.lavendernasilemak.sg/#/",
      fbImage:
        "https://order.lavendernasilemak.sg/img/lavendernasilemak/logo.png",
      fbTitle: "Lavender Nasi Lemak",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.lavendernasilemak.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.lavendernasilemak.sg/#/",
      twTitile: "Lavender Nasi Lemak",
      twvia: "Lavender Nasi Lemak",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.lavendernasilemak.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.lavendernasilemak.sg/#/",
      waSeparator: "Lavender Nasi Lemak",
    },
    site_title: "Lavender Nasi Lemak",
    site_title_with_separator: "Lavender Nasi Lemak",
    home_meta_title: "Lavender Nasi Lemak",
    bgimage: "/img/ninjaprocmn/lavendernasilemak/body_bg.jpg",
  },
  "order.gerainenekobek.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3B806592-1677-4219-96CD-893382DD7281",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Gerai Nenek Obek",
    stripeImage:
      "https://order.gerainenekobek.com/img/ninjaprocmn/gerainenekobek/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gerainenekobek",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.gerainenekobek.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.gerainenekobek.com/#/",
      fbImage: "https://order.gerainenekobek.com/img/gerainenekobek/logo.png",
      fbTitle: "Gerai Nenek Obek",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.gerainenekobek.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.gerainenekobek.com/#/",
      twTitile: "Gerai Nenek Obek",
      twvia: "Gerai Nenek Obek",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.gerainenekobek.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.gerainenekobek.com/#/",
      waSeparator: "Gerai Nenek Obek",
    },
    site_title: "Gerai Nenek Obek",
    site_title_with_separator: "Gerai Nenek Obek",
    home_meta_title: "Gerai Nenek Obek",
    bgimage: "/img/ninjaprocmn/gerainenekobek/body_bg.jpg",
  },
  "order.sengkangoysteromelette.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "67C3C333-7F55-4296-B56D-9E53940B7F4E",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sengkang Oyster Omelette",
    stripeImage:
      "https://order.sengkangoysteromelette.com/img/ninjaprocmn/sengkangoysteromelette/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sengkangoysteromelette",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.sengkangoysteromelette.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.sengkangoysteromelette.com/#/",
      fbImage:
        "https://order.sengkangoysteromelette.com/img/sengkangoysteromelette/logo.png",
      fbTitle: "Sengkang Oyster Omelette",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.sengkangoysteromelette.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.sengkangoysteromelette.com/#/",
      twTitile: "Sengkang Oyster Omelette",
      twvia: "Sengkang Oyster Omelette",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.sengkangoysteromelette.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.sengkangoysteromelette.com/#/",
      waSeparator: "Sengkang Oyster Omelette",
    },
    site_title: "Sengkang Oyster Omelette",
    site_title_with_separator: "Sengkang Oyster Omelette",
    home_meta_title: "Sengkang Oyster Omelette",
    bgimage: "/img/ninjaprocmn/sengkangoysteromelette/body_bg.jpg",
  },
  "www.sweeteacaffe.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A1DAF62B-6E3B-4509-98F0-A0BA7BE216BC",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sweetea Caffe",
    stripeImage:
      "https://www.sweeteacaffe.com/img/ninjaprocmn/sweeteacaffe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sweeteacaffe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.sweeteacaffe.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.sweeteacaffe.com/#/",
      fbImage: "https://www.sweeteacaffe.com/img/sweeteacaffe/logo.png",
      fbTitle: "Sweetea Caffe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.sweeteacaffe.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.sweeteacaffe.com/#/",
      twTitile: "Sweetea Caffe",
      twvia: "Sweetea Caffe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.sweeteacaffe.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.sweeteacaffe.com/#/",
      waSeparator: "Sweetea Caffe",
    },
    site_title: "Sweetea Caffe",
    forcewww: "YES",
    site_title_with_separator: "Sweetea Caffe",
    home_meta_title: "Sweetea Caffe",
    bgimage: "/img/ninjaprocmn/sweeteacaffe/body_bg.jpg",
  },
  "www.happywokie.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D0014A91-28CA-4C39-90E6-A3CD30FC465B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Happy Woki",
    stripeImage:
      "https://www.happywokie.com/img/ninjaprocmn/happywokie/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "happywokie",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.happywokie.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.happywokie.com/#/",
      fbImage: "https://www.happywokie.com/img/happywokie/logo.png",
      fbTitle: "Happy Woki",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.happywokie.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.happywokie.com/#/",
      twTitile: "Happy Woki",
      twvia: "Happy Woki",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.happywokie.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.happywokie.com/#/",
      waSeparator: "Happy Woki",
    },
    site_title: "Happy Woki",
    forcewww: "YES",
    site_title_with_separator: "Happy Woki",
    home_meta_title: "Happy Woki",
    bgimage: "/img/ninjaprocmn/happywokie/body_bg.jpg",
  },
  "order.dstarbistro.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F5B81837-70AF-49C3-BFB0-A21BF5324745",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "DStar Bistro",
    stripeImage:
      "https://order.dstarbistro.com.sg/img/ninjaprocmn/dstarbistro/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "dstarbistro",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.dstarbistro.com.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.dstarbistro.com.sg/#/",
      fbImage: "https://order.dstarbistro.com.sg/img/happywokie/logo.png",
      fbTitle: "DStar Bistro",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.dstarbistro.com.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.dstarbistro.com.sg/#/",
      twTitile: "DStar Bistro",
      twvia: "DStar Bistro",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.dstarbistro.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.dstarbistro.com.sg/#/",
      waSeparator: "DStar Bistro",
    },
    site_title: "DStar Bistro",
    site_title_with_separator: "DStar Bistro",
    home_meta_title: "DStar Bistro",
    bgimage: "/img/ninjaprocmn/dstarbistro/body_bg.jpg",
  },
  "order.ddesservir.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "81E217F5-F599-4721-A228-D4B280C8EE1B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ddesservir",
    stripeImage:
      "https://order.ddesservir.com/img/ninjaprocmn/ddesservir/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ddesservir",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ddesservir.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ddesservir.com/#/",
      fbImage: "https://order.ddesservir.com/img/ddesservir/logo.png",
      fbTitle: "Ddesservir",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ddesservir.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ddesservir.com/#/",
      twTitile: "Ddesservir",
      twvia: "Ddesservir",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ddesservir.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ddesservir.com/#/",
      waSeparator: "Ddesservir",
    },
    site_title: "Ddesservir",
    site_title_with_separator: "Ddesservir",
    home_meta_title: "Ddesservir",
    bgimage: "/img/ninjaprocmn/ddesservir/body_bg.jpg",
  },
  "www.happywokie.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D0014A91-28CA-4C39-90E6-A3CD30FC465B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Happy Woki",
    stripeImage:
      "https://www.happywokie.com/img/ninjaprocmn/happywokie/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "happywokie",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.happywokie.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.happywokie.com/#/",
      fbImage: "https://www.happywokie.com/img/happywokie/logo.png",
      fbTitle: "Happy Woki",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.happywokie.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.happywokie.com/#/",
      twTitile: "Happy Woki",
      twvia: "Happy Woki",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.happywokie.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.happywokie.com/#/",
      waSeparator: "Happy Woki",
    },
    site_title: "Happy Woki",
    forcewww: "YES",
    site_title_with_separator: "Happy Woki",
    home_meta_title: "Happy Woki",
    bgimage: "/img/ninjaprocmn/happywokie/body_bg.jpg",
  },
  "order.multifoodsupply.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E77ADD5F-2C13-47E5-940D-29DE0FB0444C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Multi Food",
    stripeImage:
      "https://order.multifoodsupply.com/img/ninjaprocmn/multifood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "multifood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.multifoodsupply.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.multifoodsupply.com/#/",
      fbImage: "https://order.multifoodsupply.com/img/multifood/logo.png",
      fbTitle: "Multi Food",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.multifoodsupply.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.multifoodsupply.com/#/",
      twTitile: "Multi Food",
      twvia: "Multi Food",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.multifoodsupply.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.multifoodsupply.com/#/",
      waSeparator: "Multi Food",
    },
    site_title: "Multi Food",
    forcewww: "NO",
    site_title_with_separator: "Multi Food",
    home_meta_title: "Multi Food",
    bgimage: "/img/ninjaprocmn/multifood/body_bg.jpg",
  },
  "www.totogourmet.net": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "21C5C6BE-B8C4-4073-A7CA-78833B6D1680",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "TOTO Gourmet",
    stripeImage:
      "https://www.totogourmet.net/img/ninjaprocmn/totogourmet/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "totogourmet",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.totogourmet.net/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.totogourmet.net/#/",
      fbImage: "https://www.totogourmet.net/img/totogourmet/logo.png",
      fbTitle: "TOTO Gourmet",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.totogourmet.net/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.totogourmet.net/#/",
      twTitile: "TOTO Gourmet",
      twvia: "TOTO Gourmet",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.totogourmet.net/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.totogourmet.net/#/",
      waSeparator: "TOTO Gourmet",
    },
    site_title: "TOTO Gourmet",
    forcewww: "YES",
    site_title_with_separator: "TOTO Gourmet",
    home_meta_title: "TOTO Gourmet",
    bgimage: "/img/ninjaprocmn/totogourmet/body_bg.jpg",
  },
  "order.snowangelsdesserthouse.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "73BF7668-B7E1-4677-ACEA-FE9C70D8D249",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Snow Angels Dessert House",
    stripeImage:
      "https://order.snowangelsdesserthouse.sg/img/ninjaprocmn/snowangels/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "snowangels",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.totogourmet.net/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.snowangelsdesserthouse.sg/#/",
      fbImage:
        "https://order.snowangelsdesserthouse.sg/img/snowangels/logo.png",
      fbTitle: "Snow Angels Dessert House",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.snowangelsdesserthouse.sg",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.snowangelsdesserthouse.sg/#/",
      twTitile: "Snow Angels Dessert House",
      twvia: "Snow Angels Dessert House",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.snowangelsdesserthouse.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.snowangelsdesserthouse.sg/#/",
      waSeparator: "Snow Angels Dessert House",
    },
    site_title: "Snow Angels Dessert House",
    site_title_with_separator: "Snow Angels Dessert House",
    home_meta_title: "Snow Angels Dessert House",
    bgimage: "/img/ninjaprocmn/snowangels/body_bg.jpg",
  },
  "www.asamgaram29.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "EA68D170-74CE-4305-AABE-335368C8D45E",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Asam Garam",
    stripeImage:
      "https://www.asamgaram29.com/img/ninjaprocmn/asamgaram/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "asamgaram",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.asamgaram29.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.asamgaram29.com/#/",
      fbImage: "https://www.asamgaram29.com/img/asamgaram/logo.png",
      fbTitle: "Asam Garam",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.asamgaram29.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.asamgaram29.com/#/",
      twTitile: "Asam Garam",
      twvia: "Asam Garam",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.asamgaram29.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.asamgaram29.com/#/",
      waSeparator: "Asam Garam",
    },
    site_title: "Asam Garam",
    forcewww: "YES",
    site_title_with_separator: "Asam Garam",
    home_meta_title: "Asam Garam",
    bgimage: "/img/ninjaprocmn/asamgaram/body_bg.jpg",
  },
  "www.curryclubsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5C28CCD6-6A1F-44AD-BE9A-0765B906BDB8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Curry Club",
    stripeImage:
      "https://www.curryclubsg.com/img/ninjaprocmn/curryclub/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "curryclub",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.curryclubsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.curryclubsg.com/#/",
      fbImage: "https://www.curryclubsg.com/img/curryclub/logo.png",
      fbTitle: "The Curry Club",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.curryclubsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.curryclubsg.com/#/",
      twTitile: "The Curry Club",
      twvia: "The Curry Club",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.curryclubsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.curryclubsg.com/#/",
      waSeparator: "The Curry Club",
    },
    site_title: "The Curry Club",
    forcewww: "YES",
    site_title_with_separator: " | The Curry Club",
    home_meta_title: "Welcome to The Curry Club",
    bgimage: "/img/ninjaprocmn/curryclub/body_bg.jpg",
  },
  "www.origanicssg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F57B2471-7A4A-4B43-B870-A7BE17843FEF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "VEGZ DELIGHTS",
    stripeImage:
      "https://www.origanicssg.com/img/ninjaprocmn/origanics/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "origanics",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.origanicssg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.origanicssg.com/#/",
      fbImage: "https://www.origanicssg.com/img/origanics/logo.png",
      fbTitle: "VEGZ DELIGHTS",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.origanicssg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.origanicssg.com/#/",
      twTitile: "VEGZ DELIGHTS",
      twvia: "VEGZ DELIGHTS",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.origanicssg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.origanicssg.com/#/",
      waSeparator: "VEGZ DELIGHTS",
    },
    site_title: "VEGZ DELIGHTS",
    forcewww: "YES",
    site_title_with_separator: " | VEGZ DELIGHTS",
    home_meta_title: "Welcome to VEGZ DELIGHTS",
    bgimage: "/img/ninjaprocmn/origanics/body_bg.jpg",
  },
  "www.madraswoodlandsganga.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5D4FB593-06E2-4F9F-832D-E8F5A93F0FB7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Madras Woodlands Ganga",
    stripeImage:
      "https://www.madraswoodlandsganga.com/img/ninjaprocmn/madraswoodlandsganga/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "madraswoodlandsganga",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.madraswoodlandsganga.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.madraswoodlandsganga.com/#/",
      fbImage:
        "https://www.madraswoodlandsganga.com/img/madraswoodlandsganga/logo.png",
      fbTitle: "Madras Woodlands Ganga",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.madraswoodlandsganga.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.madraswoodlandsganga.com/#/",
      twTitile: "Madras Woodlands Ganga",
      twvia: "Madras Woodlands Ganga",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.madraswoodlandsganga.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.madraswoodlandsganga.com/#/",
      waSeparator: "Madras Woodlands Ganga",
    },
    site_title: "Madras Woodlands Ganga",
    forcewww: "YES",
    site_title_with_separator: " | Madras Woodlands Ganga",
    home_meta_title: "Welcome to Madras Woodlands Ganga",
    bgimage: "/img/ninjaprocmn/madraswoodlandsganga/body_bg.jpg",
  },
  "www.chiru2u.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "775ADF2B-1EC9-4615-8D95-63EBEC7CA287",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Chiru",
    stripeImage:
      "https://www.chiru2u.com/img/ninjaprocmn/chiru2u/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "chiru2u",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.chiru2u.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.chiru2u.com/#/",
      fbImage: "https://www.chiru2u.com/img/chiru2u/logo.png",
      fbTitle: "Chiru",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.chiru2u.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.chiru2u.com/#/",
      twTitile: "Chiru",
      twvia: "Chiru",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.chiru2u.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.chiru2u.com/#/",
      waSeparator: "Chiru",
    },
    site_title: "Chiru",
    forcewww: "YES",
    site_title_with_separator: " | Chiru",
    home_meta_title: "Welcome to Chiru",
    bgimage: "/img/ninjaprocmn/chiru2u/body_bg.jpg",
  },
  "www.fiscatch.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5C83FFC3-8AE1-4936-8240-F1EDD2C179F0",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "FISCatch Seafood",
    stripeImage:
      "https://www.fiscatch.com/img/ninjaprocmn/chiru2u/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fiscatch",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.fiscatch.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.fiscatch.com/#/",
      fbImage: "https://www.fiscatch.com/img/fiscatch/logo.png",
      fbTitle: "FISCatch Seafood",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.fiscatch.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.fiscatch.com/#/",
      twTitile: "FISCatch Seafood",
      twvia: "FISCatch Seafood",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.fiscatch.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.fiscatch.com/#/",
      waSeparator: "FISCatch Seafood",
    },
    site_title: "FISCatch Seafood",
    forcewww: "YES",
    site_title_with_separator: " | FISCatch Seafood",
    home_meta_title: "Welcome to FISCatch Seafood",
    bgimage: "/img/ninjaprocmn/fiscatch/body_bg.jpg",
  },
  "www.sunsetrailwaycafe.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E63C6A59-C17B-4B00-B257-6B5B3B80BE69",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "SUNSET RAILWAY CAFE",
    stripeImage:
      "https://www.sunsetrailwaycafe.com/img/ninjaprocmn/sunsetrailwaycafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sunsetrailwaycafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.sunsetrailwaycafe.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.sunsetrailwaycafe.com/#/",
      fbImage:
        "https://www.sunsetrailwaycafe.com/img/sunsetrailwaycafe/logo.png",
      fbTitle: "SUNSET RAILWAY CAFE",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.sunsetrailwaycafe.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.sunsetrailwaycafe.com/#/",
      twTitile: "SUNSET RAILWAY CAFE",
      twvia: "SUNSET RAILWAY CAFE",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.sunsetrailwaycafe.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.sunsetrailwaycafe.com/#/",
      waSeparator: "SUNSET RAILWAY CAFE",
    },
    site_title: "SUNSET RAILWAY CAFE",
    forcewww: "YES",
    site_title_with_separator: " | SUNSET RAILWAY CAFE",
    home_meta_title: "Welcome to SUNSET RAILWAY CAFE",
    bgimage: "/img/ninjaprocmn/sunsetrailwaycafe/body_bg.jpg",
  },
  "order.houseofthaal.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "41200A37-9022-4E14-89DB-F86B31AD7E53",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "House of Thaal",
    stripeImage:
      "https://order.houseofthaal.com/img/ninjaprocmn/houseofthaal/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "houseofthaal",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.houseofthaal.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.houseofthaal.com/#/",
      fbImage: "https://order.houseofthaal.com/img/houseofthaal/logo.png",
      fbTitle: "House of Thaal",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.houseofthaal.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.houseofthaal.com/#/",
      twTitile: "House of Thaal",
      twvia: "House of Thaal",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.houseofthaal.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.houseofthaal.com/#/",
      waSeparator: "House of Thaal",
    },
    site_title: "House of Thaal",
    site_title_with_separator: " | House of Thaal",
    home_meta_title: "Welcome to House of Thaal",
    bgimage: "/img/ninjaprocmn/houseofthaal/body_bg.jpg",
  },
  "order.yummynonya.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "36AFC00D-603D-4521-9F4E-70C8C408A7B9",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Yummy Nonya Peranakanl",
    stripeImage:
      "https://order.yummynonya.com/img/ninjaprocmn/yummynonya/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yummynonya",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.yummynonya.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.yummynonya.com/#/",
      fbImage: "https://order.yummynonya.com/img/yummynonya/logo.png",
      fbTitle: "Yummy Nonya Peranakan",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.yummynonya.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.yummynonya.com/#/",
      twTitile: "Yummy Nonya Peranakan",
      twvia: "Yummy Nonya Peranakan",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.yummynonya.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.yummynonya.com/#/",
      waSeparator: "Yummy Nonya Peranakan",
    },
    site_title: "Yummy Nonya Peranakan",
    site_title_with_separator: " | Yummy Nonya Peranakan",
    home_meta_title: "Welcome to Yummy Nonya Peranakan",
    bgimage: "/img/ninjaprocmn/yummynonya/body_bg.jpg",
  },
  "order.zhongxincafe.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D492C0C5-ECB7-4D0D-935C-3297A325174B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "ZHONG XIN CAFE",
    stripeImage:
      "https://order.zhongxincafe.com/img/ninjaprocmn/zhongxincafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "zhongxincafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.zhongxincafe.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.zhongxincafe.com/#/",
      fbImage: "https://order.zhongxincafe.com/img/zhongxincafe/logo.png",
      fbTitle: "ZHONG XIN CAFE",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.zhongxincafe.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.zhongxincafe.com/#/",
      twTitile: "ZHONG XIN CAFE",
      twvia: "ZHONG XIN CAFE",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.zhongxincafe.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.zhongxincafe.com/#/",
      waSeparator: "ZHONG XIN CAFE",
    },
    site_title: "ZHONG XIN CAFE",
    site_title_with_separator: " | ZHONG XIN CAFE",
    home_meta_title: "Welcome to ZHONG XIN CAFE",
    bgimage: "/img/ninjaprocmn/zhongxincafe/body_bg.jpg",
  },
  "order.helefoods.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "92ED29EF-5F44-41F6-BBEE-409908D1C66B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Hele Foods",
    stripeImage:
      "https://order.helefoods.com/img/ninjaprocmn/helefoods/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "helefoods",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.helefoods.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.helefoods.com/#/",
      fbImage: "https://order.helefoods.com/img/helefoods/logo.png",
      fbTitle: "Hele Foods",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.helefoods.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.helefoods.com/#/",
      twTitile: "Hele Foods",
      twvia: "Hele Foods",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.helefoods.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.helefoods.com/#/",
      waSeparator: "Hele Foods",
    },
    site_title: "Hele Foods",
    site_title_with_separator: " | Hele Foods",
    home_meta_title: "Welcome to Hele Foods",
    bgimage: "/img/ninjaprocmn/helefoods/body_bg.jpg",
  },
  "www.healthyplatz.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A5905140-5A12-44EF-841E-8BF0E7350294",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Healthyplatz",
    stripeImage:
      "https://www.healthyplatz.com/img/ninjaprocmn/healthyplatz/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "healthyplatz",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.healthyplatz.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.healthyplatz.com/#/",
      fbImage: "https://www.healthyplatz.com/img/healthyplatz/logo.png",
      fbTitle: "Healthyplatz",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.healthyplatz.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.healthyplatz.com/#/",
      twTitile: "Healthyplatz",
      twvia: "Healthyplatz",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.healthyplatz.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.healthyplatz.com/#/",
      waSeparator: "Healthyplatz",
    },
    site_title: "Healthyplatz",
    forcewww: "YES",
    site_title_with_separator: " | Healthyplatz",
    home_meta_title: "Welcome to Healthyplatz",
    bgimage: "/img/ninjaprocmn/healthyplatz/body_bg.jpg",
  },
  "www.g-kitchenholdings.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4111EC2C-4612-49F0-A402-06D92E5C240B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "KING OF PAO FAN",
    stripeImage:
      "https://www.g-kitchenholdings.com/img/ninjaprocmn/kingofpaofan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kingofpaofan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.g-kitchenholdings.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.g-kitchenholdings.com/#/",
      fbImage: "https://www.g-kitchenholdings.com/img/kingofpaofan/logo.png",
      fbTitle: "KING OF PAO FAN",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.g-kitchenholdings.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.g-kitchenholdings.com/#/",
      twTitile: "KING OF PAO FAN",
      twvia: "KING OF PAO FAN",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.g-kitchenholdings.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.g-kitchenholdings.com/#/",
      waSeparator: "KING OF PAO FAN",
    },
    site_title: "KING OF PAO FAN",
    forcewww: "YES",
    site_title_with_separator: " | KING OF PAO FAN",
    home_meta_title: "Welcome to KING OF PAO FAN",
    bgimage: "/img/ninjaprocmn/kingofpaofan/body_bg.jpg",
  },
  "order.insidescoop.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "12BFE3FC-DE70-44DF-999A-898CB155D738",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Inside Scoop",
    stripeImage:
      "https://order.insidescoop.sg/img/ninjaprocmn/insidescoop/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "insidescoop",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.insidescoop.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.insidescoop.sg/#/",
      fbImage: "https://order.insidescoop.sg/img/insidescoop/logo.png",
      fbTitle: "Inside Scoop",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.insidescoop.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.insidescoop.sg/#/",
      twTitile: "Inside Scoop",
      twvia: "Inside Scoop",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.insidescoop.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.insidescoop.sg/#/",
      waSeparator: "Inside Scoop",
    },
    site_title: "Inside Scoop",
    forcewww: "YES",
    site_title_with_separator: " | Inside Scoop",
    home_meta_title: "Welcome to Inside Scoop",
    bgimage: "/img/ninjaprocmn/insidescoop/body_bg.jpg",
  },
  "www.hajjahjamillah.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "07DBB7D9-F66D-49C8-98A1-34C88D9006AB",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "HAJJAH JAMILLAH",
    stripeImage:
      "https://www.hajjahjamillah.com/img/ninjaprocmn/hajjahjamillah/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hajjahjamillah",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.hajjahjamillah.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.hajjahjamillah.com/#/",
      fbImage: "https://www.hajjahjamillah.com/img/hajjahjamillah/logo.png",
      fbTitle: "HAJJAH JAMILLAH",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.hajjahjamillah.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.hajjahjamillah.com/#/",
      twTitile: "HAJJAH JAMILLAH",
      twvia: "HAJJAH JAMILLAH",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.hajjahjamillah.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.hajjahjamillah.com/#/",
      waSeparator: "HAJJAH JAMILLAH",
    },
    site_title: "HAJJAH JAMILLAH",
    forcewww: "YES",
    site_title_with_separator: " | HAJJAH JAMILLAH",
    home_meta_title: "Welcome to HAJJAH JAMILLAH",
    bgimage: "/img/ninjaprocmn/hajjahjamillah/body_bg.jpg",
  },
  "order.ashkafe.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "123CEF6E-0204-4153-83BD-D6FB54E63D76",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ash Kafe",
    stripeImage:
      "https://order.ashkafe.com/img/ninjaprocmn/ashkafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ashkafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ashkafe.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ashkafe.com/#/",
      fbImage: "https://order.ashkafe.com/img/ashkafe/logo.png",
      fbTitle: "Ash Kafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ashkafe.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ashkafe.com/#/",
      twTitile: "Ash Kafe",
      twvia: "Ash Kafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ashkafe.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ashkafe.com/#/",
      waSeparator: "Ash Kafe",
    },
    site_title: "Ash Kafe",
    site_title_with_separator: " | Ash Kafe",
    home_meta_title: "Welcome to Ash Kafe",
    bgimage: "/img/ninjaprocmn/ashkafe/body_bg.jpg",
  },
  "order.bedok85friedoyster.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D69E7B1F-B08A-4C87-A438-94D267504BD9",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Bedok 85 Fried Oyster",
    stripeImage:
      "https://order.bedok85friedoyster.com/img/ninjaprocmn/bedok85friedoyster/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bedok85friedoyster",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.bedok85friedoyster.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.bedok85friedoyster.com/#/",
      fbImage:
        "https://order.bedok85friedoyster.com/img/bedok85friedoyster/logo.png",
      fbTitle: "Bedok 85 Fried Oyster",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.bedok85friedoyster.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.bedok85friedoyster.com/#/",
      twTitile: "Bedok 85 Fried Oyster",
      twvia: "Bedok 85 Fried Oyster",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.bedok85friedoyster.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.bedok85friedoyster.com/#/",
      waSeparator: "Bedok 85 Fried Oyster",
    },
    site_title: "Bedok 85 Fried Oyster",
    site_title_with_separator: " | Bedok 85 Fried Oyster",
    home_meta_title: "Welcome to Bedok 85 Fried Oyster",
    bgimage: "/img/ninjaprocmn/bedok85friedoyster/body_bg.jpg",
  },
  "order.gopizza.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "48084EE2-3BE1-421B-94DE-232B4F460C09",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "GOPIZZA",
    stripeImage:
      "https://order.gopizza.sg/img/ninjaprocmn/gopizzaadmin/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "gopizzaadmin",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.gopizza.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.gopizza.sg/#/",
      fbImage: "https://order.gopizza.sg/img/gopizzaadmin/logo.png",
      fbTitle: "GOPIZZA",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.gopizza.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.gopizza.sg/#/",
      twTitile: "GOPIZZA",
      twvia: "GOPIZZA",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.gopizza.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.gopizza.sg/#/",
      waSeparator: "GOPIZZA",
    },
    site_title: "GOPIZZA",
    site_title_with_separator: " | GOPIZZA",
    home_meta_title: "Welcome to GOPIZZA",
    bgimage: "/img/ninjaprocmn/gopizzaadmin/body_bg.jpg",
  },
  "order.sidetrackbistro.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5FC5E7BC-71C5-49DD-B6FA-D40820DDC6AE",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sidetrack",
    stripeImage:
      "https://order.sidetrackbistro.com/img/ninjaprocmn/sidetrackbistro/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sidetrackbistro",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.sidetrackbistro.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.sidetrackbistro.com/#/",
      fbImage: "https://order.sidetrackbistro.com/img/sidetrackbistro/logo.png",
      fbTitle: "Sidetrack",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.sidetrackbistro.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.sidetrackbistro.com/#/",
      twTitile: "Sidetrack",
      twvia: "Sidetrack",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.sidetrackbistro.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.sidetrackbistro.com/#/",
      waSeparator: "Sidetrack",
    },
    site_title: "Sidetrack",
    site_title_with_separator: " | Sidetrack",
    home_meta_title: "Welcome to Sidetrack",
    bgimage: "/img/ninjaprocmn/sidetrackbistro/body_bg.jpg",
  },
  "order.deckbistro.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E27E4C81-3E2F-4DBC-B6E9-B1BCBABF3156",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Happy Hut Bistro & Bar",
    stripeImage:
      "https://order.deckbistro.com/img/ninjaprocmn/deckbistro/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "deckbistro",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.deckbistro.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.deckbistro.com/#/",
      fbImage: "https://order.deckbistro.com/img/deckbistro/logo.png",
      fbTitle: "Happy Hut Bistro & Bar",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.deckbistro.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.deckbistro.com/#/",
      twTitile: "Happy Hut Bistro & Bar",
      twvia: "Happy Hut Bistro & Bar",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.deckbistro.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.deckbistro.com/#/",
      waSeparator: "Happy Hut Bistro & Bar",
    },
    site_title: "Happy Hut Bistro & Bar",
    site_title_with_separator: " | Happy Hut Bistro & Bar",
    home_meta_title: "Welcome to Happy Hut Bistro & Bar",
    bgimage: "/img/ninjaprocmn/deckbistro/body_bg.jpg",
  },
  "order.ayasofyarestaurant.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E23CF43C-7434-40F7-8C8A-130E3927608F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ayasofya Turkish Restaurant",
    stripeImage:
      "https://order.ayasofyarestaurant.com.sg/img/ninjaprocmn/ayasofyarestaurant/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ayasofyarestaurant",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ayasofyarestaurant.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ayasofyarestaurant.com.sg/#/",
      fbImage:
        "https://order.ayasofyarestaurant.com.sg/img/ayasofyarestaurant/logo.png",
      fbTitle: "Ayasofya Turkish Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ayasofyarestaurant.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ayasofyarestaurant.com.sg/#/",
      twTitile: "Ayasofya Turkish Restaurant",
      twvia: "Ayasofya Turkish Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ayasofyarestaurant.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ayasofyarestaurant.com.sg/#/",
      waSeparator: "Ayasofya Turkish Restaurant",
    },
    site_title: "Ayasofya Turkish Restaurant",
    site_title_with_separator: " | Ayasofya Turkish Restaurant",
    home_meta_title: "Welcome to Ayasofya Turkish Restaurant",
    bgimage: "/img/ninjaprocmn/ayasofyarestaurant/body_bg.jpg",
  },
  "order.pungpungkitchen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "FD4D99BA-0298-4CBC-9CD0-7380161408A8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Pung Pung Kitchen",
    stripeImage:
      "https://order.pungpungkitchen.com/img/ninjaprocmn/pungpungkitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pungpungkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.pungpungkitchen.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.pungpungkitchen.com/#/",
      fbImage: "https://order.pungpungkitchen.com/img/pungpungkitchen/logo.png",
      fbTitle: "Pung Pung Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.pungpungkitchen.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.pungpungkitchen.com/#/",
      twTitile: "Pung Pung Kitchen",
      twvia: "Pung Pung Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.pungpungkitchen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.pungpungkitchen.com/#/",
      waSeparator: "Pung Pung Kitchen",
    },
    site_title: "Pung Pung Kitchen",
    site_title_with_separator: " | Pung Pung Kitchen",
    home_meta_title: "Welcome to Pung Pung Kitchen",
    bgimage: "/img/ninjaprocmn/pungpungkitchen/body_bg.jpg",
  },
  "order.ministarfermentedbeancurd.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3D55242D-EEFA-4849-9E3A-EF7442380931",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "MINI STAR",
    stripeImage:
      "https://order.ministarfermentedbeancurd.com/img/ninjaprocmn/ministar/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ministar",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ministarfermentedbeancurd.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ministarfermentedbeancurd.com/#/",
      fbImage:
        "https://order.ministarfermentedbeancurd.com/img/ministar/logo.png",
      fbTitle: "MINI STAR",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ministarfermentedbeancurd.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ministarfermentedbeancurd.com/#/",
      twTitile: "MINI STAR",
      twvia: "MINI STAR",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ministarfermentedbeancurd.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ministarfermentedbeancurd.com/#/",
      waSeparator: "MINI STAR",
    },
    site_title: "MINI STAR",
    site_title_with_separator: " | MINI STAR",
    home_meta_title: "Welcome to MINI STAR",
    bgimage: "/img/ninjaprocmn/ministar/body_bg.jpg",
  },
  "order.theblacksheepcafe.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3BBC2C53-B9B0-47DA-ACB5-BB703A8FB04E",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Black Sheep Cafe",
    stripeImage:
      "https://order.theblacksheepcafe.com/img/ninjaprocmn/theblacksheepcafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "theblacksheepcafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.theblacksheepcafe.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.theblacksheepcafe.com/#/",
      fbImage:
        "https://order.theblacksheepcafe.com/img/theblacksheepcafe/logo.png",
      fbTitle: "The Black Sheep Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.theblacksheepcafe.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.theblacksheepcafe.com/#/",
      twTitile: "The Black Sheep Cafe",
      twvia: "The Black Sheep Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.theblacksheepcafe.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.theblacksheepcafe.com/#/",
      waSeparator: "The Black Sheep Cafe",
    },
    site_title: "The Black Sheep Cafe",
    site_title_with_separator: " | The Black Sheep Cafe",
    home_meta_title: "Welcome to The Black Sheep Cafe",
    bgimage: "/img/ninjaprocmn/theblacksheepcafe/body_bg.jpg",
  },
  "order.chezbiryaneco.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "8FA5ED0C-5482-46CA-8534-FF8BDE22056F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Chez Biryane",
    stripeImage:
      "https://order.chezbiryaneco.com.sg/img/ninjaprocmn/chezbiryaneco/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "chezbiryaneco",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.chezbiryaneco.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.chezbiryaneco.com.sg/#/",
      fbImage: "https://order.chezbiryaneco.com.sg/img/chezbiryaneco/logo.png",
      fbTitle: "Chez Biryane",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.chezbiryaneco.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.chezbiryaneco.com.sg/#/",
      twTitile: "Chez Biryane",
      twvia: "Chez Biryane",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.chezbiryaneco.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.chezbiryaneco.com.sg/#/",
      waSeparator: "Chez Biryane",
    },
    site_title: "Chez Biryane",
    site_title_with_separator: " | Chez Biryane",
    home_meta_title: "Welcome to Chez Biryane",
    bgimage: "/img/ninjaprocmn/chezbiryaneco/body_bg.jpg",
  },
  "order.bedok85oysteromelette.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B93F0EAA-A13A-48D5-B0EF-7B7550787955",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "BEDOK 85 FRIED OYSTER PTE",
    stripeImage:
      "https://order.bedok85oysteromelette.com/img/ninjaprocmn/bedok85oysteromelette/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bedok85oysteromelette",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.bedok85oysteromelette.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.bedok85oysteromelette.com/#/",
      fbImage:
        "https://order.bedok85oysteromelette.com/img/bedok85oysteromelette/logo.png",
      fbTitle: "BEDOK 85 FRIED OYSTER PTE",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.bedok85oysteromelette.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.bedok85oysteromelette.com/#/",
      twTitile: "BEDOK 85 FRIED OYSTER PTE",
      twvia: "BEDOK 85 FRIED OYSTER PTE",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.bedok85oysteromelette.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.bedok85oysteromelette.com/#/",
      waSeparator: "BEDOK 85 FRIED OYSTER PTE",
    },
    site_title: "BEDOK 85 FRIED OYSTER PTE",
    site_title_with_separator: " | BEDOK 85 FRIED OYSTER PTE",
    home_meta_title: "Welcome to BEDOK 85 FRIED OYSTER PTE",
    bgimage: "/img/ninjaprocmn/bedok85oysteromelette/body_bg.jpg",
  },
  "www.thechailounge.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1B8B9475-E42E-4CED-8A7C-25A37E69FF2A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "The Chai Lounge",
    stripeImage:
      "https://www.thechailounge.sg/img/ninjaprocmn/thechailounge/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thechailounge",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.thechailounge.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.thechailounge.sg/#/",
      fbImage: "https://www.thechailounge.sg/img/thechailounge/logo.png",
      fbTitle: "The Chai Lounge",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.thechailounge.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.thechailounge.sg/#/",
      twTitile: "The Chai Lounge",
      twvia: "The Chai Lounge",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.thechailounge.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.thechailounge.sg/#/",
      waSeparator: "The Chai Lounge",
    },
    site_title: "The Chai Lounge",
    forcewww: "YES",
    site_title_with_separator: " | The Chai Lounge",
    home_meta_title: "Welcome to The Chai Lounge",
    bgimage: "/img/ninjaprocmn/thechailounge/body_bg.jpg",
  },
  "order.moosh.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "137FF94D-F8D5-4D63-A588-26D0856A92C8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Moosh Softserve",
    stripeImage: "https://order.moosh.sg/img/ninjaprocmn/moosh/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "moosh",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.moosh.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.moosh.sg/#/",
      fbImage: "https://order.moosh.sg/img/moosh/logo.png",
      fbTitle: "Moosh Softserve",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.moosh.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.moosh.sg/#/",
      twTitile: "Moosh Softserve",
      twvia: "Moosh Softserve",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.moosh.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.moosh.sg/#/",
      waSeparator: "Moosh Softserve",
    },
    site_title: "Moosh Softserve",
    site_title_with_separator: " | Moosh Softserve",
    home_meta_title: "Welcome to Moosh Softserve",
    bgimage: "/img/ninjaprocmn/moosh/body_bg.jpg",
  },
  "order.asamtree.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D40E0C2F-A4B8-474F-B92E-C3EA9D2B5F25",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Asam Tree",
    stripeImage:
      "https://order.asamtree.com/img/ninjaprocmn/asamtree/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "asamtree",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.asamtree.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.asamtree.com/#/",
      fbImage: "https://order.asamtree.com/img/asamtree/logo.png",
      fbTitle: "Asam Tree",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.asamtree.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.asamtree.com/#/",
      twTitile: "Asam Tree",
      twvia: "Asam Tree",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.asamtree.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.asamtree.com/#/",
      waSeparator: "Asam Tree",
    },
    site_title: "Asam Tree",
    site_title_with_separator: " | Asam Tree",
    home_meta_title: "Welcome to Asam Tree",
    bgimage: "/img/ninjaprocmn/asamtree/body_bg.jpg",
  },
  "www.zhenvegetarian.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "72963D8E-D367-45CE-BA71-CE38B52EFC7E",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "ZHEN VEGETARIAN",
    stripeImage:
      "https://www.zhenvegetarian.com/img/ninjaprocmn/zhenvegetarian/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "zhenvegetarian",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.zhenvegetarian.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.zhenvegetarian.com/#/",
      fbImage: "https://www.zhenvegetarian.com/img/zhenvegetarian/logo.png",
      fbTitle: "ZHEN VEGETARIAN",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.zhenvegetarian.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.zhenvegetarian.com/#/",
      twTitile: "ZHEN VEGETARIAN",
      twvia: "ZHEN VEGETARIAN",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.zhenvegetarian.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.zhenvegetarian.com/#/",
      waSeparator: "ZHEN VEGETARIAN",
    },
    site_title: "ZHEN VEGETARIAN",
    forcewww: "YES",
    site_title_with_separator: " | ZHEN VEGETARIAN",
    home_meta_title: "Welcome to ZHEN VEGETARIAN",
    bgimage: "/img/ninjaprocmn/zhenvegetarian/body_bg.jpg",
  },
  "order.syed-cafe.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E3CF7BD4-6B20-4E1C-A126-CBE3ED1EB975",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Syed Cafe",
    stripeImage:
      "https://order.syed-cafe.com/img/ninjaprocmn/syed-cafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "syed-cafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.syed-cafe.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.syed-cafe.com/#/",
      fbImage: "https://order.syed-cafe.com/img/syed-cafe/logo.png",
      fbTitle: "Syed Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.syed-cafe.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.syed-cafe.com/#/",
      twTitile: "Syed Cafe",
      twvia: "Syed Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.syed-cafe.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.syed-cafe.com/#/",
      waSeparator: "Syed Cafe",
    },
    site_title: "Syed Cafe",
    site_title_with_separator: " | Syed Cafe",
    home_meta_title: "Welcome to Syed Cafe",
    bgimage: "/img/ninjaprocmn/syed-cafe/body_bg.jpg",
  },
  "order.mixedgreens.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F79AC4C3-9BD4-456B-86DB-99842DDA4F21",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mixed Greens",
    stripeImage:
      "https://order.mixedgreens.sg/img/ninjaprocmn/mixedgreens/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mixedgreens",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.mixedgreens.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.mixedgreens.sg/#/",
      fbImage: "https://order.mixedgreens.sg/img/mixedgreens/logo.png",
      fbTitle: "Mixed Greens",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.mixedgreens.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.mixedgreens.sg/#/",
      twTitile: "Mixed Greens",
      twvia: "Mixed Greens",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.mixedgreens.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.mixedgreens.sg/#/",
      waSeparator: "Mixed Greens",
    },
    site_title: "Mixed Greens",
    site_title_with_separator: " | Mixed Greens",
    home_meta_title: "Welcome to Mixed Greens",
    bgimage: "/img/ninjaprocmn/mixedgreens/body_bg.jpg",
  },
  "order.manmanchisg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "796C89C5-882E-44B2-BFE9-A549D903E32B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Man Man Chi",
    stripeImage:
      "https://order.manmanchisg.com/img/ninjaprocmn/manmanchisg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "manmanchisg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.manmanchisg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.manmanchisg.com/#/",
      fbImage: "https://order.manmanchisg.com/img/manmanchisg/logo.png",
      fbTitle: "Man Man Chi",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.manmanchisg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.manmanchisg.com/#/",
      twTitile: "Man Man Chi",
      twvia: "Man Man Chi",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.manmanchisg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.manmanchisg.com/#/",
      waSeparator: "Man Man Chi",
    },
    site_title: "Man Man Chi",
    site_title_with_separator: " | Man Man Chi",
    home_meta_title: "Welcome to Man Man Chi",
    bgimage: "/img/ninjaprocmn/manmanchisg/body_bg.jpg",
  },
  "orders.teabrary.online": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DF79B08D-141B-425E-9143-A4C8D4D007AB",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Stars Tea and Coffee Asia",
    stripeImage:
      "https://orders.teabrary.online/img/ninjaprocmn/teabrary/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "teabrary",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orders.teabrary.online /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orders.teabrary.online/#/",
      fbImage: "https://orders.teabrary.online/img/teabrary/logo.png",
      fbTitle: "Stars Tea and Coffee Asia",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orders.teabrary.online /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orders.teabrary.online/#/",
      twTitile: "Stars Tea and Coffee Asia",
      twvia: "Stars Tea and Coffee Asia",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://orders.teabrary.online/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://orders.teabrary.online/#/",
      waSeparator: "Stars Tea and Coffee Asia",
    },
    site_title: "Stars Tea and Coffee Asia",
    site_title_with_separator: " | Stars Tea and Coffee Asia",
    home_meta_title: "Welcome to Stars Tea and Coffee Asia",
    bgimage: "/img/ninjaprocmn/teabrary/body_bg.jpg",
  },
  "www.desertdessert.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C920B567-99E7-452E-ABFC-CEF90FF311E2",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "DESERT DESSERT",
    stripeImage:
      "https://www.desertdessert.sg/img/ninjaprocmn/desertdessert/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "desertdessert",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.desertdessert.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.desertdessert.sg/#/",
      fbImage: "https://www.desertdessert.sg/img/desertdessert/logo.png",
      fbTitle: "DESERT DESSERT",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.desertdessert.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.desertdessert.sg/#/",
      twTitile: "DESERT DESSERT",
      twvia: "DESERT DESSERT",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.desertdessert.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.desertdessert.sg/#/",
      waSeparator: "DESERT DESSERT",
    },
    site_title: "DESERT DESSERT",
    forcewww: "YES",
    site_title_with_separator: " | DESERT DESSERT",
    home_meta_title: "Welcome to DESERT DESSERT",
    bgimage: "/img/ninjaprocmn/desertdessert/body_bg.jpg",
  },
  "order.carrotcubes.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D4CD2AFC-78F1-4C14-B670-CF724D17FFC8",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "CARROT CUBES",
    stripeImage:
      "https://order.carrotcubes.sg/img/ninjaprocmn/carrotcubes/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "carrotcubes",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.carrotcubes.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.carrotcubes.sg/#/",
      fbImage: "https://order.carrotcubes.sg/img/carrotcubes/logo.png",
      fbTitle: "CARROT CUBES",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.carrotcubes.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.carrotcubes.sg/#/",
      twTitile: "CARROT CUBES",
      twvia: "CARROT CUBES",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.carrotcubes.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.carrotcubes.sg/#/",
      waSeparator: "CARROT CUBES",
    },
    site_title: "CARROT CUBES",
    site_title_with_separator: " | CARROT CUBES",
    home_meta_title: "Welcome to CARROT CUBES",
    bgimage: "/img/ninjaprocmn/carrotcubes/body_bg.jpg",
  },
  "order.viiogastropub.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4C9E5828-301C-48D7-BFC8-03AA6FA4B939",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "VIIO GASTROPUB",
    stripeImage:
      "https://order.viiogastropub.com/img/ninjaprocmn/viiogastropub/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "viiogastropub",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.viiogastropub.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.viiogastropub.com/#/",
      fbImage: "https://order.viiogastropub.com/img/viiogastropub/logo.png",
      fbTitle: "VIIO GASTROPUB",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.viiogastropub.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.viiogastropub.com/#/",
      twTitile: "VIIO GASTROPUB",
      twvia: "VIIO GASTROPUB",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.viiogastropub.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.viiogastropub.com/#/",
      waSeparator: "VIIO GASTROPUB",
    },
    site_title: "VIIO GASTROPUB",
    site_title_with_separator: " | VIIO GASTROPUB",
    home_meta_title: "Welcome to VIIO GASTROPUB",
    bgimage: "/img/ninjaprocmn/viiogastropub/body_bg.jpg",
  },
  "order.sinkeefamouschickenrice.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "94B08008-AB71-424D-9580-B78B4DF07194",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sin Kee Famous Chicken Rice",
    stripeImage:
      "https://order.sinkeefamouschickenrice.com/img/ninjaprocmn/sinkeefamouschickenrice/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sinkeefamouschickenrice",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.sinkeefamouschickenrice.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.sinkeefamouschickenrice.com/#/",
      fbImage:
        "https://order.sinkeefamouschickenrice.com/img/sinkeefamouschickenrice/logo.png",
      fbTitle: "Sin Kee Famous Chicken Rice",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.sinkeefamouschickenrice.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.sinkeefamouschickenrice.com/#/",
      twTitile: "Sin Kee Famous Chicken Rice",
      twvia: "Sin Kee Famous Chicken Rice",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.sinkeefamouschickenrice.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.sinkeefamouschickenrice.com/#/",
      waSeparator: "Sin Kee Famous Chicken Rice",
    },
    site_title: "Sin Kee Famous Chicken Rice",
    site_title_with_separator: " | Sin Kee Famous Chicken Rice",
    home_meta_title: "Welcome to Sin Kee Famous Chicken Rice",
    bgimage: "/img/ninjaprocmn/sinkeefamouschickenrice/body_bg.jpg",
  },
  "order.switchkitchensg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F97CD809-E010-496C-8C45-804CCADAF331",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Switch Kitchen Sg",
    stripeImage:
      "https://order.switchkitchensg.com/img/ninjaprocmn/switchkitchensg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "switchkitchensg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.switchkitchensg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.switchkitchensg.com/#/",
      fbImage: "https://order.switchkitchensg.com/img/switchkitchensg/logo.png",
      fbTitle: "Switch Kitchen Sg",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.switchkitchensg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.switchkitchensg.com/#/",
      twTitile: "Switch Kitchen Sg",
      twvia: "Switch Kitchen Sg",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.switchkitchensg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.switchkitchensg.com/#/",
      waSeparator: "Switch Kitchen Sg",
    },
    site_title: "Switch Kitchen Sg",
    site_title_with_separator: " | Switch Kitchen Sg",
    home_meta_title: "Welcome to Switch Kitchen Sg",
    bgimage: "/img/ninjaprocmn/switchkitchensg/body_bg.jpg",
  },
  "order.jiaxiangkolomee.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "7B637086-CDB5-461E-B93A-AF2527DEE755",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Jia Xiang Kuching",
    stripeImage:
      "https://order.jiaxiangkolomee.com/img/ninjaprocmn/jiaxiangkolomee/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "jiaxiangkolomee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.jiaxiangkolomee.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.jiaxiangkolomee.com/#/",
      fbImage: "https://order.jiaxiangkolomee.com/img/jiaxiangkolomee/logo.png",
      fbTitle: "Jia Xiang Kuching",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.jiaxiangkolomee.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.jiaxiangkolomee.com/#/",
      twTitile: "Jia Xiang Kuching",
      twvia: "Jia Xiang Kuching",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.jiaxiangkolomee.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.jiaxiangkolomee.com/#/",
      waSeparator: "Jia Xiang Kuching",
    },
    site_title: "Jia Xiang Kuching",
    site_title_with_separator: " | Jia Xiang Kuching",
    home_meta_title: "Welcome to Jia Xiang Kuching",
    bgimage: "/img/ninjaprocmn/jiaxiangkolomee/body_bg.jpg",
  },
  "www.brightaste.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "97EC1352-DEAF-41D1-AE42-A09FBC2C770D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Shugar Gourmet",
    stripeImage:
      "https://www.brightaste.com/img/ninjaprocmn/brightaste/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "brightaste",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.brightaste.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.brightaste.com/#/",
      fbImage: "https://www.brightaste.com/img/brightaste/logo.png",
      fbTitle: "Shugar Gourmet",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.brightaste.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.brightaste.com/#/",
      twTitile: "Shugar Gourmet",
      twvia: "Shugar Gourmet",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.brightaste.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.brightaste.com/#/",
      waSeparator: "Shugar Gourmet",
    },
    site_title: "Shugar Gourmet",
    forcewww: "YES",
    site_title_with_separator: " | Shugar Gourmet",
    home_meta_title: "Welcome to Shugar Gourmet",
    bgimage: "/img/ninjaprocmn/brightaste/body_bg.jpg",
  },
  "order.sove.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C35B8131-D028-487F-9112-2E5D84AE7C5E",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sove ",
    stripeImage: "https://order.sove.sg/img/ninjaprocmn/sove/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sove",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.sove.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.sove.sg/#/",
      fbImage: "https://order.sove.sg/img/sove/logo.png",
      fbTitle: "Sove ",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.sove.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.sove.sg/#/",
      twTitile: "Sove ",
      twvia: "Sove ",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.sove.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.sove.sg/#/",
      waSeparator: "Sove ",
    },
    site_title: "Sove ",
    site_title_with_separator: " | Sove ",
    home_meta_title: "Welcome to Sove ",
    bgimage: "/img/ninjaprocmn/sove/body_bg.jpg",
  },
  "www.yiji.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9629BD63-13E3-4E2B-96CB-2915B7CF3335",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "T&T FOODIE",
    stripeImage: "https://www.yiji.sg/img/ninjaprocmn/ttfood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ttfood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.yiji.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.yiji.sg/#/",
      fbImage: "https://www.yiji.sg/img/ttfood/logo.png",
      fbTitle: "T&T FOODIE",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.yiji.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.yiji.sg/#/",
      twTitile: "T&T FOODIE",
      twvia: "T&T FOODIE",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.yiji.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.yiji.sg/#/",
      waSeparator: "T&T FOODIE",
    },
    site_title: "T&T FOODIE",
    forcewww: "YES",
    site_title_with_separator: " | T&T FOODIE",
    home_meta_title: "Welcome to T&T FOODIE",
    bgimage: "/img/ninjaprocmn/ttfood/body_bg.jpg",
  },
  "www.annswesternfood.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C04515C6-9F9E-40B1-91AE-D7A19B508929",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "ANNS WESTERN FOOD",
    stripeImage:
      "https://www.annswesternfood.com/img/ninjaprocmn/annswesternfood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "annswesternfood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.annswesternfood.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.annswesternfood.com/#/",
      fbImage: "https://www.annswesternfood.com/img/annswesternfood/logo.png",
      fbTitle: "ANNS WESTERN FOOD",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.annswesternfood.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.annswesternfood.com/#/",
      twTitile: "ANNS WESTERN FOOD",
      twvia: "ANNS WESTERN FOOD",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.annswesternfood.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.annswesternfood.com/#/",
      waSeparator: "ANNS WESTERN FOOD",
    },
    site_title: "ANNS WESTERN FOOD",
    forcewww: "YES",
    site_title_with_separator: " | ANNS WESTERN FOOD",
    home_meta_title: "Welcome to ANNS WESTERN FOOD",
    bgimage: "/img/ninjaprocmn/annswesternfood/body_bg.jpg",
  },
  "www.lianghaus.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "79623D14-1407-412E-A3F6-2515F47DDF51",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "LIANG HAUS",
    stripeImage:
      "https://www.lianghaus.com/img/ninjaprocmn/lianghaus/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lianghaus",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.lianghaus.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.lianghaus.com/#/",
      fbImage: "https://www.lianghaus.com/img/lianghaus/logo.png",
      fbTitle: "LIANG HAUS",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.lianghaus.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.lianghaus.com/#/",
      twTitile: "LIANG HAUS",
      twvia: "LIANG HAUS",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.lianghaus.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.lianghaus.com/#/",
      waSeparator: "LIANG HAUS",
    },
    site_title: "LIANG HAUS",
    forcewww: "YES",
    site_title_with_separator: " | LIANG HAUS",
    home_meta_title: "Welcome to LIANG HAUS",
    bgimage: "/img/ninjaprocmn/lianghaus/body_bg.jpg",
  },
  "order.almajlis.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "10714D13-7DB8-464B-A418-7BBC6E27ED55",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Al Majlis Thai Seafood Restaurant",
    stripeImage:
      "https://order.almajlis.sg/img/ninjaprocmn/almajlisthaiseafood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "almajlisthaiseafood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.almajlis.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.almajlis.sg/#/",
      fbImage: "https://order.almajlis.sg/img/almajlisthaiseafood/logo.png",
      fbTitle: "Al Majlis Thai Seafood Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.almajlis.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.almajlis.sg/#/",
      twTitile: "Al Majlis Thai Seafood Restaurant",
      twvia: "Al Majlis Thai Seafood Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.almajlis.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.almajlis.sg/#/",
      waSeparator: "Al Majlis Thai Seafood Restaurant",
    },
    site_title: "Al Majlis Thai Seafood Restaurant",
    site_title_with_separator: " | Al Majlis Thai Seafood Restaurant",
    home_meta_title: "Welcome to Al Majlis Thai Seafood Restaurant",
    bgimage: "/img/ninjaprocmn/almajlisthaiseafood/body_bg.jpg",
  },
  "order.jamilcatering.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B01FEEE4-6834-4F05-A67A-91CF5719D069",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "JAMIL CATERING",
    stripeImage:
      "https://order.jamilcatering.co/img/ninjaprocmn/jamilcatering/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "jamilcatering",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.jamilcatering.co /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.jamilcatering.co/#/",
      fbImage: "https://order.jamilcatering.co/img/jamilcatering/logo.png",
      fbTitle: "JAMIL CATERING",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.jamilcatering.co /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.jamilcatering.co/#/",
      twTitile: "JAMIL CATERING",
      twvia: "JAMIL CATERING",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.jamilcatering.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.jamilcatering.co/#/",
      waSeparator: "JAMIL CATERING",
    },
    site_title: "JAMIL CATERING",
    site_title_with_separator: " | JAMIL CATERING",
    home_meta_title: "Welcome to JAMIL CATERING",
    bgimage: "/img/ninjaprocmn/jamilcatering/body_bg.jpg",
  },
  "order.centong.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B562096E-63E5-4F7F-B2F8-08A217C83E50",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Centong",
    stripeImage:
      "https://order.centong.sg/img/ninjaprocmn/centong/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "centong",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.centong.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.centong.sg/#/",
      fbImage: "https://order.centong.sg/img/centong/logo.png",
      fbTitle: "Centong",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.centong.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.centong.sg/#/",
      twTitile: "Centong",
      twvia: "Centong",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.centong.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.centong.sg/#/",
      waSeparator: "Centong",
    },
    site_title: "Centong",
    site_title_with_separator: " | Centong",
    home_meta_title: "Welcome to Centong",
    bgimage: "/img/ninjaprocmn/centong/body_bg.jpg",
  },
  "www.lifebowlsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3286367D-CB18-4B7B-9F17-8D1E8F6FBFA9",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Lifebowl",
    stripeImage:
      "https://www.lifebowlsg.com/img/ninjaprocmn/lifebowlsg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lifebowlsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.lifebowlsg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.lifebowlsg.com/#/",
      fbImage: "https://www.lifebowlsg.com/img/lifebowlsg/logo.png",
      fbTitle: "Lifebowl",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.lifebowlsg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.lifebowlsg.com/#/",
      twTitile: "Lifebowl",
      twvia: "Lifebowl",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.lifebowlsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.lifebowlsg.com/#/",
      waSeparator: "Lifebowl",
    },
    site_title: "Lifebowl",
    forcewww: "YES",
    site_title_with_separator: " | Lifebowl",
    home_meta_title: "Welcome to Lifebowl",
    bgimage: "/img/ninjaprocmn/lifebowlsg/body_bg.jpg",
  },
  "order.samrang.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C0E58DA0-FD5F-45E8-A4BA-3D9145311D85",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Samrang",
    stripeImage:
      "https://order.samrang.com.sg/img/ninjaprocmn/samrang/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "samrang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.samrang.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.samrang.com.sg/#/",
      fbImage: "https://order.samrang.com.sg/img/samrang/logo.png",
      fbTitle: "Samrang",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.samrang.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.samrang.com.sg/#/",
      twTitile: "Samrang",
      twvia: "Samrang",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.samrang.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.samrang.com.sg/#/",
      waSeparator: "Samrang",
    },
    site_title: "Samrang",
    site_title_with_separator: " | Samrang",
    home_meta_title: "Welcome to Samrang",
    bgimage: "/img/ninjaprocmn/samrang/body_bg.jpg",
  },
  "order.whitfieldstreetbakery.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "145E3A37-C131-4603-A5EB-52AEA37E748C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Whitfield Street Bakery",
    stripeImage:
      "https://order.whitfieldstreetbakery.com/img/ninjaprocmn/whitfieldstbakery/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "whitfieldstbakery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.whitfieldstreetbakery.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.whitfieldstreetbakery.com/#/",
      fbImage:
        "https://order.whitfieldstreetbakery.com/img/whitfieldstbakery/logo.png",
      fbTitle: "Whitfield Street Bakery",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.whitfieldstreetbakery.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.whitfieldstreetbakery.com/#/",
      twTitile: "Whitfield Street Bakery",
      twvia: "Whitfield Street Bakery",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.whitfieldstreetbakery.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.whitfieldstreetbakery.com/#/",
      waSeparator: "Whitfield Street Bakery",
    },
    site_title: "Whitfield Street Bakery",
    forcewww: "YES",
    site_title_with_separator: " | Whitfield Street Bakery",
    home_meta_title: "Welcome to Whitfield Street Bakery",
    bgimage: "/img/ninjaprocmn/whitfieldstbakery/body_bg.jpg",
  },
  "www.kiposkitchen.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1A19B725-8B45-4414-9713-5F270E557D6A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kipos Kitchen",
    stripeImage:
      "https://www.kiposkitchen.sg/img/ninjaprocmn/kiposkitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kiposkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kiposkitchen.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kiposkitchen.sg/#/",
      fbImage: "https://www.kiposkitchen.sg/img/kiposkitchen/logo.png",
      fbTitle: "Kipos Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kiposkitchen.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kiposkitchen.sg/#/",
      twTitile: "Kipos Kitchen",
      twvia: "Kipos Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.kiposkitchen.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.kiposkitchen.sg/#/",
      waSeparator: "Kipos Kitchen",
    },
    site_title: "Kipos Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Kipos Kitchen",
    home_meta_title: "Welcome to Kipos Kitchen",
    bgimage: "/img/ninjaprocmn/kiposkitchen/body_bg.jpg",
  },
  "order.flavvorsgourmet.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D8F2F4D4-079D-4CE9-B025-C9073C8D045F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Flavvors Gourmet",
    stripeImage:
      "https://order.flavvorsgourmet.sg/img/ninjaprocmn/flavvorsgourmet/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "flavvorsgourmet",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.flavvorsgourmet.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.flavvorsgourmet.sg/#/",
      fbImage: "https://order.flavvorsgourmet.sg/img/flavvorsgourmet/logo.png",
      fbTitle: "Flavvors Gourmet",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.flavvorsgourmet.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.flavvorsgourmet.sg/#/",
      twTitile: "Flavvors Gourmet",
      twvia: "Flavvors Gourmet",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.flavvorsgourmet.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.flavvorsgourmet.sg/#/",
      waSeparator: "Flavvors Gourmet",
    },
    site_title: "Flavvors Gourmet",
    forcewww: "YES",
    site_title_with_separator: " | Flavvors Gourmet",
    home_meta_title: "Welcome to Flavvors Gourmet",
    bgimage: "/img/ninjaprocmn/flavvorsgourmet/body_bg.jpg",
  },
  "www.tandooribites.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1DD31A95-B02E-45C3-8EA3-7EB68F8D0E9A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tandoori Bites",
    stripeImage:
      "https://www.tandooribites.sg/img/ninjaprocmn/tandooribites/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tandooribites",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.tandooribites.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.tandooribites.sg/#/",
      fbImage: "https://www.tandooribites.sg/img/tandooribites/logo.png",
      fbTitle: "Tandoori Bites",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.tandooribites.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.tandooribites.sg/#/",
      twTitile: "Tandoori Bites",
      twvia: "Tandoori Bites",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.tandooribites.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.tandooribites.sg/#/",
      waSeparator: "Tandoori Bites",
    },
    site_title: "Tandoori Bites",
    forcewww: "YES",
    site_title_with_separator: " | Tandoori Bites",
    home_meta_title: "Welcome to Tandoori Bites",
    bgimage: "/img/ninjaprocmn/tandooribites/body_bg.jpg",
  },
  "order.projectburgs.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E68BBCD4-9C71-41EA-8BF8-84DF3222CC67",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Burgs",
    stripeImage:
      "https://order.projectburgs.com/img/ninjaprocmn/burgs/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "burgs",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.projectburgs.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.projectburgs.com/#/",
      fbImage: "https://order.projectburgs.com/img/burgs/logo.png",
      fbTitle: "Burgs",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.projectburgs.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.projectburgs.com/#/",
      twTitile: "Burgs",
      twvia: "Burgs",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.projectburgs.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.projectburgs.com/#/",
      waSeparator: "Burgs",
    },
    site_title: "Burgs",
    site_title_with_separator: " | Burgs",
    home_meta_title: "Welcome to Burgs",
    bgimage: "/img/ninjaprocmn/burgs/body_bg.jpg",
  },
  "order.beirut.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "014E9FC6-A56D-43AF-A39C-1F8CDAA4D59B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Beirut Grill",
    stripeImage:
      "https://order.beirut.com.sg/img/ninjaprocmn/beirut/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "beirut",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.beirut.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.beirut.com.sg/#/",
      fbImage: "https://order.beirut.com.sg/img/beirut/logo.png",
      fbTitle: "Beirut Grill",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.beirut.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.beirut.com.sg/#/",
      twTitile: "Beirut Grill",
      twvia: "Beirut Grill",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.beirut.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.beirut.com.sg/#/",
      waSeparator: "Beirut Grill",
    },
    site_title: "Beirut Grill",
    site_title_with_separator: " | Beirut Grill",
    home_meta_title: "Welcome to Beirut Grill",
    bgimage: "/img/ninjaprocmn/beirut/body_bg.jpg",
  },
  "order.biryanibrews.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "71F68480-A4B8-4B02-B5D5-44A5CD393EA1",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Biryani Brews",
    stripeImage:
      "https://order.biryanibrews.sg/img/ninjaprocmn/biryanibrews/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "biryanibrews",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.biryanibrews.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.biryanibrews.sg/#/",
      fbImage: "https://order.biryanibrews.sg/img/biryanibrews/logo.png",
      fbTitle: "Biryani Brews",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.biryanibrews.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.biryanibrews.sg/#/",
      twTitile: "Biryani Brews",
      twvia: "Biryani Brews",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.biryanibrews.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.biryanibrews.sg/#/",
      waSeparator: "Biryani Brews",
    },
    site_title: "Biryani Brews",
    site_title_with_separator: " | Biryani Brews",
    home_meta_title: "Welcome to Biryani Brews",
    bgimage: "/img/ninjaprocmn/biryanibrews/body_bg.jpg",
  },
  "order.ahyatrestaurant.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B9618066-964F-4948-AFFE-C569D2F3126B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Owen Seafood Restaurant",
    stripeImage:
      "https://order.ahyatrestaurant.com.sg/img/ninjaprocmn/ahyatrestaurant/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ahyatrestaurant",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ahyatrestaurant.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ahyatrestaurant.com.sg/#/",
      fbImage:
        "https://order.ahyatrestaurant.com.sg/img/ahyatrestaurant/logo.png",
      fbTitle: "Owen Seafood Restaurant",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ahyatrestaurant.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ahyatrestaurant.com.sg/#/",
      twTitile: "Owen Seafood Restaurant",
      twvia: "Owen Seafood Restaurant",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ahyatrestaurant.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ahyatrestaurant.com.sg/#/",
      waSeparator: "Owen Seafood Restaurant",
    },
    site_title: "Owen Seafood Restaurant",
    site_title_with_separator: " | Owen Seafood Restaurant",
    home_meta_title: "Welcome to Owen Seafood Restaurant",
    bgimage: "/img/ninjaprocmn/ahyatrestaurant/body_bg.jpg",
  },
  "www.zarinabegum.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F8A09672-EFCB-4740-AAD4-B30E53233A00",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Zarina Begum",
    stripeImage:
      "https://www.zarinabegum.com/img/ninjaprocmn/zarinabegum/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "zarinabegum",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.zarinabegum.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.zarinabegum.com/#/",
      fbImage: "https://www.zarinabegum.com/img/zarinabegum/logo.png",
      fbTitle: "Zarina Begum",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.zarinabegum.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.zarinabegum.com/#/",
      twTitile: "Zarina Begum",
      twvia: "Zarina Begum",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.zarinabegum.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.zarinabegum.com/#/",
      waSeparator: "Zarina Begum",
    },
    site_title: "Zarina Begum",
    forcewww: "YES",
    site_title_with_separator: " | Zarina Begum",
    home_meta_title: "Welcome to Zarina Begum",
    bgimage: "/img/ninjaprocmn/zarinabegum/body_bg.jpg",
  },
  "order.fangko.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "751C03C3-825B-4FC8-80CB-C61B20FA70BA",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Fangko",
    stripeImage:
      "https://order.fangko.co/img/ninjaprocmn/fangko/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fangko",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.fangko.co /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.fangko.co/#/",
      fbImage: "https://order.fangko.co/img/fangko/logo.png",
      fbTitle: "Fangko",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.fangko.co /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.fangko.co/#/",
      twTitile: "Fangko",
      twvia: "Fangko",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.fangko.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.fangko.co/#/",
      waSeparator: "Fangko",
    },
    site_title: "Fangko",
    site_title_with_separator: " | Fangko",
    home_meta_title: "Welcome to Fangko",
    bgimage: "/img/ninjaprocmn/fangko/body_bg.jpg",
  },
  "www.kuehbykakwan.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B8AB6B91-B8A5-49F9-9CA8-A1A7E910362E",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Fangko",
    stripeImage:
      "https://www.kuehbykakwan.sg/img/ninjaprocmn/kuehbykakwan/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kuehbykakwan",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kuehbykakwan.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kuehbykakwan.sg/#/",
      fbImage: "https://www.kuehbykakwan.sg/img/kuehbykakwan/logo.png",
      fbTitle: "Kueh by Kak Wan",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kuehbykakwan.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kuehbykakwan.sg/#/",
      twTitile: "Kueh by Kak Wan",
      twvia: "Kueh by Kak Wan",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.kuehbykakwan.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.kuehbykakwan.sg/#/",
      waSeparator: "Kueh by Kak Wan",
    },
    site_title: "Kueh by Kak Wan",
    site_title_with_separator: " | Kueh by Kak Wan",
    home_meta_title: "Welcome to Kueh by Kak Wan",
    bgimage: "/img/ninjaprocmn/kuehbykakwan/body_bg.jpg",
  },
  "www.ichiwangtori.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E84B2EC4-5D90-46BE-A775-EC9D6173BD67",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ichiwang Tori",
    stripeImage:
      "https://www.ichiwangtori.com/img/ninjaprocmn/ichiwangtori/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ichiwangtori",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ichiwangtori.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ichiwangtori.com/#/",
      fbImage: "https://www.ichiwangtori.com/img/ichiwangtori/logo.png",
      fbTitle: "Ichiwang Tori",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ichiwangtori.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ichiwangtori.com/#/",
      twTitile: "Ichiwang Tori",
      twvia: "Ichiwang Tori",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.ichiwangtori.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.ichiwangtori.com/#/",
      waSeparator: "Ichiwang Tori",
    },
    site_title: "Ichiwang Tori",
    forcewww: "YES",
    site_title_with_separator: " | Ichiwang Tori",
    home_meta_title: "Welcome to Ichiwang Tori",
    bgimage: "/img/ninjaprocmn/ichiwangtori/body_bg.jpg",
  },
  "www.putraminangsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1450D789-6446-4AE2-B2FA-5E0231264390",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Putra Minang",
    stripeImage:
      "https://www.putraminangsg.com/img/ninjaprocmn/putraminangsg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "putraminangsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.putraminangsg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.putraminangsg.com/#/",
      fbImage: "https://www.putraminangsg.com/img/putraminangsg/logo.png",
      fbTitle: "Putra Minang",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.putraminangsg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.putraminangsg.com/#/",
      twTitile: "Putra Minang",
      twvia: "Putra Minang",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.putraminangsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.putraminangsg.com/#/",
      waSeparator: "Putra Minang",
    },
    site_title: "Putra Minang",
    forcewww: "YES",
    site_title_with_separator: " | Putra Minang",
    home_meta_title: "Welcome to Putra Minang",
    bgimage: "/img/ninjaprocmn/putraminangsg/body_bg.jpg",
  },
  "www.orientaldesserts.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3CB2DAE9-8FCE-4A3D-BD9B-ECBE2A71E484",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Oriental Desserts",
    stripeImage:
      "https://www.orientaldesserts.com.sg/img/ninjaprocmn/orientaldesserts/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "orientaldesserts",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.orientaldesserts.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.orientaldesserts.com.sg/#/",
      fbImage:
        "https://www.orientaldesserts.com.sg/img/orientaldesserts/logo.png",
      fbTitle: "Oriental Desserts",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.orientaldesserts.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.orientaldesserts.com.sg/#/",
      twTitile: "Oriental Desserts",
      twvia: "Oriental Desserts",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.orientaldesserts.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.orientaldesserts.com.sg/#/",
      waSeparator: "Oriental Desserts",
    },
    site_title: "Oriental Desserts",
    forcewww: "YES",
    site_title_with_separator: " | Oriental Desserts",
    home_meta_title: "Welcome to Oriental Desserts",
    bgimage: "/img/ninjaprocmn/orientaldesserts/body_bg.jpg",
  },
  "order.sgmy.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E155EED2-6C5B-442F-A14E-CBFCDF350E09",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Auriga Culinary",
    stripeImage:
      "https://order.sgmy.com.sg/img/ninjaprocmn/sgmy/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sgmy",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.sgmy.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.sgmy.com.sg/#/",
      fbImage: "https://order.sgmy.com.sg/img/sgmy/logo.png",
      fbTitle: "Auriga Culinary",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.sgmy.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.sgmy.com.sg/#/",
      twTitile: "Auriga Culinary",
      twvia: "Auriga Culinary",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.sgmy.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.sgmy.com.sg/#/",
      waSeparator: "Auriga Culinary",
    },
    site_title: "Auriga Culinary",
    site_title_with_separator: " | Auriga Culinary",
    home_meta_title: "Welcome to Auriga Culinary",
    bgimage: "/img/ninjaprocmn/sgmy/body_bg.jpg",
  },
  "order.lowercase.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "118FDED9-B1D3-455D-90A9-5C8BE9B880FF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Broun Cafe",
    stripeImage:
      "https://order.lowercase.sg/img/ninjaprocmn/lowercase/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lowercase",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.lowercase.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.lowercase.sg/#/",
      fbImage: "https://order.lowercase.sg/img/lowercase/logo.png",
      fbTitle: "Broun Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.lowercase.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.lowercase.sg/#/",
      twTitile: "Broun Cafe",
      twvia: "Broun Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.lowercase.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.lowercase.sg/#/",
      waSeparator: "Broun Cafe",
    },
    site_title: "Broun Cafe",
    site_title_with_separator: " | Broun Cafe",
    home_meta_title: "Welcome to Broun Cafe ",
    bgimage: "/img/ninjaprocmn/lowercase/body_bg.jpg",
  },
  "order.ciel.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "7A4DF3BB-B383-4D1F-8986-C157BE4C18A9",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ciel Patisserie",
    stripeImage:
      "https://order.ciel.com.sg/img/ninjaprocmn/ciel/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ciel",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ciel.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ciel.com.sg/#/",
      fbImage: "https://order.ciel.com.sg/img/ciel/logo.png",
      fbTitle: "Ciel Patisserie",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ciel.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ciel.com.sg/#/",
      twTitile: "Ciel Patisserie",
      twvia: "Ciel Patisserie",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ciel.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ciel.com.sg/#/",
      waSeparator: "Ciel Patisserie",
    },
    site_title: "Ciel Patisserie",
    site_title_with_separator: " | Ciel Patisserie",
    home_meta_title: "Welcome to Ciel Patisserie",
    bgimage: "/img/ninjaprocmn/ciel/body_bg.jpg",
  },
  "order.forknspoonsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1DA36208-F0D4-4B33-ADE6-7707AC709FFA",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Fork and Spoon",
    stripeImage:
      "https://order.forknspoonsg.com/img/ninjaprocmn/forknspoonsg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "forknspoonsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.forknspoonsg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.forknspoonsg.com/#/",
      fbImage: "https://order.forknspoonsg.com/img/forknspoonsg/logo.png",
      fbTitle: "Fork and Spoon",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.forknspoonsg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.forknspoonsg.com/#/",
      twTitile: "Fork and Spoon",
      twvia: "Fork and Spoon",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.forknspoonsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.forknspoonsg.com/#/",
      waSeparator: "Fork and Spoon",
    },
    site_title: "Fork and Spoon",
    site_title_with_separator: " | Fork and Spoon",
    home_meta_title: "Welcome to Fork and Spoon",
    bgimage: "/img/ninjaprocmn/forknspoonsg/body_bg.jpg",
  },
  "www.sedapwok.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1111111",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Siam Wok",
    stripeImage:
      "https://www.sedapwok.com/img/ninjaprocmn/sedapwok/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sedapwok",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.sedapwok.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.sedapwok.com/#/",
      fbImage: "https://www.sedapwok.com/img/sedapwok/logo.png",
      fbTitle: "Siam Wok",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.sedapwok.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.sedapwok.com/#/",
      twTitile: "Siam Wok",
      twvia: "Siam Wok",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.sedapwok.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.sedapwok.com/#/",
      waSeparator: "Siam Wok",
    },
    site_title: "Siam Wok",
    forcewww: "YES",
    site_title_with_separator: " | Siam Wok",
    home_meta_title: "Welcome to Siam Wok",
    bgimage: "/img/ninjaprocmn/sedapwok/body_bg.jpg",
  },
  "order.walkingonsunshine.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4696350D-3670-4E1D-90ED-2008EDAC8E88",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Walking on Sunshine Cafe",
    stripeImage:
      "https://order.walkingonsunshine.sg/img/ninjaprocmn/walkingonsunshine/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "walkingonsunshine",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.walkingonsunshine.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.walkingonsunshine.sg/#/",
      fbImage:
        "https://order.walkingonsunshine.sg/img/walkingonsunshine/logo.png",
      fbTitle: "Walking on Sunshine Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.walkingonsunshine.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.walkingonsunshine.sg/#/",
      twTitile: "Walking on Sunshine Cafe",
      twvia: "Walking on Sunshine Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.walkingonsunshine.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.walkingonsunshine.sg/#/",
      waSeparator: "Walking on Sunshine Cafe",
    },
    site_title: "Walking on Sunshine Cafe",
    site_title_with_separator: " | Walking on Sunshine Cafe",
    home_meta_title: "Welcome to Walking on Sunshine Cafe",
    bgimage: "/img/ninjaprocmn/walkingonsunshine/body_bg.jpg",
  },
  "www.halalketobyswitch.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F97CD809-E010-496C-8C45-804CCADAF331",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Switch Kitchen",
    stripeImage:
      "https://www.halalketobyswitch.com/img/ninjaprocmn/halalketobyswitch/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "halalketobyswitch",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.halalketobyswitch.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.halalketobyswitch.com/#/",
      fbImage:
        "https://www.halalketobyswitch.com/img/halalketobyswitch/logo.png",
      fbTitle: "Switch Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.halalketobyswitch.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.halalketobyswitch.com/#/",
      twTitile: "Switch Kitchen",
      twvia: "Switch Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.halalketobyswitch.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.halalketobyswitch.com/#/",
      waSeparator: "Switch Kitchen",
    },
    site_title: "Switch Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Switch Kitchen",
    home_meta_title: "Welcome to Switch Kitchen",
    bgimage: "/img/ninjaprocmn/halalketobyswitch/body_bg.jpg",
  },
  "www.pu3restaurantsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D0F92B76-AF0D-45A0-B784-C80054AE556B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "PU3 Restaurantsg",
    stripeImage:
      "https://www.pu3restaurantsg.com/img/ninjaprocmn/halalketobyswitch/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pu3restaurantsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.pu3restaurantsg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.pu3restaurantsg.com/#/",
      fbImage: "https://www.pu3restaurantsg.com/img/pu3restaurantsg/logo.png",
      fbTitle: "PU3 Restaurantsg",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.pu3restaurantsg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.pu3restaurantsg.com/#/",
      twTitile: "PU3 Restaurantsg",
      twvia: "PU3 Restaurantsg",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.pu3restaurantsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.pu3restaurantsg.com/#/",
      waSeparator: "PU3 Restaurantsg",
    },
    site_title: "PU3 Restaurantsg",
    forcewww: "YES",
    site_title_with_separator: " | PU3 Restaurantsg",
    home_meta_title: "Welcome to PU3 Restaurantsg",
    bgimage: "/img/ninjaprocmn/pu3restaurantsg/body_bg.jpg",
  },
  "orders.stephanieebakery.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5C01570D-9780-42C8-9C67-5C7297BE985A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Stephanie eBakery",
    stripeImage:
      "https://orders.stephanieebakery.sg/img/ninjaprocmn/stephanieebakery/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "stephanieebakery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://orders.stephanieebakery.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://orders.stephanieebakery.sg/#/",
      fbImage:
        "https://orders.stephanieebakery.sg/img/stephanieebakery/logo.png",
      fbTitle: "Stephanie eBakery",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://orders.stephanieebakery.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://orders.stephanieebakery.sg/#/",
      twTitile: "Stephanie eBakery",
      twvia: "Stephanie eBakery",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://orders.stephanieebakery.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://orders.stephanieebakery.sg/#/",
      waSeparator: "Stephanie eBakery",
    },
    site_title: "Stephanie eBakery",
    site_title_with_separator: " | Stephanie eBakery",
    home_meta_title: "Welcome to Stephanie eBakery",
    bgimage: "/img/ninjaprocmn/stephanieebakery/body_bg.jpg",
  },
  "order.sonasgrill.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "20EEEAFC-E290-48CF-9609-672D8DCA4BF5",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sona’s Grill",
    stripeImage:
      "https://order.sonasgrill.com/img/ninjaprocmn/sonasgrill/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sonasgrill",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.sonasgrill.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.sonasgrill.com/#/",
      fbImage: "https://order.sonasgrill.com/img/sonasgrill/logo.png",
      fbTitle: "Sona’s Grill",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.sonasgrill.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.sonasgrill.com/#/",
      twTitile: "Sona’s Grill",
      twvia: "Sona’s Grill",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.sonasgrill.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.sonasgrill.com/#/",
      waSeparator: "Sona’s Grill",
    },
    site_title: "Sona’s Grill",
    site_title_with_separator: " | Sona’s Grill",
    home_meta_title: "Welcome to Sona’s Grill",
    bgimage: "/img/ninjaprocmn/sonasgrill/body_bg.jpg",
  },
  "order.shifudo.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "655AE08E-3AB6-4A4B-9685-2BBDCB555922",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "SHIFUDO",
    stripeImage:
      "https://order.shifudo.sg/img/ninjaprocmn/shifudo/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "shifudo",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.shifudo.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.shifudo.sg/#/",
      fbImage: "https://order.shifudo.sg/img/shifudo/logo.png",
      fbTitle: "SHIFUDO",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.shifudo.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.shifudo.sg/#/",
      twTitile: "SHIFUDO",
      twvia: "SHIFUDO",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.shifudo.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.shifudo.sg/#/",
      waSeparator: "SHIFUDO",
    },
    site_title: "SHIFUDO",
    site_title_with_separator: " | SHIFUDO",
    home_meta_title: "Welcome to SHIFUDO",
    bgimage: "/img/ninjaprocmn/shifudo/body_bg.jpg",
  },
  "www.seleramerlyn.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9D54028B-CFFD-4969-9F6A-63871D4E3470",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "SELERA MERLYN",
    stripeImage:
      "https://www.seleramerlyn.com/img/ninjaprocmn/seleramerlyn/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "seleramerlyn",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.seleramerlyn.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.seleramerlyn.com/#/",
      fbImage: "https://www.seleramerlyn.com/img/seleramerlyn/logo.png",
      fbTitle: "SELERA MERLYN",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.seleramerlyn.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.seleramerlyn.com/#/",
      twTitile: "SELERA MERLYN",
      twvia: "SELERA MERLYN",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.seleramerlyn.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.seleramerlyn.com/#/",
      waSeparator: "SELERA MERLYN",
    },
    site_title: "SELERA MERLYN",
    forcewww: "YES",
    site_title_with_separator: " | SELERA MERLYN",
    home_meta_title: "Welcome to SELERA MERLYN",
    bgimage: "/img/ninjaprocmn/seleramerlyn/body_bg.jpg",
  },
  "www.chemilanofficial.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "CADEC225-ECA2-410F-B703-FB89FD19ECED",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Chemilan",
    stripeImage:
      "https://www.chemilanofficial.com/img/ninjaprocmn/chemilanofficial/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "chemilanofficial",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.chemilanofficial.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.chemilanofficial.com/#/",
      fbImage: "https://www.chemilanofficial.com/img/chemilanofficial/logo.png",
      fbTitle: "Chemilan",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.chemilanofficial.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.chemilanofficial.com/#/",
      twTitile: "Chemilan",
      twvia: "Chemilan",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.chemilanofficial.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.chemilanofficial.com/#/",
      waSeparator: "Chemilan",
    },
    site_title: "Chemilan",
    forcewww: "YES",
    site_title_with_separator: " | Chemilan",
    home_meta_title: "Welcome to Chemilan",
    bgimage: "/img/ninjaprocmn/chemilanofficial/body_bg.jpg",
  },
  "holland.wangdaebakbbq.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DB6B3299-45AE-44F4-AC0C-50FCEE32B04B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Wang Dae Bak Korean",
    stripeImage:
      "https://holland.wangdaebakbbq.sg/img/ninjaprocmn/amoy/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "amoy",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://holland.wangdaebakbbq.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://holland.wangdaebakbbq.sg/#/",
      fbImage: "https://holland.wangdaebakbbq.sg/img/amoy/logo.png",
      fbTitle: "Wang Dae Bak Korean",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://holland.wangdaebakbbq.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://holland.wangdaebakbbq.sg/#/",
      twTitile: "Wang Dae Bak Korean",
      twvia: "Wang Dae Bak Korean",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://holland.wangdaebakbbq.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://holland.wangdaebakbbq.sg/#/",
      waSeparator: "Wang Dae Bak Korean",
    },
    site_title: "Wang Dae Bak Korean",
    site_title_with_separator: " | Wang Dae Bak Korean",
    home_meta_title: "Welcome to Wang Dae Bak Korean",
    bgimage: "/img/ninjaprocmn/amoy/body_bg.jpg",
  },
  "order.kalindiskuzines.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "614F3FD1-8659-4FD0-BF07-0560BC039AE6",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kalindis Kuzines",
    stripeImage:
      "https://order.kalindiskuzines.com/img/ninjaprocmn/kalindiskuzines/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kalindiskuzines",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.kalindiskuzines.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.kalindiskuzines.com/#/",
      fbImage: "https://order.kalindiskuzines.com/img/kalindiskuzines/logo.png",
      fbTitle: "Kalindis Kuzines",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.kalindiskuzines.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.kalindiskuzines.com/#/",
      twTitile: "Kalindis Kuzines",
      twvia: "Kalindis Kuzines",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.kalindiskuzines.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.kalindiskuzines.com/#/",
      waSeparator: "Kalindis Kuzines",
    },
    site_title: "Kalindis Kuzines",
    site_title_with_separator: " | Kalindis Kuzines",
    home_meta_title: "Welcome to Kalindis Kuzines",
    bgimage: "/img/ninjaprocmn/kalindiskuzines/body_bg.jpg",
  },
  "www.thaistreet.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "1B62C926-EE9B-4B4A-A83E-908FC10AC8D6",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Thai Street",
    stripeImage:
      "https://www.thaistreet.sg/img/ninjaprocmn/thaistreet/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thaistreet",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.thaistreet.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.thaistreet.sg/#/",
      fbImage: "https://www.thaistreet.sg/img/thaistreet/logo.png",
      fbTitle: "Thai Street",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.thaistreet.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.thaistreet.sg/#/",
      twTitile: "Thai Street",
      twvia: "Thai Street",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.thaistreet.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.thaistreet.sg/#/",
      waSeparator: "Thai Street",
    },
    site_title: "Thai Street",
    forcewww: "YES",
    site_title_with_separator: " | Thai Street",
    home_meta_title: "Welcome to Thai Street",
    bgimage: "/img/ninjaprocmn/thaistreet/body_bg.jpg",
  },
  "www.ruhesg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "EF059958-1F29-4B55-A6B4-E4861FE565DF",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "RUHE",
    stripeImage:
      "https://www.ruhesg.com/img/ninjaprocmn/socialhideoutsg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ruhesg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ruhesg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ruhesg.com/#/",
      fbImage: "https://www.ruhesg.com/img/socialhideoutsg/logo.png",
      fbTitle: "RUHE",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ruhesg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ruhesg.com/#/",
      twTitile: "RUHE",
      twvia: "RUHE",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.ruhesg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.ruhesg.com/#/",
      waSeparator: "RUHE",
    },
    site_title: "RUHE",
    forcewww: "YES",
    site_title_with_separator: " | RUHE",
    home_meta_title: "Welcome to RUHE",
    bgimage: "/img/ninjaprocmn/socialhideoutsg/body_bg.jpg",
  },
  "www.tasteforyousg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DB73A28F-7F93-46BD-AD24-5A9EBF7E181C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Taste For You",
    stripeImage:
      "https://www.tasteforyousg.com/img/ninjaprocmn/tasteforyousg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "tasteforyousg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.tasteforyousg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.tasteforyousg.com/#/",
      fbImage: "https://www.tasteforyousg.com/img/tasteforyousg/logo.png",
      fbTitle: "Taste For You",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.tasteforyousg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.tasteforyousg.com/#/",
      twTitile: "Taste For You",
      twvia: "Taste For You",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.tasteforyousg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.tasteforyousg.com/#/",
      waSeparator: "Taste For You",
    },
    site_title: "Taste For You",
    forcewww: "YES",
    site_title_with_separator: " | Taste For You",
    home_meta_title: "Welcome to Taste For You",
    bgimage: "/img/ninjaprocmn/tasteforyousg/body_bg.jpg",
  },
  "order.fatsaigonboy.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "179E5292-2AD0-4C66-A68F-DFAA888E60A2",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Taste For You",
    stripeImage:
      "https://order.fatsaigonboy.com/img/ninjaprocmn/fatsaigonboy/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fatsaigonboy",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.fatsaigonboy.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.fatsaigonboy.com/#/",
      fbImage: "https://order.fatsaigonboy.com/img/fatsaigonboy/logo.png",
      fbTitle: "Fat Saigon Boy",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.fatsaigonboy.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.fatsaigonboy.com/#/",
      twTitile: "Fat Saigon Boy",
      twvia: "Fat Saigon Boy",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.fatsaigonboy.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.fatsaigonboy.com/#/",
      waSeparator: "Fat Saigon Boy",
    },
    site_title: "Fat Saigon Boy",
    site_title_with_separator: " | Fat Saigon Boy",
    home_meta_title: "Welcome to Fat Saigon Boy",
    bgimage: "/img/ninjaprocmn/fatsaigonboy/body_bg.jpg",
  },
  "order.homekitchen.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "96D2729C-D822-438A-A6BF-54A000DC3F58",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "HOME KITCHEN",
    stripeImage:
      "https://order.homekitchen.com.sg/img/ninjaprocmn/homekitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "homekitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.homekitchen.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.homekitchen.com.sg/#/",
      fbImage: "https://order.homekitchen.com.sg/img/homekitchen/logo.png",
      fbTitle: "HOME KITCHEN",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.homekitchen.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.homekitchen.com.sg/#/",
      twTitile: "HOME KITCHEN",
      twvia: "HOME KITCHEN",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.homekitchen.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.homekitchen.com.sg/#/",
      waSeparator: "HOME KITCHEN",
    },
    site_title: "HOME KITCHEN",
    site_title_with_separator: " | HOME KITCHEN",
    home_meta_title: "Welcome to HOME KITCHEN",
    bgimage: "/img/ninjaprocmn/homekitchen/body_bg.jpg",
  },
  "www.hometalkscafe.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E53E9643-7E94-4C03-9C4B-D7B0C6650CB7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Home Talks Cafe",
    stripeImage:
      "https://www.hometalkscafe.com/img/ninjaprocmn/hometalkscafe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hometalkscafe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.hometalkscafe.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.hometalkscafe.com/#/",
      fbImage: "https://www.hometalkscafe.com/img/hometalkscafe/logo.png",
      fbTitle: "Home Talks Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.hometalkscafe.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.hometalkscafe.com/#/",
      twTitile: "Home Talks Cafe",
      twvia: "Home Talks Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.hometalkscafe.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.hometalkscafe.com/#/",
      waSeparator: "Home Talks Cafe",
    },
    site_title: "Home Talks Cafe",
    forcewww: "YES",
    site_title_with_separator: " | Home Talks Cafe",
    home_meta_title: "Welcome to Home Talks Cafe",
    bgimage: "/img/ninjaprocmn/hometalkscafe/body_bg.jpg",
  },
  "www.teasocialsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "86E93BFA-26B5-4961-A0EA-F7D294A47B56",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tea Social Cafe",
    stripeImage:
      "https://www.teasocialsg.com/img/ninjaprocmn/teasocialsg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "teasocialsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.teasocialsg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.teasocialsg.com/#/",
      fbImage: "https://www.teasocialsg.com/img/teasocialsg/logo.png",
      fbTitle: "Tea Social Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.teasocialsg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.teasocialsg.com/#/",
      twTitile: "Tea Social Cafe",
      twvia: "Tea Social Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.teasocialsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.teasocialsg.com/#/",
      waSeparator: "Tea Social Cafe",
    },
    site_title: "Tea Social Cafe",
    forcewww: "YES",
    site_title_with_separator: " | Tea Social Cafe",
    home_meta_title: "Welcome to Tea Social Cafe",
    bgimage: "/img/ninjaprocmn/teasocialsg/body_bg.jpg",
  },
  "www.curryncurry.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "62A11668-6068-47C7-AC12-4CC4F036A452",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Curry & Curry",
    stripeImage:
      "https://www.curryncurry.com/img/ninjaprocmn/curryncurry/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "curryncurry",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.curryncurry.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.curryncurry.com/#/",
      fbImage: "https://www.curryncurry.com/img/curryncurry/logo.png",
      fbTitle: "Curry & Curry",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.curryncurry.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.curryncurry.com/#/",
      twTitile: "Curry & Curry",
      twvia: "Curry & Curry",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.curryncurry.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.curryncurry.com/#/",
      waSeparator: "Curry & Curry",
    },
    site_title: "Curry & Curry",
    forcewww: "YES",
    site_title_with_separator: " | Curry & Curry",
    home_meta_title: "Welcome to Curry & Curry",
    bgimage: "/img/ninjaprocmn/curryncurry/body_bg.jpg",
  },
  "order.53galaxyspace.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E0FB2263-1246-4E43-B25C-6743B013F142",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Toxic Room",
    stripeImage:
      "https://order.53galaxyspace.com/img/ninjaprocmn/toxicroom/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "toxicroom",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.53galaxyspace.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.53galaxyspace.com/#/",
      fbImage: "https://order.53galaxyspace.com/img/toxicroom/logo.png",
      fbTitle: "Toxic Room",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.53galaxyspace.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.53galaxyspace.com/#/",
      twTitile: "Toxic Room",
      twvia: "Toxic Room",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.53galaxyspace.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.53galaxyspace.com/#/",
      waSeparator: "Toxic Room",
    },
    site_title: "Toxic Room",
    site_title_with_separator: " | Toxic Room",
    home_meta_title: "Welcome to Toxic Room",
    bgimage: "/img/ninjaprocmn/toxicroom/body_bg.jpg",
  },
  "order.bacos.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "00167E5E-67A7-42E3-85EB-8D80342FACF1",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "BACOS THE DINE",
    stripeImage: "https://order.bacos.sg/img/ninjaprocmn/bacos/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "bacos",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.bacos.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.bacos.sg/#/",
      fbImage: "https://order.bacos.sg/img/bacos/logo.png",
      fbTitle: "BACOS THE DINE",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.bacos.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.bacos.sg/#/",
      twTitile: "BACOS THE DINE",
      twvia: "BACOS THE DINE",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.bacos.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.bacos.sg/#/",
      waSeparator: "BACOS THE DINE",
    },
    site_title: "BACOS THE DINE",
    site_title_with_separator: " | BACOS THE DINE",
    home_meta_title: "Welcome to BACOS THE DINE",
    bgimage: "/img/ninjaprocmn/bacos/body_bg.jpg",
  },
  "www.honolulucafesg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B637EE61-A5A9-4303-ACF7-4A3C055DC845",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Honolulu Cafe",
    stripeImage:
      "https://www.honolulucafesg.com/img/ninjaprocmn/honolulu/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "honolulu",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.honolulucafesg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.honolulucafesg.com/#/",
      fbImage: "https://www.honolulucafesg.com/img/honolulu/logo.png",
      fbTitle: "Honolulu Cafe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.honolulucafesg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.honolulucafesg.com/#/",
      twTitile: "Honolulu Cafe",
      twvia: "Honolulu Cafe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.honolulucafesg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.honolulucafesg.com/#/",
      waSeparator: "Honolulu Cafe",
    },
    site_title: "Honolulu Cafe",
    forcewww: "YES",
    site_title_with_separator: " | Honolulu Cafe",
    home_meta_title: "Welcome to Honolulu Cafe",
    bgimage: "/img/ninjaprocmn/honolulu/body_bg.jpg",
  },
  "www.nainaskitchensg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0253AE3F-3989-450A-9FC4-897A3458A27A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Nainas Kitchen",
    stripeImage:
      "https://www.nainaskitchensg.com/img/ninjaprocmn/nainaskitchensg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "nainaskitchensg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.nainaskitchensg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.nainaskitchensg.com/#/",
      fbImage: "https://www.nainaskitchensg.com/img/nainaskitchensg/logo.png",
      fbTitle: "Nainas Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.nainaskitchensg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.nainaskitchensg.com/#/",
      twTitile: "Nainas Kitchen",
      twvia: "Nainas Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.nainaskitchensg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.nainaskitchensg.com/#/",
      waSeparator: "Nainas Kitchen",
    },
    site_title: "Nainas Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Nainas Kitchen",
    home_meta_title: "Welcome to Nainas Kitchen",
    bgimage: "/img/ninjaprocmn/nainaskitchensg/body_bg.jpg",
  },
  "www.nhungkitchen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "BF2000BF-9B3C-43F8-BA14-CD0899A4E7DD",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Nhung Kitchen",
    stripeImage:
      "https://www.nhungkitchen.com/img/ninjaprocmn/nhungkitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "nhungkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.nhungkitchen.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.nhungkitchen.com/#/",
      fbImage: "https://www.nhungkitchen.com/img/nhungkitchen/logo.png",
      fbTitle: "Nhung Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.nhungkitchen.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.nhungkitchen.com/#/",
      twTitile: "Nhung Kitchen",
      twvia: "Nhung Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.nhungkitchen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.nhungkitchen.com/#/",
      waSeparator: "Nhung Kitchen",
    },
    site_title: "Nhung Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Nhung Kitchen",
    home_meta_title: "Welcome to Nhung Kitchen",
    bgimage: "/img/ninjaprocmn/nhungkitchen/body_bg.jpg",
  },
  "order.fluffbakery.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "9F47BAE5-9A6B-4FB1-81ED-260C8C4A6533",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Fluff Bakery",
    stripeImage:
      "https://order.fluffbakery.sg/img/ninjaprocmn/fluffbakery/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fluffbakery",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.fluffbakery.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.fluffbakery.sg/#/",
      fbImage: "https://order.fluffbakery.sg/img/fluffbakery/logo.png",
      fbTitle: "Fluff Bakery",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.fluffbakery.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.fluffbakery.sg/#/",
      twTitile: "Fluff Bakery",
      twvia: "Fluff Bakery",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.fluffbakery.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.fluffbakery.sg/#/",
      waSeparator: "Fluff Bakery",
    },
    site_title: "Fluff Bakery",
    site_title_with_separator: " | Fluff Bakery",
    home_meta_title: "Welcome to Fluff Bakery",
    bgimage: "/img/ninjaprocmn/fluffbakery/body_bg.jpg",
  },
  "order.yogurtlady.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "41EBC14C-5BEC-4051-97F7-ACE106222F11",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Yogurt Lady",
    stripeImage:
      "https://order.yogurtlady.sg/img/ninjaprocmn/yogurtlady/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yogurtlady",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.yogurtlady.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.yogurtlady.sg/#/",
      fbImage: "https://order.yogurtlady.sg/img/yogurtlady/logo.png",
      fbTitle: "Yogurt Lady",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.yogurtlady.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.yogurtlady.sg/#/",
      twTitile: "Yogurt Lady",
      twvia: "Yogurt Lady",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.yogurtlady.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.yogurtlady.sg/#/",
      waSeparator: "Yogurt Lady",
    },
    site_title: "Yogurt Lady",
    site_title_with_separator: " | Yogurt Lady",
    home_meta_title: "Welcome to Yogurt Lady",
    bgimage: "/img/ninjaprocmn/yogurtlady/body_bg.jpg",
  },
  "order.yummyraja.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4175698E-03E3-4815-8798-F438A3265C75",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Yummy Raja",
    stripeImage:
      "https://order.yummyraja.com/img/ninjaprocmn/yummyraja/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yummyraja",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.yummyraja.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.yummyraja.com/#/",
      fbImage: "https://order.yummyraja.com/img/yummyraja/logo.png",
      fbTitle: "Yummy Raja",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.yummyraja.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.yummyraja.com/#/",
      twTitile: "Yummy Raja",
      twvia: "Yummy Raja",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.yummyraja.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.yummyraja.com/#/",
      waSeparator: "Yummy Raja",
    },
    site_title: "Yummy Raja",
    site_title_with_separator: " | Yummy Raja",
    home_meta_title: "Welcome to Yummy Raja",
    bgimage: "/img/ninjaprocmn/yummyraja/body_bg.jpg",
  },
  "order.kruabaanthai.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "CA78F793-7987-4656-9B6A-B101C89EABB1",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Krua Baan Thai",
    stripeImage:
      "https://order.kruabaanthai.sg/img/ninjaprocmn/kruabaanthai/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kruabaanthai",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.kruabaanthai.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.kruabaanthai.sg/#/",
      fbImage: "https://order.kruabaanthai.sg/img/kruabaanthai/logo.png",
      fbTitle: "Krua Baan Thai",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.kruabaanthai.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.kruabaanthai.sg/#/",
      twTitile: "Krua Baan Thai",
      twvia: "Krua Baan Thai",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.kruabaanthai.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.kruabaanthai.sg/#/",
      waSeparator: "Krua Baan Thai",
    },
    site_title: "Krua Baan Thai",
    site_title_with_separator: " | Krua Baan Thai",
    home_meta_title: "Welcome to Krua Baan Thai",
    bgimage: "/img/ninjaprocmn/kruabaanthai/body_bg.jpg",
  },
  "www.eacsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "8AAB3D00-C15E-4D95-9573-239A1500FFC5",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Each a Cup",
    stripeImage: "https://www.eacsg.com/img/ninjaprocmn/eacsg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "eacsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.eacsg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.eacsg.com/#/",
      fbImage: "https://www.eacsg.com/img/eacsg/logo.png",
      fbTitle: "Each a Cup",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.eacsg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.eacsg.com/#/",
      twTitile: "Each a Cup",
      twvia: "Each a Cup",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.eacsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.eacsg.com/#/",
      waSeparator: "Each a Cup",
    },
    site_title: "Each a Cup",
    forcewww: "YES",
    site_title_with_separator: " | Each a Cup",
    home_meta_title: "Welcome to Each a Cup",
    bgimage: "/img/ninjaprocmn/eacsg/body_bg.jpg",
  },
  "www.fengzhensg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "529914DF-266E-462E-9511-3E4EBB806AE9",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Yew Tee Feng Zhen Lor Mee",
    stripeImage:
      "https://www.fengzhensg.com/img/ninjaprocmn/fengzhensg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fengzhensg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.fengzhensg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.fengzhensg.com/#/",
      fbImage: "https://www.fengzhensg.com/img/fengzhensg/logo.png",
      fbTitle: "Yew Tee Feng Zhen Lor Mee",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.fengzhensg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.fengzhensg.com/#/",
      twTitile: "Yew Tee Feng Zhen Lor Mee",
      twvia: "Yew Tee Feng Zhen Lor Mee",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.fengzhensg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.fengzhensg.com/#/",
      waSeparator: "Yew Tee Feng Zhen Lor Mee",
    },
    site_title: "Yew Tee Feng Zhen Lor Mee",
    forcewww: "YES",
    site_title_with_separator: " | Yew Tee Feng Zhen Lor Mee",
    home_meta_title: "Welcome to Yew Tee Feng Zhen Lor Mee",
    bgimage: "/img/ninjaprocmn/fengzhensg/body_bg.jpg",
  },
  "order.ekiben.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "014D1A83-2F46-4074-B6AF-83413A3E654D",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ekiben",
    stripeImage:
      "https://order.ekiben.sg/img/ninjaprocmn/ekiben/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ekiben",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.ekiben.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.ekiben.sg/#/",
      fbImage: "https://order.ekiben.sg/img/ekiben/logo.png",
      fbTitle: "Ekiben",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.ekiben.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.ekiben.sg/#/",
      twTitile: "Ekiben",
      twvia: "Ekiben",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.ekiben.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.ekiben.sg/#/",
      waSeparator: "Ekiben",
    },
    site_title: "Ekiben",
    site_title_with_separator: " | Ekiben",
    home_meta_title: "Welcome to Ekiben",
    bgimage: "/img/ninjaprocmn/ekiben/body_bg.jpg",
  },
  "www.vietspicesg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D9C6C55E-EBCA-4C4C-8D89-A5E11BB876D9",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "VietSpice",
    stripeImage:
      "https://www.vietspicesg.com/img/ninjaprocmn/vietspicesg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "vietspicesg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.vietspicesg.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.vietspicesg.com/#/",
      fbImage: "https://www.vietspicesg.com/img/vietspicesg/logo.png",
      fbTitle: "VietSpice",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.vietspicesg.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.vietspicesg.com/#/",
      twTitile: "VietSpice",
      twvia: "VietSpice",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.vietspicesg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.vietspicesg.com/#/",
      waSeparator: "VietSpice",
    },
    site_title: "VietSpice",
    forcewww: "YES",
    site_title_with_separator: " | VietSpice",
    home_meta_title: "Welcome to VietSpice",
    bgimage: "/img/ninjaprocmn/vietspicesg/body_bg.jpg",
  },
  "www.vintagetreecatering.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A226445E-1396-4AFD-AF4E-601898E17984",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Vintagetree",
    stripeImage:
      "https://www.vintagetreecatering.com/img/ninjaprocmn/vintagetreecatering/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "vintagetreecatering",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.vintagetreecatering.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.vintagetreecatering.com/#/",
      fbImage:
        "https://www.vintagetreecatering.com/img/vintagetreecatering/logo.png",
      fbTitle: "Vintagetree",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.vintagetreecatering.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.vintagetreecatering.com/#/",
      twTitile: "Vintagetree",
      twvia: "Vintagetree",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.vintagetreecatering.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.vintagetreecatering.com/#/",
      waSeparator: "Vintagetree",
    },
    site_title: "Vintagetree",
    forcewww: "YES",
    site_title_with_separator: " | Vintagetree",
    home_meta_title: "Welcome to Vintagetree",
    bgimage: "/img/ninjaprocmn/vintagetreecatering/body_bg.jpg",
  },
  "order.smokinjoe.com.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E98B5F15-D7FE-4C7C-9D9E-C24469278849",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Smokin Joe",
    stripeImage:
      "https://order.smokinjoe.com.sg/img/ninjaprocmn/smokinjoe/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "smokinjoe",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.smokinjoe.com.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.smokinjoe.com.sg/#/",
      fbImage: "https://order.smokinjoe.com.sg/img/smokinjoe/logo.png",
      fbTitle: "Smokin Joe",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.smokinjoe.com.sg /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.smokinjoe.com.sg/#/",
      twTitile: "Smokin Joe",
      twvia: "Smokin Joe",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.smokinjoe.com.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.smokinjoe.com.sg/#/",
      waSeparator: "Smokin Joe",
    },
    site_title: "Smokin Joe",
    site_title_with_separator: " | Smokin Joe",
    home_meta_title: "Welcome to Smokin Joe",
    bgimage: "/img/ninjaprocmn/smokinjoe/body_bg.jpg",
  },
  "ninjaprodemo.ninjaos.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B19417EE-1A66-4F3E-B056-0A2B1E797F9B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "NinjaPro Demo",
    stripeImage:
      "https://ninjaprodemo.ninjaos.com/img/ninjaprocmn/ninjaprodemo/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ninjaprodemo",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://ninjaprodemo.ninjaos.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://ninjaprodemo.ninjaos.com/#/",
      fbImage: "https://ninjaprodemo.ninjaos.com/img/ninjaprodemo/logo.png",
      fbTitle: "NinjaPro Demo",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://ninjaprodemo.ninjaos.com /",
    },
    /* Twitter  */
    tw: {
      twURL: "https://ninjaprodemo.ninjaos.com/#/",
      twTitile: "NinjaPro Demo",
      twvia: "NinjaPro Demo",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://ninjaprodemo.ninjaos.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://ninjaprodemo.ninjaos.com/#/",
      waSeparator: "NinjaPro Demo",
    },
    site_title: "NinjaPro Demo",
    site_title_with_separator: " | NinjaPro Demo",
    home_meta_title: "Welcome to NinjaPro Demo",
    bgimage: "/img/ninjaprocmn/ninjaprodemo/body_bg.jpg",
  },
  "order.liulianman.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5C231373-6EFC-49DF-8EB5-90D874A55E96",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Liu Lian Man",
    stripeImage:
      "https://order.liulianman.com/img/ninjaprocmn/liulianman/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "liulianman",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.liulianman.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.liulianman.com/#/",
      fbImage: "https://order.liulianman.com/img/liulianman/logo.png",
      fbTitle: "Liu Lian Man",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.liulianman.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.liulianman.com/#/",
      twTitile: "Liu Lian Man",
      twvia: "Liu Lian Man",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.liulianman.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.liulianman.com/#/",
      waSeparator: "Liu Lian Man",
    },
    site_title: "Liu Lian Man",
    site_title_with_separator: " | Liu Lian Man",
    home_meta_title: "Welcome to Liu Lian Man",
    bgimage: "/img/ninjaprocmn/liulianman/body_bg.jpg",
  },
  "www.taterist.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E636FC8A-DC6B-4F04-ADA1-F83BFE53E219",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Taterist",
    stripeImage:
      "https://www.taterist.com/img/ninjaprocmn/taterist/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "taterist",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.taterist.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.taterist.com/#/",
      fbImage: "https://www.taterist.com/img/taterist/logo.png",
      fbTitle: "Taterist",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.taterist.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.taterist.com/#/",
      twTitile: "Taterist",
      twvia: "Taterist",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.taterist.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.taterist.com/#/",
      waSeparator: "Taterist",
    },
    site_title: "Taterist",
    forcewww: "YES",
    site_title_with_separator: " | Taterist",
    home_meta_title: "Welcome to Taterist",
    bgimage: "/img/ninjaprocmn/taterist/body_bg.jpg",
  },
  "www.karnivalsingapore.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "352A0F60-2894-44FD-AF9E-3D23C3395FF3",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Karnival Singapore",
    stripeImage:
      "https://www.karnivalsingapore.com/img/ninjaprocmn/karnivalsingapore/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "karnivalsingapore",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.karnivalsingapore.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.karnivalsingapore.com/#/",
      fbImage:
        "https://www.karnivalsingapore.com/img/karnivalsingapore/logo.png",
      fbTitle: "Karnival Singapore",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.karnivalsingapore.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.karnivalsingapore.com/#/",
      twTitile: "Karnival Singapore",
      twvia: "Karnival Singapore",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.karnivalsingapore.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.karnivalsingapore.com/#/",
      waSeparator: "Karnival Singapore",
    },
    site_title: "Karnival Singapore",
    forcewww: "YES",
    site_title_with_separator: " | Karnival Singapore",
    home_meta_title: "Welcome to Karnival Singapore",
    bgimage: "/img/ninjaprocmn/karnivalsingapore/body_bg.jpg",
  },
  "www.juparocoffee.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "EB23A9E5-0D63-49C3-9EB2-7D2A5C37B68C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "JUPARO COFFEE",
    stripeImage:
      "https://www.juparocoffee.com/img/ninjaprocmn/juparocoffee/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "juparocoffee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.juparocoffee.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.juparocoffee.com/#/",
      fbImage: "https://www.juparocoffee.com/img/juparocoffee/logo.png",
      fbTitle: "JUPARO COFFEE",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.juparocoffee.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.juparocoffee.com/#/",
      twTitile: "JUPARO COFFEE",
      twvia: "JUPARO COFFEE",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.juparocoffee.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.juparocoffee.com/#/",
      waSeparator: "JUPARO COFFEE",
    },
    site_title: "JUPARO COFFEE",
    forcewww: "YES",
    site_title_with_separator: " | JUPARO COFFEE",
    home_meta_title: "Welcome to JUPARO COFFEE",
    bgimage: "/img/ninjaprocmn/juparocoffee/body_bg.jpg",
  },
  "order.chilispizza.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "E4C4E8F2-672D-476F-876B-45102C243932",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "CHILIS PIZZA",
    stripeImage:
      "https://order.chilispizza.com/img/ninjaprocmn/chilispizza/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "chilispizza",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.chilispizza.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.chilispizza.com/#/",
      fbImage: "https://order.chilispizza.com/img/chilispizza/logo.png",
      fbTitle: "CHILIS PIZZA",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.chilispizza.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.chilispizza.com/#/",
      twTitile: "CHILIS PIZZA",
      twvia: "CHILIS PIZZA",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.chilispizza.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.chilispizza.com/#/",
      waSeparator: "CHILIS PIZZA",
    },
    site_title: "CHILIS PIZZA",
    site_title_with_separator: " | CHILIS PIZZA",
    home_meta_title: "Welcome to CHILIS PIZZA",
    bgimage: "/img/ninjaprocmn/chilispizza/body_bg.jpg",
  },
  "www.mreggfriedrice.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "4D9FBC3C-48EE-482B-8A1D-663E332DC739",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mr. Egg Fried Rice",
    stripeImage:
      "https://www.mreggfriedrice.com/img/ninjaprocmn/mreggfriedrice/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mreggfriedrice",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.mreggfriedrice.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.mreggfriedrice.com/#/",
      fbImage: "https://www.mreggfriedrice.com/img/mreggfriedrice/logo.png",
      fbTitle: "Mr. Egg Fried Rice",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.mreggfriedrice.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.mreggfriedrice.com/#/",
      twTitile: "Mr. Egg Fried Rice",
      twvia: "Mr. Egg Fried Rice",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.mreggfriedrice.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.mreggfriedrice.com/#/",
      waSeparator: "Mr. Egg Fried Rice",
    },
    site_title: "Mr. Egg Fried Rice",
    forcewww: "YES",
    site_title_with_separator: " | Mr. Egg Fried Rice",
    home_meta_title: "Welcome to Mr. Egg Fried Rice",
    bgimage: "/img/ninjaprocmn/mreggfriedrice/body_bg.jpg",
  },
  "www.dooda.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B02F1AEB-C370-4034-A358-C14579AFE9D4",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Dooda",
    stripeImage: "https://www.dooda.sg/img/ninjaprocmn/dooda/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "dooda",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.dooda.sg /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.dooda.sg/#/",
      fbImage: "https://www.dooda.sg/img/dooda/logo.png",
      fbTitle: "Dooda",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.dooda.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.dooda.sg/#/",
      twTitile: "Dooda",
      twvia: "Dooda",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.dooda.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.dooda.sg/#/",
      waSeparator: "Dooda",
    },
    site_title: "Dooda",
    forcewww: "YES",
    site_title_with_separator: " | Dooda",
    home_meta_title: "Welcome to Dooda",
    bgimage: "/img/ninjaprocmn/dooda/body_bg.jpg",
  },
  "www.kanesonkitchen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "85C5D8A5-9BF9-4F81-B026-936FAFD7B3D1",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kaneson Kitchen",
    stripeImage:
      "https://www.kanesonkitchen.com/img/ninjaprocmn/kanesonkitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kanesonkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kanesonkitchen.com /#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kanesonkitchen.com/#/",
      fbImage: "https://www.kanesonkitchen.com/img/kanesonkitchen/logo.png",
      fbTitle: "Kaneson Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kanesonkitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kanesonkitchen.com/#/",
      twTitile: "Kaneson Kitchen",
      twvia: "Kaneson Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.kanesonkitchen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.kanesonkitchen.com/#/",
      waSeparator: "Kaneson Kitchen",
    },
    site_title: "Kaneson Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Kaneson Kitchen",
    home_meta_title: "Welcome to Kaneson Kitchen",
    bgimage: "/img/ninjaprocmn/kanesonkitchen/body_bg.jpg",
  },
  "www.handinhandfoodsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "D5618CE8-AD7E-4F58-B181-FE970D1719CB",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Hand in Hand",
    stripeImage:
      "https://www.handinhandfoodsg.com/img/ninjaprocmn/handinhandfood/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "handinhandfood",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.handinhandfoodsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.handinhandfoodsg.com/#/",
      fbImage: "https://www.handinhandfoodsg.com/img/handinhandfood/logo.png",
      fbTitle: "Hand in Hand",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kanesonkitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.handinhandfoodsg.com/#/",
      twTitile: "Hand in Hand",
      twvia: "Hand in Hand",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.handinhandfoodsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.handinhandfoodsg.com/#/",
      waSeparator: "Hand in Hand",
    },
    site_title: "Hand in Hand",
    forcewww: "YES",
    site_title_with_separator: " | Hand in Hand",
    home_meta_title: "Welcome to Hand in Hand",
    bgimage: "/img/ninjaprocmn/handinhandfood/body_bg.jpg",
  },
  "www.kingrojak.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "7B8628E8-3B24-4F83-8918-A113F85366D6",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "King Rojak",
    stripeImage:
      "https://www.kingrojak.com/img/ninjaprocmn/kingrojak/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "kingrojak",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kingrojak.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kingrojak.com/#/",
      fbImage: "https://www.kingrojak.com/img/kingrojak/logo.png",
      fbTitle: "King Rojak",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kanesonkitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kingrojak.com/#/",
      twTitile: "King Rojak",
      twvia: "King Rojak",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.kingrojak.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.kingrojak.com/#/",
      waSeparator: "King Rojak",
    },
    site_title: "King Rojak",
    forcewww: "YES",
    site_title_with_separator: " | King Rojak",
    home_meta_title: "Welcome to King Rojak",
    bgimage: "/img/ninjaprocmn/kingrojak/body_bg.jpg",
  },
  "www.teaattarik.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "29F1186E-74C4-49BA-BECB-F8ECB7CCBE24",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Tarik",
    stripeImage:
      "https://www.teaattarik.com/img/ninjaprocmn/teaattarik/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "teaattarik",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.teaattarik.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.teaattarik.com/#/",
      fbImage: "https://www.teaattarik.com/img/teaattarik/logo.png",
      fbTitle: "Tarik",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kanesonkitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.teaattarik.com/#/",
      twTitile: "Tarik",
      twvia: "Tarik",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.teaattarik.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.teaattarik.com/#/",
      waSeparator: "Tarik",
    },
    site_title: "Tarik",
    forcewww: "YES",
    site_title_with_separator: " | Tarik",
    home_meta_title: "Welcome to Tarik",
    bgimage: "/img/ninjaprocmn/teaattarik/body_bg.jpg",
  },
  "www.groundfloorcoffee.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "CAF8774B-4C44-4CD3-BF39-10F3705520E2",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ground Floor Coffee",
    stripeImage:
      "https://www.groundfloorcoffee.co/img/ninjaprocmn/groundfloorcoffee/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "groundfloorcoffee",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.groundfloorcoffee.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.groundfloorcoffee.co/#/",
      fbImage:
        "https://www.groundfloorcoffee.co/img/groundfloorcoffee/logo.png",
      fbTitle: "Ground Floor Coffee",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kanesonkitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.groundfloorcoffee.co/#/",
      twTitile: "Ground Floor Coffee",
      twvia: "Ground Floor Coffee",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.groundfloorcoffee.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.groundfloorcoffee.co/#/",
      waSeparator: "Ground Floor Coffee",
    },
    site_title: "Ground Floor Coffee",
    forcewww: "YES",
    site_title_with_separator: " | Ground Floor Coffee",
    home_meta_title: "Welcome to Ground Floor Coffee",
    bgimage: "/img/ninjaprocmn/groundfloorcoffee/body_bg.jpg",
  },
  "www.ainpopiahbasah.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "24D0F527-B654-4FBA-8400-DD86E24DF31B",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Ain Popiah Basah",
    stripeImage:
      "https://www.ainpopiahbasah.com/img/ninjaprocmn/ainpopiahbasah/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ainpopiahbasah",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ainpopiahbasah.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ainpopiahbasah.com/#/",
      fbImage: "https://www.ainpopiahbasah.com/img/ainpopiahbasah/logo.png",
      fbTitle: "Ain Popiah Basah",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kanesonkitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ainpopiahbasah.com/#/",
      twTitile: "Ain Popiah Basah",
      twvia: "Ain Popiah Basah",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.ainpopiahbasah.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.ainpopiahbasah.com/#/",
      waSeparator: "Ain Popiah Basah",
    },
    site_title: "Ain Popiah Basah",
    forcewww: "YES",
    site_title_with_separator: " | Ain Popiah Basah",
    home_meta_title: "Welcome to Ain Popiah Basah",
    bgimage: "/img/ninjaprocmn/ainpopiahbasah/body_bg.jpg",
  },
  "order.jixingmalatang.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "81E8BA48-026E-4044-B202-5666405A02DD",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Jixing Hotpot",
    stripeImage:
      "https://order.jixingmalatang.com/img/ninjaprocmn/jixingmalatang/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "jixingmalatang",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ainpopiahbasah.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.jixingmalatang.com/#/",
      fbImage: "https://order.jixingmalatang.com/img/jixingmalatang/logo.png",
      fbTitle: "Jixing Hotpot",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.jixingmalatang.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.jixingmalatang.com/#/",
      twTitile: "Jixing Hotpot",
      twvia: "Jixing Hotpot",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.jixingmalatang.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.jixingmalatang.com/#/",
      waSeparator: "Jixing Hotpot",
    },
    site_title: "Jixing Hotpot",
    site_title_with_separator: " | Jixing Hotpot",
    home_meta_title: "Welcome to Jixing Hotpot",
    bgimage: "/img/ninjaprocmn/jixingmalatang/body_bg.jpg",
  },
  "www.eatsbysam.asia": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "0F8612BD-A60D-4BBF-9BB7-E81A5A29FED7",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Eats by Sam",
    stripeImage:
      "https://www.eatsbysam.asia/img/ninjaprocmn/eatbysam/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "eatbysam",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.eatsbysam.asia/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.eatsbysam.asia/#/",
      fbImage: "https://order.jixingmalatang.com/img/eatbysam/logo.png",
      fbTitle: "Eats by Sam",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.eatsbysam.asia/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.eatsbysam.asia/#/",
      twTitile: "Eats by Sam",
      twvia: "Eats by Sam",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.eatsbysam.asia/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.eatsbysam.asia/#/",
      waSeparator: "Eats by Sam",
    },
    site_title: "Eats by Sam",
    forcewww: "YES",
    site_title_with_separator: " | Eats by Sam",
    home_meta_title: "Welcome to Eats by Sam",
    bgimage: "/img/ninjaprocmn/eatbysam/body_bg.jpg",
  },
  "www.yuhuangpremiumseafoodsoup.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B3C6369B-E3D4-4D67-B3D8-6C6991CB8F7F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Yu Huang Premium Seafood Soup",
    stripeImage:
      "https://www.yuhuangpremiumseafoodsoup.com/img/ninjaprocmn/yuhuangpremiumseafoodsoup/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "yuhuangpremiumseafoodsoup",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.yuhuangpremiumseafoodsoup.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.yuhuangpremiumseafoodsoup.com/#/",
      fbImage:
        "https://www.yuhuangpremiumseafoodsoup.com/img/yuhuangpremiumseafoodsoup/logo.png",
      fbTitle: "Yu Huang Premium Seafood Soup",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.yuhuangpremiumseafoodsoup.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.yuhuangpremiumseafoodsoup.com/#/",
      twTitile: "Yu Huang Premium Seafood Soup",
      twvia: "Yu Huang Premium Seafood Soup",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.yuhuangpremiumseafoodsoup.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.yuhuangpremiumseafoodsoup.com/#/",
      waSeparator: "Yu Huang Premium Seafood Soup",
    },
    site_title: "Yu Huang Premium Seafood Soup",
    forcewww: "YES",
    site_title_with_separator: " | Yu Huang Premium Seafood Soup",
    home_meta_title: "Welcome to Yu Huang Premium Seafood Soup",
    bgimage: "/img/ninjaprocmn/yuhuangpremiumseafoodsoup/body_bg.jpg",
  },
  "www.woai.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "997BE745-2103-4E1F-BE8C-546164A20F42",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "WOAI",
    stripeImage: "https://www.woai.sg/img/ninjaprocmn/woai/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "woai",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.woai.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.woai.sg/#/",
      fbImage: "https://www.woai.sg/img/woai/logo.png",
      fbTitle: "WOAI",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.woai.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.woai.sg/#/",
      twTitile: "WOAI",
      twvia: "WOAI",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.woai.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.woai.sg/#/",
      waSeparator: "WOAI",
    },
    site_title: "WOAI",
    forcewww: "YES",
    site_title_with_separator: " | WOAI",
    home_meta_title: "Welcome to WOAI",
    bgimage: "/img/ninjaprocmn/woai/body_bg.jpg",
  },
  "www.ordersogreen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DC5995FE-B9CD-45C3-9DA6-AECACFB09776",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "So Green",
    stripeImage:
      "https://www.ordersogreen.com/img/ninjaprocmn/ordersogreen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "ordersogreen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.ordersogreen.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.ordersogreen.com/#/",
      fbImage: "https://www.ordersogreen.com/img/ordersogreen/logo.png",
      fbTitle: "So Green",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.ordersogreen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.ordersogreen.com/#/",
      twTitile: "So Green",
      twvia: "So Green",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.ordersogreen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.ordersogreen.com/#/",
      waSeparator: "So Green",
    },
    site_title: "So Green",
    forcewww: "YES",
    site_title_with_separator: " | So Green",
    home_meta_title: "Welcome to So Green",
    bgimage: "/img/ninjaprocmn/ordersogreen/body_bg.jpg",
  },
  "www.hometasteleicha.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "BAAB7D5D-07DB-41B4-92A1-EFAE2022A494",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Hometaste Hakka Lei Cha",
    stripeImage:
      "https://www.hometasteleicha.com/img/ninjaprocmn/hometasteleicha/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "hometasteleicha",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.hometasteleicha.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.hometasteleicha.com/#/",
      fbImage: "https://www.hometasteleicha.com/img/hometasteleicha/logo.png",
      fbTitle: "Hometaste Hakka Lei Cha",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.hometasteleicha.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.hometasteleicha.com/#/",
      twTitile: "Hometaste Hakka Lei Cha",
      twvia: "Hometaste Hakka Lei Cha",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.hometasteleicha.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.hometasteleicha.com/#/",
      waSeparator: "Hometaste Hakka Lei Cha",
    },
    site_title: "Hometaste Hakka Lei Cha",
    forcewww: "YES",
    site_title_with_separator: " | Hometaste Hakka Lei Cha",
    home_meta_title: "Welcome to Hometaste Hakka Lei Cha",
    bgimage: "/img/ninjaprocmn/hometasteleicha/body_bg.jpg",
  },
  "www.lotusgrocerysg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F5BBD8AF-DDC1-48F9-A16F-2628CD398DCC",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Lotus Grocery",
    stripeImage:
      "https://www.lotusgrocerysg.com/img/ninjaprocmn/lotusgrocerysg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lotusgrocerysg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.lotusgrocerysg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.lotusgrocerysg.com/#/",
      fbImage: "https://www.lotusgrocerysg.com/img/lotusgrocerysg/logo.png",
      fbTitle: "Lotus Grocery",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.lotusgrocerysg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.lotusgrocerysg.com/#/",
      twTitile: "Lotus Grocery",
      twvia: "Lotus Grocery",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.lotusgrocerysg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.lotusgrocerysg.com/#/",
      waSeparator: "Lotus Grocery",
    },
    site_title: "Lotus Grocery",
    forcewww: "YES",
    site_title_with_separator: " | Lotus Grocery",
    home_meta_title: "Welcome to Lotus Grocery",
    bgimage: "/img/ninjaprocmn/lotusgrocerysg/body_bg.jpg",
  },
  "shop.soupdayz.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "81E8BA48-026E-4044-B202-5666405A02DD",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Soupdayz",
    stripeImage:
      "https://shop.soupdayz.com/img/ninjaprocmn/soupdayz/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "soupdayz",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.soupdayz.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://shop.soupdayz.com/#/",
      fbImage: "https://shop.soupdayz.com/img/soupdayz/logo.png",
      fbTitle: "Soupdayz",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://shop.soupdayz.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://shop.soupdayz.com/#/",
      twTitile: "Soupdayz",
      twvia: "Soupdayz",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://shop.soupdayz.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://shop.soupdayz.com/#/",
      waSeparator: "Soupdayz",
    },
    site_title: "Soupdayz",
    site_title_with_separator: " | Soupdayz",
    home_meta_title: "Welcome to Soupdayz",
    bgimage: "/img/ninjaprocmn/soupdayz/body_bg.jpg",
  },
  "www.uulicious.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "19F0724A-7125-494C-B2EC-699FC716B71A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Uulicious",
    stripeImage:
      "https://www.uulicious.com/img/ninjaprocmn/uulicious/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "uulicious",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.uulicious.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.uulicious.com/#/",
      fbImage: "https://www.uulicious.com/img/uulicious/logo.png",
      fbTitle: "Uulicious",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.uulicious.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.uulicious.com/#/",
      twTitile: "Uulicious",
      twvia: "Uulicious",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.uulicious.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.uulicious.com/#/",
      waSeparator: "Uulicious",
    },
    site_title: "Uulicious",
    forcewww: "YES",
    site_title_with_separator: " | Uulicious",
    home_meta_title: "Welcome to Uulicious",
    bgimage: "/img/ninjaprocmn/uulicious/body_bg.jpg",
  },
  "www.veggielovers.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3A2B3C39-DF47-4D79-B96C-F512526BAE6E",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Veggie lovers",
    stripeImage:
      "https://www.veggielovers.sg/img/ninjaprocmn/veggielovers/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "veggielovers",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.veggielovers.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.veggielovers.sg/#/",
      fbImage: "https://www.veggielovers.sg/img/veggielovers/logo.png",
      fbTitle: "Veggie lovers",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.veggielovers.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.veggielovers.sg/#/",
      twTitile: "Veggie lovers",
      twvia: "Veggie lovers",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.veggielovers.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.veggielovers.sg/#/",
      waSeparator: "Veggie lovers",
    },
    site_title: "Veggie lovers",
    forcewww: "YES",
    site_title_with_separator: " | Veggie lovers",
    home_meta_title: "Welcome to Veggie lovers",
    bgimage: "/img/ninjaprocmn/veggielovers/body_bg.jpg",
  },
  "www.fernvalehappydurian.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "37835F97-6948-46CE-BF7C-0F4F0072EA8A",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Happy Durian",
    stripeImage:
      "https://www.fernvalehappydurian.com/img/ninjaprocmn/fernvalehappydurian/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "fernvalehappydurian",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.fernvalehappydurian.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.fernvalehappydurian.com/#/",
      fbImage:
        "https://www.fernvalehappydurian.com/img/fernvalehappydurian/logo.png",
      fbTitle: "Happy Durian",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.fernvalehappydurian.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.fernvalehappydurian.com/#/",
      twTitile: "Happy Durian",
      twvia: "Happy Durian",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.fernvalehappydurian.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.fernvalehappydurian.com/#/",
      waSeparator: "Happy Durian",
    },
    site_title: "Happy Durian",
    forcewww: "YES",
    site_title_with_separator: " | Happy Durian",
    home_meta_title: "Welcome to Happy Durian",
    bgimage: "/img/ninjaprocmn/fernvalehappydurian/body_bg.jpg",
  },
  "www.norazkitchen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5737BF76-F5CC-41C6-91B9-8BA3DCBE754F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Noraz Kitchen",
    stripeImage:
      "https://www.norazkitchen.com/img/ninjaprocmn/norazkitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "norazkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.norazkitchen.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.norazkitchen.com/#/",
      fbImage: "https://www.norazkitchen.com/img/norazkitchen/logo.png",
      fbTitle: "Noraz Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.norazkitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.norazkitchen.com/#/",
      twTitile: "Noraz Kitchen",
      twvia: "Noraz Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.norazkitchen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.norazkitchen.com/#/",
      waSeparator: "Noraz Kitchen",
    },
    site_title: "Noraz Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | Noraz Kitchen",
    home_meta_title: "Welcome to Noraz Kitchen",
    bgimage: "/img/ninjaprocmn/norazkitchen/body_bg.jpg",
  },
  "www.lovefulgroup.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "15FC3E21-FE67-4446-8E17-FC13D5B78C35",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Loveful",
    stripeImage:
      "https://www.lovefulgroup.com/img/ninjaprocmn/lovefulgroup/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "lovefulgroup",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.lovefulgroup.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.lovefulgroup.com/#/",
      fbImage: "https://www.lovefulgroup.com/img/lovefulgroup/logo.png",
      fbTitle: "Loveful",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.lovefulgroup.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.lovefulgroup.com/#/",
      twTitile: "Loveful",
      twvia: "Loveful",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.lovefulgroup.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.lovefulgroup.com/#/",
      waSeparator: "Loveful",
    },
    site_title: "Loveful",
    forcewww: "YES",
    site_title_with_separator: " | Loveful",
    home_meta_title: "Welcome to Loveful",
    bgimage: "/img/ninjaprocmn/lovefulgroup/body_bg.jpg",
  },
  "www.khairunmiyah.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "8002102B-F3D1-4850-BCBF-14FE3739D82F",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Khairunmiyah Crispy Prata",
    stripeImage:
      "https://www.khairunmiyah.com/img/ninjaprocmn/khairunmiyah/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "khairunmiyah",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.khairunmiyah.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.khairunmiyah.com/#/",
      fbImage: "https://www.khairunmiyah.com/img/khairunmiyah/logo.png",
      fbTitle: "Khairunmiyah Crispy Prata",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.khairunmiyah.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.khairunmiyah.com/#/",
      twTitile: "Khairunmiyah Crispy Prata",
      twvia: "Khairunmiyah Crispy Prata",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.khairunmiyah.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.khairunmiyah.com/#/",
      waSeparator: "Khairunmiyah Crispy Prata",
    },
    site_title: "Khairunmiyah Crispy Prata",
    forcewww: "YES",
    site_title_with_separator: " | Khairunmiyah Crispy Prata",
    home_meta_title: "Welcome to Khairunmiyah Crispy Prata",
    bgimage: "/img/ninjaprocmn/khairunmiyah/body_bg.jpg",
  },
  "www.pizzapazzasg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "423079F7-2DEB-45FF-9080-6594857779BB",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Pizza pazza",
    stripeImage:
      "https://www.pizzapazzasg.com/img/ninjaprocmn/pizzapazzasg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "pizzapazzasg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.pizzapazzasg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.pizzapazzasg.com/#/",
      fbImage: "https://www.pizzapazzasg.com/img/pizzapazzasg/logo.png",
      fbTitle: "Pizza pazza",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.pizzapazzasg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.pizzapazzasg.com/#/",
      twTitile: "Pizza pazza",
      twvia: "Pizza pazza",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.pizzapazzasg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.pizzapazzasg.com/#/",
      waSeparator: "Pizza pazza",
    },
    site_title: "Pizza pazza",
    forcewww: "YES",
    site_title_with_separator: " | Pizza pazza",
    home_meta_title: "Welcome to Pizza pazza",
    bgimage: "/img/ninjaprocmn/pizzapazzasg/body_bg.jpg",
  },
  "www.5grillkitchen.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "FDC6D6C1-AE13-4098-9F28-D2EA3E17E113",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "5 Grill Kitchen",
    stripeImage:
      "https://www.5grillkitchen.com/img/ninjaprocmn/5grillkitchen/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "5grillkitchen",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.5grillkitchen.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.5grillkitchen.com/#/",
      fbImage: "https://www.5grillkitchen.com/img/5grillkitchen/logo.png",
      fbTitle: "5 Grill Kitchen",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.5grillkitchen.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.5grillkitchen.com/#/",
      twTitile: "5 Grill Kitchen",
      twvia: "5 Grill Kitchen",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.5grillkitchen.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.5grillkitchen.com/#/",
      waSeparator: "5 Grill Kitchen",
    },
    site_title: "5 Grill Kitchen",
    forcewww: "YES",
    site_title_with_separator: " | 5 Grill Kitchen",
    home_meta_title: "Welcome to 5 Grill Kitchen",
    bgimage: "/img/ninjaprocmn/5grillkitchen/body_bg.jpg",
  },
  "order.mickeymomo.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "C7529362-730D-4495-A0C1-9440A57CC899",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Mickeymomo",
    stripeImage:
      "https://order.mickeymomo.com/img/ninjaprocmn/mickeymomo/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "mickeymomo",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.mickeymomo.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.mickeymomo.com/#/",
      fbImage: "https://order.mickeymomo.com/img/mickeymomo/logo.png",
      fbTitle: "Mickeymomo",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.mickeymomo.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.mickeymomo.com/#/",
      twTitile: "Mickeymomo",
      twvia: "Mickeymomo",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.mickeymomo.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.mickeymomo.com/#/",
      waSeparator: "Mickeymomo",
    },
    site_title: "Mickeymomo",
    site_title_with_separator: " | Mickeymomo",
    home_meta_title: "Welcome to Mickeymomo",
    bgimage: "/img/ninjaprocmn/mickeymomo/body_bg.jpg",
  },
  "www.sgbuttercakes.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "5694483D-B9D5-4B2C-859D-8382BBA10D2C",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "SG Butter Cakes",
    stripeImage:
      "https://www.sgbuttercakes.com/img/ninjaprocmn/sgbuttercakes/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sgbuttercakes",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.sgbuttercakes.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.sgbuttercakes.com/#/",
      fbImage: "https://www.sgbuttercakes.com/img/sgbuttercakes/logo.png",
      fbTitle: "SG Butter Cakes",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.sgbuttercakes.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.sgbuttercakes.com/#/",
      twTitile: "SG Butter Cakes",
      twvia: "SG Butter Cakes",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.sgbuttercakes.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.sgbuttercakes.com/#/",
      waSeparator: "SG Butter Cakes",
    },
    site_title: "SG Butter Cakes",
    forcewww: "YES",
    site_title_with_separator: " | SG Butter Cakes",
    home_meta_title: "Welcome to SG Butter Cakes",
    bgimage: "/img/ninjaprocmn/sgbuttercakes/body_bg.jpg",
  },
  "order.thericenspice.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "709C9821-905E-4DED-98D4-6FF566CD5E85",
    fbAppId: "299877000824930",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Rice and Spice",
    stripeImage:
      "https://order.thericenspice.com/img/ninjaprocmn/thericenspice/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "thericenspice",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.thericenspice.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.thericenspice.com/#/",
      fbImage: "https://order.thericenspice.com/img/thericenspice/logo.png",
      fbTitle: "Rice and Spice",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.thericenspice.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.thericenspice.com/#/",
      twTitile: "Rice and Spice",
      twvia: "Rice and Spice",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.thericenspice.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.thericenspice.com/#/",
      waSeparator: "Rice and Spice",
    },
    site_title: "Rice and Spice",
    site_title_with_separator: " | Rice and Spice",
    home_meta_title: "Welcome to Rice and Spice",
    bgimage: "/img/ninjaprocmn/thericenspice/body_bg.jpg",
  },
  "www.twobakingpans.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "DB9DF050-44BF-47BB-85DC-D9981FA37AD5",
    fbAppId: "6719004438130245",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Two Baking Pans",
    stripeImage:
      "https://www.twobakingpans.com/img/ninjaprocmn/twobakingpans/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "twobakingpans",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.twobakingpans.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.twobakingpans.com/#/",
      fbImage: "https://www.twobakingpans.com/img/twobakingpans/logo.png",
      fbTitle: "Two Baking Pans",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.twobakingpans.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.twobakingpans.com/#/",
      twTitile: "Two Baking Pans",
      twvia: "Two Baking Pans",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.twobakingpans.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.twobakingpans.com/#/",
      waSeparator: "Two Baking Pans",
    },
    site_title: "Two Baking Pans",
    site_title_with_separator: " | Two Baking Pans",
    home_meta_title: "Welcome to Two Baking Pans",
    bgimage: "/img/ninjaprocmn/twobakingpans/body_bg.jpg",
  },
  "sweetcheeks.ninjapro.co": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B0A122F4-BAB4-4906-A1AA-A6103115EA97",
    fbAppId: "986531779440247",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sweet Cheeks",
    stripeImage:
      "https://sweetcheeks.ninjapro.co/img/ninjaprocmn/sweetcheeks/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sweetcheeks",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://sweetcheeks.ninjapro.co/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://sweetcheeks.ninjapro.co/#/",
      fbImage: "https://sweetcheeks.ninjapro.co/img/sweetcheeks/logo.png",
      fbTitle: "Sweet Cheeks",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://sweetcheeks.ninjapro.co/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://sweetcheeks.ninjapro.co/#/",
      twTitile: "Sweet Cheeks",
      twvia: "Sweet Cheeks",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://sweetcheeks.ninjapro.co/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://sweetcheeks.ninjapro.co/#/",
      waSeparator: "Sweet Cheeks",
    },
    site_title: "Sweet Cheeks",
    site_title_with_separator: " | Sweet Cheeks",
    home_meta_title: "Welcome to Sweet Cheeks",
    bgimage: "/img/ninjaprocmn/sweetcheeks/body_bg.jpg",
  },
  "order.sweetcheeks.sg": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "B0A122F4-BAB4-4906-A1AA-A6103115EA97",
    fbAppId: "986531779440247",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Sweet Cheeks",
    stripeImage:
      "https://order.sweetcheeks.sg/img/ninjaprocmn/sweetcheeks/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "sweetcheeks",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://order.sweetcheeks.sg/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://order.sweetcheeks.sg/#/",
      fbImage: "https://order.sweetcheeks.sg/img/sweetcheeks/logo.png",
      fbTitle: "Sweet Cheeks",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://order.sweetcheeks.sg/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://order.sweetcheeks.sg/#/",
      twTitile: "Sweet Cheeks",
      twvia: "Sweet Cheeks",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://order.sweetcheeks.sg/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://order.sweetcheeks.sg/#/",
      waSeparator: "Sweet Cheeks",
    },
    site_title: "Sweet Cheeks",
    site_title_with_separator: " | Sweet Cheeks",
    home_meta_title: "Welcome to Sweet Cheeks",
    bgimage: "/img/ninjaprocmn/sweetcheeks/body_bg.jpg",
  },
  "www.qilixiangsg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "957CDEF7-25F1-446C-B758-AF0BC637722C",
    fbAppId: "992697608823469",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Qi Li Xiang 七里香",
    stripeImage:
      "https://www.qilixiangsg.com/img/ninjaprocmn/qilixiangsg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "qilixiangsg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.qilixiangsg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.qilixiangsg.com/#/",
      fbImage: "https://www.qilixiangsg.com/img/qilixiangsg/logo.png",
      fbTitle: "Qi Li Xiang 七里香",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.qilixiangsg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.qilixiangsg.com/#/",
      twTitile: "Qi Li Xiang 七里香",
      twvia: "Qi Li Xiang 七里香",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.qilixiangsg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.qilixiangsg.com/#/",
      waSeparator: "Qi Li Xiang 七里香",
    },
    site_title: "Qi Li Xiang 七里香",
    site_title_with_separator: " | Qi Li Xiang 七里香",
    home_meta_title: "Welcome to Qi Li Xiang 七里香",
    bgimage: "/img/ninjaprocmn/qilixiangsg/body_bg.jpg",
  },
  "www.cafeiace.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "70BC17DD-EF1B-49D5-94FC-3E7F5E7E0720",
    fbAppId: "199361353119509",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Cafeiace",
    stripeImage:
      "https://www.cafeiace.com/img/ninjaprocmn/cafeiace/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "cafeiace",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.cafeiace.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.cafeiace.com/#/",
      fbImage: "https://www.cafeiace.com/img/cafeiace/logo.png",
      fbTitle: "Cafeiace",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.cafeiace.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.cafeiace.com/#/",
      twTitile: "Cafeiace",
      twvia: "Cafeiace",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.cafeiace.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.cafeiace.com/#/",
      waSeparator: "Cafeiace",
    },
    site_title: "Cafeiace",
    site_title_with_separator: " | Cafeiace",
    home_meta_title: "Welcome to Cafeiace",
    bgimage: "/img/ninjaprocmn/cafeiace/body_bg.jpg",
  },
  "www.dsweettouch.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "23265954-7AC1-4D85-B3CD-CE446670EB1B",
    fbAppId: "893334919127628",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "D Sweet Touch",
    stripeImage:
      "https://www.dsweettouch.com/img/ninjaprocmn/dsweettouch/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "dsweettouch",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.dsweettouch.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.dsweettouch.com/#/",
      fbImage: "https://www.dsweettouch.com/img/dsweettouch/logo.png",
      fbTitle: "D Sweet Touch",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.dsweettouch.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.dsweettouch.com/#/",
      twTitile: "D Sweet Touch",
      twvia: "D Sweet Touch",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.dsweettouch.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.dsweettouch.com/#/",
      waSeparator: "dsweettouch",
    },
    site_title: "D Sweet Touch",
    site_title_with_separator: " | D Sweet Touch",
    home_meta_title: "Welcome to D Sweet Touch",
    bgimage: "/img/ninjaprocmn/dsweettouch/body_bg.jpg",
  },
  "www.chunfusg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "8797E7FC-3BCD-40FC-9772-22B29A092B9F",
    fbAppId: "893334919127628",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Chun Fu Seafood",
    stripeImage:
      "https://www.chunfusg.com/img/ninjaprocmn/chunfusg/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "chunfusg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.chunfusg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.chunfusg.com/#/",
      fbImage: "https://www.chunfusg.com/img/chunfusg/logo.png",
      fbTitle: "Chun Fu Seafood",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.chunfusg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.chunfusg.com/#/",
      twTitile: "Chun Fu Seafood",
      twvia: "Chun Fu Seafood",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.chunfusg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.chunfusg.com/#/",
      waSeparator: "chunfusg",
    },
    site_title: "Chun Fu Seafood",
    site_title_with_separator: " | Chun Fu Seafood",
    home_meta_title: "Welcome to Chun Fu Seafood",
    bgimage: "/img/ninjaprocmn/chunfusg/body_bg.jpg",
  },
  "www.supersisterdurians.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "F1C3A062-A88A-466C-A273-5DBDCED4EBFD",
    fbAppId: "893334919127628",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Super Sister Durian",
    stripeImage:
      "https://www.supersisterdurians.com/img/ninjaprocmn/supersisterdurians/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "supersisterdurians",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.supersisterdurians.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.supersisterdurians.com/#/",
      fbImage:
        "https://www.supersisterdurians.com/img/supersisterdurians/logo.png",
      fbTitle: "Super Sister Durian",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.supersisterdurians.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.supersisterdurians.com/#/",
      twTitile: "Super Sister Durian",
      twvia: "Super Sister Durian",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.supersisterdurians.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.supersisterdurians.com/#/",
      waSeparator: "supersisterdurians",
    },
    site_title: "Super Sister Durian",
    site_title_with_separator: " | Super Sister Durian",
    home_meta_title: "Welcome to Super Sister Durian",
    bgimage: "/img/ninjaprocmn/supersisterdurians/body_bg.jpg",
  },
  "www.liyangmalahotpot.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "	721BA299-1E18-46A6-A0A1-26E90052A2B2",
    fbAppId: "893334919127628",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Li Yang Mala Hotpot",
    stripeImage:
      "https://www.liyangmalahotpot.com/img/ninjaprocmn/liyangmalahotpot/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "liyangmalahotpot",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.liyangmalahotpot.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.liyangmalahotpot.com/#/",
      fbImage: "https://www.liyangmalahotpot.com/img/liyangmalahotpot/logo.png",
      fbTitle: "Li Yang Mala Hotpot",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.liyangmalahotpot.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.liyangmalahotpot.com/#/",
      twTitile: "Li Yang Mala Hotpot",
      twvia: "Li Yang Mala Hotpot",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.liyangmalahotpot.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.liyangmalahotpot.com/#/",
      waSeparator: "liyangmalahotpot",
    },
    site_title: "Li Yang Mala Hotpot",
    site_title_with_separator: " | Li Yang Mala Hotpot",
    home_meta_title: "Welcome to Li Yang Mala Hotpot",
    bgimage: "/img/ninjaprocmn/liyangmalahotpot/body_bg.jpg",
  },
  "www.orderchooswok.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "A09AF37E-F4B3-47CB-8869-5792ED8D983B",
    fbAppId: "893334919127628",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Lucky Starsb F&B Pte Ltd",
    stripeImage:
      "https://www.orderchooswok.com/img/ninjaprocmn/orderchooswok/stripe_logo.png",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "orderchooswok",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.orderchooswok.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.orderchooswok.com/#/",
      fbImage: "https://www.orderchooswok.com/img/orderchooswok/logo.png",
      fbTitle: "Lucky Starsb F&B Pte Ltd",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.orderchooswok.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.orderchooswok.com/#/",
      twTitile: "Lucky Starsb F&B Pte Ltd",
      twvia: "Lucky Starsb F&B Pte Ltd",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.orderchooswok.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.orderchooswok.com/#/",
      waSeparator: "orderchooswok",
    },
    site_title: "Lucky Starsb F&B Pte Ltd",
    site_title_with_separator: " | Lucky Starsb F&B Pte Ltd",
    home_meta_title: "Welcome to Lucky Starsb F&B Pte Ltd",
    bgimage: "/img/ninjaprocmn/orderchooswok/body_bg.jpg",
  },
  "www.kimkeehainanesesg.com": {
    apiUrl: protocoltxt + "//mars.ninjaos.dev/api/", //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: protocoltxt + "//mars.ninjaos.dev/ninjapro/", //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: protocoltxt + "//mars.ninjaos.dev/apiv2/",
    tinThumpUrl: protocoltxt + "//mars.ninjaos.dev/timthumb.php?src=",
    appId: "3900251D-B30D-4D69-8B0F-D81BE5C52A46",
    fbAppId: "893334919127628",
    logo: "/img/ninjaprocmn/logo.png",
    chatWidget: "eeeee",
    stripeKey: "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
    stripeCompany: "Kim Kee Hainanese Chicken Rice",
    stripeImage:
      "https://www.kimkeehainanesesg.com/img/ninjaprocmn/kimkeesg/stripe_logo.png",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "kimkeesg",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultAvilablityId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    defaultAvilablityName: "Delivery",
    defaultAvilablityImage: "/img/icons/Delivery_white.png",
    baseUrl: "https://www.kimkeehainanesesg.com/#/",
    product_thumbnail: "/img/no-images/productthump-no-image.jpg",
    product_listing: "/img/no-images/products-no-image.jpg",
    fb: {
      fbURL: "https://www.kimkeehainanesesg.com/#/",
      fbImage: "https://www.kimkeehainanesesg.com/img/kimkeesg/logo.png",
      fbTitle: "Kim Kee Hainanese Chicken Rice",
      fbDesc:
        "Yes ! I love the food here, and did I say its also a great place to eat ! Check out : url : https://www.kimkeehainanesesg.com/",
    },
    /* Twitter  */
    tw: {
      twURL: "https://www.kimkeehainanesesg.com/#/",
      twTitile: "Kim Kee Hainanese Chicken Rice",
      twvia: "Kim Kee Hainanese Chicken Rice",
    },

    /* google Plus data   */
    gp: {
      gpURL: "https://www.kimkeehainanesesg.com/#/",
    },

    /* Whatsapp data   */
    wa: {
      waURL: "https://www.kimkeehainanesesg.com/#/",
      waSeparator: "kimkeesg",
    },
    site_title: "Kim Kee Hainanese Chicken Rice",
    site_title_with_separator: " | Kim Kee Hainanese Chicken Rice",
    home_meta_title: "Welcome to Kim Kee Hainanese Chicken Rice",
    bgimage: "/img/ninjaprocmn/kimkeesg/body_bg.jpg",
  },
};

/*console.log(hostname);
console.log(domainValues[hostname]);
console.log(hostname.substring(0,3));*/

if (hostname.substring(0, 3) !== "www") {
  var hostnametemp = "www." + hostname;
  if (
    domainValues[hostnametemp] !== undefined &&
    domainValues[hostnametemp].forcewww !== undefined &&
    domainValues[hostnametemp].forcewww === "YES"
  ) {
    window.location = "https://" + hostnametemp;
  }
}

/*if(hostname.substring(0,3)!=='www'){
	let hostnametemp ="www."+hostname; 
	try {
	if(domainValues[hostnametemp] !== undefined && domainValues[hostnametemp].forcewww=='YES')
	{
		window.location = "https://"+hostnametemp;
	}
	}catch(err) {
		console.log(err)}
}*/

/* get current host name */
console.log(hostname, domainValues[hostname]);
const getAppValues = {
  /* all path values */
  path: {
    baseUrl: domainValues[hostname].baseUrl,
    apiUrl: domainValues[hostname].apiUrl, //'https://ccpl.ninjaos.com/api/',
    apiUrlPro: domainValues[hostname].apiUrlPro, //'https://ccpl.ninjaos.com/ninjapro/',
    apiUrlv2: domainValues[hostname].apiUrlv2,
    apiUrlNotify: protocoltxt + "//mars.ninjaos.dev/",
    tinThumpUrl: domainValues[hostname].tinThumpUrl,
    logo: domainValues[hostname].logo,
  },
  ids: {
    appId: domainValues[hostname].appId,
    deliveryId: "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
    pickupId: "718B1A92-5EBB-4F25-B24D-3067606F67F0",
    dineinId: "EF9FB350-4FD4-4894-9381-3E859AB74019",
    reservationId: "79FA4C7F-75A1-4A95-B7CE-81ECA2575363",
    currency: "$",
    defaultAvilablityId: domainValues[hostname].defaultAvilablityId,
    defaultAvilablityName: domainValues[hostname].defaultAvilablityName,
    defaultAvilablityImage: domainValues[hostname].defaultAvilablityImage,
    fbAppId: domainValues[hostname].fbAppId, //'104121986790084' demo - 410091149364782
  },
  stripe: {
    stripeCompany: domainValues[hostname].stripeCompany,
    stripeImage: domainValues[hostname].stripeImage,
    stripeKey: domainValues[hostname].stripeKey,
    stripeEmail: domainValues[hostname].stripeEmail,
    stripeReference: domainValues[hostname].stripeReference,
    stripeDesc: domainValues[hostname].stripeDesc,
    stripeCurrency: domainValues[hostname].stripeCurrency,
  },
  noImages: {
    product_thumbnail: domainValues[hostname].product_thumbnail,
    product_listing: domainValues[hostname].product_listing,
  },
  fb: domainValues[hostname].fb,
  /* Twitter  */
  tw: domainValues[hostname].tw,
  /* google Plus data   */
  gp: domainValues[hostname].gp,
  /* Whatsapp data   */
  wa: domainValues[hostname].wa,
  site_title: domainValues[hostname].site_title,
  site_title_with_separator: domainValues[hostname].site_title_with_separator,
  home_meta_title: domainValues[hostname].home_meta_title,
  home_meta_keywords: domainValues[hostname].home_meta_keywords,
  home_meta_description: domainValues[hostname].home_meta_description,
  enablescan:
    domainValues[hostname].enablesccan !== undefined &&
    domainValues[hostname].enablesccan !== "No"
      ? "Yes"
      : "No",
};

/* Chat Widget */
var fileref = document.createElement("script");
fileref.setAttribute("type", "text/javascript");
fileref.setAttribute("src", "//keyreply.com/chat/widget.js");
fileref.setAttribute("data-align", "left");
fileref.setAttribute("data-overlay", "false");
fileref.setAttribute("id", "keyreply-script");
fileref.setAttribute("id", "keyreply-script");
fileref.setAttribute("data-color", "#E4392B");
fileref.setAttribute("data-apps", domainValues[hostname].chatWidget);
document.getElementsByTagName("body")[0].appendChild(fileref);

document.body.style.background =
  "url(" + domainValues[hostname].bgimage + ") repeat";
export default getAppValues;
