import React from "react"
import axios from "axios"
import "react-select/dist/react-select.css"
import Loading from "./loading"
import Overlay from "./Overlay"
import Select from "react-select"

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = { selectedOption: "" }
    this.handleChange = this.handleChange.bind(this)
    this.getOptions = this.getOptions.bind(this)
  }

  getOptions(input) {
    var searchResult = []
    return axios.get("https://developers.onemap.sg/commonapi/search?returnGeom=Y&getAddrDetails=Y&searchVal=" + input).then((response) => {
      if (response.data.found > 0) {
        var resultData = response.data.results
        resultData.map(
          (loadData) =>
            loadData.POSTAL !== "NIL" &&
            searchResult.push({
              value: JSON.stringify(loadData),
              label: loadData.ADDRESS,
            })
        )
      }

      return { options: searchResult }
    })
  }

  handleChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption.value,
    })
    //his.props.postalcode
    this.props.parentCallback(this, true, this.props.postalcode, selectedOption.value)
  }

  render() {
    const { selectedOption } = this.state
    if (this.props.Loading === true) {
      return (
        <div className="container-fluid desktop-container position-rel" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    } else {
      return (
        <div className="modal" id="address-auto">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                </button>
                <div className="space-10" style={{ padding: "22px" }}></div>
                <h4 className="modal-title">WE COULDNT FIND THE POSTAL CODE YOU ENTERED</h4>
                <h4>Could you type your address to search instead</h4>
              </div>
              <Overlay load={this.state.load} />
              <div className="modal-body">
                <div style={{ textAlign: "left" }} className="location-search">
                  <Select.Async name="form-field-name" placeholder={"Search here"} value={selectedOption} noResultsText="No result found" autoload={false} onChange={this.handleChange} loadOptions={this.getOptions.bind(this)} />
                </div>
              </div>
              <div className="modal-footer"></div>
              <div className="space-10" style={{ padding: "22px" }}></div>
            </div>
          </div>
        </div>
      )
    }
  }
}
export default LocationSearchInput
