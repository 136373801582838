import axios from "axios"
import cookie from "react-cookie"
import config from "./config"

const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
const APPID = config.ids.appId

export default function (type, param) {
  if (type === "category") {
    /* API CALL FOR PRODUCT MENU NAVIGATION */
    return axios.get(APIURL + "productsver3/navigation_menu?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId"))
  } else if (type === "settings") {
    /* get all settings  */

    return axios.get(APIURL + "settings/get_common_settings?app_id=" + APPID + "&postal_code=" + cookie.load("orderPostalCode") + "&postalFilter=" + param)
  } else if (type === "settingsPro") {
    /* get all settings  */
    return axios.get(APIURLPRO + "settings/get_common_settings?app_id=" + APPID + "&postal_code=" + cookie.load("orderPostalCode") + "&postalFilter=" + param)
  } else if (type === "settingsProWithavailability") {
    /* get all settings  */
    return axios.get(APIURLPRO + "settings/get_common_settings?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + "&postal_code=" + cookie.load("orderPostalCode") + "&postalFilter=" + param)
  } else if (type === "product") {
    return axios.get(APIURLPRO + "productsver2/ninjapro_products_list?app_id=" + APPID + "&status=A&availability=" + cookie.load("defaultAvilablityId") + "&" + param)
  } else if (type === "cartImage") {
    /* used to load cart image */
    return cookie.load("cartValue") === "Yes" ? "/img/icons/shopping-cart.png" : "/img/icons/shopping-cart.png"
  } else if (type === "referenceId") {
    /* get referenceId */
    if (typeof cookie.load("referenceId") === "undefined") {
      var randomKey = randomId()
      cookie.save("referenceId", randomKey, {})
      return randomKey
    } else {
      return cookie.load("referenceId")
    }
  } else if (type === "Auth") {
    /* this function used to cyheck user login */

    if (typeof cookie.load("UserId") === "undefined") {
      return false
    }
  } else if (type === "fillAllData") {
    /* this function used to fill all customer information  */
    if (typeof cookie.load("UserId") === "undefined" || typeof cookie.load("UserId") === "undefined" || typeof cookie.load("UserId") === "undefined" || typeof cookie.load("UserId") === "undefined") {
      return false
    }
  } else if (type === "minCartAmount") {
    /* this function used to validate minimum cart amount  */
  } else if (type === "UserDetails") {
    /* this function used to cyheck user login */

    if (
      typeof cookie.load("UserId") === "undefined" ||
      cookie.load("UserId") === "" ||
      cookie.load("UserId") === "null" ||
      typeof cookie.load("UserFname") === "undefined" ||
      cookie.load("UserFname") === "" ||
      cookie.load("UserFname") === "null" ||
      typeof cookie.load("UserEmail") === "undefined" ||
      cookie.load("UserEmail") === "" ||
      cookie.load("UserEmail") === "null" ||
      typeof cookie.load("UserMobile") === "undefined" ||
      cookie.load("UserMobile") === "" ||
      cookie.load("UserMobile") === "null"
    ) {
      return false
    }
  } else if (type === "DeliveryDetails") {
    /* this function used to cyheck user login */

    if (typeof cookie.load("DeliveryDate") === "undefined" || cookie.load("DeliveryDate") === "") {
      if ((typeof cookie.load("DeliveryTime") === "undefined" || cookie.load("DeliveryTime") === "") && cookie.load("isSletedTimeSlot") === "No") {
        return false
      } else if ((typeof cookie.load("DeliveryTimeSlot") === "undefined" || cookie.load("DeliveryTimeSlot") === "") && cookie.load("isSletedTimeSlot") === "Yes") {
        return false
      }
    }
  }

  /* this function used to create random string*/
  function randomId() {
    var text = ""
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (var i = 0; i < 50; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
  }
}
