import React from "react"
import moment from "moment"
import config from "./config"
const APPID = config.ids.appId
class Distancebar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { distance, tat, minDateValue } = this.props
    return (
      <p className="map-sub">
        {distance && (
          <span>
            <img src="/img/icons/location.svg" className="icon-img-small" />
            {distance ? distance + " km" : "-"}
          </span>
        )}

        {APPID !== "D89F3695-82D5-4105-991A-7A373AEBEABF" && (
          <span>
            <img src="/img/icons/calendar.svg" className="icon-img-small" />
            {moment().add(minDateValue, "day").format("DD-MM-YYYY")}
          </span>
        )}
        {tat && APPID !== "D89F3695-82D5-4105-991A-7A373AEBEABF" && (
          <span>
            <img src="/img/icons/clock.svg" className="icon-img-small" />
            {tat + " mins"}
          </span>
        )}
      </p>
    )
  }
}
export default Distancebar
