/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import ThemeProvider from "react-toolbox/lib/ThemeProvider"
import theme from "./react-toolbox/theme.js"
import "./react-toolbox/theme.css"
import DatePicker from "react-toolbox/lib/date_picker/DatePicker"
import DocumentMeta from "react-document-meta"
import cookie from "react-cookie"
import getResult from "./Api"
import ReactDom from "react-dom"
import moment from "moment"
import TimePicker from "rc-time-picker"
import axios from "axios"
import "rc-time-picker/assets/index.css"

import lang from "./lang"
const apps = require("./apps")
import config from "./config"
const APPID = config.ids.appId
const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
const datetime = new Date()
var currentDateValue = datetime.getDate()
var helper = require("./Helper.js")

//const min_datetime = new Date(new Date(datetime).setDate(currentDate3));

var CurrentDate = new Date()
var dateFormat = require("dateformat")
var currentDate2 = dateFormat(datetime, "d/m/yyyy")
var currentTime = dateFormat(datetime, "H")
var currentMins = dateFormat(datetime, "MM")
var currentMins3 = dateFormat(datetime, "HH:MM:ss")
//alert(currentMins3);
var HtmlToReactParser = require("html-to-react").Parser
var tat = cookie.load("orderTAT") === "" ? 0 : cookie.load("orderTAT")
var htmlToReactParser = new HtmlToReactParser()

//alert(APPID + "//" + apps.milkHoney);

/* Only apply for chillz india gate */
var defaultDate = datetime
var disabledClassMins = ""
var disabledDays = []

var newdefaultDate = new Date(new Date(datetime).setDate(currentDateValue + 2))

if (APPID === apps.chilliz) {
  //6C9AEE37-BC05-4FB8-A351-D51458B83F26

  var min_datetime = new Date(new Date(datetime).setDate(currentDateValue - 1))
  var max_datetime = new Date(new Date(datetime).setDate(currentDateValue))
  var readOnly = "readonly"
  var disabledClass = "sel-disabled"
} else if (APPID === apps.milkHoney) {
  /*  milk and honey */
  // alert();
  min_datetime = new Date(new Date(datetime).setDate(currentDateValue + 2))
  var defaultDate = new Date(new Date(datetime).setDate(currentDateValue + 3))

  readOnly = ""
  disabledClass = ""
} else if (APPID === apps.leanbento) {
  /* learn bento */

  if (currentTime <= 19) {
    var min_datetime = new Date(new Date(datetime).setDate(currentDateValue))
    var defaultDate = new Date(new Date(datetime).setDate(currentDateValue + 1))
    //alert(defaultDate);
  } else {
    var min_datetime = new Date(new Date(datetime).setDate(currentDateValue + 1))
    var defaultDate = new Date(new Date(datetime).setDate(currentDateValue + 2))
  }
  max_datetime = new Date(CurrentDate.setMonth(CurrentDate.getMonth() + 1))
  readOnly = ""
  disabledClass = ""
  disabledClassMins = ""
} else if (APPID === apps.bushido) {
  /*  bushido order  - 11 am to 12 pm -> 15 mins time interval 

   after  10am choose next day */
  console.log(currentTime)
  if (currentTime >= 9) {
    console.log(1)
    var min_datetime = new Date(new Date(datetime).setDate(currentDateValue))
    var defaultDate = new Date(new Date(datetime).setDate(currentDateValue + 1))
    //alert(defaultDate);
  } else {
    console.log("else condition")
    min_datetime = new Date(new Date(datetime).setDate(currentDateValue - 1))
    max_datetime = new Date(CurrentDate.setMonth(CurrentDate.getMonth() + 1))
  }
  //  max_datetime = new Date(CurrentDate.setMonth(CurrentDate.getMonth() + 1));
  readOnly = ""
  disabledClass = ""
  disabledClassMins = ""
} else {
  min_datetime = new Date(new Date(datetime).setDate(currentDateValue - 1))
  max_datetime = new Date(CurrentDate.setMonth(CurrentDate.getMonth() + 1))
  readOnly = ""
  disabledClass = ""
}

//var  defaultDate2 = new Date(new Date(datetime).setDate(defaultDate + 1 ));

//alert(defaultDate2);
/* Load meta tags */
const meta = {
  title: lang.choose_delvery_date,
  description: lang.site_title,
  meta: {
    name: {
      keywords: lang.site_title,
    },
  },
}
const showSecond = false
const str = showSecond ? "HH:mm:ss" : "HH:mm"

const now = moment().hour(14).minute(30)

let vStartTime = ""
let vEndTime = ""
let vDisplayHrs = []
let vDisplayMins = []
var utc = ""
var valueutc = ""
class DeliveryDatePicker extends React.Component {
  constructor(props) {
    super(props)

    /*add class */
    // helper.addBodyClass();

    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    /* validate delivery avilablity */
    if (cookie.load("defaultAvilablityId") === config.ids.dineinId || typeof cookie.load("defaultAvilablityId") === "undefined" || cookie.load("defaultAvilablityId") === "") {
      hashHistory.push("/")
    }

    if (typeof cookie.load("DeliveryDate") === "undefined" || cookie.load("DeliveryDate") === "" || cookie.load("DeliveryDate") === null) {
      var autoFillDate = defaultDate
    } else {
      // var  autoFillDate =  cookie.load('DeliveryDate');
      var cookieDate = cookie.load("DeliveryDate")
      //	alert(cookieDate);
      var splitedDate = cookieDate.split("/")
      var selDate = this.pad(splitedDate[0])
      var selMonth = this.pad(splitedDate[1])
      var selYear = this.pad(splitedDate[2])
      //var autoFillDate = new Date(selYear, selMonth, selDate);
      autoFillDate = defaultDate
    }

    //alert(autoFillDate );
    this.state = {
      date: autoFillDate,
      settings: [],
      selectTime: currentTime,
      selectMins: currentMins,
      startTime: 0,
      endTime: 24,
      startMins: 0,
      endMins: 60,
      overWriteMins: "No",
      overWriteTime: "No",
      timeHtml: "",
      minsHtml: "",
      fianlstartTime: 0,
      serverTat: 15,
      timeExceed: "No",
      windowHeight: 0,
      timeSlots: [],
      disableDate: [],
      allowTimeSlot: "No",
      hollidayList: [],
      allowHoliDay: "No",
      TimeSlotHtmlVal: "",
      timeText: "Time (HH/MM)",
      productleadtime: 0,
    }
    this.changeTimer = this.changeTimer.bind(this) //changeMins
    this.changeMins = this.changeMins.bind(this) //changeMins

    this.getsettings()

    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getResult("referenceId")
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId")
    }
    var postalCodeWhere = ""
    if (typeof cookie.load("orderPostalCode") !== "undefined") {
      postalCodeWhere = "&postal_code=" + cookie.load("orderPostalCode")
    }
    axios.get(APIURL + "cartver2/contents?status=A&app_id=" + APPID + customerParam + postalCodeWhere).then((res) => {
      if (res.data.status === "ok") {
        if (typeof res.data.result_set !== "undefined") {
          //this.setState('productleadtime',res.data.result_set.product_lead_time);
          var productleadtime = parseInt(res.data.result_set.product_lead_time)
          if (productleadtime > 0) {
            min_datetime = new Date(new Date(datetime).setDate(currentDateValue + (productleadtime - 1)))
            var str_datetime = new Date(new Date(datetime).setDate(currentDateValue + productleadtime))
            this.setState({ productleadtime: productleadtime, date: str_datetime })
          }
        }
      }
    })
  }

  handleChange = (item, value) => {
    this.setState({ ...this.state, [item]: value })
    var des = document.getElementsByClassName("deliverydate")
    var label = des[0].getElementsByTagName("label")
    label[0].style.top = "0px"
    var input = des[0].getElementsByTagName("input")
    utc = new Date().toJSON().slice(0, 10).replace(/-/g, "/")
    valueutc = value.toJSON().slice(0, 10).replace(/-/g, "/")
    //alert(utc)
    if (valueutc === utc) {
      this.loadTimeDropDown("", "")
    } else {
      this.loadTimeDropDown("nextDay")
    }
    ;<p></p>
  }
  /* Disaple year */
  showTimeslotExceed() {
    if (this.state.timeExceed === "Yes") {
      if (APPID === "97347EE4-2845-4011-BCE6-9C0B806DA437" && (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11" || cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0")) {
        if (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11") {
          return <div className="timeslot_exc">Delivery available only on Saturdays (12- 4) pm</div>
        } else if (cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0") {
          return <div className="timeslot_exc">Takeaway available only on Saturdays (9 am to 2pm)</div>
        }
      } else {
        /*return (<div className="timeslot_exc"> Note: Please refer our operating hours. The time you are selecting is outside of our delivery timing. Apologies for the inconvenience.</div>);*/
        return <div className="timeslot_exc"> Note: Please click on the date/time to select the next available timeslot.</div>
      }
    }
  }

  getsettings() {
    var promise = helper.getProSettings("", "No") //getResult('settings','');
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })

      if (res.data.result_set.client_advanced_timeslot_enable === "1") {
        hashHistory.push("/delivery-date-advanced")
      } else if (res.data.result_set.client_delivery_time_setting_enable === "1") {
        hashHistory.push("/delivery-date-new")
      }

      /* find current tat value */
      axios.get(APIURL + "outlets/gettat_details?app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&outlet_id=" + cookie.load("orderOutletId")).then((res) => {
        if (res.data.status === "ok") {
          cookie.save("orderTat", res.data.tat)
          this.setState({ serverTat: res.data.tat })

          this.loadTimeDropDown()
        } else {
          helper.showInfo("Your selected outlet currently closed or disabled by an administrator. Please choose another outlet.")
          hashHistory.push("/")
        }
      })
      /* find current tat value - End  */

      /* timeslot - start */
      if (parseInt(res.data.result_set.client_timeslot_enable) === 1 && cookie.load("defaultAvilablityId") !== config.ids.dineinId) {
        axios.get(APIURLPRO + "settings/get_timeslotlist?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId")).then((res) => {
          if (res.data.status === "ok") {
            this.setState({ timeSlots: res.data.result_set })
            this.setState({ allowTimeSlot: "Yes", timeText: "Choose Time slot" })
            this.loadTimeDropDown("initail") /* this enabled for time slot only */
          }
        })
      }
      /* timeslot - End  */

      /* Holiday  - start */

      var DisableDats = []
      var DisableDatsArr = []
      if (parseInt(res.data.result_set.client_holiday_enable) === 1 && cookie.load("defaultAvilablityId") !== config.ids.dineinId) {
        axios.get(APIURLPRO + "settings/get_holidaylist?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + "&outlet_id=" + cookie.load("orderOutletId")).then((res) => {
          if (res.data.status === "ok") {
            var resultData = res.data.result_set

            resultData.map((loadData) => DisableDats.push(this.parseDate(loadData.date)))

            resultData.map((loadData) => DisableDatsArr.push(loadData.date))
            var strdate_obj = this.state.date
            if (DisableDatsArr.length > 0 && strdate_obj !== "") {
              this.checkCrntDateHolliday(strdate_obj, DisableDatsArr)
            }

            this.setState({ hollidayList: resultData, allowHoliDay: "Yes", disableDate: DisableDats })
          }
        })
      }

      /* Holiday - end */
    })
  }
  componentDidMount() {
    loadAnalytics()
    /* get settings */

    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })

    //this.getsettings();
    var des = document.getElementsByClassName("deliverydate")
    var fc = des[0].getElementsByTagName("div")
    var se = fc[0].getElementsByTagName("div")
    se[0].style.padding = "0px"
    var input = se[0].getElementsByTagName("input")
    input[0].style.borderBottom = "0px"
    var label = des[0].getElementsByTagName("label")
    label[0].style.top = "6px"
    //document.getElementsByClassName('rc-time-picker-input')[0].classList.add("form-control");

    /* load time and mins  html values */

    /* disabled */
    if (this.state.allowTimeSlot === "Yes") {
      if (APPID === apps.chilliz) {
        /* disabled date and time picker chilliz */
        document.getElementById("deliveryTime").disabled = true
        document.getElementById("deliveryMins").disabled = true
      } else {
        document.getElementById("deliveryTime").disabled = false
        document.getElementById("deliveryMins").disabled = false
      }
    }
  }
  onChange(value) {
    var list = document.getElementsByClassName("form-group")
    var n
    for (n = 0; n < list.length; ++n) {
      list[n].classList.remove("is-empty")
    }
  }

  /* this function used to load cart icon image */
  loadCartImage() {
    return getResult("cartImage", "Yes")
  }

  /* store unit no's */
  stroteUnitNoOne(event) {
    cookie.save("unitNoOne", event.target.value)
  }

  /* store unit no's */
  stroteUnitNoTwo(event) {
    cookie.save("unitNoTwo", event.target.value)
  }

  /* store unit no's */
  strotetowerNo(event) {
    cookie.save("towerNo", event.target.value)
  }
  /* validate delivery date */
  checkDeliveryDate(holidayData) {
    this.loadTimeDropDown("", "")
    var seldate = document.getElementsByClassName("deliverydate")[0].getElementsByTagName("input")[0].value

    if (this.state.allowTimeSlot === "Yes") {
      /* for timeslot */
      var selMins = ""
      var selTime = ""
      var Timeslot = document.getElementById("timeSlot").value
    } else {
      /* For TAT */
      selTime = document.getElementById("deliveryTime").value
      selMins = document.getElementById("deliveryMins").value
      Timeslot = ""
    }

    if ((seldate !== "" && selMins !== "" && selTime !== "") || (this.state.allowTimeSlot === "Yes" && Timeslot !== "")) {
      cookie.save("DeliveryDate", seldate)
      if (this.state.allowTimeSlot === "Yes") {
        /* For timeslot */

        var splited = Timeslot.split("/")
        cookie.save("isSletedTimeSlot", "Yes")
        cookie.save("DeliveryTimeSlotFormated", splited[1])
        cookie.save("DeliveryTimeSlot", splited[0])

        var splitedSlot = splited[0].split("-")
        var orderTimeSlotFrom = splitedSlot[0].replace(/"+$/g, "")
        /*var DeliveryTimeTxt = this.convertTime12to24(orderTimeSlotFrom);
		 cookie.save('DeliveryTime', DeliveryTimeTxt);*/
        cookie.save("DeliveryTime", orderTimeSlotFrom)
      } else {
        /* For TAT  */
        cookie.save("isSletedTimeSlot", "No")
        cookie.save("DeliveryTime", selTime + ":" + selMins)
      }

      /* start - holiday validation  */
      var holidayLen = holidayData.length
      if (holidayLen > 0 && this.state.allowHoliDay === "Yes") {
        for (var hl = 0; hl < holidayLen; hl++) {
          //alert(seldate + "===" );
          if (seldate === dateFormat(holidayData[hl].date, "d/m/yyyy")) {
            helper.showInfo("The selected date is a holiday. Please select another day")
            return false
          }
        }
      }

      /* End of holiday validation  */

      //Check unit number validation
      if (cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
        var AlertOne = document.getElementById("unitone").value
        var AlertTwo = document.getElementById("unittwo").value
        if (AlertOne === "" || AlertTwo === "") {
          var res = confirm("You did not enter a Floor No / Unit No. \n Do you want to continue")

          if (!res) {
            error = "1"
            return false
          }
        }
      }

      var splitedDate = seldate.split("/")
      var OrderTime = splitedDate[2] + "-" + this.pad(splitedDate[1]) + "-" + this.pad(splitedDate[0]) + " " + selTime + ":" + selMins
      var cookieSlot = cookie.load("DeliveryTimeSlot")
      var splitedSlot = cookieSlot.split("-")
      var order_pickup_time_slot_from = splitedSlot[0]
      var order_pickup_time_slot_to = splitedSlot[1]

      axios
        .get(
          APIURLPRO +
            "ordersv1/validate_order_count?app_id=" +
            APPID +
            "&availability_id=" +
            cookie.load("defaultAvilablityId") +
            "&outlet_id=" +
            cookie.load("orderOutletId") +
            "&order_date=" +
            OrderTime +
            "&order_pickup_time_slot_from=" +
            order_pickup_time_slot_from +
            "&order_pickup_time_slot_to=" +
            order_pickup_time_slot_to
        )
        .then((res) => {
          if (res.data.status === "ok") {
            hashHistory.push("/checkout")
          } else {
            helper.showInfo(res.data.message)
          }
        })
      //hashHistory.push("/checkout");
    } else {
      helper.showInfo(lang.choose_delvery_date_error)
    }
  }

  changeTimer(e) {
    console.log("change date action")
    var seletedTime = e.target.value

    /* add date wise calculation */
    var selectedDate = document.getElementsByClassName("deliverydate")[0].getElementsByTagName("input")[0].value
    this.setState({ selectTime: seletedTime })
    if (selectedDate === currentDate2) {
      if (parseInt(seletedTime) === parseInt(this.state.fianlstartTime)) {
        this.loadTimeDropDown("", "samemins", seletedTime)
      } else {
        this.loadTimeDropDown("", "nextMins", seletedTime)
      }
    } else {
      //  alert('next day');
      this.loadTimeDropDown("nextDay", "nextMins", seletedTime)
    }
  }

  convertTime12to24(time12h) {
    var ts = time12h
    if (ts !== "") {
      const [time, modifier] = time12h.split(" ")

      let [hours, minutes] = time.split(":")

      if (hours === "12") {
        hours = "00"
      }

      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12
      }

      ts = `${hours}:${minutes}`
    }
    return ts
  }

  /* chnage minutes */
  changeMins(e) {
    this.setState({ selectMins: e.target.value })
  }

  /* set default Avilablity */
  loadTimeDropDown(day = null, mins = null, triggerTime = null) {
    //console.log("load Time INIT");
    if (this.state.allowTimeSlot === "No") {
      /* if calculate server TAT time */
      var serStartTat = 0
      var serEndTat = 0
      var serverStartFloat = 0
      //var bushidoStart = "11:00:00";
      //var bushidoEndt = "13:30:00";
      if (
        parseFloat(this.state.settings.client_timer_enable) === 1 &&
        typeof this.state.settings.client_timer_enable !== "undefined" &&
        this.state.settings.client_start_time !== "" &&
        typeof this.state.settings.client_start_time !== "undefined" &&
        this.state.settings.client_end_time !== "" &&
        typeof this.state.settings.client_end_time !== "undefined"
      ) {
        var serStrTime = this.state.settings.client_start_time //(APPID === apps.bushido ? bushidoStart :   this.state.settings.client_start_time );
        var serEndTime = this.state.settings.client_end_time //(APPID === apps.bushido ? bushidoEndt : this.state.settings.client_end_time );

        /* split start time */
        var strrtSplited = (serStrTime || "").split(":")
        if (typeof strrtSplited[1] !== "undefined") {
          serStartTat = parseInt(strrtSplited[1])

          //var newFlatRes = parseInt(strrtSplited[0])+"."+parseInt(strrtSplited[1]);
          // var serverStartFloat = parseFloat(newFlatRes);
        }

        /* split start time */
        var endTimeSplited = serEndTime.split(":")
        if (typeof endTimeSplited[1] !== "undefined") {
          serEndTat = parseInt(endTimeSplited[1])
        }
      }

      tat = parseInt(this.state.serverTat)
      var tatTime = 0
      var tatmins = 0

      var converted = tat / 60
      var addTatTime = day !== "nextDay" ? parseInt(currentMins) + parseInt(tat) : parseInt(tat)
      //alert(addTatTime);
      if (addTatTime < 45) {
        //alert(addTatTime + "//");
        tatTime = 0
        tatmins = this.makeround(addTatTime) //alert(tatmins + "/////");
      } else if (addTatTime >= 45 && addTatTime <= 104) {
        tatTime = 1
        var minusVlaue = parseInt(addTatTime) > 60 ? parseInt(addTatTime) - 60 : 0
        tatmins = this.makeround(minusVlaue)
      } else {
        //alert('else');
        tatTime = 2
        var minusVlaue = parseInt(addTatTime) > 120 ? parseInt(addTatTime) - 120 : 0
        tatmins = this.makeround(minusVlaue)
        /* if choose next day over default time */
      }

      /* set start timing */

      /* calculate timer dropdown values */
      if (
        parseFloat(this.state.settings.client_timer_enable) === 1 &&
        typeof this.state.settings.client_timer_enable !== "undefined" &&
        this.state.settings.client_start_time !== "" &&
        typeof this.state.settings.client_start_time !== "undefined" &&
        this.state.settings.client_end_time !== "" &&
        typeof this.state.settings.client_end_time !== "undefined"
      ) {
        var serverTime = parseInt(this.state.settings.client_start_time) // (APPID === apps.bushido? parseInt(bushidoStart) :    parseInt(this.state.settings.client_start_time));

        //  var serStrTime = (APPID === apps.bushido? "10:30:00" :   this.state.settings.client_start_time );
        var startTime = serverTime <= currentTime && day !== "nextDay" ? currentTime : serverTime
        var endTime = parseInt(this.state.settings.client_end_time) //(APPID === apps.bushido? parseInt(bushidoEndt) :  parseInt(this.state.settings.client_end_time));

        /*only for learn bento */
        var startTimeLeanbento = parseInt(this.state.settings.client_start_time)
        var endTimeLeanbento = parseInt(this.state.settings.client_end_time)
      } else {
        var startTime = day !== "nextDay" ? currentTime : 0
        var endTime = 24

        /*only for learn bento */
        var startTimeLeanbento = 0
        var endTimeLeanbento = 24
      }

      var fianlstartTime = parseInt(startTime) + parseInt(tatTime)

      /* add server 30 mins calculation  
	   if(day === "nextDay"){
	   
	    var newAddTatTime = parseInt(tat) + parseInt(serStartTat) ;
	     //alert(newAddTatTime);
		  
 
			 //alert(tatmins);
		   if(serStartTat > 44 ){
		      fianlstartTime =  fianlstartTime + 1 ;
		   }
	   }*/

      this.setState({ fianlstartTime: fianlstartTime })
      var TimeHtml = ""
      var timerStatus = "No"
      var endTimeCalc = endTime
      var bushidoEndTime = null

      /* for leanbento apply server tine */
      if (APPID === apps.leanbento) {
        if (serStartTat > 0) {
          fianlstartTime = startTimeLeanbento + 1
        } else {
          fianlstartTime = startTimeLeanbento
        }

        if (serEndTat >= 30) {
          endTime = endTimeLeanbento + 1
        } else {
          endTime = endTimeLeanbento
        }

        document.getElementById("deliveryMins").disabled = false
      }

      /* for bushido apply server tome  */
      if (APPID === apps.bushido) {
        fianlstartTime = 11
        endTime = 12
      }

      for (let valueTime = fianlstartTime; valueTime < endTime; valueTime++) {
        if (
          (APPID !== apps.vantase && APPID !== apps.workspace) ||
          (APPID === apps.vantase && parseInt(valueTime) !== parseInt(15) && parseInt(valueTime) !== parseInt(16) && parseInt(valueTime) !== parseInt(17)) ||
          (APPID === apps.workspace && parseInt(valueTime) !== parseInt(12) && APPID === apps.workspace && parseInt(valueTime) !== parseInt(13))
        ) {
          TimeHtml += "<option value='" + this.pad(valueTime) + "'  >" + this.pad(valueTime) + "</option>"
          timerStatus = "Yes"
          var endTimeCalc = valueTime
          bushidoEndTime = valueTime
        }
      }

      var timeDropDown = htmlToReactParser.parse(TimeHtml)
      this.setState({ timeHtml: timeDropDown })
      /* End of timer dropdown */

      /* calculate Mins dropdown values */
      var htmlMins = ""
      // alert(serverTime + "//" + triggerTime )
      if (parseInt(triggerTime) === parseInt(serverTime) && day === "nextDay") {
        //  console.log("server start time - click time(same) / next day");
        //alert("time same" + tatmins + "//" + tat);
        // var startMins =  parseInt(tatmins) + tat;   commented by vinoth = 06/10/2017
        //alert(tatmins);
        var startMins = parseInt(tatmins)
      } else {
        if (day === "nextDay" && parseInt(triggerTime) === fianlstartTime) {
          /* set next day + tat time */
          // console.log("server start time - click time(same) / next day");
          var startMins = parseInt(tatmins)
        } else {
          var startMins = mins === "nextMins" ? 0 : parseInt(tatmins) /* if choose next miin s apply 0 */
        }
      }
      //alert(startMins);

      var endMins = 60

      /* check final time tat */

      if (parseInt(triggerTime) === parseInt(endTimeCalc)) {
        /* startMins = startMins + tat;  this code removed for testing purpose -- 17/04/2017 */
        startMins = startMins + tat
      }

      /* for leanbento apply server tine */
      var incrementVal = 15
      if (APPID === apps.leanbento) {
        startMins = 0
        endMins = 45
        incrementVal = 30
      }

      //  console.log(triggerTime + " // triggerTime")
      var bushidotime = triggerTime !== null ? parseInt(triggerTime) : 10
      /*  console.log(bushidotime);
	    if(parseInt(bushidotime) === parseInt(serverTime) && day ==="nextDay" && APPID === apps.bushido){
			console.log('choose tomorrow');
			 // startMins = 45;
		}else if(APPID === apps.bushido){
			 startMins = 0;
		} */
      if (APPID === apps.bushido) {
        console.log("apps bushido")
        incrementVal = 5
        /// var calTime = (triggerTime !== null && triggerTime !==""? triggerTime : fianlstartTime );
        console.log("bushidoEndTime " + bushidoEndTime + " // triggerTime " + triggerTime)
        if (endTimeCalc === 12) {
          console.log("endMins 15")
          startMins = 0
          endMins = 15
        } else {
          console.log(2)
          startMins = 0
        }
        /*if(parseInt(calTime) === parseInt("12") ){
				 console.log(1);
				    startMins = 0;
				    endMins = 15;
			 }else{
				  console.log(2);
				  startMins = 0;
			 } */
      }

      // alert(startMins);
      var minsStatus = "No"
      for (let valuemins = startMins; valuemins < endMins; valuemins += incrementVal) {
        htmlMins += "<option value='" + this.pad(valuemins) + "'  >" + this.pad(valuemins) + "</option>"
        minsStatus = "Yes"
      }

      if (timerStatus === "No" || minsStatus === "No") {
        this.setState({ timeExceed: "Yes" })
      } else {
        this.setState({ timeExceed: "No" })
      }
      var minsDropDown = htmlToReactParser.parse(htmlMins)
      this.setState({ minsHtml: minsDropDown })

      /* End of Mins dropdown values  */
    } else {
      /* if time slot enabled */

      var todayDateonly = new Date().toISOString().slice(0, 10)
      var nowDateTimetxt = new Date()

      var timeSlotHtml = ""
      //	     state.timeSlots =>
      var stateTimeSlot = this.state.timeSlots
      var lenghtTimeSlots = stateTimeSlot.length
      var exceedLoop = "No"
      if (lenghtTimeSlots > 0) {
        if (day === "initail" && this.state.productleadtime > 0) {
          day = "nextDay"
        }

        for (var tl = 0; tl < lenghtTimeSlots; tl++) {
          //	alert(currentTime + " --" + stateTimeSlot[tl].timeslot_to_time );
          var chkngTxt = new Date(todayDateonly + " " + stateTimeSlot[tl].timeslot_from_time)
          //if(   day !=="nextDay" && ( Date.parse(currentDate2+' '+currentMins3) <  Date.parse(currentDate2+' '+stateTimeSlot[tl].timeslot_from_time )) ) {
          if (day !== "nextDay" && nowDateTimetxt < chkngTxt) {
            exceedLoop = "Yes"
            timeSlotHtml += "<option value='" + stateTimeSlot[tl].timeslot_from_time + '"-"' + stateTimeSlot[tl].timeslot_to_time + "'>" + stateTimeSlot[tl].FormatedFrom + " - " + stateTimeSlot[tl].FormatedTo + "</option>"
          } else if (day === "nextDay") {
            exceedLoop = "Yes"
            timeSlotHtml += "<option value='" + stateTimeSlot[tl].timeslot_from_time + "-" + stateTimeSlot[tl].timeslot_to_time + "/" + stateTimeSlot[tl].FormatedFrom + "-" + stateTimeSlot[tl].FormatedTo + "'>" + stateTimeSlot[tl].FormatedFrom + " - " + stateTimeSlot[tl].FormatedTo
          }
        }
        // alert(exceedLoop);

        if (exceedLoop === "No") {
          this.setState({ timeExceed: "Yes" })
        } else {
          this.setState({ timeExceed: "No" })
        }
      }

      // console.log(timeSlotDD);
      var timeSlotDD = htmlToReactParser.parse(timeSlotHtml)
      this.setState({ TimeSlotHtmlVal: timeSlotDD })

      //alert('nect day ');
      //}
    }
  }

  /* add zero */
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString()
  }

  /* maek round of */
  makeround(value) {
    var returnValue = 0
    if (value > 0 && value <= 15) {
      returnValue = 15
    } else if (value >= 16 && value <= 30) {
      returnValue = 30
    } else if (value >= 31 && value <= 45) {
      returnValue = 45
    }

    return returnValue
  }

  /* format date'es */
  changeDateFun(chgDateTxt) {
    var mnth_txt = parseInt(chgDateTxt.getMonth()) + 1
    mnth_txt = this.pad(mnth_txt)
    var date_txt = parseInt(chgDateTxt.getDate())
    date_txt = this.pad(date_txt)
    var strdate_txt = chgDateTxt.getFullYear() + "-" + mnth_txt + "-" + date_txt

    return strdate_txt
  }
  /* format date'es */
  checkCrntDateHolliday(DateTxt, DateArr) {
    if (DateArr.indexOf(this.changeDateFun(DateTxt)) === -1) {
      this.setState({ date: DateTxt })
    } else {
      var daytextstr = parseInt(DateTxt.getDate()) + 1
      var str_datetime = new Date(new Date(DateTxt).setDate(daytextstr))
      this.checkCrntDateHolliday(str_datetime, DateArr)
    }
  }

  /* format date'es */
  parseDate(input) {
    var parts = input.match(/(\d+)/g)
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2]) // months are 0-based
  }

  /* show timig DD or timelsot */

  /* function sued to load time slot and time dd vales */
  loadTimer() {
    /* <select className={' customdd timelsotdd'}   id="timeSlot"   >
										   {this.state.TimeSlotHtmlVal}
										    </select>
											 */

    ;(
      <select className={disabledClass + " customdd"} value={this.state.selectTime} onChange={this.changeTimer} id="deliveryTime">
        {this.state.timeHtml}
      </select>
    ) /
      (
        <select id="deliveryMins" className={disabledClassMins + " " + disabledClass + " customdd"} value={this.state.selectMins} onChange={this.changeMins}>
          {this.state.minsHtml}
        </select>
      )
  }

  /*show outlet text for workspace */
  outletNameText() {
    if (apps.workspace === APPID) {
      return htmlToReactParser.parse("Workspace Espresso Bar <br/> 12 Marina Boulevard<br/> Marina Bay Financial Centre Tower 3<br/> #01-08 Singapore 018982")
    } else {
      return cookie.load("defaultAvilablityId") === config.ids.deliveryId ? cookie.load("orderDeliveryAddress") : cookie.load("orderHandled")
    }
  }

  render() {
    if (this.state.allowTimeSlot === "Yes") {
      return (
        <div>
          {" "}
          <DocumentMeta {...meta} />
          <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeight }}>
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20  head-child">
                  <Link to={"/cart"}>
                    <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                  </Link>
                </div>
                <div className="col-xs-6 nav-head head-child">
                  <h1 className="main-title">{cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "Delivery" : "PICKUP"}</h1>
                </div>
                <div className="col-xs-3 nopadding head-child">
                  {/*	<p className="b-txt text-right ">
								<Link to={"/cart"} >  <img alt=""  src={this.loadCartImage()}className="icon-img-small-1"/> </Link>
							</p> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 calender-area">
                <div className="col-xs-12 calender-area-sub">
                  <div className="col-xs-offset-2 col-xs-8 cal-div">
                    <h1 className="sub-heading" style={{ textAlign: "center" }}>
                      {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "SELECT YOUR DELIVERY DATE AND TIME" : "SELECT YOUR PICKUP DATE AND TIME"}
                    </h1>
                    <div className="form-group label-floating is-empty card-no custom-date-label">
                      <div className="input-group deliverydate">
                        <ThemeProvider theme={theme}>
                          <DatePicker
                            label="Date (DD/MM/YYYY)"
                            readonly={readOnly}
                            inputFormat={(value) => `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`}
                            minDate={min_datetime}
                            maxDate={max_datetime}
                            sundayFirstDayOfWeek
                            onChange={this.handleChange.bind(this, "date")}
                            className="delivery_datepiker"
                            disabledDates={this.state.disableDate}
                            value={this.state.date}
                          />
                        </ThemeProvider>
                      </div>
                    </div>
                    <div className="form-group label-floating is-empty card-no custom-time-label min-hgt-hour-t">
                      <div className="input-group">
                        <label className="control-label">{this.state.timeText}</label>

                        <select className={" customdd timelsotdd"} id="timeSlot">
                          {this.state.TimeSlotHtmlVal}
                        </select>
                      </div>
                    </div>
                    {this.showTimeslotExceed()}

                    <div className="space-35"></div>
                  </div>
                </div>
                <div className="col-xs-12 calender-area-sub-2">
                  <h1 className="sub-heading" style={{ textAlign: "center" }}>
                    {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "DELIVER TO" : "PICKUP AT"}
                  </h1>
                  <h1 className="sub-heading-2" style={{ textAlign: "center" }}>
                    {this.outletNameText()}
                  </h1>

                  {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? (
                    <div className="col-xs-12 unit-no-div">
                      <div className="form-group col-sm-4 col-xs-12 no-margin">
                        <div className="form-group label-floating is-empty card-no">
                          <div className="input-group">
                            <label className="control-label">Building Name/ Tower</label>
                            <input type="text" id="towerno" className="form-control" onChange={this.strotetowerNo} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-sm-4 col-xs-12 no-margin">
                        <div className="form-group label-floating is-empty card-no">
                          <div className="input-group">
                            <label className="control-label">Floor No</label>
                            <input type="text" id="unitone" className="form-control" onChange={this.stroteUnitNoOne} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-sm-4 col-xs-12 no-margin">
                        <div className="form-group label-floating is-empty card-no">
                          <div className="input-group">
                            <label className="control-label">Unit No</label>
                            <input type="text" id="unittwo" className="form-control" onChange={this.stroteUnitNoTwo} />
                          </div>
                        </div>
                        <br />
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  ) : (
                    <div className="space-30"></div>
                  )}
                </div>
                <div className="space-30"></div>
              </div>
            </div>

            <div className="space-30"></div>

            <div className="row">
              <Link onClick={this.checkDeliveryDate.bind(this, this.state.hollidayList)}>
                <button className="col-lg-12 continue-but desktop-container container-fluid">Continue</button>
              </Link>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          {" "}
          <DocumentMeta {...meta} />
          <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeight }}>
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20  head-child">
                  <Link to={"/cart"}>
                    <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                  </Link>
                </div>
                <div className="col-xs-6 nav-head head-child">
                  <h1 className="main-title">{cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "Delivery" : "PICKUP"}</h1>
                </div>
                <div className="col-xs-3 nopadding head-child">
                  {/*	<p className="b-txt text-right ">
								<Link to={"/cart"} >  <img alt=""  src={this.loadCartImage()}className="icon-img-small-1"/> </Link>
							</p> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 calender-area">
                <div className="col-xs-12 calender-area-sub">
                  <div className="col-xs-offset-2 col-xs-8 cal-div">
                    <h1 className="sub-heading" style={{ textAlign: "center" }}>
                      {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "SELECT YOUR DELIVERY DATE AND TIME" : "SELECT YOUR PICKUP DATE AND TIME"}
                    </h1>
                    <div className="form-group label-floating is-empty card-no custom-date-label">
                      <div className="input-group deliverydate">
                        <ThemeProvider theme={theme}>
                          <DatePicker
                            label="Date (DD/MM/YYYY)"
                            readonly={readOnly}
                            inputFormat={(value) => `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`}
                            minDate={min_datetime}
                            maxDate={max_datetime}
                            sundayFirstDayOfWeek
                            onChange={this.handleChange.bind(this, "date")}
                            className="delivery_datepiker"
                            disabledDates={this.state.disableDate}
                            value={this.state.date}
                          />
                        </ThemeProvider>
                      </div>
                    </div>
                    <div className="form-group label-floating is-empty card-no custom-time-label min-hgt-hour-t">
                      <div className="input-group">
                        <label className="control-label">{this.state.timeText}</label>
                        <select className={disabledClass + " customdd"} value={this.state.selectTime} onChange={this.changeTimer} id="deliveryTime">
                          {this.state.timeHtml}
                        </select>{" "}
                        /
                        <select id="deliveryMins" className={disabledClassMins + " " + disabledClass + " customdd"} value={this.state.selectMins} onChange={this.changeMins}>
                          {this.state.minsHtml}
                        </select>
                        {/*   <select className={disabledClass + ' customdd'} value={this.state.selectTime} onChange={this.changeTimer} id="deliveryTime"   >
										   {this.state.timeHtml}
										    </select> /
											
										 <select id="deliveryMins" className={disabledClassMins +" "+disabledClass + ' customdd'} value={this.state.selectMins} onChange={this.changeMins}  >
										   {this.state.minsHtml}
										</select>   */}
                      </div>
                    </div>
                    {this.showTimeslotExceed()}

                    <div className="space-35"></div>
                  </div>
                </div>
                <div className="col-xs-12 calender-area-sub-2">
                  <h1 className="sub-heading" style={{ textAlign: "center" }}>
                    {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "DELIVER TO" : "PICKUP AT"}
                  </h1>
                  <h1 className="sub-heading-2" style={{ textAlign: "center" }}>
                    {this.outletNameText()}
                  </h1>

                  {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? (
                    <div className="col-xs-12 unit-no-div">
                      <div className="form-group col-sm-4 col-xs-12 no-margin">
                        <div className="form-group label-floating is-empty card-no">
                          <div className="input-group">
                            <label className="control-label">Building Name/ Tower</label>
                            <input type="text" id="towerno" className="form-control" onChange={this.strotetowerNo} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-sm-4 col-xs-12 no-margin">
                        <div className="form-group label-floating is-empty card-no">
                          <div className="input-group">
                            <label className="control-label">Floor No</label>
                            <input type="text" id="unitone" className="form-control" onChange={this.stroteUnitNoOne} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-sm-4 col-xs-12 no-margin">
                        <div className="form-group label-floating is-empty card-no">
                          <div className="input-group">
                            <label className="control-label">Unit No</label>
                            <input type="text" id="unittwo" className="form-control" onChange={this.stroteUnitNoTwo} />
                          </div>
                        </div>
                        <br />
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  ) : (
                    <div className="space-30"></div>
                  )}
                </div>
                <div className="space-30"></div>
              </div>
            </div>

            <div className="space-30"></div>

            <div className="row">
              <Link onClick={this.checkDeliveryDate.bind(this, this.state.hollidayList)}>
                <button className="col-lg-12 continue-but desktop-container container-fluid">Continue</button>
              </Link>
            </div>
          </div>
        </div>
      )
    }
  }
}
export default DeliveryDatePicker
