/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import axios from "axios"
import DocumentMeta from "react-document-meta"
import cookie from "react-cookie"
import getResult from "./Api"
import Loading from "./loading"

import config from "./config"
var moment = require("moment")
const APPID = config.ids.appId
const APIURL = config.path.apiUrl
var strp = require("strp")
import lang from "./lang"
var qs = require("qs")
var helper = require("./Helper.js")

var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParserTo = new HtmlToReactParser()

/* Load meta tags */
const meta = {
  title: "My Promotions" + lang.site_title_with_separator,
  description: "My Promotions",
  meta: {
    name: {
      keywords: "My Promotions",
    },
  },
}

class Promotion extends React.Component {
  constructor(props) {
    super(props)
    this.state = { pageTitle: "My promotion", pageDesc: "My promotion", status: "ok", windowHeight: 0, promotionStatus: "Waiting", myPromotions: [], myCatalogue: [], promoImagePath: "", currentPromo: [], cartDetails: [], cartItems: [], settings: {} }

    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    helper.isAnonymousUserRedirect()
  }

  componentDidUpdate() {
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
      return false
    }

    if (getResult("UserDetails") === false) {
      helper.showInfo(lang.users.user_info_missing, "", "redirect", "/my-account")
      return false
    }
  }

  componentWillMount() {
    axios.all([axios.get(APIURL + "promotion_api_v2/promotionlistWhitoutuniqcode?status=A&app_id=" + APPID + "&customer_id=" + cookie.load("UserId"))]).then(
      axios.spread((promotion) => {
        this.setState({ promotionStatus: "Ready" })

        console.log(promotion)
        /* Set page content */

        /* set promotion content */
        if (promotion.data.status === "ok") {
          // this.setState({ pageTitle:  "My promotion",pageDesc: "My promotion" });
          this.setState({ myCatalogue: promotion.data.result_set.promo_history, myPromotions: promotion.data.result_set.my_promo, promoImagePath: promotion.data.common.promo_image_source })
        }
      })
    )

    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight + "px" })
  }

  componentDidMount() {
    var postalCodeWhere = ""
    if (typeof cookie.load("orderPostalCode") !== "undefined") {
      postalCodeWhere = "&postal_code=" + cookie.load("orderPostalCode")
    }
    axios.get(APIURL + "cartver2/contents?status=A&app_id=" + APPID + "&customer_id=" + cookie.load("UserId") + postalCodeWhere).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ cartDetails: res.data.result_set.cart_details, cartItems: res.data.result_set.cart_items })
      }
    })

    loadAnalytics()

    var promise = getResult("settingsPro", "No")
    promise.then((res) => {
      if (!helper.disableMenu(res.data.result_set, "myaccount_promos")) {
        hashHistory.push("/")
        return false
      }
    })
  }

  showPromo(promo) {
    this.setState({ currentPromo: promo })
    $("#RedeemPromocodeModal").modal("toggle")
  }

  /*apply coupon  function*/
  applyPromo(promocode) {
    $("#RedeemPromocodeModal").modal("hide")

    if (typeof this.state.cartDetails !== "undefined" && this.state.cartDetails.length === 0) {
      helper.showInfo("Your cart is currently empty. Please add products and try again", "", "")
      return false
    }
    var postObject = {}
    var promocode = promocode !== "" ? promocode : $("#promocode").val()

    /* postObject = {
			/* cart details  /
			'app_id': APPID,
			'reference_id': cookie.load('UserId'),
			'promo_code': promocode,
      		'cart_amount': cookie.load('cartsubtotal'),
      		'availability_id':cookie.load('defaultAvilablityId'),
			'cart_quantity': cookie.load('carttotalitems'),
		} */

    var cartSubToatl = parseFloat(this.state.cartDetails.cart_sub_total)
    var cartItemsCount = parseInt(this.state.cartDetails.cart_total_items)
    postObject = {
      /* cart details */
      app_id: APPID,
      reference_id: cookie.load("UserId"),
      promo_code: promocode,
      cart_amount: parseFloat(cartSubToatl),
      cart_quantity: cartItemsCount,
      category_id: this.getPromoCategory(),
      availability_id: cookie.load("defaultAvilablityId"),
    }

    axios.post(APIURL + "promotion_api_v2/apply_promotion", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "success") {
        //alert(res.data.message);
        helper.showInfo(res.data.message, "", "reload")
        cookie.save("promotion_id", res.data.result_set.promotion_id)
        cookie.save("promotion_applied", "Yes")
        cookie.save("promotion_code", res.data.result_set.promotion_code)
        cookie.save("promotion_delivery_charge_applied", res.data.result_set.promotion_delivery_charge_applied)
        cookie.save("promotion_amount", res.data.result_set.promotion_amount)
        cookie.save("promotion_category", res.data.result_set.promotion_category)
        cookie.save("prmo_type", res.data.result_set.prmo_type)

        /*Remove if voucher applied*/
        cookie.remove("voucher_applied")
        cookie.remove("voucher_code")
        cookie.remove("voucher_amount")

        hashHistory.push("/delivery-date")
      } else {
        if (typeof res.data.show_message !== "undefined" && res.data.show_message === "Yes") {
          helper.showInfo(res.data.message, "", "redirect", "/")
        } else if (typeof res.data.form_error !== "undefined") {
          helper.showInfo(res.data.form_error, "", "reload")
        } else {
          helper.showInfo(res.data.message, "", "reload")
        }
        if (res.data.clear_offer === "Yes") {
          cookie.remove("promotion_id")
          cookie.remove("promotion_applied")
          cookie.remove("promotion_code")
          cookie.remove("promotion_delivery_charge_applied")
          cookie.remove("promotion_amount")
          cookie.remove("promotion_category")
          cookie.remove("prmo_type")
        }
      }
    })
  }

  /*  get promo category details  */
  getPromoCategory() {
    var product_cartid = ""
    var cartItems = this.state.cartItems
    var cartCount = cartItems.length
    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        if (product_cartid !== "" && cartItems[p].cart_item_product_id !== "") {
          product_cartid += ";"
        }
        product_cartid += cartItems[p].cart_item_product_id + "|" + cartItems[p].cart_item_total_price + "|" + cartItems[p].cart_item_qty
      }
    }

    return product_cartid
  }
  /*claim coupon  function*/
  claimPromo(promocode) {
    var postObject = {}
    var promocode = promocode !== "" ? promocode : $("#promocode").val()

    postObject = {
      /* cart details */
      app_id: APPID,
      reference_id: cookie.load("UserId"),
      promo_code: promocode,
      cart_amount: cookie.load("cartsubtotal"),
      availability_id: cookie.load("defaultAvilablityId"),
      cart_quantity: cookie.load("carttotalitems"),
    }

    axios.post(APIURL + "promotion_api_v2/claimpromo", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "success") {
        helper.showInfo(res.data.message, "", "reload")
      } else {
        if (typeof res.data.show_message !== "undefined" && res.data.show_message === "Yes") {
          helper.showInfo(res.data.message, "", "")
        } else if (typeof res.data.form_error !== "undefined") {
          //alert(res.data.form_error);
          helper.showInfo(res.data.form_error, "", "reload")
        } else {
          // alert(res.data.message);
          helper.showInfo(res.data.message, "", "")
        }
      }
    })
  }

  /*load my promotion list here */
  loadMyPromotions(e, type) {
    if (this.state.promotionStatus === "Waiting") {
      return (
        <div>
          <Loading />
        </div>
      )
    } else {
      /* load promotions */
      if (type === "promotion") {
        var myPromotion = this.state.myPromotions
        if (myPromotion.length > 0) {
          return myPromotion.map((promo, index) => (
            <div className="col-md-6" key={index}>
              <div className="promo-box">
                <div className="promo-img-item">
                  <img src={this.loadPromoImage(promo.promotion_image)} />
                </div>
                <div className="promo-btm-section">
                  <span className="promo-text">Promo Code</span>
                  <span className="promo-amount">{promo.promo_code}</span>
                  <span className="promo-expiry-date"> Valid till {moment(promo.promotion_end_date).format("Do of MMM YYYY")} </span>
                  <div className="promo-btn-block">
                    <Link style={{ cursor: "pointer" }} onClick={this.showPromo.bind(this, promo)} className="promo-btn-view">
                      <span>View</span>
                    </Link>
                    <Link onClick={this.applyPromo.bind(this, promo.promo_code)} style={{ cursor: "pointer" }} className="promo-btn-redeem">
                      <span>Redeem</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <Link  className="promo-box"  onClick={this.applyPromo.bind(this,promo.promo_code)}  style={{cursor: 'pointer'}}>
                    {promo.promotion_image!='' && promo.promotion_image!=null &&
                      <div className="promo-img-item">
                         <img src={this.loadPromoImage(promo.promotion_image)} />
                        
                        <div className="updated-date">
                          <span>{promo.promo_days_left}</span>
                        </div>
                      </div>
                      }
                      {promo.promotion_image==''  || promo.promotion_image==null && <div className="empty-promo-bx"> 
						<div className="updated-date">
						<span>{promo.promo_days_left}</span>
						</div> <span className="propmo_code_emp">{promo.promo_code}</span></div>}
                      <div className="promo-btm-section">
                        <span>Reedem</span>
                      </div>
				  </Link> */}
            </div>
          ))
        } else {
          return (
            <div className="productparent">
              <div className="raw">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <br />
                  <p className="text-center">No promotion found</p>
                </div>
              </div>
            </div>
          )
        }
      }

      /* load my catalogue*/
      if (type === "catalogue") {
        var myCatalogue = this.state.myCatalogue
        console.log(myCatalogue)
        if (typeof myCatalogue !== "undefined" && myCatalogue.length > 0) {
          return myCatalogue.map((cate, indexx) => (
            <div className="col-md-6" key={indexx}>
              <div className="promo-box">
                <div className="promo-img-item">
                  <img src={this.loadPromoImage(cate.promotion_image)} />
                </div>
                <div className="promo-btm-section">
                  <span className="promo-text">Promo Code</span>
                  <span className="promo-amount">{cate.promo_code}</span>
                  {/*<span className="promo-expiry-date">{cate.promotion_end_date}</span>
						 <div className="promo-btn-block">
							<Link style={{cursor:'pointer'}}   className="promo-btn-view" >
								<span>View</span>
							</Link >
							<Link style={{cursor:'pointer'}}  className="promo-btn-redeem" >
								<span>Redeem</span>
							</Link >
						</div> */}
                </div>
              </div>
            </div>
          ))
        } else {
          return (
            <div className="productparent">
              <div className="raw">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <br />
                  <p className="text-center">No promotion found</p>
                </div>
              </div>
            </div>
          )
        }
      }
    }
  }

  loadPromoImage(promoImage, type = "") {
    //console.log(promoImage);
    if (promoImage !== "" && promoImage !== null && typeof promoImage !== "undefined") {
      if (type === "popup") {
        return config.path.tinThumpUrl + this.state.promoImagePath + promoImage + "&w=756&h=500&q=80"
      } else {
        return config.path.tinThumpUrl + this.state.promoImagePath + promoImage + "&w=156&h=137&q=80"
      }
    } else {
      return "img/no-images/products-no-image.jpg"
    }
  }

  render() {
    if (this.state.status !== "Loading") {
      return (
        <div className="container-fluid no-padding ninja-theme-v2 desktop-container" style={{ minHeight: this.state.windowHeight }}>
          <DocumentMeta {...meta} />

          <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
            <div className="col-xs-12 top-nav inner-top-nav head-main">
              <div className="col-xs-3 nav-head head-child">
                {" "}
                <Link to={"/"}>
                  <img src="/img/icons/arrow-right.png" className="left_arr icon-img-small-3" alt="left-arrow" />
                </Link>
              </div>

              <div className="col-xs-6 nav-head head-child">
                <h1 className="main-title">My Promotions</h1>
              </div>

              <div className="col-xs-3"></div>
            </div>
          </div>

          <div className="reserve-wrap promo-cat">
            <div className="promo-apply-block">
              <p>Redeem Your</p>
              <h2>Promotions</h2>
              <div className="promo-appy-form">
                <input type="text" className="txt-field promocode" id="promocode" placeholder="Add Your Promo/Invite Code Here" />
                <button onClick={this.applyPromo.bind(this, "")} className="btn btn-promo-appy">
                  Apply Now
                </button>
              </div>
            </div>

            <div className="tabbable-panel">
              <div className="tabbable-line">
                <ul className="nav nav-tabs">
                  <li className="active">
                    <Link to={"#tab_default_1"} data-toggle="tab">
                      PROMOTIONS{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to={"#tab_default_2"} data-toggle="tab">
                      USED PROMOTIONS{" "}
                    </Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade in active" id="tab_default_1">
                    <div className="row">{this.loadMyPromotions(this, "promotion")}</div>
                  </div>
                  <div className="tab-pane fade" id="tab_default_2">
                    <div className="row">{this.loadMyPromotions(this, "catalogue")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal ninja-theme-v2 redeem-promocode-modal" style={{ display: "none" }} id="RedeemPromocodeModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header no-padding">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/black-close.png" className="img-responsive" alt="close" />
                  </button>

                  <div className="col-xs-12 no-padding">
                    <div className="promo-box1">
                      <div className="promo-img-item">
                        <img src={this.loadPromoImage(this.state.currentPromo.promotion_image, "popup")} />
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 no-padding">
                    <div className="promo-box1">
                      <div className="promo-btm-section">
                        <span className="promo-text">Promo Code</span>
                        <span className="promo-amount">{this.state.currentPromo.promo_code}</span>
                        <span className="promo-expiry-date">Valid till {moment(this.state.currentPromo.promotion_end_date).format("Do of MMM YYYY")}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-body">
                  <div className="promo-code-popup-block">
                    {/*<div className="prmcode-pup-img">							
								<img src="/img/promo-popup-dishes.jpg" />
							</div>
							<div className="promo-pup-content">
							<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
							</div> */}{" "}
                    <div className="promo-pup-content">{this.state.currentPromo.promo_desc !== "" ? htmlToReactParserTo.parse(this.state.currentPromo.promo_desc_showtext) : ""}</div>
                    <div className="col-xs-12 promo-redeem-btn-block">
                      <button onClick={this.applyPromo.bind(this, this.state.currentPromo.promo_code)} type="button" className="btn btn-raised">
                        Redeem
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
          {/*   <div className="row"> <img src="img/my-promo.jpg" alt="{lang.common.loading_dots}" /> </div>   <br />
                <div className="mgn-wrap-refer refer_slide_section">
                     
                 <div id="myCarousel" className="carousel slide" data-ride="carousel">
                   
                                <div className="carousel-inner">
								
								
                                { /*this.mypromotions()}
                                 </div>
                         
                    </div>
				   </div> */}
        </div>
      )
    } else {
      return (
        <div className="container-fluid desktop-container">
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    }
  }
}
export default Promotion
