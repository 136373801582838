import React from 'react'; 
import { Link, hashHistory} from 'react-router' 
import axios from 'axios';
import cookie from 'react-cookie';
import { loadAnalytics } from "./withTracker";
import config from './config';
import qs from 'qs';
const APPID = config.ids.appId;
const APIURL = config.path.apiUrl;
const APIURLPRO = config.path.apiUrlPro;

import DocumentMeta from 'react-document-meta';

const lang = require('./lang');
var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParserTo = new HtmlToReactParser();
const Loading = require('./loading');
var helper = require("./Helper.js");
var dateFormat = require('dateformat');
var now = new Date();
var currentDate = dateFormat(now, "d/m/yyyy");
var currentTime = dateFormat(now, "h:MM TT");

 
class Placeorder extends React.Component {


	 constructor(props) {
	   super(props);
	   this.state = {
	      processingText: 'We are processing your order',
	      defaultClass: '',
	      orderLoading:'/img/loading.gif',
	      paymentLoading:'/img/loading.gif'
	    };
	    var urlParams = new URLSearchParams(this.props.location.search);
	    var redirectUrl	= window.location.protocol+'//'+window.location.hostname+'/#/my-account';
	if (
      urlParams.get("status") == "success" &&
      urlParams.get("sessionid") == cookie.load("paymentSession")
    ) {
      var postObject =
        window.sessionStorage.getItem("postObject") +
        "&payment_session=" +
        cookie.load("paymentSession");
			var OrderAPIUrl = APIURLPRO+"ordersv2/submit_order"; 

      axios
        .post(OrderAPIUrl, postObject)
        .then((res) => {
          if (res.data.status === "ok") {
            var localOrderNo = res.data.common.local_order_no;
            var subscription = res.data.common.subscription;
            var orderID = res.data.common.order_id;

            var statusObject = {};
            statusObject = {
              app_id: APPID,
              order_primary_id: res.data.common.order_primary_id,
              payment_reference: config.stripe.stripeReference,
              customer_id: cookie.load("UserId"),
              customer_email: cookie.load("UserEmail"),
              payment_session: urlParams.get("sessionid"),
            };

            axios
              .post(
                APIURL + "orders/statusUpdateSession",
                qs.stringify(statusObject)
              )
              .then((captureRes) => {
                if (captureRes.data.status === "ok") {
                  this.showSuccessPage(localOrderNo, res.data.common.order_primary_id);
                  return;
                } else {
                  this.deleteOrderCookie();
                  this.setState({
                    processingText: 'Please check in myorders, to get order details.',
                  });
                  setTimeout(function () {
                    window.location.href = redirectUrl;
                  }, 5000);
                }
              })
              .catch(
                function (error) {
                  this.deleteOrderCookie();
                  this.setState({
                    processingText: 'Please check in myorders, to get order details.',
                  });
                  setTimeout(function () {
                    window.location.href = redirectUrl;
                  }, 5000);
                }.bind(this)
              );
          } else {
            this.deleteOrderCookie();
            this.setState({ processingText: res.data.message });            
            setTimeout(function () {
                    window.location.href = redirectUrl;
            }, 5000);
          }
        })
        .catch(
          function (error) {
            console.log(error, "errorerror");
            this.deleteOrderCookie();
            this.setState({ processingText: 'Please check in myorders, to get order details.' });
            setTimeout(function () {
              window.location.href = redirectUrl;
            }, 5000);
          }.bind(this)
        );
    } else if (urlParams.get("status") == "failure") {
      setTimeout(
        function () {
          this.deleteOrderCookie();
          this.setState({ processingText: 'Please check in myorders, to get order details.' });
        }.bind(this),
        300
      );
      setTimeout(function () {
                    window.location.href = redirectUrl;
      }, 5000);
    }else{
        setTimeout(function(){
        this.deleteOrderCookie();
        this.setState({ processingText: 'Please check in myorders, to get order details.'});
        }.bind(this),300)
        setTimeout(function(){
                    window.location.href = redirectUrl;
        },5000);
    }
  	}
 
  sentchatbox(orderPrimaryId){
    var postObject = {
      app_id : APPID,
      order_primary_id: orderPrimaryId,
      customer_id: cookie.load("UserId"),
      };
      axios.post(APIURL + "chatbot/order_notify", qs.stringify(postObject)).then((res) => {

        if(res.data.status === "ok") {
					console.log("Chatbot Notified")
        }

      });
   }


 /* sucess page */
  showSuccessPage(localOrderNo, orderPrimaryId) {
		 this.setState({'orderLoading':'/img/tick.png'});
     this.setState({status:"ok"});
		 this.destroyCart();
		 this.deleteOrderCookie();
     this.sentchatbox(orderPrimaryId);
		 setTimeout(function() {
			hashHistory.push("/order-success/"+localOrderNo	);
			}.bind(this), 500);
	}

	destroyCart(){
    var postObject = {};
    postObject = { 'app_id' : config.ids.appId } ;
		postObject['customer_id'] = cookie.load('UserId');
		axios.post(APIURL+"cart/destroy", qs.stringify(postObject))
	}

	deleteOrderCookie()
{
	cookie.save('cartValue','No');
	cookie.remove('orderPaymentMode');
	cookie.remove('orderOutletId');
	cookie.remove('orderTableNo');
	cookie.remove('product_remarks');
	cookie.remove('orderOutletName');
	cookie.remove('orderOutletId');
	cookie.remove('orderZoneID');
	cookie.remove('promotion_code');
	/* Delivery avilablity */
	cookie.remove('DeliveryDate');
	cookie.remove('DeliveryTime');
	cookie.remove('isSletedTimeSlot');
	cookie.remove('DeliveryTimeSlot'); //
	cookie.remove('DeliveryTimeSlotFormated');
	cookie.remove('unitNoOne');
	cookie.remove('unitNoTwo'); //
	cookie.remove('towerNo');

	cookie.remove('outlet_tax_option');
	cookie.remove('outlet_tax_surcharge');
	cookie.remove('outlet_tax_surcharge_inclusive');
	cookie.remove('tempPromoCode');
	cookie.remove('tempPoints');
    window.sessionStorage.removeItem("postObject");
    window.sessionStorage.removeItem("paymentSession");	
}
     

  render() {

    return (
    	<div>
			<div  id="loadIcon"  style={{ bottom: 0, left: 0, position: 'fixed', right: 0, top: 0, margin: 'auto', zIndex: 9999, background: 'rgba(255, 255, 255, 0.8)'}} > <img  style={{ bottom : 0, left: 0, position: 'absolute', right : 0,  top : 0, margin :'auto' }} src="/img/loader.gif" alt="Loading"  /> <p className="validateOrder">{this.state.processingText} </p> </div>

		    <div className="container-fluid desktop-container"> 
	            <div className="row">
	                <div className="col-xs-12 main-title-bar">
	                    <Link to={"/"}> <img src="/img/left-arrow.png" className="pull-left icon-img-small" alt="left-arrow" /> </Link>
	                    <h3 className="top-title main-title padding-right-20 text-uppercase">Placeorder</h3>
	                </div>
	            </div>
	            <div className="row"><div className="space-10 bg-gray"></div></div>
	            <div className="white-outer center-block">
	                
	                <div className="clearfix"></div>
	            </div>
	             
	            <div className="space-30"></div>
				
	      	</div>
						
   		</div>
					
	 ); 
	 
	 
	 }
 }
 export default Placeorder;
