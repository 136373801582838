import React from "react"
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParser = new HtmlToReactParser()
import lang from "./lang"

class Outletclosedmessage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { messages } = this.props
    return (
      <div className="no_outlet_msg">
        {" "}
        <h4> {messages === "" ? lang.outlet_closed_message : htmlToReactParser.parse(messages)}</h4>{" "}
      </div>
    )
  }
}
export default Outletclosedmessage
