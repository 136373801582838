/* eslint-disable */
import React from 'react';
import {  Link, hashHistory  } from 'react-router';
import axios from 'axios';
import { loadAnalytics } from "./withTracker";
import getResult from './Api';
import DocumentMeta from 'react-document-meta';
import cookie from 'react-cookie';
import QrReader from 'react-qr-reader';
import './assets/css/qr-custom.css';
import config from './config';

const lang = require('./lang');
const APIURL = config.path.apiUrl;
const APPID = config.ids.appId;
var qs = require('qs');
 var SiteURL = cookie.load('siteURL');
 var ResultURL = SiteURL//SiteURL.replace(/(^\w+:|^)\/\//, '');
  var targetURL = SiteURL//ResultURL.replace(/\/$/, '');
 var helper = require("./Helper.js");

 
/* Load meta tags */
const meta = {
  title: lang.scan_qr_code_page,
  description: lang.scan_qr_code_page,
  meta: {
	name: {
	  keywords: lang.scan_qr_code_page
	}
  }
};


class Qrcodescan extends React.Component {

constructor(props) {
	 super(props);
	 
	 if(APPID === "AA6A05F0-BEEE-49AF-9CA5-747EF4E901B9"   ) {
		 	 if (location.protocol != 'https:')
			{
				   location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
			}
	 }
	 

		
		/* check Authentication */
		/*if(getResult('Auth') === false ){
		    hashHistory.push("/sign-in");
		}*/
		/* validate cart details */
		var postalCodeWhere = "";
		if (typeof cookie.load("orderPostalCode") !== "undefined") {
		postalCodeWhere='&postal_code='+cookie.load("orderPostalCode");
		}
		 axios.get(APIURL+'cartver2/contents?app_id='+APPID+"&customer_id="+cookie.load('UserId')+postalCodeWhere)
		 .then(res => {
		  if(res.data.status === "ok"){
			  console.log('success');
		  } else{
		  helper.showInfo('something went wrong');
			 hashHistory.push("/");
		  }
		  });

		/* Redirect to main page */
		  if(cookie.load('defaultAvilablityId') !== config.ids.dineinId){
			   hashHistory.push("/");
		  }

		  /* Dine in Disabled apps */
		  if(APPID === "6C9AEE37-BC05-4FB8-A351-D51458B83F26")
		  {
		     hashHistory.push("/");
		  }
		  
		  var tablenumtxt = (typeof cookie.load('orderTableNo') !== "undefined" && cookie.load('orderTableNo') !== '' && cookie.load('applyOutletId') === 'yes') ? cookie.load('orderTableNo') : '';
		  
		  var backlinktxt = '/cart'; 
		  if(cookie.load('dineinClick') === 'yes') {
			  backlinktxt = '/';
			  cookie.save('dineinFrom', 'booktbl');
		  }
		  
		  this.state = {
      delay: 500000,
      result: 'No result',windowHeight:0, qrResult: '', tableNumTxt: tablenumtxt, backLinkTxt: backlinktxt,
	  qrresultClass : ''
    }
 
     
  //  this.handleScan = this.handleScan.bind(this)
		 this.handleScan = this.handleScan.bind(this)
    this.openImageDialog = this.openImageDialog.bind(this)
		   //	 this.handleChange = this.handleChange.bind(this);  
		  

}

handleScan(result){
    if(result){
		//alert('succ');
		//$("#qrcode_bg").hide();
		this.validatetableNo(result);
		//alert(result);
      this.setState({ qrResult: result })
	  //this.setState({qrresultClass : "is-focused"})
    }else{
		 helper.showInfo('Please try agian');
	}	
  }
  handleError(err){
	  helper.showInfo('Invalid table no,Please try agian');
	//  $("#qrcode_bg").hide();
	       // this.setState({ qrResult: "" })
	   
    //console.error(err)
  }
  openImageDialog() {
    this.refs.qrReader1.openImageDialog()
  }

	
	 handleChange(event) {
		//  alert(event.target.value);
   // this.setState({qrresultClass: event.target.value});
  } 
 
	
    componentWillMount() {
        var windowHeight =  window.innerHeight;
         this.setState({'windowHeight':(windowHeight - 45)+'px'}); 

	}
	

componentDidMount(){
 /* window.load();
  window.setimg();
  setTimeout(function() {
  this.checkQrFileDiv();
  }.bind(this), 500); */
  
   
	var tableno = this.props.params.tableNo;
	console.log("Test message"+tableno);
	if(tableno!=='' && tableno!== undefined){
		this.handleScan(tableno);
	}
	loadAnalytics();
	
}

handleChangeTblValue(event) {
    this.setState({tableNumTxt: event.target.value});
}
 /* this function used to validate table no*/
 checkTablNo(){   
 
		var tableNo =  document.getElementById("tableNo").value;

 
  if(tableNo !==""){
  document.getElementById("loadIcon").style.display = "block";
  document.getElementById("SubBtn").style.display = "none"

   var postObject = {};
   postObject = { 'app_id' : APPID, 'tm_table_number' : tableNo }

   axios.post(APIURL+"tablemanagement/find_table",qs.stringify(postObject) ).then(res => {
  document.getElementById("loadIcon").style.display = "none";
  document.getElementById("SubBtn").style.display = "block"
		  if(res.data.status === "ok")
			{
			   cookie.save('orderOutletId',res.data.result_set[0].outlet_id);
			   cookie.save('orderOutletName',res.data.result_set[0].outlet_name);
			   cookie.save('orderTableNo',tableNo);

			   cookie.save('outlet_tax_option',res.data.result_set[0].outlet_tax_option);
			   cookie.save('outlet_tax_surcharge',res.data.result_set[0].outlet_tax_surcharge);
			   cookie.save('outlet_tax_surcharge_inclusive',res.data.result_set[0].outlet_tax_surcharge_inclusive);

			   var tatVal = (res.data.result_set[0].outlet_dine_tat !==""? res.data.result_set[0].outlet_dine_tat : res.data.result_set[0].outlet_delivery_timing )

				 cookie.save('orderTAT',tatVal);
			  // window.location = "/checkout";
			  if(cookie.load('dineinClick') === 'yes'){
				  cookie.save('dineinFrom', '');
				  if(cookie.load('bckurlTxt') !== "undefined" && cookie.load('bckurlTxt') !== ""){
					 var bckurltxt = cookie.load('bckurlTxt');
					 cookie.save('bckurlTxt', '');
					 hashHistory.push(bckurltxt);
				  } else {
				     hashHistory.push("/");
				  }
			  } else {
				  hashHistory.push("/checkout");
			  }
			}
		  else if(res.data.status === "error"){
				document.getElementById("tableNo").value="";
				 helper.showInfo(res.data.result_set);
		   }
	  });

  }else {
   helper.showInfo('Please fill table No');
  }
 }
 
 
 /* qr code result validation */
 validatetableNo(tableNo){   
 
	//	var tableNo =  document.getElementById("tableNo").value;

 
  if(tableNo !==""){
  document.getElementById("loadIcon").style.display = "block";
  document.getElementById("SubBtn").style.display = "none"

   var postObject = {};
   postObject = { 'app_id' : APPID, 'tm_table_number' : tableNo }

   axios.post(APIURL+"tablemanagement/find_table",qs.stringify(postObject) ).then(res => {
  document.getElementById("loadIcon").style.display = "none";
  document.getElementById("SubBtn").style.display = "block"
		  if(res.data.status === "ok")
			{
			   cookie.save('orderOutletId',res.data.result_set[0].outlet_id);
			   cookie.save('orderOutletName',res.data.result_set[0].outlet_name);
			   cookie.save('orderTableNo',tableNo);
			   var tatVal = (res.data.result_set[0].outlet_dine_tat !==""? res.data.result_set[0].outlet_dine_tat : res.data.result_set[0].outlet_delivery_timing )

				 cookie.save('orderTAT',tatVal);
			  // window.location = "/checkout";
			  // if(tableNo !==""){
			   $("#qr-code-alert").modal("hide");
			  // }
			    if(cookie.load('dineinClick') === 'yes'){
					  cookie.save('dineinFrom', '');
					  hashHistory.push("/");
				} else {
					  hashHistory.push("/checkout");
			    }
			}
		  else if(res.data.status === "error"){
				document.getElementById("tableNo").value="";
				 helper.showInfo(res.data.result_set);
		   }
	  });

  }else {
   helper.showInfo('Please fill table No');
  }
 }
 
 /**/
 checkQrFileDiv(){
	 if(document.getElementById("outdiv").innerHTML===''){
		 location.reload();
	 }
 }


render() {
	
	
	    const previewStyle = {
      height: 240,
      width: 320,
            border :0,
	 display:'none'
			 
    }

	 return ( <div>

	 <DocumentMeta  {...meta} />
	 <div className="container-fluid desktop-container" style={{minHeight:this.state.windowHeight}}>
			<div className="row">
				<div className="col-xs-12 main-title-bar">
				 <Link to={this.state.backLinkTxt}><img src="/img/left-arrow.png" className="pull-left icon-img-small" alt="left-arrow"/></Link>
					<h3 className="top-title main-title padding-right-20">DINE IN</h3>
				</div>
			</div>
			
			<div className="row"><div className="space-10 bg-gray"></div></div>
			<div className="white-outer center-block" style={{borderBottom:'0px'}}>
				<div className="col-sm-6 col-xs-12 div-center" style={{marginBottom:'0px'}}>
					<div className="space-30"></div>
					<p className="text-center font-bold font-size-18">Scan The QR Code</p>
					<div className="space-15"></div>
					<div id="mainbody"  >
						 <div className="my_qr_design">
							<div className="outdiv_c"  id="outdiv"></div>
							{/*  <p className="text-center" style={{margin:0}}><input className="qr-button" type="button" value="Submit QR Code" onClick={this.openImageDialog} /></p> <a className="btn btn-info btn-lg btn-qr-submit" data-toggle="modal" data-target="#qr-code-alert">Submit QR Code</a> */}
							<a className="btn btn-info btn-lg btn-qr-submit"  href="https://dinein.ninjapro.co">Click Here To Scan</a>
						</div>
					</div>
				</div>
			</div><div className="clearfix"></div>
			
			 
			{/*	 <div className="row"><div className="space-10 bg-gray"></div></div>
			 
			 <div className="row"><div className="space-10 bg-gray"></div></div>
			<div className="white-outer center-block" style={{borderBottom:'0px'}}>
				<div className="col-sm-6 col-xs-12 div-center" style={{marginBottom:'0px'}}>
					<div className="space-30"></div>
					<p className="text-center font-bold font-size-18">Snap a picture
of the QR Code</p>
					<div className="space-15"></div>
					<div id="mainbody" onClick={this.checkQrFileDiv.bind(this)} >
						  
					</div>
				</div>
</div><div className="clearfix"></div> */}
			
		 <div className="clearfix"></div >
             
             
				
			<div className="white-outer center-block" style={{borderTop:'0px'}}>
			<div className="space-20"></div>
			<p className="text-center font-size-18 color-gray or_t_qr">OR</p>  
				
             
			 {/* <a className="btn btn-info btn-lg" data-toggle="modal" data-target="#qr-code-alert">QR Code</a> */}
           
				<div className="col-sm-4 col-xs-12 div-center" style={{marginTop:'0px'}}>
					<div className="space-10" id="message"></div>

					{/*<p className="text-center font-size-18 color-gray">Or</p> */}
					
					<p className="text-center font-bold text-uppercase font-size-18">enter table number</p>
					<div className="space-5"></div>
					<div className="form-group label-floating is-empty card-no table-no">
						<div className="input-group">
							<label className="control-label" htmlFor="tableNo">Table Number</label>
							<input type="text" id="tableNo" value={this.state.tableNumTxt} onChange={this.handleChangeTblValue} className="form-control"/>
						</div>
					</div>
				</div>
				<div className="clearfix">
					 <div className="space-30"></div> <div className="space-30"></div>  
				</div>

				
				{/* pop up open */}
				<div className="modal custom_qr_t" id="qr-code-alert">
				<div className="modal-dialog locWidth">
					<div className="modal-content">
						<div className="modal-header">
							 <button type="button" className="close" data-dismiss="modal">&times;</button>
							 <div className="space-20"></div>
                            <p>   Please Take A Photo Of The QR On Your Table To Proceed</p>
                          <div className="qr_sec_t">
                                <img className="img-responsive" src="/img/qr-screen.png" alt="qrCode"/>
                                 <div className="ninj_pro">
                                            <span>Visit :</span> &nbsp; <a href={targetURL} title={targetURL}  target="_blank" >{targetURL}</a>
                                 </div>
                                <img className="code_q" src="/img/qr_code_t.png" alt="qrCode" id="qrcode_bg"/>
								   <QrReader
          ref="qrReader1"
          delay={this.state.delay}
          style={previewStyle}
          onError={this.handleError}
          onScan={this.handleScan}
          legacyMode
        />
		{/*   <span className="qr_num_t"> {this.state.qrResult}</span> */}
                           </div>
						   <div>
      
		
        <p className="text-center" style={{margin:0}}><a className="btn-qr-snap" onClick={this.openImageDialog}   ><img  src="/img/camio.png" alt="camera"/><span>Take a Picture</span></a> </p>
		
        <div className="clear"></div> 
      </div>
	  
	  {/*  <a className="btn-qr-snap" href="#" onClick={this.openImageDialog}   ><img  src="/img/camio.png" alt="camera"/><span>Take a Picture</span></a> */}
             </div>
             
             
						  
						 
					</div>
				</div>
			</div>
			{/* popup end */}
				
				
				<div className="row">   <img  style={{display : 'none',bottom : 0, left: 0, position: 'fixed', right : 0,  top : 0, margin :'auto' }} src="/img/loader.gif" alt="Loading" id="loadIcon"  />
				   <Link onClick={this.checkTablNo.bind(this)} id="SubBtn"> <button className="col-lg-12 continue-but desktop-container container-fluid" >Submit</button>  </Link>

				</div>     </div>
			</div>
	 </div> );

   }


}
export default Qrcodescan;
