/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import axios from "axios"
// import Moment from "react-moment"
import Loading from "./loading"
import { isEmpty } from "lodash"
import Distancebar from "./Distancebar"
import DocumentMeta from "react-document-meta"
import { API_v4_URL } from "./constants"
import Outletclosedmessage from "./Outletclosedmessage"
import getResult from "./Api"
import Overlay from "./Overlay"

import config from "./config"
const APPID = config.ids.appId
const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
var strp = require("strp")
import lang from "./lang"
import cookie from "react-cookie"
var qs = require("qs")
var helper = require("./Helper.js")
const apps = require("./apps")

var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParserTo = new HtmlToReactParser()

/* Load meta tags */
const meta = {
  title: "My Vouchers" + lang.site_title_with_separator,
  description: "My e-Vouchers",
  meta: {
    name: {
      keywords: "My e-Vouchers",
    },
  },
}

class Vouchers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageTitle: "My e-Vouchers",
      pageDesc: "My e-Vouchers",
      status: "ok",
      windowHeight: 0,
      voucherStatus: "Waiting",
      myVouchers: [],
      usedVouchers: [],
      productimagePath: "",
      openModal: false,
      manualLoading: false,
      currentVoucher: [],
      currnetDate: "",
      Outlets: [],
      redirectToCart: false,
      Purchased_voucher: false,
      zoneEnabled: 0,
      zonePopupDisable: 0,
      secAddrData: [],
      deliveryOutlets: [],
      addCartValues: [],
    }

    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    helper.isAnonymousUserRedirect()
  }

  componentDidUpdate() {
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
      return false
    }

    if (getResult("UserDetails") === false) {
      helper.showInfo(lang.users.user_info_missing, "", "redirect", "/my-account")
      return false
    }
  }

  componentWillMount() {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, "0")
    var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    var yyyy = today.getFullYear()
    today = yyyy + "-" + mm + "-" + dd
    this.setState({
      currnetDate: today,
    })

    axios.all([axios.get(APIURL + "promotion_api_v3/vouchers?app_id=" + APPID + "&customer_id=" + cookie.load("UserId"))]).then(
      axios.spread((vouchers) => {
        this.setState({ voucherStatus: "Ready" })
        /* set promotion content */
        if (vouchers.data.status === "ok") {
          this.setState({ myVouchers: vouchers.data.result_set.product_list, usedVouchers: vouchers.data.result_set.used_vouchers, productimagePath: vouchers.data.common.image_source })
        }
      })
    )

    axios.get(APIURLPRO + "settings/get_common_settings?app_id=" + APPID + "&postal_code=" + cookie.load("orderPostalCode") + "&postalFilter=No").then((res) => {
      this.setState({ zoneEnabled: parseInt(res.data.result_set.client_outlet_zone) }, function () {
        if ((this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery && typeof cookie.load("orderOutletId") === "undefined") || typeof cookie.load("orderPostalCode") === "undefined") {
          axios.get(API_v4_URL + "Getoutlets/getAllOutles/pickup_outlets?app_id=" + APPID + "&availability_id=" + config.ids.deliveryId).then((res) => {
            if (res.data.status === "ok") {
              this.setState({ deliveryOutlets: res.data.result_set })
            } else {
              /* this.setState({Status: res.data.status}); */
            }
          })
        }
      })
      this.setState({ zonePopupDisable: parseInt(res.data.result_set.client_zone_outlet_disable) })
    })

    if ((cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay && typeof cookie.load("orderOutletId") === "undefined") || typeof cookie.load("orderPostalCode") === "undefined") {
      axios.get(API_v4_URL + "Getoutlets/pickup_outlets?app_id=" + APPID).then((res) => {
        if (res.data.status === "ok") {
          this.setState({ Status: res.data.status })
          this.setState({ Outlets: res.data.result_set })
          if (apps.workspace === APPID) {
            this.getaddWorkSpaceCookieValues(res.data.result_set)
          }
        } else {
          this.setState({ Status: res.data.status })
        }
      })
    }

    var windowHeight = window.innerHeight

    this.setState({ windowHeight: windowHeight + "px" })
  }

  componentDidMount() {
    loadAnalytics()

    var promise = getResult("settingsPro", "No")
    promise.then((res) => {
      if (!helper.disableMenu(res.data.result_set, "myaccount_vouchers")) {
        hashHistory.push("/")
        return false
      }
    })
  }

  // /* add outlet ids work spac */
  getaddWorkSpaceCookieValues(data) {
    var loaddata = data[0]
    var outlet_name = loaddata.outlet_name
    var outlet_id = loaddata.outlet_id,
      loaddata
    var outlet_address_line1 = loaddata.outlet_address_line1
    var outlet_address_line2 = loaddata.outlet_address_line2
    var outlet_postal_code = loaddata.outlet_postal_code
    var outlet_pickup_tat = loaddata.outlet_postal_code

    var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code
    this.setState({ orderHandled: orderHandled })
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)
    cookie.save("orderHandledByText", orderHandled)
  }

  showPromo(promo, type) {
    if (type === "usedpromotions") {
      this.setState({
        Purchased_voucher: true,
      })
    } else {
      this.setState({
        Purchased_voucher: false,
      })
    }

    this.setState({ currentVoucher: promo, openModal: true })
    $("#RedeemPromocodeModal").modal("toggle")
  }

  showZonalPostalCode(outletId) {
    $("#delivery_outlet").modal("toggle")
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      $("#AddressList").modal("toggle")
    } else {
      $("#DeliveryAddress").modal("toggle")
    }

    this.setState({ zoneDeliveryOutlet: outletId })
  }

  getSecAddr() {
    axios.get(APIURL + "/customer/get_user_secondary_address?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ secAddrData: res.data.result_set })
      }
    })
  }

  loadAddressList() {
    if (this.state.secAddrData !== "") {
      return this.state.secAddrData.map((item, index) => (
        <p key={index}>
          <input name="radio" value={item.postal_code} id={item.secondary_address_id} className="css-radio" type="radio" onChange={this.handleChange} />
          <label htmlFor={item.secondary_address_id} className="css-label ">
            {" "}
            {item.address + " Singapore " + item.postal_code}
          </label>
        </p>
      ))
    }
  }

  addToCart(voucher) {
    if (voucher !== "") {
      if (voucher.product_voucher === "c") {
        this.setState(
          {
            manualLoading: true,
          },
          function () {
            this.loadManualLoading()
          }
        )

        var modifier = []
        var combo = []
        var postObject = {}
        var price = parseFloat("0.00")
        postObject = {
          app_id: config.ids.appId,
          product_qty: voucher.item_qty,
          product_voucher_points: voucher.product_voucher_points,
          customer_id: cookie.load("UserId"),
          order_item_id: voucher.order_item_id,
        }

        axios.post(APIURLPRO + "ordersv2/add_voucher_redeem", qs.stringify(postObject)).then((res) => {
          this.setState({ status: "ok" })
          if (res.data.status === "ok") {
            hashHistory.push("/rewards")
            this.setState({ manualLoading: false })
            //hashHistory.push(reDirectUrl);
          } else if (res.data.status === "error") {
            helper.showInfo(res.data.message)
          }
        })
      } else {
        var modifier = []
        var combo = []
        var postObject = {}
        var price = parseFloat("0.00")
        postObject = {
          app_id: config.ids.appId,
          product_id: voucher.item_product_id,
          product_name: strp.stripslashes(voucher.item_name),
          product_sku: voucher.item_sku,
          product_image: this.state.productimagePath + voucher.product_thumbnail,
          availability_id: cookie.load("defaultAvilablityId"),
          product_unit_price: price,
          product_qty: voucher.item_qty,
          product_total_price: price,
          voucher_gift_name: "",
          voucher_gift_email: "",
          voucher_gift_message: "",
          product_voucher_points: "",
          modifiers: modifier,
          menu_set_component: combo,
          customer_id: cookie.load("UserId"),
          order_availability_id: voucher.order_availability_id,
          order_item_id: voucher.order_item_id,
          order_outlet_id: voucher.order_outlet_id,
        }

        if (this.state.openModal === true) {
          $(".close").trigger("click")
        }

        if (cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery) {
          if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
            if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
              if (this.state.zoneEnabled === 1) {
                if (parseInt(this.state.zonePopupDisable) === 1) {
                  $("#AddressList").modal("toggle")
                  this.setState({
                    addCartValues: postObject,
                  })
                  return false
                } else {
                  this.setState({
                    addCartValues: postObject,
                  })
                  $("#delivery_outlet").modal("toggle")
                  return false
                }
              } else {
                this.setState({
                  addCartValues: postObject,
                })

                $("#AddressList").modal("toggle")
                return false
              }
            }
          }
        } else if (cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay) {
          if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
            var pickupOutlets = this.state.Outlets

            if (pickupOutlets.length === 1) {
              var orderHandled = pickupOutlets[0].outlet_name + " " + pickupOutlets[0].outlet_address_line1 + " " + pickupOutlets[0].outlet_address_line2 + ", Singapore " + pickupOutlets[0].outlet_postal_code

              this.setState({ orderHandled: orderHandled })
              cookie.save("orderOutletId", pickupOutlets[0].outlet_id)
              cookie.save("orderOutletName", pickupOutlets[0].outlet_name)
              cookie.save("orderPostalCode", pickupOutlets[0].outlet_postal_code)
              cookie.save("orderTAT", pickupOutlets[0].outlet_pickup_tat)
              cookie.save("orderHandled", orderHandled)
              cookie.save("orderHandledByText", orderHandled)

              cookie.save("outlet_tax_option", pickupOutlets[0].outlet_tax_option)
              cookie.save("outlet_tax_surcharge", pickupOutlets[0].outlet_tax_surcharge)
              cookie.save("outlet_tax_surcharge_inclusive", pickupOutlets[0].outlet_tax_surcharge_inclusive)

              cookie.save("outlet_option_single", 1)

              $("#pickup-outlet-sucess").modal("toggle")
            } else {
              $("#Pickup-OutletSelection").modal("toggle")
            }

            return false
          }
        }

        this.setState(
          {
            manualLoading: true,
          },
          function () {
            this.loadManualLoading()
          }
        )

        axios.post(APIURLPRO + "cartv2/insert", qs.stringify(postObject)).then((res) => {
          this.setState({ status: "ok" })
          if (res.data.status === "ok") {
            this.setState({ redirectToCart: true, openModal: false }, function () {
              this.loadOutletDetails()
            })
            var cartCount = res.data.contents.cart_details.cart_total_items
            cookie.save("cartValue", "Yes")
            cookie.save("cartCount", cartCount)
            cookie.save("voucher_types_enable", "1")
            /* find previous page */
            var slugType = cookie.load("categoryType")
            var slugValue = cookie.load("categoryId")

            //hashHistory.push(reDirectUrl);
          } else if (res.data.status === "error") {
            helper.showInfo(res.data.message)
            this.setState({
              manualLoading: false,
            })
          }
        })
      }
    }
  }

  loadManualLoading() {
    if (this.state.manualLoading === true) {
      return (
        <div style={{ bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto" }}>
          {" "}
          <img src="/img/loader.gif" alt="Loading" style={{ bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto" }} />{" "}
        </div>
      )
    }
  }

  loadOutletDetails() {
    axios.get(API_v4_URL + "Getoutlets/pickup_outlets?app_id=" + APPID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ Outlets: res.data.result_set }, function () {
          this.addWorkSpaceCookieValues(res.data.result_set)
        })
      } else {
      }
    })
  }

  /* Select outlet */

  selectOutlet(thisobject, retry = false, callbackPostalCode, addressObj) {
    var postalcode = ""
    let address = ""
    let googleRetryAttempt = 0
    if (retry === false) {
      if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
        postalcode = $("#postalcode").val() //"postalcode";
      } else {
        postalcode = $("#postalcode1").val() // "postalcode1";
      }
    } else {
      postalcode = callbackPostalCode
      address = addressObj
      googleRetryAttempt = 1
    }

    if (postalcode.length < 5) {
      return false
    }

    this.setState({ load: "true" })

    var findPostUrl = APIURL + "outlets/find_outlet?skip_timing=Yes&app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode
    if (this.state.zoneEnabled === 1) {
      findPostUrl =
        API_v4_URL + "test/outlets/findOutletZone?skip_timing=Yes&app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode + "&outlet_id=" + this.state.zoneDeliveryOutlet + "&googleRetryAttempt=" + googleRetryAttempt + "&addressObject=" + address
    }
    /*add loading */

    axios.get(findPostUrl).then((res) => {
      /* Success response */
      /* Success response  && Object.keys(res.data.result_set.postal_code_information).length !==0 */
      if (res.data.status === "ok") {
        this.setState({ load: "true" })
        /*if(Object.keys(res.data.result_set.postal_code_information).length !==0)
					{ */
        var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname
        var orderHandled = res.data.result_set.outlet_name + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes"

        cookie.save("orderOutletId", res.data.result_set.outlet_id)
        cookie.save("orderOutletName", res.data.result_set.outlet_name)
        cookie.save("orderPostalCode", res.data.result_set.postal_code_information.zip_code)
        cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing)
        cookie.save("orderDeliveryAddress", orderDeliveryAddress)
        cookie.save("orderHandled", orderHandled)

        cookie.save("outlet_tax_option", res.data.result_set.outlet_tax_option)
        cookie.save("outlet_tax_surcharge", res.data.result_set.outlet_tax_surcharge)
        cookie.save("outlet_tax_surcharge_inclusive", res.data.result_set.outlet_tax_surcharge_inclusive)

        var orderHandledText = res.data.result_set.outlet_name + " " + res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", Singapore " + postalcode
        cookie.save("orderHandledByText", orderHandledText)

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("hide")
        } else {
          $("#DeliveryAddress").modal("hide")
        }
        $("#address-auto").modal("hide")
        $("#DeliverySucess").modal("toggle")

        if (this.state.zoneEnabled === 1) {
          cookie.save("orderZoneID", res.data.result_set.zone_id)
        }

        this.setState({ orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code })
        this.setState({ orderHandled: orderHandled }, function () {
          this.addToCartVoucher()
        })

        /* }else{
						 $("#DeliveryAddress").modal("toggle");
						 $("#DeliveryError").modal("toggle");
					 } */
      }
      /* Error response */
      if (res.data.status === "error") {
        this.setState({ load: "false" })
        /* check google api result */
        let google_api_result = false
        if (typeof res.data.not_fount_in_google !== "undefined" && res.data.not_fount_in_google === true) {
          this.setState({ enableAddressSearch: true })
          google_api_result = true
        }

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("hide")
        } else {
          $("#DeliveryAddress").modal("hide")
        }

        if (google_api_result === false) {
          if (res.data.shop_close === "Yes") {
            $("#ShopClosedError").modal("toggle")
          } else {
            $("#DeliveryError").modal("toggle")
          }
        } else {
          $("#address-auto").modal("show")
        }
      }
    })
  }

  addToCartVoucher() {
    if (this.state.addCartValues !== "" && this.state.addCartValues !== "undefined") {
      this.setState(
        {
          manualLoading: true,
        },
        function () {
          this.loadManualLoading()
        }
      )

      var voucher = this.state.addCartValues

      var modifier = []
      var combo = []
      var postObject = {}
      var price = parseFloat("0.00")

      postObject = {
        app_id: config.ids.appId,
        product_id: voucher.product_id,
        product_name: voucher.product_name,
        product_sku: voucher.product_sku,
        product_image: voucher.product_image,
        availability_id: cookie.load("defaultAvilablityId"),
        product_unit_price: price,
        product_qty: voucher.product_qty,
        product_total_price: price,
        voucher_gift_name: "",
        voucher_gift_email: "",
        voucher_gift_message: "",
        product_voucher_points: "",
        modifiers: modifier,
        menu_set_component: combo,
        customer_id: cookie.load("UserId"),
        order_availability_id: voucher.order_availability_id,
        order_item_id: voucher.order_item_id,
        order_outlet_id: voucher.order_outlet_id,
      }

      axios.post(APIURLPRO + "cartv2/insert", qs.stringify(postObject)).then((res) => {
        this.setState({ status: "ok" })
        if (res.data.status === "ok") {
          this.setState({ redirectToCart: true, openModal: false, addCartValues: "" }, function () {
            this.loadOutletDetails()
          })
          var cartCount = res.data.contents.cart_details.cart_total_items
          cookie.save("cartValue", "Yes")
          cookie.save("cartCount", cartCount)
          cookie.save("voucher_types_enable", "1")
          //hashHistory.push(reDirectUrl);
        } else if (res.data.status === "error") {
          helper.showInfo(res.data.message)
          this.setState({
            manualLoading: false,
          })
        }
      })
    }
  }

  /* continue with pickup success */
  pickupSuccess() {
    var productId = this.state.productId
    var proName = this.state.proName
    var prosku = this.state.prosku
    var proPrice = this.state.proPrice
    var proQty = this.state.proQty
    var proImage = this.state.proImage
    var allowCart = this.state.allowCart
    var applyMinmaxSelect = this.state.applyMinmaxSelect
    $("#Pickup-Sucess").modal("hide")
    $("#pickup-outlet-sucess").modal("hide")
    //this.addToCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart, applyMinmaxSelect);
  }

  __loadDeliveryOutlets() {
    if (this.state.deliveryOutlets) {
      let list = helper.getSortedList(this.state.deliveryOutlets, this.props.coords)

      return (
        !isEmpty(list) &&
        list.map((loaddata, index) => (
          <div className="map-li" key={index} onClick={this.showZonalPostalCode.bind(this, loaddata.outlet_id)}>
            <p className="map-h outlet-name">{helper.stripslashes(loaddata.outlet_name)}</p>
            <p className="map-sub">
              {this.showNewAddressFrmd(loaddata.outlet_postal_code, loaddata.outlet_address_line1, "", "", "")} {loaddata.outlet_unit_number2}
            </p>
            <Distancebar minDateValue={loaddata.min_date} isOutletBased={loaddata.is_outlet_based} distance={loaddata.distance} coords={this.props.coords} tat={loaddata.outlet_delivery_tat ? loaddata.outlet_delivery_tat : loaddata.outlet_delivery_timing} />
          </div>
        ))
      )
    }
  }

  showNewAddressFrmd(pstcode, address1, address2, unittxt1, unittxt2) {
    var addressLn = address2 != "" ? address1 + ", " + address2 : address1
    if (unittxt1 != "" && unittxt2 != "") {
      addressLn = addressLn + ", #" + unittxt1 + "-" + unittxt2
    } else if (unittxt1 != "" || unittxt2 != "") {
      addressLn = unittxt1 != "" ? addressLn + ", #" + unittxt1 : addressLn + ", #" + unittxt2
    }

    addressLn = addressLn + ", Singapore " + pstcode
    return addressLn
  }

  /* Validate zipcode */
  validatezip(e) {
    const re = /[0-9A-F:]+/g
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  }

  addWorkSpaceCookieValues(data) {
    var loaddata = data[0]
    var outlet_name = loaddata.outlet_name
    var outlet_id = loaddata.outlet_id,
      loaddata
    var outlet_address_line1 = loaddata.outlet_address_line1
    var outlet_address_line2 = loaddata.outlet_address_line2
    var outlet_postal_code = loaddata.outlet_postal_code
    var outlet_pickup_tat = loaddata.outlet_postal_code

    var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code
    this.setState({ orderHandled: orderHandled })
    //cookie.save('orderOutletId',outlet_id);
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)
    cookie.save("orderHandledByText", orderHandled)

    var customerParam = "&customer_id=" + cookie.load("UserId")
    var postalCodeWhere = ""
    if (typeof cookie.load("orderPostalCode") !== "undefined") {
      postalCodeWhere = "&postal_code=" + cookie.load("orderPostalCode")
    }

    axios.get(APIURL + "cartver2/contents?status=A&app_id=" + APPID + customerParam + postalCodeWhere).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ status: "ok", manualLoading: false })
        if (typeof res.data.result_set !== "undefined") {
          var cartDetails = res.data.result_set.cart_details
          cookie.save("cart_grand_total", cartDetails.cart_sub_total)
          cookie.save("cartValue", "Yes")

          var reDirectUrl = "/cart"
          hashHistory.push(reDirectUrl)
        } else {
          cookie.save("cartValue", "No")
        }
      } else {
        cookie.save("cartValue", "No")
      }
    })
  }

  /*apply coupon  function*/
  applyPromo(promocode) {
    $("#RedeemPromocodeModal").modal("hide")
    var postObject = {}
    var promocode = promocode !== "" ? promocode : $("#promocode").val()

    /* postObject = {
			/* cart details  /
			'app_id': APPID,
			'reference_id': cookie.load('UserId'),
			'promo_code': promocode,
      		'cart_amount': cookie.load('cartsubtotal'),
      		'availability_id':cookie.load('defaultAvilablityId'),
			'cart_quantity': cookie.load('carttotalitems'),
		} */

    var cartSubToatl = parseFloat(this.state.cartDetails.cart_sub_total)
    var cartItemsCount = parseInt(this.state.cartDetails.cart_total_items)
    postObject = {
      /* cart details */
      app_id: APPID,
      reference_id: cookie.load("UserId"),
      promo_code: promocode,
      cart_amount: parseFloat(cartSubToatl),
      cart_quantity: cartItemsCount,
      category_id: this.getPromoCategory(),
      availability_id: cookie.load("defaultAvilablityId"),
    }

    axios.post(APIURL + "promotion_api_v2/apply_promotion", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "success") {
        //alert(res.data.message);
        helper.showInfo(res.data.message, "", "reload")
        cookie.save("promotion_id", res.data.result_set.promotion_id)
        cookie.save("promotion_applied", "Yes")
        cookie.save("promotion_code", res.data.result_set.promotion_code)
        cookie.save("promotion_delivery_charge_applied", res.data.result_set.promotion_delivery_charge_applied)
        cookie.save("promotion_amount", res.data.result_set.promotion_amount)
        cookie.save("promotion_category", res.data.result_set.promotion_category)
        cookie.save("prmo_type", res.data.result_set.prmo_type)

        /*Remove if voucher applied*/
        cookie.remove("voucher_applied")
        cookie.remove("voucher_code")
        cookie.remove("voucher_amount")

        hashHistory.push("/delivery-date")
      } else {
        if (typeof res.data.show_message !== "undefined" && res.data.show_message === "Yes") {
          helper.showInfo(res.data.message, "", "redirect", "/")
        } else if (typeof res.data.form_error !== "undefined") {
          helper.showInfo(res.data.form_error, "", "reload")
        } else {
          helper.showInfo(res.data.message, "", "reload")
        }
        if (res.data.clear_offer === "Yes") {
          cookie.remove("promotion_id")
          cookie.remove("promotion_applied")
          cookie.remove("promotion_code")
          cookie.remove("promotion_delivery_charge_applied")
          cookie.remove("promotion_amount")
          cookie.remove("promotion_category")
          cookie.remove("prmo_type")
        }
      }
    })
  }

  /*  get promo category details  */
  getPromoCategory() {
    var product_cartid = ""
    var cartItems = this.state.cartItems
    var cartCount = cartItems.length

    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        if (product_cartid !== "" && cartItems[p].cart_item_product_id !== "") {
          product_cartid += ";"
        }
        product_cartid += cartItems[p].cart_item_product_id + "|" + cartItems[p].cart_item_total_price + "|" + cartItems[p].cart_item_qty
      }
    }

    return product_cartid
  }

  /*claim coupon  function*/
  claimPromo(promocode) {
    var postObject = {}
    var promocode = promocode !== "" ? promocode : $("#promocode").val()

    postObject = {
      /* cart details */
      app_id: APPID,
      reference_id: cookie.load("UserId"),
      promo_code: promocode,
      cart_amount: cookie.load("cartsubtotal"),
      availability_id: cookie.load("defaultAvilablityId"),
      cart_quantity: cookie.load("carttotalitems"),
    }

    axios.post(APIURL + "promotion_api_v2/claimpromo", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "success") {
        helper.showInfo(res.data.message, "", "reload")
      } else {
        if (typeof res.data.show_message !== "undefined" && res.data.show_message === "Yes") {
          helper.showInfo(res.data.message, "", "")
        } else if (typeof res.data.form_error !== "undefined") {
          //alert(res.data.form_error);
          helper.showInfo(res.data.form_error, "", "reload")
        } else {
          // alert(res.data.message);
          helper.showInfo(res.data.message, "", "")
        }
      }
    })
  }

  /*load my promotion list here */
  loadMyPromotions(e, type) {
    if (this.state.voucherStatus === "Waiting") {
      return (
        <div>
          <Loading />
        </div>
      )
    } else {
      /* load promotions */
      if (type === "promotion") {
        var myVouchers = this.state.myVouchers
        if (myVouchers.length > 0) {
          return myVouchers.map((vouch, index) => (
            <div className="col-md-6" key={index}>
              <div className="promo-box">
                <div className="promo-img-item">
                  <img src={this.loadPromoImage(vouch.product_thumbnail)} />
                </div>
                <div className="promo-btm-section">
                  {this.state.currnetDate > vouch.expiry_date ? (
                    <div className="rws_list_title">
                      <p style={{ float: "right" }}>Expired</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <span className="promo-text">{vouch.item_name}</span>
                  <span className="promo-amount">{this.loadProductDate(vouch.expiry_date)}</span>
                  <div className="promo-btn-block">
                    <Link style={{ cursor: "pointer" }} onClick={this.showPromo.bind(this, vouch, type)} className="promo-btn-view">
                      <span>View</span>
                    </Link>
                    {this.state.currnetDate > vouch.expiry_date ? (
                      <Link style={{ cursor: "pointer" }} className="promo-btn-redeem">
                        <span>Expired</span>
                      </Link>
                    ) : (
                      <Link onClick={this.addToCart.bind(this, vouch)} style={{ cursor: "pointer" }} className="promo-btn-redeem">
                        <span>Redeem</span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        } else {
          return (
            <div className="productparent">
              <div className="raw">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <br />
                  <p className="text-center">No e-vouchers found</p>
                </div>
              </div>
            </div>
          )
        }
      }

      /* load my catalogue*/
      if (type === "catalogue") {
        var myCatalogue = this.state.myCatalogue

        if (typeof myCatalogue !== "undefined" && myCatalogue.length > 0) {
        } else {
          return (
            <div className="productparent">
              <div className="raw">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <br />
                  <p className="text-center">No e-vouchers found</p>
                </div>
              </div>
            </div>
          )
        }
      }
    }
  }

  loadProductDate(date) {
    var product_date = new Date(date).toString().split(" ")
    return product_date[2] + "-" + product_date[1] + "-" + product_date[3]
  }

  loadUsedPromotions(e, type) {
    if (this.state.voucherStatus === "Waiting") {
      return (
        <div>
          <Loading />
        </div>
      )
    } else {
      /* load promotions */
      if (type === "usedpromotions") {
        var myVouchers = this.state.usedVouchers
        if (myVouchers.length > 0) {
          return myVouchers.map((vouch, index) => (
            <div className="col-md-6" key={index}>
              <div className="promo-box">
                <div className="promo-img-item">
                  <img src={this.loadPromoImage(vouch.product_thumbnail)} />
                </div>
                <div className="promo-btm-section">
                  <span className="promo-text">{vouch.item_name}</span>
                  <span className="promo-amount">{this.loadProductDate(vouch.expiry_date)}</span>
                  <div className="promo-btn-block">
                    <Link style={{ cursor: "pointer", textAlign: "center" }} onClick={this.showPromo.bind(this, vouch, type)} className="promo-btn-view">
                      <span>View</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))
        } else {
          return (
            <div className="productparent">
              <div className="raw">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <br />
                  <p className="text-center">No vouchers found</p>
                </div>
              </div>
            </div>
          )
        }
      }

      /* load my catalogue*/
      if (type === "catalogue") {
        var myCatalogue = this.state.myCatalogue
        console.log(myCatalogue)
        if (typeof myCatalogue !== "undefined" && myCatalogue.length > 0) {
        } else {
          return (
            <div className="productparent">
              <div className="raw">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <br />
                  <p className="text-center">No vouchers found</p>
                </div>
              </div>
            </div>
          )
        }
      }
    }
  }

  __loadOutlets() {
    if (this.state.Outlets) {
      let list = helper.getSortedList(this.state.Outlets, this.props.coords)

      if (!isEmpty(list) && list.length > 0) {
        return list.map((loaddata, index) => (
          <div
            className="map-li"
            key={index}
            onClick={this.pickOutlet.bind(
              this,
              loaddata.outlet_id,
              loaddata.outlet_name,
              loaddata.outlet_postal_code,
              loaddata.outlet_address_line1,
              loaddata.outlet_address_line2,
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2,
              loaddata.outlet_pickup_tat,
              loaddata.outlet_tax_option,
              loaddata.outlet_tax_surcharge,
              loaddata.outlet_tax_surcharge_inclusive
            )}
          >
            <p className="map-h outlet-name">{helper.stripslashes(loaddata.outlet_name)}</p>
            <p className="map-sub">{loaddata.outlet_address_line1 + ", " + loaddata.outlet_address_line2 + " " + loaddata.outlet_postal_code}</p>
            <Distancebar minDateValue={loaddata.min_date} isOutletBased={loaddata.is_outlet_based} distance={loaddata.distance} coords={this.props.coords} tat={loaddata.outlet_pickup_tat ? loaddata.outlet_pickup_tat : loaddata.outlet_delivery_timing} />
          </div>
        ))
      } else {
        return <Outletclosedmessage messages={this.state.outletCloseddMessage} />
      }
    }
  }

  /* pick outlet */
  pickOutlet(outlet_id, outlet_name, outlet_postal_code, outlet_address_line1, outlet_address_line2, outlet_unit_number1, outlet_unit_number2, outlet_pickup_tat, outlet_tax_option, outlet_tax_surcharge, outlet_tax_surcharge_inclusive) {
    var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code
    this.setState({ orderHandled: orderHandled })
    cookie.save("orderOutletId", outlet_id)
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)
    cookie.save("orderHandledByText", orderHandled)

    cookie.save("outlet_tax_option", outlet_tax_option)
    cookie.save("outlet_tax_surcharge", outlet_tax_surcharge)
    cookie.save("outlet_tax_surcharge_inclusive", outlet_tax_surcharge_inclusive)

    $("#Pickup-OutletSelection").modal("toggle")
    $("#Pickup-Sucess").modal("toggle")
  }

  loadPromoImage(promoImage) {
    //console.log(promoImage);
    if (promoImage !== "" && promoImage !== null && typeof promoImage !== "undefined") {
      return this.state.productimagePath + promoImage
    } else {
      return "img/no-images/products-no-image.jpg"
    }
  }

  render() {
    if (this.state.status !== "Loading") {
      return (
        <div className="container-fluid no-padding ninja-theme-v2 desktop-container" style={{ minHeight: this.state.windowHeight }}>
          <DocumentMeta {...meta} />

          <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
            <div className="col-xs-12 top-nav inner-top-nav head-main">
              <div className="col-xs-3 nav-head head-child">
                {" "}
                <Link to={"/my-account"}>
                  <img src="/img/icons/arrow-right.png" className="left_arr icon-img-small-3" alt="left-arrow" />
                </Link>
              </div>

              <div className="col-xs-6 nav-head head-child">
                <h1 className="main-title vouchers_title">My e-Vouchers</h1>
              </div>

              <div className="col-xs-3"></div>
            </div>
          </div>

          <div className="reserve-wrap promo-cat">
            <div className="promo-apply-block">
              <p>Redeem Your</p>
              <h2>e-Vouchers</h2>
            </div>

            <div className="tabbable-panel">
              <div className="tabbable-line">
                <ul className="nav nav-tabs">
                  <li className="active">
                    <Link to={"#tab_default_1"} className="vouchers_title" data-toggle="tab">
                      e-Vouchers{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to={"#tab_default_2"} className="vouchers_title" data-toggle="tab">
                      Used e-Vouchers{" "}
                    </Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade in active" id="tab_default_1">
                    <div className="row">{this.loadMyPromotions(this, "promotion")}</div>
                  </div>
                  <div className="tab-pane fade" id="tab_default_2">
                    <div className="row">{this.loadUsedPromotions(this, "usedpromotions")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.loadManualLoading()}

          <div className="modal" id="AddressList">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt />
                    Your Delivery Address
                  </h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body" style={{ textAlign: "left" }}>
                  {this.loadAddressList()}
                  <p>
                    <input value={this.state.addressField} onChange={this.handleChangeValue} id="postalcode" maxLength="6" placeholder="Enter Your Postal Code" className="add_list" onKeyPress={(e) => this.validatezip(e)} />
                  </p>
                  <br />

                  <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          <div className="modal common-distance" id="delivery_outlet">
            <div className="modal-dialog locWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">PLEASE SELECT WHICH OUTLET DO YOU PREFER</h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body">
                  <div className="map-loc">
                    {/* <Map markers={this.state.markers} /> */}

                    <div className="map-box scrollbar" id="style-3">
                      {this.__loadDeliveryOutlets()}
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          <div className="modal" id="pickup-outlet-sucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="" />
                    Awesome, you can pickup !
                  </h4>
                  <p>{cookie.load("orderHandled")}</p>
                </div>
                <div className="modal-body text-center">
                  {this.showDeliveryTiming}
                  <br />
                  <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          <div className="modal" id="Pickup-Sucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="" />
                    Awesome, you can pickup !
                  </h4>
                </div>
                <div className="modal-body text-center">
                  {this.showDeliveryTiming}
                  <br />
                  <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          <div className="modal common-distance" id="Pickup-OutletSelection">
            <div className="modal-dialog locWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">Select Your Pickup Location</h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body">
                  <div className="map-loc">
                    {/* <Map markers={this.state.markers} /> */}

                    <div className="map-box scrollbar" id="style-3">
                      {this.__loadOutlets()}
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          <div className="modal ninja-theme-v2 redeem-promocode-modal" style={{ display: "none" }} id="RedeemPromocodeModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header no-padding">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/black-close.png" className="img-responsive" alt="close" />
                  </button>

                  <div className="col-md-12 no-padding">
                    <div className="promo-box">
                      <div className="promo-img-item">
                        <img src={this.loadPromoImage(this.state.currentVoucher.product_thumbnail)} />
                      </div>
                      <div className="promo-btm-section">
                        <span className="promo-text">{this.state.currentVoucher.item_name}</span>
                        <span className="promo-amount">{this.state.currentVoucher.product_price}</span>
                        <span className="promo-expiry-date">Valid till {this.state.currentVoucher.expiry_date}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-body">
                  <div className="promo-code-popup-block">
                    <div className="promo-pup-content">{this.state.currentVoucher.product_long_description !== "" ? htmlToReactParserTo.parse(this.state.currentVoucher.product_long_description) : ""}</div>
                    <div className="col-xs-12 promo-redeem-btn-block">
                      {this.state.Purchased_voucher === false && this.state.currnetDate < this.state.currentVoucher.expiry_date ? (
                        <button onClick={this.addToCart.bind(this, this.state.currentVoucher)} type="button" className="btn btn-raised">
                          Redeem
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="container-fluid desktop-container">
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    }
  }
}
export default Vouchers
