const React = require("react")

const Overlay = (props) => {
  if (props.load === "true") {
    return (
      <div style={{ bottom: 0, left: 0, position: "fixed", zIndex: 2, right: 0, top: 0, margin: "auto" }}>
        {" "}
        <img src="/img/loader.gif" alt="" className="loader_ov" style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} />{" "}
      </div>
    )
  } else {
    return <span></span>
  }
}

export default Overlay
