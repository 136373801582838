import React from "react"
import { loadAnalytics } from "./withTracker"
import cookie from "react-cookie"
import { hashHistory } from "react-router"
import Loading from "./loading"

import config from "./config"
const APPID = config.ids.appId
class Promobuddytracking extends React.Component {
  componentWillMount() {
    const tokenId = this.props.params.tokenId
    if (typeof tokenId !== "undefined" && tokenId !== null && APPID === tokenId) {
      cookie.save("ispromobuddyOrder", "Yes")
      hashHistory.push("/")
    }
  }

  componentDidMount() {
    loadAnalytics()
  }
  render() {
    return (
      <div className="container-fluid desktop-container">
        <div className="row">
          <Loading />
        </div>
      </div>
    )
  }
}
export default Promobuddytracking
