export default {

 /*Common alerts  */
   invalidEmail: 'Please enter valid email address',
   
  /* promo poup */ 
    promopopSuc: 'Success! Please check your email for more information.',
    promopopFail: "Currently you don't have any available coupon code",
	
   /* promotion module */	
	promoRequired: "The Promo code field is Required.",
	promoCodeDeliveryAlert: "This promo code only for delivery orders.",
	promoPaymentText : "Promotion",
	stripeMinPaymentText: "Minimum payment $0.50",
	
	/* points module */
	
	pointsRequired : "Please enter a valid point value",
	
};

