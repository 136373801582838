/* eslint-disable */
import React from "react"
import { Link, hashHistory } from "react-router"
import { loadAnalytics } from "./withTracker"
import cookie from "react-cookie"
import axios from "axios"
import Moment from "react-moment"
import DocumentMeta from "react-document-meta"
import getResult from "./Api"
import Loading from "./loading"

import config from "./config"
import lang from "./lang"
//const APIURL = config.path.apiUrl;
const APIURLPRO = config.path.apiUrlPro
const APPID = config.ids.appId
var currency = config.ids.currency
var helper = require("./Helper.js")
var strp = require("strp")
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParserTo = new HtmlToReactParser()

/* Load meta tags */
const meta = {
  title: lang.orders_meta_title,
  description: lang.orders_meta_title,
  meta: {
    name: {
      keywords: lang.orders_meta_title,
    },
  },
}

class Orders extends React.Component {
  constructor(props) {
    super(props)
    this.state = { orders: [], status: "Loading", pastorders: [], orderscount: 0, pastorderscount: 0, windowHeightNormal: 0, windowHeight: 0 }
    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    /*add class */
    helper.addBodyClass()
    helper.isAnonymousUserRedirect()
    this.isTempUserRedirect()
  }

  isTempUserRedirect() {
    var isTempUser = parseInt(cookie.load("isTempUser"))
    console.log("isTempUser", isTempUser)
    if (isTempUser === 1) {
      cookie.remove("UserId")
      cookie.remove("UserFname")
      cookie.remove("UserLname")
      cookie.remove("UserMobile")
      cookie.remove("UserEmail")
      cookie.remove("userAccountType")
      cookie.remove("isAnonymousUser")
      this.setState({ login: "No" })
      setTimeout(function () {
        cookie.remove("isTempUser")
      }, 240000)

      hashHistory.push("/sign-in")
      helper.showInfo("YOU ARE TRYING TO ACCESS PRIVILAGED INFO , PLEASE USE YOUR PHONE NUMBER AND ATTACHED EMAIL TO ACCESS THIS PAGE.")
    }
  }

  componentWillMount() {
    this.setState({ status: "Loading" })
    /* API CALL FOR CUSTOMER DETAIL cookie.load('UserId')*/
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })
    cookie.remove("gstInclusive")
  }

  componentDidMount() {
    this.showAllOrders()
    loadAnalytics()
  }

  /* show all orders */
  showAllOrders() {
    axios.get(APIURLPRO + "reports/order_history?order_status=C&app_id=" + APPID + "&customer_id=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ orders: res.data.result_set, orderscount: 1 })
        this.setState({ status: "ok" })
      } else {
        this.setState({ orderscount: 1 })
        this.setState({ status: "ok" })
      }
    })

    axios.get(APIURLPRO + "reports/order_history?order_status=P&app_id=" + APPID + "&customer_id=" + cookie.load("UserId")).then((res) => {
      setTimeout(
        function () {
          this.showAllOrders()
        }.bind(this),
        10000
      )
      if (res.data.status === "ok") {
        this.setState({ pastorders: res.data.result_set, pastorderscount: 1 })
      } else {
        this.setState({ pastorderscount: 1 })
      }
    })
  }

  /* show tower information */
  showTowerInfo(data) {
    if (data !== null && data !== "") {
      //return htmlToReactParserTo.parse("Building Name/ Tower : "+data+" <br/>");

      var str = "Building Name/ Tower : " + data + " <br/>"
      var reactElement = htmlToReactParserTo.parse(str)
      return reactElement
    }
  }

  /* show Outlet information */
  getAddressInfo(address1, postlCd, unitOne, unitTwo) {
    var unit = unitTwo !== "" && unitOne !== "" ? "#" + unitOne + "-" + unitTwo + ", " : unitOne !== "" ? "#" + unitOne + ", " : ""
    var addressLineTxt = address1 + ", " + unit + "Singapore " + postlCd
    return addressLineTxt
  }

  loadOrderTime(order) {
    if (order.order_date !== null) {
      var orderDateTime = order.order_date.split(" ")
      var orderDate = orderDateTime[0].split("-")
      var orderTime = orderDateTime[1].split(":")
      if (order.FormatedFrom !== null && order.FormatedTo !== null) {
        return <div>{orderDate[2] + "/" + orderDate[1] + "/" + orderDate[0] + " " + order.FormatedFrom + "-" + order.FormatedTo}</div>
      } else {
        return <div>{orderDate[2] + "/" + orderDate[1] + "/" + orderDate[0] + " " + orderTime[0] + ":" + orderTime[1]}</div>
      }
    }
  }

  __ordersList(orders, paramStr, pmCount) {
    if (pmCount !== 0) {
      if (orders.length === 0) {
        return (
          <div className="col-lg-12 calender-area">
            <div className="col-xs-12 calender-area-sub-2">
              <h1 className="sub-heading" style={{ textAlign: "center" }}>
                No Order found.
              </h1>
            </div>
            <div className="space-30"></div>
          </div>
        )
      } else {
        var htmlStr = orders.map(
          function (order, index) {
            return (
              <div key={index}>
                <div className={index === 0 ? "topp" : "order-b"}>
                  {/* <img src="/img/orders.jpg" className="img-responsive" alt="" /> */}
                  <div className="col-xs-12 padd1 main-outer2 bg-white">
                    <div className="col-xs-12 price-list">
                      <div className="row">
                        <div className="col-sm-3 ">
                          <Link className="btn btn-raised my2-btn btn-success">{order.order_availability_name}</Link>
                        </div>
                        <div className="col-sm-7">
                          <p className="order-date"> {this.loadOrderTime(order)} </p>
                          {order.order_availability_name === "Dine In" && order.order_table_number !== "" && order.order_table_number !== null && (
                            <p>
                              <b>Table Number:</b> {order.order_table_number}
                            </p>
                          )}
                        </div>
                        <div className="col-sm-2">
                          <p className="order-date">
                            <Link to={"/"} style={{ color: "#8f8e8e" }}>
                              VIEW MENU
                            </Link>
                          </p>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-xs-12">
                          <hr />
                        </div>
                      </div>
                      <p className="text-left span-txt1">
                        <b>Order No - {order.order_local_no}</b>{" "}
                      </p>
                      <p className="text-left span-txt1">
                        <b>{order.outlet_name}</b>{" "}
                      </p>
                      {order.order_availability_name === "Pickup" && (
                        <p className="text-left span-txt1">
                          {(() => {
                            if (order.outlet_unit_number1 !== "" && order.outlet_unit_number2 !== "") {
                              return "#" + order.outlet_unit_number1 + "-" + order.outlet_unit_number2 + ", "
                            } else if (order.outlet_unit_number1 !== "" || order.outlet_unit_number2 !== "") {
                              return order.outlet_unit_number1 !== "" ? "#" + order.outlet_unit_number1 : "#" + order.outlet_unit_number2 + ", "
                            } else {
                              return ""
                            }
                          })()}
                          {order.outlet_address_line1 + ", Singapore " + order.outlet_postal_code}
                        </p>
                      )}
                      {this.__orderItemsList(order.items)}
                      <div className="clearfix"></div>
                      <hr />
                      <p className="text-left span-txt1">
                        <b>
                          Total : {currency}
                          {order.order_total_amount}
                        </b>
                      </p>
                      <div className="tab my_tab">
                        <div className="my_tab_left">
                          <Link to={"/"} data-toggle="modal" data-target={"#" + paramStr + "DeliverySucess" + index}>
                            <p className="order-date">VIEW RECEIPT</p>
                          </Link>
                        </div>

                        <div className="tabs my_tab_right">
                          {order.order_status === "5" ? (
                            <div className="btn-group btn-group-justified btn-group-raised tab-group ">
                              <Link className="btn" style={{ float: "right", color: "red" }}>
                                {" "}
                                Cancelled{" "}
                              </Link>
                            </div>
                          ) : (
                            <div className="btn-group btn-group-justified btn-group-raised tab-group ">
                              {order.order_status === "1" || order.order_status === "2" || order.order_status === "3" || order.order_status === "4" ? (
                                <Link className="btn">
                                  <img alt="" className="number-icon" src="/img/icons/1.png" /> Received{" "}
                                </Link>
                              ) : (
                                <Link className="btn disabled" disabled="true">
                                  <img className="number-icon" src="/img/icons/1.png" alt="" /> Received{" "}
                                </Link>
                              )}

                              {order.order_status === "2" || order.order_status === "3" || order.order_status === "4" ? (
                                <Link className="btn">
                                  <img alt="" className="number-icon" src="/img/icons/2.png" /> Preparing{" "}
                                </Link>
                              ) : (
                                <Link className="btn disabled" disabled="true">
                                  <img alt="" className="number-icon" src="/img/icons/2.png" /> Preparing{" "}
                                </Link>
                              )}

                              {order.order_status === "2" || order.order_status === "4" ? (
                                <Link className="btn">
                                  <img className="number-icon" alt="" src="/img/icons/3.png" /> {order.order_availability_name === "Dine In" ? "Ready To Eat" : ""}
                                  {order.order_availability_name === "Delivery" ? "Delivering" : ""}
                                  {order.order_availability_name === "Pickup" ? "Ready to Pickup" : ""}{" "}
                                </Link>
                              ) : (
                                <Link className="btn disabled" disabled="true">
                                  <img className="number-icon" alt="" src="/img/icons/3.png" /> {order.order_availability_name === "Dine In" ? "Ready To Eat" : ""}
                                  {order.order_availability_name === "Delivery" ? "Delivering" : ""}
                                  {order.order_availability_name === "Pickup" ? "Ready to Pickup" : ""}
                                </Link>
                              )}

                              {order.order_status === "4" ? (
                                <Link className="btn">
                                  <img alt="" className="number-icon" src="/img/icons/4.png" /> Completed{" "}
                                </Link>
                              ) : (
                                <Link className="btn disabled" disabled="true">
                                  <img className="number-icon" alt="" src="/img/icons/4.png" /> Completed{" "}
                                </Link>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal" id={paramStr + "DeliverySucess222" + index}>
                    <div className="modal-dialog PopupWidth2 popup_tax_wrapper">
                      <div className="modal-content ">
                        <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                            <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                          </button>
                          <div className="top_tax">
                            <div className="row">
                              <div className="col-xs-6">
                                <p className="span-txt1 text-left pop_text_big">Tax Invoice</p>
                              </div>
                              <div className="col-xs-6 custom_invoice_div">
                                <img src="/img/salad.jpg" />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6">
                                <p className="span-txt1 text-left pop_text_md">Order No - {order.order_local_no}</p>
                                <p className="span-txt1 text-left  pop_text_sm">
                                  Order made at - <Moment format="DD/MM/YYYY,  HH:mm">{order.order_created_on}</Moment>{" "}
                                </p>
                              </div>
                              <div className="col-xs-6 custom_invoice_div">
                                {/*   <p className="span-txt1 pop_text_sm">Business Reg Number : 12-3456789-X</p> */}
                                <p className="span-txt1 pop_text_sm">www.saladbox.com</p>
                              </div>
                              <div className="col-xs-12">
                                {" "}
                                <hr />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6">
                                <p className="span-txt1 text-left pop_text_md ">Deliver To</p>
                                <p className="span-txt1 text-left pop_text_sm ">
                                  {order.order_customer_fname + " " + order.order_customer_lname} (Tel :{order.order_customer_mobile_no})
                                </p>
                              </div>
                              <div className="col-xs-6 custom_invoice_div">
                                <p className="span-txt1 pop_text_md">Outlet</p>
                                <p className="span-txt1 pop_text_sm">{order.outlet_name} </p>
                                {/*  <p className="span-txt1 pop_text_sm">100, SYED ALWI ROAD</p>
						<p className="span-txt1 pop_text_sm">Singapore 207676</p> */}
                              </div>
                            </div>
                            <div className="row btm-15">
                              <div className="col-xs-6">
                                <p className="span-txt1 text-left  pop_text_md">Deliver On</p>
                                <p className="span-txt1 text-left pop_text_sm ">
                                  <Moment format="DD/MM/YYYY,  HH:mm">{order.order_date}</Moment>
                                </p>
                                <p className="span-txt1 text-left  pop_text_sm">{order.order_customer_address_line1}</p>
                                <p className="span-txt1 text-left  pop_text_sm">Singapore {order.order_customer_postal_code}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6">
                                <p className="span-txt1 text-left pop_text_sm">
                                  <span className="pop_text_md">TAT </span>: {order.order_tat_time} Mins
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-body text-center">
                          <div className="wrap-order-details">
                            {/*                        
							<div class="order-r" >
								<p class="span-txt1">Pastamaina Restaurant (Bukit Merah)</span>
									<br>
									<span class="span-size2">Dine In - 07/01/2017,  4.00pm</span>
							</div>
*/}
                            <div className="center-block">
                              <h4 className="modal-title "> Order Details</h4>
                              {/*							<p class="span-size">Order No - CC123456</p>*/}
                              <hr />
                              <div className="clearfix" />
                            </div>
                            <div className="row">
                              <div className="col-xs-8">
                                <p className="span-txt1 text-left ">
                                  1 <small>x</small> &nbsp; Signature Rainbow Shrimp
                                </p>
                                <p className="span-size text-left  ">LARGE</p>
                              </div>
                              <div className="col-xs-4">
                                <p className="span-txt1">$23.00</p>
                              </div>
                              <div className="col-xs-8">
                                <p className="span-txt1 text-left  ">
                                  1 <small>x</small> &nbsp; Potato Salad{" "}
                                </p>
                              </div>
                              <div className="col-xs-4">
                                <p className="span-txt1">$10.00</p>
                              </div>
                              <div className="col-xs-8  ">
                                <p className="span-txt1 text-left">
                                  1 <small>x</small> &nbsp; Aglio Olio
                                </p>
                                <p className="span-size text-left ">REGULAR</p>
                              </div>
                              <div className="col-xs-4">
                                <span className="span-txt1 ">$7.00</span>
                              </div>
                              <div className="col-xs-12">
                                {" "}
                                <hr />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-8">
                                <p className="span-txt1 text-left  ">Subtotal </p>
                              </div>
                              <div className="col-xs-4">
                                <p className="span-txt1">$39.00</p>
                              </div>
                              <div className="col-xs-8  ">
                                <p className="span-txt1 text-left">Delivery Charge</p>
                              </div>
                              <div className="col-xs-4">
                                <p className="span-txt1">$10.00</p>
                              </div>
                              <div className="col-xs-8  ">
                                <p className="span-txt1 text-left">GST(7.00%)</p>
                              </div>
                              <div className="col-xs-4">
                                <p className="span-txt1">$2.90</p>
                              </div>
                              <div className="col-xs-12">
                                {" "}
                                <hr />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-8 ">
                                <p className="span-tot text-left">Grand Total</p>
                              </div>
                              <div className="col-xs-4">
                                <p className="span-tot">$79.00</p>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer btm_tax">
                            <div className="row">
                              <div className="col-xs-6">
                                <p className="span-txt1 text-left  pop_text_sm">
                                  <span className="pop_text_md">Rider </span>: Test Rider /4325435453
                                </p>
                              </div>
                              <div className="col-xs-6 custom_invoice_div">
                                <p className="span-txt1 pop_text_sm">Paid by online payment</p>
                              </div>
                            </div>
                            {/* <div className="btn_pop_center">
                  <button type="button" className="btn btn-raised btn-info">Email Receipt</button>
                </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal" id={paramStr + "DeliverySucess" + index}>
                    <div className="modal-dialog PopupWidth2">
                      <div className="modal-content ">
                        <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                            <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                          </button>
                          <div className="center-block">
                            <h4 className="modal-title"> Order Receipt</h4>
                            <p className="span-size">Order No - {order.order_local_no}</p>
                            <hr width="80%" />
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <div className="modal-body text-center">
                          <div>
                            <div className="order-r">
                              <p className="span-txt1">{order.outlet_name}</p>
                              {order.outlet_receipt_information !== "" && (
                                <p className="span-txt1" style={{ textAlign: "justify" }}>
                                  {htmlToReactParserTo.parse(order.outlet_receipt_information)}
                                </p>
                              )}

                              <span className="span-size2">
                                {order.order_availability_name} - <Moment format="DD/MM/YYYY HH:mm">{order.order_date}</Moment>
                                <br />
                                {this.showTowerInfo(order.order_customer_tower_number)}
                                {
                                  /*(order.order_availability_name==='Delivery'?helper.addressFormat(order.order_customer_unit_no1,order.order_customer_unit_no2,order.order_customer_address_line1,order.order_customer_address_line2,order.order_customer_postal_code):'')*/
                                  order.order_availability_name === "Delivery" ? this.getAddressInfo(order.order_customer_address_line1, order.order_customer_postal_code, order.order_customer_unit_no1, order.order_customer_unit_no2) : ""
                                }
                                {order.order_availability_name === "Dine In" ? order.order_table_number : ""}
                                {order.order_availability_name === "Pickup" ? this.getAddressInfo(order.outlet_address_line1, order.outlet_postal_code, order.outlet_unit_number1, order.outlet_unit_number2) : ""}
                              </span>
                            </div>

                            <div className="row">
                              {this.__ordersubItemsList(order.items)}
                              <div className="col-xs-12">
                                <hr />
                              </div>
                              {this.__showServiceAmount(order)}
                              {order.order_availability_name === "Delivery" ? this.__showDeliveryCharge(order.order_delivery_charge) : ""}
                              {order.order_availability_name === "Delivery" ? this.__showAdditionalDeliveryCharge(order.order_additional_delivery) : ""}
                              {this.__showSurchargeAmount(order.order_subcharge_amount)}
                              {this.__showDiscountAmt(order.order_discount_amount)}
                              {this.__showGSTAmount(order.order_tax_charge, order.order_tax_calculate_amount)}
                              <div className="col-xs-8 ">
                                <p className="span-tot text-left">Total</p>
                              </div>
                              <div className="col-xs-4">
                                <p className="span-tot">
                                  {currency}
                                  {order.order_total_amount}
                                </p>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <br />
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  {/*end */}
                </div>
                <div className="clearfix"></div>
              </div>
            )
          }.bind(this)
        )
        return htmlStr
      }
    }
  }

  __showDeliveryCharge(delivery_charge) {
    return (
      <div>
        <div className="col-xs-8 ">
          <p className="span-tot text-left">Delivery</p>
        </div>
        <div className="col-xs-4">
          <p className="span-tot">
            {currency}
            {delivery_charge}
          </p>
        </div>
      </div>
    )
  }

  __showAdditionalDeliveryCharge(Additnldelivery_charge) {
    if (parseFloat(Additnldelivery_charge) > 0) {
      return (
        <div>
          <div className="col-xs-8 ">
            <p className="span-tot text-left">Additional Surcharge</p>
          </div>
          <div className="col-xs-4">
            <p className="span-tot">
              {currency}
              {Additnldelivery_charge}
            </p>
          </div>
        </div>
      )
    } else {
      return ""
    }
  }

  __showDiscountAmt(DiscountAmt) {
    if (parseFloat(DiscountAmt) > 0) {
      return (
        <div>
          <div className="col-xs-8 ">
            <p className="span-tot text-left">Discount Amount</p>
          </div>
          <div className="col-xs-4">
            <p className="span-tot">
              {currency}
              {DiscountAmt}
            </p>
          </div>
        </div>
      )
    } else {
      return ""
    }
  }

  __showGSTAmount(gstPer, gstAmt) {
    gstPer = parseFloat(gstPer)
    gstAmt = parseFloat(gstAmt)

    if (gstAmt > 0) {
      return (
        <div>
          <div className="col-xs-8 ">
            <p className="span-tot text-left">Gst ({gstPer}%) </p>
          </div>
          <div className="col-xs-4">
            <p className="span-t >ot">
              {currency}
              {gstAmt.toFixed(2)}
            </p>
          </div>
        </div>
      )
    } else {
      return ""
    }
  }

  __showSurchargeAmount(surchargePer) {
    surchargePer = parseFloat(surchargePer)
    if (surchargePer > 0) {
      return (
        <div>
          <div className="col-xs-8 ">
            <p className="span-tot text-left">Surcharge</p>
          </div>
          <div className="col-xs-4">
            <p className="span-t >ot">
              {currency}
              {surchargePer.toFixed(2)}
            </p>
          </div>
        </div>
      )
    } else {
      return ""
    }
  }

  __showServiceAmount(orderData) {
    var gstPer = orderData.order_service_charge
    var gstAmt = orderData.order_service_charge_amount
    gstPer = parseFloat(gstPer)
    gstAmt = parseFloat(gstAmt)
    var ServiceChargeText = orderData.order_servicecharge_displaylabel !== "" && orderData.order_servicecharge_displaylabel !== null ? orderData.order_servicecharge_displaylabel : "Service Charge"
    if (gstAmt > 0) {
      return (
        <div>
          <div className="col-xs-8 ">
            {gstPer > 0 ? (
              <p className="span-tot text-left">
                {ServiceChargeText} ({gstPer}%){" "}
              </p>
            ) : (
              <p className="span-tot text-left">{ServiceChargeText} </p>
            )}
          </div>
          <div className="col-xs-4">
            <p className="span-t >ot">
              {currency}
              {gstAmt.toFixed(2)}
            </p>
          </div>
        </div>
      )
    } else {
      return ""
    }
  }

  __orderItemsList(orderItems) {
    return orderItems.map((orderItem, index) => (
      <p key={index} className="text-left spantxt3">
        <b>{orderItem.item_qty}x </b>
        {strp.stripslashes(orderItem.item_name)}
      </p>
    ))
  }
  __ordersubItemsList(orderItems) {
    return orderItems.map((orderItem, index) => (
      <div key={index} className="col-xs-12">
        <div className="col-xs-8">
          <p className="span-txt1 text-left ">
            {orderItem.item_qty} <small>x</small> &nbsp; {strp.stripslashes(orderItem.item_name)}
          </p>
          {this.loadModifierItems(orderItem.modifiers)}
          {this.loadComboItems(orderItem.set_menu_component)}
        </div>
        <div className="col-xs-4">
          <p className="span-txt1">
            {currency}
            {orderItem.item_total_amount}
          </p>
        </div>
      </div>
    ))
  }

  loadCartImage() {
    return getResult("cartImage", "Yes")
  }

  /* this function used to load modifer items */
  loadModifierItems(modifiers) {
    if (typeof modifiers !== "undefined") {
      var modlen = parseInt(modifiers.length)
      var modifiers = modifiers
      var html = "<ul className='table_list cart_sub_items view-list'   >"
      if (modlen > 0) {
        for (var i = 0, length = modlen; i < length; i++) {
          var modName = modifiers[i]["order_modifier_name"]
          var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"]
          html += "<li className='sub-item'>" + modName + " : " + modval + "</li> "
        }
        html += "</ul>"
        var reactElement = htmlToReactParserTo.parse(html)
        return reactElement
      }
    }
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    //console.log(modifiers);
    var lenMod = modifiers.length
    var html = "<ul className='table_list cart_sub_items combo view-list' style={{textAlign: 'left'}}>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"]
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"]
        var modValPrice = modifiers[i]["modifiers_values"][0]["order_modifier_price"]
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : ""

        html += "<li className='sub-item'>" + modName + ": " + modval + newModValPrice + "</li> "
      }
      html += "</ul>"
      return html
    }

    return ""
  }

  /* this function used to load modifer items */
  loadComboItems(combo) {
    if (typeof combo !== "undefined") {
      var comboLen = parseInt(combo.length)
      var combo = combo
      var html = "<ul className='table_list cart_sub_items mod view-list' style={{textAlign: 'left'}}>"
      if (comboLen > 0) {
        for (var i = 0, length = comboLen; i < length; i++) {
          var comboName = combo[i]["menu_component_name"]
          var comboVal = this.showComboProducts(combo[i]["product_details"])
          html += " <li className='sub-item'><b>" + comboName + " :</b>" + comboVal + " " + this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) + "</li> "
        }
        html += "</ul>"
        var reactElement = htmlToReactParserTo.parse(html)
        return reactElement
      }
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = " "
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"]
        comboPro = comboPro !== "" ? strp.stripslashes(comboPro) : comboPro
        var comboQty = combos[r]["menu_product_qty"]
        var comboPrice = combos[r]["menu_product_price"]
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : ""
        html += "<li className='sub-item'>" + comboQty + " X " + comboPro + newPrice + " </li> "
      }
      return html
    }
    return ""
  }

  render() {
    if (this.state.status === "Loading") {
      return (
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row"></div>
          <div className="row">
            <div className="space-10 bg-gray"></div>
          </div>
          <div className=" ">
            <div className="col-xs-10 div-center">
              <div className="space-5"></div>
              <Loading />
              <div className="space-5"></div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-5"></div>
          <div className="space-5"></div>
          <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div>
        </div>
      )
    } else {
      return (
        <div>
          <DocumentMeta {...meta} />
          <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20  head-child">
                  {" "}
                  <Link to={"/my-account"}>
                    <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                  </Link>
                </div>
                <div className="col-xs-6 nav-head   head-child">
                  <h1 className="main-title">MY ORDERS</h1>
                </div>
                <div className="col-xs-3 nopadding   head-child">
                  <p className="b-txt text-right ">
                    <Link to={"/cart"} className={helper.cartClass()}>
                      {helper.cartCount()} <img alt="" src={this.loadCartImage()} className="icon-img-small-1" />{" "}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="space-10"></div>
            <div className="row">
              <div className="line">
                <div className="item-add5">
                  <ul className="orders-tabs ">
                    <li className="active">
                      <div className="col-xs-6 order-m-l">
                        <a href="#current_orders" data-toggle="tab">
                          <span className="text-1-b">CURRENT ORDERS</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="col-xs-6 order-m-r">
                        <a href="#past_orders" data-toggle="tab">
                          <span className="text-1-b">PAST ORDERS</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br />

            <div className="tab-content clearfix">
              <div className="tab-pane active" id="current_orders">
                <div className="row  map-loc">{this.__ordersList(this.state.orders, "current", this.state.orderscount)}</div>
              </div>

              <div className="tab-pane" id="past_orders">
                <div className="row map-loc">{this.__ordersList(this.state.pastorders, "past", this.state.pastorderscount)}</div>
              </div>
              <div className="space-50"></div>
            </div>
          </div>
        </div>
      )
    }
  }
}
export default Orders
