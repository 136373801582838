import React from "react"
import { Link, hashHistory } from "react-router"
import update from "immutability-helper"
import cookie from "react-cookie"
import { loadAnalytics } from "./withTracker"
import { validated } from "react-custom-validation"
import validator from "validator"
import DocumentMeta from "react-document-meta"
import axios from "axios"
import Loading from "./loading"
import Alert from "./alert"

import lang from "./lang"
import config from "./config"
const APPID = config.ids.appId
const APIURL = config.path.apiUrl
//import cookie from 'react-cookie';
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParserTo = new HtmlToReactParser()
var helper = require("./Helper.js")

const initialState = {
  fields: {
    name: "",
    status: "Loading",
    mobile: "",
    email: "",
  },
}
/* Load meta tags */
const meta = {
  title: lang.contactus_meta_title,
  description: lang.contactus_meta_title,
  meta: {
    name: {
      keywords: lang.contactus_meta_title,
    },
  },
}

class Contactus extends React.Component {
  state = {
    fields: {
      name: "",
      mobile: "",
      email: "",
    },
  }

  setStateLoading(sts) {
    this.setState({ status: sts })
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
  }

  handleFormSubmit = () => {
    const formPayload = this.state.fields
    formPayload.feedback = document.getElementById("feedback").value

    if (formPayload.feedback === "") {
      document.getElementById("spn-feedback-error").innerHTML = '<span class="spn-error">This field is required.</span>'
      return false
    } else {
      document.getElementById("spn-feedback-error").innerHTML = ""
    }
    var qs = require("qs")
    var postObject = {
      app_id: APPID,
      customer_first_name: formPayload.name,
      customer_emailaddress: formPayload.email,
      customer_phonenumber: formPayload.mobile,
      customer_message: formPayload.feedback,
      subject: "Feedback From customer",
    }
    this.setStateLoading("Loading")

    axios
      .post(APIURL + "contactus/contact_us", qs.stringify(postObject))
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState(initialState)
          helper.showInfo("Thanks for your feedback")
          this.setStateLoading("ok")
        } else {
          this.setStateLoading("error")
          this.setState(initialState)
          helper.showInfo("Something went wrong")
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  componentDidMount() {
    var site_maintenance_verify = cookie.load("client_site_maintenance_verify") !== "" && cookie.load("client_site_maintenance_verify") !== "undefined" && cookie.load("client_site_maintenance_verify") !== undefined ? cookie.load("client_site_maintenance_verify") : ""

    var site_maintenance_popup_open = cookie.load("site_maintenance_popup_open") !== "" && cookie.load("site_maintenance_popup_open") !== "undefined" && cookie.load("site_maintenance_popup_open") !== undefined ? cookie.load("site_maintenance_popup_open") : ""

    if (site_maintenance_popup_open === "1" && (site_maintenance_verify === "" || site_maintenance_verify === "0")) {
      hashHistory.push("/")
      return
    }

    loadAnalytics()
  }
  render() {
    if (this.state.status === "Loading") {
      return <Loading />
    } else {
      return <Form fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleFormSubmit} onInvalid={() => console.log("Form invalid!")} />
    }
  }
}
const phonenumberPattern = /^[0-9]{6,14}$/
const isEmpty = (value) => (value === "" ? "This field is required." : null)

const isMobile = (mobile) => (mobile.match(phonenumberPattern) ? null : "This is not a valid mobile number.")

const isEmail = (email) => (validator.isEmail(email) ? null : "This is not a valid email.")

function validationConfig(props) {
  const { name, mobile, email } = props.fields

  return {
    fields: ["name", "mobile", "email"],

    validations: {
      name: [[isEmpty, name]],
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      email: [[isEmpty, email, isEmail]],
    },
  }
}

class Form extends React.Component {
  state = {
    pageTitle: "",
    pageDesc: "",
    windowHeightNormal: 0,
    windowHeight: 0,
  }

  componentWillMount(props) {
    /*add class */
    helper.addBodyClass()

    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })

    axios.get(APIURL + "cms/page?status=A&app_id=" + APPID + "&page_slug=contact-us").then((res) => {
      if (res.data.status === "ok") {
        //document.getElementById("header").innerHTML = res.data.result_set[0].cmspage_title;
        //document.getElementById("textDesc").innerHTML = res.data.result_set[0].cmspage_description;
        this.setState({ pageTitle: res.data.result_set[0].cmspage_title })
        this.setState({ pageDesc: res.data.result_set[0].cmspage_description })
      }
    })
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
    let errMsgFirstName, errMsgEmail, errMsgMobile

    if ($validation.name.error.reason !== undefined) {
      errMsgFirstName = $validation.name.show && <span className="spn-error">{$validation.name.error.reason}</span>
    }

    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && <span className="spn-error">{$validation.mobile.error.reason}</span>
    }

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && <span className="spn-error">{$validation.email.error.reason}</span>
    }

    return (
      <div>
        <DocumentMeta {...meta} />
        <Alert />
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <div className="col-xs-12 main-title-bar">
              <Link to={"/"}>
                {" "}
                <img src="/img/left-arrow.png" className="pull-left icon-img-small" alt="left-arrow" />{" "}
              </Link>
              <h3 className="top-title main-title padding-right-20 text-uppercase" id="header">
                {this.state.pageTitle}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="space-10 bg-gray"></div>
          </div>
          <div className="white-outer center-block">
            <div className="col-xs-10 div-center">
              <div className="space-20"></div>
              <div id="textDesc"> {htmlToReactParserTo.parse(this.state.pageDesc)}</div>
              <div className="space-5"></div>
              <div className="form-group label-floating is-empty card-no">
                <div className="input-group">
                  <label className="control-label">Name</label>
                  <input type="text" className="form-control" value={fields.name} {...$field("name", (e) => onChange("name", e.target.value))} />
                  {errMsgFirstName}
                </div>
              </div>
              <div className="form-group label-floating is-empty card-no">
                <div className="input-group">
                  <label className="control-label">Contact Number</label>
                  <input type="text" className="form-control" value={fields.mobile} {...$field("mobile", (e) => onChange("mobile", e.target.value))} />
                  {errMsgMobile}
                </div>
              </div>
              <div className="form-group label-floating is-empty card-no">
                <div className="input-group">
                  <label className="control-label">Email</label>
                  <input type="text" className="form-control" value={fields.email} {...$field("email", (e) => onChange("email", e.target.value))} />
                  {errMsgEmail}
                </div>
              </div>
              <div className="form-group label-floating is-empty card-no">
                <div className="input-group">
                  <label className="control-label">Your Feedback</label>
                  <textarea className="form-control" id="feedback"></textarea>
                  <span id="spn-feedback-error"></span>
                </div>
              </div>
              <div className="text-center ">
                <button
                  type="button"
                  className="btn btn-raised btn-info full-width-btn text-uppercase"
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.$submit(onValid, onInvalid)
                  }}
                >
                  Send<div className="ripple-container"></div>
                </button>
                <br />
              </div>
              <div className="space-20"></div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    )
  }
}
Form = validated(validationConfig)(Form)

export default Contactus
